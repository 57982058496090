import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import packageJson from '../package.json';
import Api from './api/api';
import Maintenance from './components/maintenance';
import NavigationBar from './components/NavigationBar/navbar';
import PurchaseThisSubdomain from './components/Pricing/purchase-this-subdomain';
import LoginDialog from './components/Registration/login-dialog';
import SuspendedRoutesMap from './components/Subdomain/suspended-routes-map';
import Constants from './constants';
import CookieHelper from './lib/cookie-helper';
import { useQuery } from './lib/uri-helper';
import Analytics from './lib/user-analytics';
import Routes from './routes';
import RoutesMobile from './routes-mobile';
import { actionShowTutorial, actionUpdateConfig } from './store/App/actions';
import { selectConfig, selectMaintenance } from './store/App/selectors';
import { actionGetNotifications, actionIsNotificationsOpen } from './store/Notifications/actions';
import UserHelper from './lib/user-helper';
import { actionGetMyProfile, actionLogoutUser } from './store/Account/actions';
import { selectMyProfile } from './store/Account/selectors';
import SoarHelper, { isMobileVersion } from './lib/soar-helper';
import { actionFetchLikedMaps } from './store/Map/Favourites/actions';
import { Permission } from './api/model';
import { actionGetDomainUsers, actionGetSubdomain, actionGetSubdomainListings } from './store/Dashboard/actions';

const ConfiguredRoot = () => {
    const dispatch = useDispatch();
    const config = useSelector(selectConfig);
    const maintenance = useSelector(selectMaintenance);
    const myProfile = useSelector(selectMyProfile);
    const isLoggedIn = UserHelper.isLoggedIn();

    const query = useQuery();

    useEffect(() => {
        const trackerCode = query.get('t');
        if (trackerCode) {
            CookieHelper.setTrackerCode(trackerCode);
        }
    }, [query]);

    useEffect(() => {
        if (isLoggedIn && !myProfile) {
            dispatch(actionGetMyProfile());
        } else if (!isLoggedIn && myProfile) {
            dispatch(actionLogoutUser());
        } else if (myProfile) {
            if (SoarHelper.isSoar()) {
                dispatch(actionFetchLikedMaps());
            } else if (UserHelper.getSubdomainPermission(myProfile) !== Permission.None) {
                dispatch(actionGetSubdomain());
                dispatch(actionGetSubdomainListings());
                dispatch(actionGetDomainUsers());
            }
            dispatch(actionGetNotifications());
        }

        Analytics.setIsLoggedIn(isLoggedIn);
    }, [isLoggedIn, myProfile, dispatch]);

    useEffect(() => {
        ReactGA.initialize(Constants.GOOGLE_ANALYTICS_ID_G4);

        const fetchNotificationsOnBrowserView = () => {
            if (document.visibilityState === 'visible' && UserHelper.isLoggedIn()) {
                // When the user has left the tab and comes back check for new notifications
                dispatch(actionIsNotificationsOpen(true));
                dispatch(actionGetNotifications());
            }
        };

        dispatch(actionUpdateConfig());

        Analytics.setDeviceDimension();

        console.log('Soar Platform v' + packageJson.version);
        console.log('React stage: ' + process.env.REACT_APP_STAGE);
        console.log('React environment: ' + process.env.REACT_APP_ENVIRONMENT);
        console.log('Subdomain: ' + Api.getSubdomain());
        console.log('Root API: ' + Constants.API_URL);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (CookieHelper.isFirstTime() && !(window as any).SOAR_PRERENDER) {
            dispatch(actionShowTutorial());
        }

        document.addEventListener('visibilitychange', () => fetchNotificationsOnBrowserView());

        return () => {
            document.removeEventListener('visibilitychange', () => fetchNotificationsOnBrowserView());
        };
    }, [dispatch]);

    if (maintenance) {
        return <Maintenance />;
    }

    if (config.IS_AVAILABLE_FOR_PURCHASE) {
        return <PurchaseThisSubdomain />;
    }

    if (config.STATUS === 'SUSPENDED') {
        return <SuspendedRoutesMap />;
    }

    if (config.IS_LOADING_CONFIG) {
        return <React.Fragment />; // TODO:  Loading spinner?
    } else {
        return (
            <React.Fragment>
                {!isMobileVersion ? (
                    <React.Fragment>
                        <NavigationBar />
                        <ToastContainer className="toast-container" autoClose={3000} />
                        <LoginDialog />
                        <Routes />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <ToastContainer autoClose={3000} />
                        <LoginDialog />
                        <RoutesMobile />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
};

export default ConfiguredRoot;
