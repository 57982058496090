import L from 'leaflet';
import { ANNOTATION_TOOL_BOUNDS_CHANGED_EVENT } from './rectangle-annotation';
import Rectangle from './rectangle';
import AnnotationPopup from '../annotation-popup';
import ColorSwatch from '../Popup/color-swatch';
import OpacitySlider from '../Popup/opacity-slider';
import LineStylePopup from '../Popup/line-style-popup';
import LineStyle from '../Popup/line-style';

interface RectangleAnnotationPopupProps {
    rectangle: Rectangle;
    onDeleteRectangle: () => void;
    onUpdateRectangle: (options: L.PolylineOptions) => void;
}

const RectangleAnnotationPopup = (props: RectangleAnnotationPopupProps) => {
    return (
        <AnnotationPopup
            title="Rectangle"
            keepInView={true}
            closeButton={false}
            autoClose={false}
            closeOnClick={false}
            onDelete={props.onDeleteRectangle}
            eventHandlers={{
                add: (e: L.LeafletEvent) => {
                    const sourceRectangle = e.target._source as L.Rectangle;
                    const sourceTarget = e.sourceTarget;
                    sourceRectangle.addEventListener(ANNOTATION_TOOL_BOUNDS_CHANGED_EVENT, (e) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const bounds = (e as any).boundingBox;
                        const updatedLatLng = new L.LatLng(
                            bounds.getNorth(),
                            (bounds.getEast() + bounds.getWest()) / 2
                        );
                        requestAnimationFrame(() => {
                            sourceTarget.setLatLng(updatedLatLng);
                        });
                    });
                    const bounds = props.rectangle.boundingBox;
                    const popupPosition = new L.LatLng(bounds.getNorth(), (bounds.getEast() + bounds.getWest()) / 2);
                    requestAnimationFrame(() => {
                        sourceTarget.setLatLng(popupPosition);
                    });
                },
            }}
        >
            <ColorSwatch
                selectedColor={props.rectangle.options.color || '#3388ff'}
                onSelectColor={(color) => {
                    props.onUpdateRectangle({
                        ...props.rectangle.options,
                        color: color,
                        fillColor: color,
                    });
                }}
            />

            <OpacitySlider
                color={props.rectangle.options.color || '#3388ff'}
                opacity={props.rectangle.options.fillOpacity || 0.2}
                onSelectOpacity={(opacity) => {
                    props.onUpdateRectangle({
                        ...props.rectangle.options,
                        fill: opacity === 0 ? false : true,
                        fillOpacity: opacity,
                    });
                }}
            />

            <LineStyle
                selectedLineStyle={props.rectangle.options}
                onSelectLineStyle={(lineStyle) => {
                    props.onUpdateRectangle({
                        ...props.rectangle.options,
                        dashArray: lineStyle.dashArray ? lineStyle.dashArray : undefined,
                        weight: lineStyle.weight ? lineStyle.weight : undefined,
                    });
                }}
            />
        </AnnotationPopup>
    );
};

export default RectangleAnnotationPopup;
