import L from 'leaflet';

import {
    createContainerComponent,
    createElementHook,
    createElementObject,
    createPathHook,
    extendContext,
    LeafletContextInterface,
    LeafletElement,
    PathProps,
} from '@react-leaflet/core';

import Path, { ghostPathOptions } from './path';

interface PathAnnotationProps extends L.PathOptions {
    path: Path;
    children: React.ReactNode;
}

const createPathGhost = (
    path: L.Polyline<GeoJSON.LineString | GeoJSON.MultiLineString>,
    context: LeafletContextInterface
) => {
    const pathGhost = new L.Polyline(path.getLatLngs() as L.LatLng[], ghostPathOptions);
    const pathGhostElement = createElementObject<L.Polyline, PathProps>(pathGhost, context);
    context.map.addLayer(pathGhostElement.instance);

    path.on('mouseover', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
        const currentWeight = path.options.weight || 1;
        path.setStyle({ weight: currentWeight + 3 });
    });

    path.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-interactive');

        const originalWeight = path.options.weight || 4;
        path.setStyle({ weight: originalWeight - 3 });
    });

    pathGhostElement.instance.on('remove', () => {
        const originalWeight = path.options.weight || 4;
        path.setStyle({ weight: originalWeight - 3 });
    });

    pathGhostElement.instance.on('mouseover', (e: L.LeafletMouseEvent) => {
        path.fire('mouseover', e);
    });

    pathGhostElement.instance.on('mouseout', (e: L.LeafletMouseEvent) => {
        path.fire('mouseout', e);
    });

    pathGhostElement.instance.on('click', (e: L.LeafletMouseEvent) => {
        path.fireEvent('click', e);
    });

    return pathGhostElement;
};

const createNodeMarkers = (
    path: L.Polyline<GeoJSON.LineString | GeoJSON.MultiLineString>,
    context: LeafletContextInterface
): LeafletElement<L.Marker, LeafletContextInterface>[] => {
    const nodeMarkers = (path.getLatLngs() as L.LatLng[]).map((position, index) => {
        const dotMarkerIcon = new L.DivIcon({
            iconSize: [12.0, 12.0],
            iconAnchor: [6.0, 6.0],
            className: 'annotation-node-dot-marker',
        });

        const marker = new L.Marker(position, { interactive: true, draggable: true, icon: dotMarkerIcon });
        const markerElement = createElementObject<L.Marker>(marker, context);

        markerElement.instance.on('drag', (e: L.LeafletMouseEvent) => {
            path.setLatLngs(path.getLatLngs().map((latLng, i) => (i === index ? e.latlng : latLng)));
        });

        context.map.addLayer(markerElement.instance);
        return markerElement;
    });

    return nodeMarkers;
};

const createPathAnnotation = (props: PathAnnotationProps, context: LeafletContextInterface) => {
    const path = new L.Polyline(props.path.positions, props.path.options);
    const pathElement = createElementObject<L.Polyline, PathProps>(
        path,
        extendContext(context, { overlayContainer: path })
    );

    let nodeMarkers: LeafletElement<L.Marker, LeafletContextInterface>[] = [];

    const pathGhostElement = createPathGhost(pathElement.instance, context);

    pathElement.instance.on('remove', () => {
        context.map.removeLayer(pathGhostElement.instance);
        nodeMarkers.forEach((nodeMarker) => {
            context.map.removeLayer(nodeMarker.instance);
        });
    });

    pathElement.instance.on('click', () => {
        nodeMarkers = createNodeMarkers(pathElement.instance, context);
        nodeMarkers.forEach((nodeMarker) => {
            context.map.addLayer(nodeMarker.instance);
        });

        context.map.on('click', () => {
            nodeMarkers.forEach((nodeMarker) => {
                context.map.removeLayer(nodeMarker.instance);
            });
        });
    });

    pathElement.instance.on('remove', () => {
        nodeMarkers.forEach((nodeMarker) => {
            context.map.removeLayer(nodeMarker.instance);
        });
    });

    return pathElement;
};

const updatePathAnnotation = (instance: L.Polyline, props: PathAnnotationProps, _: PathAnnotationProps) => {
    instance.setStyle({ ...instance.options, ...props.path.options });
};

const usePathAnnotation = createElementHook<L.Polyline, PathAnnotationProps, LeafletContextInterface>(
    createPathAnnotation,
    updatePathAnnotation
);

const usePath = createPathHook<L.Polyline, PathAnnotationProps>(usePathAnnotation);

const PathAnnotation = createContainerComponent(usePath);

export default PathAnnotation;
