import React from 'react';
import styled from 'styled-components';
import { RgbaColorPicker, RgbaColor } from 'react-colorful';

interface OpacitySliderProps {
    color: string;
    opacity: number;
    onSelectOpacity: (opacity: number) => void;
}

const OpacitySlider = (props: OpacitySliderProps) => {
    const colorToRGBA = (color: string, opacity: number): RgbaColor => {
        const rgba: RgbaColor = {
            r: parseInt(color.slice(1, 3), 16),
            g: parseInt(color.slice(3, 5), 16),
            b: parseInt(color.slice(5, 7), 16),
            a: opacity,
        };
        return rgba;
    };

    return (
        <Container>
            <Text>Fill Intensity</Text>
            <SliderOnlyRbgaColorPicker
                color={colorToRGBA(props.color, props.opacity)}
                onChange={(newColor) => {
                    props.onSelectOpacity(Math.max(newColor.a, 0.000001));
                }}
            />
        </Container>
    );
};

export default OpacitySlider;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 8px;
`;

const Text = styled.span`
    color: white;
    font-size: 1rem;
    margin-right: 12px;
`;

const SliderOnlyRbgaColorPicker = styled(RgbaColorPicker)`
    width: 200px !important;
    margin-top: 5px;
    height: auto !important;

    .react-colorful__saturation {
        display: none;
    }

    .react-colorful__hue {
        display: none;
    }

    .react-colorful__alpha {
        border-radius: 12px;
        height: 12px;
    }

    .react-colorful__saturation-pointer {
    }
    .react-colorful__hue-pointer,
    .react-colorful__alpha-pointer {
        width: 17px;
        height: 17px;
        border: none;
        cursor: pointer;
    }
`;
