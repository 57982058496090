import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled, { keyframes } from 'styled-components';
import ApiSocial from '../../../api/api-social';
import Analytics from '../../../lib/user-analytics';
import { actionFetchBookmarkedMaps } from '../../../store/Map/BookMarks/actions';
import { selectBookmarkedMaps } from '../../../store/Map/BookMarks/selectors';
import { selectLoggedIn } from '../../../store/Account/selectors';
import { LoginModalMode } from '../../Registration/login-enum';
import LoginRegisterDialog from '../../Registration/login-register-dialog';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../Shared/soar-modal';
import { StyledDarkButton, StyledDeleteButton } from '../../Shared/styled-button';
import { ShareLinkIcon } from './Sharing/share-link';

interface ActiveMapBookmarkProps {
    mapId: number;
}

const ActiveMapBookmark = (props: ActiveMapBookmarkProps) => {
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [isRemovingBookmark, setIsRemovingBookmark] = useState<boolean>(false);
    const [disableBookmark, setDisableBookmark] = useState<boolean>(false);

    const isLoggedIn = useSelector(selectLoggedIn);

    const dispatch = useDispatch();
    const bookmarkedMaps = useSelector(selectBookmarkedMaps);

    const isBookMarked = bookmarkedMaps?.find((t) => t.id === props.mapId) ? true : false;

    const handleRemoveBookmark = useCallback(async () => {
        Analytics.Event('SideDraw - Bookmark', 'Clicked Remove Bookmark', props.mapId);
        await ApiSocial.removeBookmarkListing(props.mapId);
        setIsRemovingBookmark(false);
        setDisableBookmark(false);
        dispatch(actionFetchBookmarkedMaps());
    }, [dispatch, props.mapId]);

    const handleClickedBookmark = async () => {
        if (isLoggedIn) {
            setDisableBookmark(true);
            try {
                if (isBookMarked) {
                    setIsRemovingBookmark(true);
                } else {
                    Analytics.Event('SideDraw - Bookmark', 'Clicked Add Bookmark', props.mapId);
                    await ApiSocial.addBookmarkListing(props.mapId);
                    dispatch(actionFetchBookmarkedMaps());
                }
            } catch (err) {
                toast.error(err.message);
            } finally {
                setDisableBookmark(false);
            }
        } else {
            setShowLogin(true);
        }
    };

    useEffect(() => {
        if (!bookmarkedMaps && isLoggedIn) {
            dispatch(actionFetchBookmarkedMaps());
        }
    }, [bookmarkedMaps, dispatch, isLoggedIn]);

    return (
        <React.Fragment>
            <BookmarkIcon
                title="Bookmark Map"
                onClick={handleClickedBookmark}
                isBookmarked={isBookMarked}
                disableBookmark={disableBookmark}
                className={isBookMarked ? 'fa fa-bookmark bookmarked' : 'fa fa-bookmark-o'}
            />
            {showLogin && (
                <LoginRegisterDialog
                    isOpen={true}
                    initialMode={LoginModalMode.LOGIN}
                    onClose={() => setShowLogin(false)}
                />
            )}
            {isRemovingBookmark && (
                <SoarModal
                    title="Remove Bookmark"
                    isOpen={isRemovingBookmark}
                    toggle={() => setIsRemovingBookmark(false)}
                    width="600px"
                >
                    <StyledModalBody>You are about to remove a bookmark</StyledModalBody>
                    <StyledModalFooter>
                        <StyledDarkButton onClick={() => setIsRemovingBookmark(false)}>CANCEL</StyledDarkButton>
                        <StyledDeleteButton onClick={() => handleRemoveBookmark()}>REMOVE</StyledDeleteButton>
                    </StyledModalFooter>
                </SoarModal>
            )}
        </React.Fragment>
    );
};

export default ActiveMapBookmark;

interface BookmarkIconProps {
    isBookmarked: boolean;
    disableBookmark: boolean;
}

const BookmarkAnimation = keyframes`
  0%, 10%, 43%, 70%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 0.3);
    transform: translate3d(0, 0, 0);
  }
  30%, 33% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -10px, 0);
  }
  60% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    transform: translate3d(0, -5px, 0);
  }
  80% {
    transform: translate3d(0, -2px, 0);
  }
`;

const BookmarkIcon = styled(ShareLinkIcon)<BookmarkIconProps>`
    color: ${(props) => (props.isBookmarked ? '#EED926' : 'white')};
    font-size: 18px;
    pointer-events: ${(props) => (props.disableBookmark ? 'none' : '')};

    &.bookmarked {
        animation: ${BookmarkAnimation} 0.75s;
        animation-iteration-count: 1;
    }
`;
