import L, { LeafletMouseEvent } from 'leaflet';
import {
    LeafletContextInterface,
    createElementHook,
    createElementObject,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import { v4 as uuidv4 } from 'uuid';

import Circle, { defaultCircleOptions } from './circle';

interface CircleBuilderProps {
    createOptions: L.CircleOptions;
    onCreateCircle: (circle: Circle) => void;
}

const createCircleBuilder = (props: CircleBuilderProps, context: LeafletContextInterface) => {
    let startLatLng = new L.LatLng(0, 0);

    const circle = new L.Circle(startLatLng, 0, { ...props.createOptions, ...defaultCircleOptions });
    const circleElement = createElementObject<L.Circle, CircleBuilderProps>(circle, context);

    circleElement.instance.on('add', () => {
        context.map.dragging.disable();
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.on('mousedown', (e: LeafletMouseEvent) => {
            startLatLng = e.latlng;
            circleElement.instance.setLatLng(e.latlng);

            context.map.on('mousemove', (e: LeafletMouseEvent) => {
                const radius = context.map.distance(startLatLng, e.latlng);
                circleElement.instance.setRadius(radius);
            });

            context.map.on('mouseup', () => {
                L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

                context.map.off('mousemove');
                context.map.off('mousedown');
                context.map.off('mouseup');
                context.map.dragging.enable();

                const circle: Circle = {
                    id: uuidv4(),
                    center: circleElement.instance.getLatLng(),
                    radius: circleElement.instance.getRadius(),
                    options: circleElement.instance.options as L.CircleOptions,
                };

                props.onCreateCircle(circle);
            });
        });
    });

    return circleElement;
};

const useCircleBuilder = createElementHook<L.Circle, CircleBuilderProps, LeafletContextInterface>(createCircleBuilder);

const CircleBuilder = (props: CircleBuilderProps) => {
    const context = useLeafletContext();
    const circleBuilder = useCircleBuilder(props, context);
    useLayerLifecycle(circleBuilder.current, context);
    return null;
};

export default CircleBuilder;
