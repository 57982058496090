import React, { useState } from 'react';
import { Circle as LeafletCircle } from 'react-leaflet';
import AnnotationControl from '../annotation-control';
import Circle from './circle';
import CircleAnnotation from './circle-annotation';
import CircleAnnotationPopup from './circle-annotation-popup';
import CircleBuilder from './circle-builder';

interface CircleControlProps {
    hideControl: boolean;
}

const CircleControl = (props: CircleControlProps) => {
    const [isCreatingCircle, setIsCreatingCircle] = useState(false);
    const [circles, setCircles] = useState<Circle[]>([]);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="middle"
                hideControl={props.hideControl}
                imageUrl="/assets/annotations/icon-circle.svg"
                name="Circle"
                onClick={() => setIsCreatingCircle(true)}
            />

            {isCreatingCircle && (
                <CircleBuilder
                    onCreateCircle={(circle: Circle) => {
                        setIsCreatingCircle(false);
                        setCircles([...circles, circle]);
                    }}
                    createOptions={{
                        radius: 0,
                    }}
                />
            )}

            {props.hideControl &&
                circles.map((circle) => {
                    return (
                        <LeafletCircle
                            key={circle.id}
                            center={circle.center}
                            radius={circle.radius}
                            color={circle.options.color}
                            fill={circle.options.fill}
                            fillOpacity={circle.options.fillOpacity}
                            opacity={circle.options.opacity}
                            weight={circle.options.weight}
                            interactive={false}
                        />
                    );
                })}

            {!props.hideControl &&
                circles.map((circle) => {
                    return (
                        <CircleAnnotation
                            key={circle.id}
                            circle={circle}
                            radius={circle.radius}
                            editMode={!props.hideControl}
                            onUpdateCircle={(circle) => {
                                setCircles((current) => {
                                    const index = current.findIndex((c) => c.id === circle.id);
                                    const newCircles = [...current];
                                    newCircles[index] = circle;
                                    return newCircles;
                                });
                            }}
                        >
                            <CircleAnnotationPopup
                                circle={circle}
                                onDelete={() => {
                                    setCircles((current) => {
                                        return current.filter((c) => c.id !== circle.id);
                                    });
                                }}
                                onUpdate={(circleOptions) => {
                                    setCircles((current) => {
                                        const index = current.findIndex((c) => c.id === circle.id);
                                        const newCircles = [...current];
                                        newCircles[index].options = circleOptions;
                                        return newCircles;
                                    });
                                }}
                            />
                        </CircleAnnotation>
                    );
                })}
        </React.Fragment>
    );
};

export default CircleControl;
