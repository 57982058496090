import L from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';

import { defaultPathOptions, PolygonPath, PolylinePath } from './path';
import { v4 as uuidv4 } from 'uuid';
import './path-node-marker-icon.css';

interface PathBuilderProps {
    onCreatePolyline: (polyline: PolylinePath) => void;
    onCreatePolygon: (polygon: PolygonPath) => void;
}

const createPathBuilder = (props: PathBuilderProps, context: LeafletContextInterface) => {
    const path = new L.Polyline([], defaultPathOptions);
    const pathElement = createElementObject<L.Polyline, PathBuilderProps>(path, context);

    const hologramPath = new L.Polyline([], { dashArray: '5, 5', interactive: false });
    const hologramPathElement = createElementObject<L.Polyline, PathBuilderProps>(hologramPath, context);
    context.map.addLayer(hologramPathElement.instance);

    const nodeMarkers: L.Marker[] = [];
    const tooltip = new L.Tooltip(new L.LatLng(0, 0), { direction: 'top' });

    pathElement.instance.on('remove', () => {
        nodeMarkers.forEach((marker) => {
            context.map.removeLayer(marker);
        });

        hologramPathElement.instance.remove();
        context.map.off('mousemove', onMouseMove);
        context.map.off('click', onMouseClick);
        context.map.dragging.enable();
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
    });

    const addPositionToPath = (position: L.LatLng) => {
        const positions = [...(pathElement.instance.getLatLngs() as L.LatLng[]), position];
        pathElement.instance.setLatLngs(positions);
    };

    const updateHologramPath = (position: L.LatLng) => {
        const lastPosition = pathElement.instance.getLatLngs()[
            pathElement.instance.getLatLngs().length - 1
        ] as L.LatLng;
        if (lastPosition) {
            const positions = [lastPosition, position];
            hologramPathElement.instance.setLatLngs(positions);
        }
    };

    const updateNodeMarkers = () => {
        const positions = pathElement.instance.getLatLngs() as L.LatLng[];
        const position = positions.length === 1 ? positions[0] : positions[positions.length - 1];

        const dotMarkerIcon = new L.DivIcon({
            iconSize: [12.0, 12.0],
            iconAnchor: [6.0, 6.0],
            className: 'annotation-node-dot-marker',
        });

        const marker = new L.Marker(position, { interactive: true, icon: dotMarkerIcon });

        marker.on('mouseover', (e: L.LeafletMouseEvent) => {
            const index = nodeMarkers.findIndex((m) => m.getLatLng() === e.latlng);
            if (index === 0) {
                if (!tooltip.isOpen()) {
                    tooltip.setLatLng(e.latlng);
                    tooltip.setContent('Click to close the polygon');
                    tooltip.openOn(context.map);
                }

                L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
                L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
            } else if (index === nodeMarkers.length - 1) {
                if (!tooltip.isOpen()) {
                    tooltip.setLatLng(e.latlng);
                    tooltip.setContent('Click to close the polyline');
                    tooltip.openOn(context.map);
                }

                L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
                L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
            }
        });

        marker.on('mouseout', (_: L.LeafletMouseEvent) => {
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-interactive');
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
            if (tooltip.isOpen()) {
                tooltip.removeFrom(context.map);
            }
        });

        marker.on('click', (e: L.LeafletMouseEvent) => {
            const index = nodeMarkers.findIndex((marker) => marker.getLatLng() === e.latlng);
            if (index === 0) {
                const polygonPositions = [...(pathElement.instance.getLatLngs() as L.LatLng[]), positions[0]];
                const polygon: PolygonPath = {
                    id: uuidv4(),
                    positions: polygonPositions,
                    options: { ...pathElement.instance.options, fill: true },
                };
                props.onCreatePolygon(polygon);
            } else if (index === positions.length - 1) {
                const polyline: PolylinePath = {
                    id: uuidv4(),
                    positions: pathElement.instance.getLatLngs() as L.LatLng[],
                    options: pathElement.instance.options,
                };
                props.onCreatePolyline(polyline);
            }
        });

        nodeMarkers.push(marker);
        context.map.addLayer(marker);
    };

    context.map.dragging.disable();
    L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');

    const onMouseMove = (e: L.LeafletMouseEvent) => {
        updateHologramPath(e.latlng);
    };

    const onMouseClick = (e: L.LeafletMouseEvent) => {
        addPositionToPath(e.latlng);
        updateNodeMarkers();
    };

    context.map.on('mousemove', onMouseMove);
    context.map.on('click', onMouseClick);

    return pathElement;
};

const usePathBuilder = createElementHook<L.Polyline, PathBuilderProps, LeafletContextInterface>(createPathBuilder);

const PathBuilder = (props: PathBuilderProps) => {
    const context = useLeafletContext();
    const pathBuilder = usePathBuilder(props, context);
    useLayerLifecycle(pathBuilder.current, context);
    return null;
};

export default PathBuilder;
