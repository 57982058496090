import React, { useEffect, useState } from 'react';
import { isMobileVersion } from '../../../lib/soar-helper';
import { Spinner, Table } from 'reactstrap';
import styled from 'styled-components';
import ApiUser from '../../../api/api-user';
import { SaleInfo } from '../../../api/model';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { StyledButton } from '../../Shared/styled-button';
import NoImageryModal from '../Imagery/Shared/no-imagery-modal';
import NavigationPanel from '../Shared/navigation-panel';
import { TransactionListItem } from './transaction-list-item';
import DashboardContainer from '../Shared/dashboard-container';

const SalesHistory = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [salesInfo, setSalesInfo] = useState<SaleInfo[]>([]);

    useEffect(() => {
        setIsLoading(true);
        const idToken = UserHelper.validateAndGetIdToken();
        if (idToken) {
            ApiUser.getMySales()
                .then((salesInfo) => {
                    setSalesInfo(salesInfo);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => setIsLoading(false));
        }
    }, []);

    return (
        <React.Fragment>
            <NavigationPanel label="Sales History" />
            <DashboardContainer>
                {isLoading && !salesInfo && (
                    <LoadingContainer>
                        <Spinner color="warning" style={{ width: '3rem', height: '3rem' }} />
                    </LoadingContainer>
                )}
                {salesInfo.length > 0 ? (
                    <SalesTable>
                        <thead>
                            <tr>
                                {!isMobileVersion && (
                                    <>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Payout</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {salesInfo.map((sale, index) => (
                                <TransactionListItem key={index} item={sale} />
                            ))}
                        </tbody>
                    </SalesTable>
                ) : (
                    <>
                        <NoImageryModal
                            icon={'/assets/floating-drawer-icons/drone-icon-yellow.svg'}
                            title={"Looks like you haven't made any sales yet."}
                            text={'Upload high resolution images now'}
                            mobileText={'You can upload drone images using a desktop computer.'}
                        />
                        <Button
                            onClick={() => {
                                UriHelper.navigateToDrawer(SideDrawerMode.SHARE_MAP);
                                Analytics.Event('My Dashboard', 'Clicked transaction history upload drone imagery');
                            }}
                        >
                            UPLOAD IMAGERY
                        </Button>
                    </>
                )}
            </DashboardContainer>
        </React.Fragment>
    );
};

export default SalesHistory;

const SalesTable = styled(Table)`
    background-color: transparent;
    color: white;

    th {
        color: white;
        border-top: none;
        border-bottom: none !important;
    }
`;

const LoadingContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 20px;
`;

const Button = styled(StyledButton)`
    margin: 12px auto;
    display: block;
    z-index: 1000;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;
