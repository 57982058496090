import L from 'leaflet';
import AnnotationPopup from '../annotation-popup';
import Circle from './circle';
import { ANNOTATION_TOOL_UPDATE_CHANGED_EVENT } from './circle-annotation';
import { calculateTopmostLatLng } from './circle-annotation-util';
import ColorSwatch from '../Popup/color-swatch';
import OpacitySlider from '../Popup/opacity-slider';

interface CircleAnnotationPopupProps {
    circle: Circle;
    onDelete: () => void;
    onUpdate: (options: L.CircleOptions) => void;
}

const CircleAnnotationPopup = (props: CircleAnnotationPopupProps) => {
    return (
        <AnnotationPopup
            title="Circle"
            keepInView={false}
            closeButton={false}
            autoClose={false}
            closeOnClick={false}
            onDelete={props.onDelete}
            eventHandlers={{
                add: (e: L.LeafletEvent) => {
                    const sourceCircle = e.target._source as L.Circle;
                    const sourceTarget = e.sourceTarget;
                    sourceCircle.addEventListener(ANNOTATION_TOOL_UPDATE_CHANGED_EVENT, (e) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const updatedLatlng = (e as any).latlng;
                        requestAnimationFrame(() => {
                            sourceTarget.setLatLng(updatedLatlng);
                        });
                    });
                    const popupPosition = calculateTopmostLatLng(props.circle.center, props.circle.radius);
                    requestAnimationFrame(() => {
                        sourceTarget.setLatLng(popupPosition);
                    });
                },
            }}
        >
            <ColorSwatch
                selectedColor={props.circle.options.color || '#3388ff'}
                onSelectColor={(color) => {
                    props.onUpdate({
                        ...props.circle.options,
                        color: color,
                        fillColor: color,
                    });
                }}
            />

            <OpacitySlider
                color={props.circle.options.color || '#3388ff'}
                opacity={props.circle.options.fillOpacity || 0.2}
                onSelectOpacity={(opacity) => {
                    props.onUpdate({
                        ...props.circle.options,
                        fill: opacity === 0 ? false : true,
                        fillOpacity: opacity,
                    });
                }}
            />
        </AnnotationPopup>
    );
};

export default CircleAnnotationPopup;
