import L from 'leaflet';
import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';

interface ArrowEditElement {
    arrowShaftElement: Readonly<{ instance: L.Polyline; context: Readonly<{ map: L.Map }> }>;
    arrowHeadElement: Readonly<{ instance: L.Marker; context: Readonly<{ map: L.Map }> }>;
}

const dragMarkerOptions: L.MarkerOptions = {
    draggable: true,
    bubblingMouseEvents: false,
    icon: new L.Icon({
        iconSize: new L.Point(16, 16),
        iconUrl: '/assets/aoi-handle.png',
        iconAnchor: new L.Point(8, 8),
    }),
};

const createDragMarker = (position: L.LatLng, context: LeafletContextInterface) => {
    const markerElement = createElementObject<L.Marker, ArrowEditElement>(
        new L.Marker(position, dragMarkerOptions),
        context
    );

    markerElement.instance.on('mouseover', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
    });

    markerElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-interactive');
    });

    return markerElement;
};

export const createArrowEditElement = (props: ArrowEditElement, context: LeafletContextInterface) => {
    const startLatLng = props.arrowShaftElement.instance.getLatLngs()[0] as L.LatLng;
    const endLatLng = props.arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;
    const startLatLngMarker = createDragMarker(startLatLng, context);
    const endLatLngMarker = createDragMarker(endLatLng, context);

    const editLayer = new L.LayerGroup();
    const editLayerElement = createElementObject<L.LayerGroup>(editLayer, context);

    editLayerElement.instance.on('add', () => {
        editLayerElement.instance.addLayer(startLatLngMarker.instance);
        editLayerElement.instance.addLayer(endLatLngMarker.instance);
    });

    editLayerElement.instance.on('remove', () => {
        editLayerElement.instance.removeLayer(startLatLngMarker.instance);
        editLayerElement.instance.removeLayer(endLatLngMarker.instance);
    });

    startLatLngMarker.instance.on('drag', (e: L.LeafletMouseEvent) => {
        const endLatLng = props.arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;
        props.arrowShaftElement.instance.setLatLngs([e.latlng, endLatLng]);
        props.arrowShaftElement.instance.fireEvent('update', {
            ...e,
            startLatLng: e.latlng,
            endLatLng: endLatLng,
        });
        props.arrowHeadElement.instance.fireEvent('update', { ...e, startLatLng: e.latlng, endLatLng: endLatLng });
        endLatLngMarker.instance.setLatLng(endLatLng);
    });

    endLatLngMarker.instance.on('drag', (e: L.LeafletMouseEvent) => {
        const startLatLng = props.arrowShaftElement.instance.getLatLngs()[0] as L.LatLng;
        props.arrowShaftElement.instance.setLatLngs([startLatLng, e.latlng]);
        props.arrowShaftElement.instance.fireEvent('update', {
            ...e,
            startLatLng: startLatLng,
            endLatLng: e.latlng,
        });
        props.arrowHeadElement.instance.fireEvent('update', { ...e, startLatLng: startLatLng, endLatLng: e.latlng });
    });

    return editLayerElement;
};
