import L from 'leaflet';

import { createElementObject, LeafletContextInterface } from '@react-leaflet/core';
import { handleUpdateRulerIconText } from './ruler-annotation-util';

interface RulerFocusElementProps extends L.PolylineOptions {
    rulerElement: Readonly<{ instance: L.Polyline; context: Readonly<{ map: L.Map }> }>;
}

const rulerTextMarkerOptions: L.MarkerOptions = {
    draggable: false,
    bubblingMouseEvents: false,
};

export const createRulerTextElement = (props: RulerFocusElementProps, context: LeafletContextInterface) => {
    const markerElement = createElementObject<L.Marker, L.MarkerOptions>(
        new L.Marker(L.latLng(0, 0), rulerTextMarkerOptions),
        context
    );

    props.rulerElement.instance.on('add', () => {
        markerElement.instance.setIcon(
            L.divIcon({
                className: 'ruler-text-marker',
                iconSize: [0, 0],
                iconAnchor: [0, 0],
                html: handleUpdateRulerIconText(context.map, props.rulerElement.instance),
            })
        );
    });

    props.rulerElement.instance.on('update', () => {
        markerElement.instance.setIcon(
            L.divIcon({
                className: 'ruler-text-marker',
                iconSize: [0, 0],
                iconAnchor: [0, 0],
                html: handleUpdateRulerIconText(context.map, props.rulerElement.instance),
            })
        );
    });

    return markerElement;
};
