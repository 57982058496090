import L from 'leaflet';

import {
    LeafletContextInterface,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createLayerHook,
    extendContext,
} from '@react-leaflet/core';
import { ImageOverlayProps } from 'react-leaflet';
import { createEditElement } from './image-annotation-edit-element';
import { createFocusElement } from './image-annotation-focus-element';
import { createGhostElement } from './image-annotation-ghost-element';
import Image from './image';

export const IMAGE_ANNOTATION_BOUNDS_CHANGED_EVENT = 'annotationTools:image:updateBounds';

interface ImageAnnotationProps extends ImageOverlayProps {
    bounds: L.LatLngBounds;
    imageAnnotation: Image;
    onUpdateImageBoundingBox: (boundingBox: L.LatLngBounds) => void;
}

const imageAnnotationOptions: L.ImageOverlayOptions = {
    zIndex: 100,
    interactive: false,
    className: 'image-annotation-overlay',
};

const createImageAnnotation = (props: ImageAnnotationProps, context: LeafletContextInterface) => {
    const imageOverlay = new L.ImageOverlay(props.url, props.bounds, { ...imageAnnotationOptions });
    const imageOverlayElement = createElementObject<L.ImageOverlay, Image>(
        imageOverlay,
        extendContext(context, { overlayContainer: imageOverlay })
    );

    const onClickMap = (e: L.LeafletMouseEvent) => {
        // Only deselect when a DIV (the map) is clicked.
        // This prevents deselection when the click event is triggered by the edit elements.
        // There is probably a better way to do this.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const target = (e.originalEvent.target as any).nodeName;
        if (target === 'DIV') {
            onDeselect();
        }
    };

    const onSelect = () => {
        context.map.on('mousedown', onClickMap);
        context.map.removeLayer(ghostElement.instance);
        context.map.addLayer(editElement.instance);
    };

    const onDeselect = () => {
        context.map.addLayer(ghostElement.instance);
        ghostElement.instance.fire('mouseout');
        context.map.removeLayer(editElement.instance);
        context.map.off('click', onDeselect);
        context.map.off('click', onClickMap);
        imageOverlayElement.instance.closePopup();
    };

    imageOverlayElement.instance.on('click', onSelect);

    imageOverlayElement.instance.on('add', () => {
        context.map.addLayer(ghostElement.instance);
    });

    imageOverlayElement.instance.on('remove', () => {
        context.map.off('mousedown', onClickMap);
        context.map.removeLayer(ghostElement.instance);
        context.map.removeLayer(focusElement.instance);
        context.map.removeLayer(editElement.instance);
    });

    imageOverlayElement.instance.on('update', (e: L.LeafletEvent) => {
        ghostElement.instance.setBounds(e.target.getBounds());
        focusElement.instance.setBounds(e.target.getBounds());
        props.onUpdateImageBoundingBox(imageOverlayElement.instance.getBounds());

        if (!imageOverlayElement.instance.isPopupOpen()) {
            imageOverlayElement.instance.openPopup();
        }

        imageOverlayElement.instance.fireEvent(IMAGE_ANNOTATION_BOUNDS_CHANGED_EVENT, {
            boundingBox: imageOverlayElement.instance.getBounds(),
        });
    });

    const editElement = createEditElement(
        {
            bounds: imageOverlayElement.instance.getBounds(),
            context: context,
            imageOverlayElement: imageOverlayElement,
        },
        context
    );

    const focusElement = createFocusElement(
        {
            bounds: imageOverlayElement.instance.getBounds(),
            context: context,
            imageOverlayElement: imageOverlayElement,
        },
        context
    );

    const ghostElement = createGhostElement(
        {
            bounds: imageOverlayElement.instance.getBounds(),
            context: context,
            focusElement: focusElement,
        },
        context
    );

    return imageOverlayElement;
};

const updateImageAnnotationElement = (
    instance: L.ImageOverlay,
    props: ImageAnnotationProps,
    prevProps: ImageAnnotationProps
) => {
    if (!props.imageAnnotation.bounds.equals(prevProps.imageAnnotation.bounds)) {
        instance.setBounds(props.imageAnnotation.bounds);
        props.onUpdateImageBoundingBox(props.imageAnnotation.bounds);
    }
    if (props.opacity && props.opacity !== prevProps.opacity) {
        props.imageAnnotation.opacity = props.opacity;
        instance.setOpacity(props.opacity);
    }
};

const useImageAnnotationElement = createElementHook<L.ImageOverlay, ImageAnnotationProps, LeafletContextInterface>(
    createImageAnnotation,
    updateImageAnnotationElement
);

const useImageAnnotation = createLayerHook(useImageAnnotationElement);

const ImageAnnotation = createContainerComponent(useImageAnnotation);

export default ImageAnnotation;
