import L from 'leaflet';
import { createElementObject, LeafletContextInterface } from '@react-leaflet/core';

interface RulerGhostElementProps extends L.PolylineOptions {
    rulerElement: Readonly<{ instance: L.Polyline; context: Readonly<{ map: L.Map }> }>;
    focusElement: Readonly<{ instance: L.Polyline; context: Readonly<{ map: L.Map }> }>;
}

const rulerGhostOptions: L.PolylineOptions = {
    color: 'transparent',
    weight: 30,
};

export const createRulerGhostElement = (props: RulerGhostElementProps, context: LeafletContextInterface) => {
    const startLatLng = props.rulerElement.instance.getLatLngs()[0] as L.LatLng;
    const endLatLng = props.rulerElement.instance.getLatLngs()[1] as L.LatLng;

    const rulerGhost = new L.Polyline([startLatLng, endLatLng], rulerGhostOptions);
    const rulerGhostElement = createElementObject<L.Polyline, RulerGhostElementProps>(rulerGhost, context);

    rulerGhostElement.instance.on('mouseover', (e: L.LeafletMouseEvent) => {
        context.map.addLayer(props.focusElement.instance);
        props.focusElement.instance.fireEvent('mouseover', e);
    });

    rulerGhostElement.instance.on('mouseout', (e: L.LeafletMouseEvent) => {
        context.map.removeLayer(props.focusElement.instance);
        props.focusElement.instance.fireEvent('mouseout', e);
    });

    rulerGhostElement.instance.on('click', (e: L.LeafletMouseEvent) => {
        props.focusElement.instance.fireEvent('click', e);
    });

    props.rulerElement.instance.on('update', () => {
        const startLatLng = props.rulerElement.instance.getLatLngs()[0] as L.LatLng;
        const endLatLng = props.rulerElement.instance.getLatLngs()[1] as L.LatLng;
        rulerGhostElement.instance.setLatLngs([startLatLng, endLatLng]);
    });

    return rulerGhostElement;
};
