import React from 'react';
import { ZoomControl as LeafletZoomControl } from 'react-leaflet';
import BoxZoomControl from './box-zoom-control';
import './zoom-control.css';

const ZoomControl = () => {
    return (
        <React.Fragment>
            <LeafletZoomControl position="topright" zoomOutText="" />
            <BoxZoomControl />
        </React.Fragment>
    );
};

export default ZoomControl;
