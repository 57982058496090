import * as React from 'react';
import { isMobileVersion } from '../../../lib/soar-helper';
import { toast } from 'react-toastify';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap';
import styled from 'styled-components';
import ApiSubdomain from '../../../api/api-subdomain';
import { DomainUserDTO, Permission, UserDTO } from '../../../api/model';
import SoarHelper from '../../../lib/soar-helper';
import Analytics from '../../../lib/user-analytics';
import profileIconUrl from '../profile-icon-url';
import AdminListUsersDataTable, { Cells } from './list-users-data-table';
import { permissionDescription } from '../../../components/Dashboard/Users/invite-user-modal';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../Shared/soar-modal';
import { StyledDarkButton, StyledButton, StyledDeleteButton } from '../../Shared/styled-button';

interface ListUsersProps {
    userData: DomainUserDTO[];
    myProfile?: UserDTO;
    refreshUsers: () => void;
}

const ListUsers: React.FC<ListUsersProps> = (props) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const [userId, setUserId] = React.useState<string>('');
    const [userInvitationId, setUserInvitationId] = React.useState<string>('');
    const [email, setEmail] = React.useState<string | undefined>(undefined);
    const [permission, setPermission] = React.useState<Permission>(Permission.Read);
    const [showPermissionsModal, setShowPermissionsModal] = React.useState<boolean>(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const [resentList, setResentList] = React.useState<string[]>([]);
    const [showResendModal, setShowResendModal] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const formatData = (userData) => {
        if (userData) {
            return userData.map((data, index) => {
                const resentIndex = resentList.indexOf(data.email);
                if (!data.userId && resentIndex === -1) {
                    data.name = 'RESEND';
                } else if (!data.userId) {
                    data.name = 'PENDING... ';
                }
                data.index = index;
                return data;
            });
        }
        return userData;
    };

    const permissionsFormat = (target, permission) => {
        return <div>{SoarHelper.permissionsEnumToUIText(permission)}</div>;
    };

    const isDomainOwner = () => {
        const domainOwner = props.userData.filter(
            (user) => user.userId === props.myProfile?.userId && user.permissions === 4
        );
        if (domainOwner.length === 1) {
            return true;
        } else {
            return false;
        }
    };

    const confirmUpdatePermissions = (user) => {
        setEmail(user.email);
        setUserId(user.userId);
        setUserInvitationId(user.invitationId);
        setPermission(user.permissions);
        setShowPermissionsModal(true);
    };

    const submitUpdatePermissions = async () => {
        setIsLoading(true);
        if (isDomainOwner()) {
            if ((userId || userInvitationId) && permission !== Permission.Admin) {
                try {
                    await ApiSubdomain.updateUserPermission(userId, userInvitationId, permission);
                    Analytics.Event(
                        'My Dashboard',
                        'Clicked to change users permission',
                        userId ? userId : userInvitationId
                    );
                    toast.dark(`You have changed permissions for ${email}`);
                    props.refreshUsers();
                } catch (error) {
                    toast.error(`Error changing permissions: ${error}`);
                }
            } else {
                toast.error(`Can not change these permissions`);
            }
        } else {
            if (userId || userInvitationId) {
                try {
                    await ApiSubdomain.updateUserPermission(userId, userInvitationId, permission);
                    Analytics.Event(
                        'My Dashboard',
                        'Clicked to change users permission',
                        userId ? userId : userInvitationId
                    );
                    toast.dark(`You have changed permissions for ${email}`);
                    props.refreshUsers();
                } catch (error) {
                    toast.error(`Error changing permissions: ${error}`);
                }
            } else {
                toast.error(`Can not change permissions at this time`);
            }
        }
        setIsLoading(false);
        setShowPermissionsModal(false);
    };

    const confirmDeleteUser = (user) => {
        setEmail(user.email);
        setUserId(user.userId);
        setUserInvitationId(user.invitationId);
        setPermission(user.permissions);
        setShowDeleteModal(true);
    };

    const submitUserDelete = () => {
        ApiSubdomain.deleteUser(userId, userInvitationId)
            .then(() => {
                Analytics.Event('My Dashboard', 'Clicked to delete user', userId);
                toast.dark(`You have deleted ${email}`);
                props.refreshUsers();
            })
            .catch(() => {
                toast.error('You can not delete this user');
            });
        setShowDeleteModal(false);
    };

    const confirmResendInvite = (user) => {
        Analytics.Event('My Dashboard', 'Clicked to confirm resend invite', user.invitationId);
        setUserInvitationId(user.invitationId);
        setEmail(user.email);
        setPermission(user.permissions);
        setShowResendModal(true);
    };

    const submitResendInvite = () => {
        if (email) {
            setIsLoading(true);
            ApiSubdomain.resendUserInvite(email, permission)
                .then(() => {
                    Analytics.Event('My Dashboard', 'Clicked to resend invite to ', email);
                    toast.dark(`You have resent an invite to ${email}`);
                    const list = resentList.concat(email);
                    setResentList(list);
                })
                .catch(() => {
                    toast.error('There was a problem re-sending an invite to this user. Please try again later.');
                })
                .finally(() => {
                    setIsLoading(false);
                    setShowResendModal(false);
                });
        }
    };

    const updateUserPermissions = (target, user) => {
        if (user.permissions !== Permission.Owner) {
            return (
                <UpdateButtonContainer onClick={() => confirmUpdatePermissions(user)}>
                    <span>
                        <UpdateIcon className="fa fa-cog" />
                    </span>
                </UpdateButtonContainer>
            );
        } else {
            return null;
        }
    };

    const deleteUserFormat = (target, user) => {
        if (user.permissions !== Permission.Owner || user.permissions !== Permission.Admin) {
            return (
                <DeleteButtonContainer onClick={() => confirmDeleteUser(user)}>
                    <span>
                        <img src={profileIconUrl.deleteIconUrl} />
                    </span>
                </DeleteButtonContainer>
            );
        } else {
            return null;
        }
    };

    const nameUserFormat = (target, user) => {
        if (!user.userId && user.name === 'RESEND') {
            return (
                <PendingActionContainer>
                    PENDING...
                    <ResendButton onClick={() => confirmResendInvite(user)}>
                        <ResendIcon className="fa fa-repeat" title="Resend Invite" />
                    </ResendButton>
                </PendingActionContainer>
            );
        } else {
            return user.name;
        }
    };

    const columns: Cells[] = [
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            grow: 2,
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            center: true,
            ignoreRowClick: true,
            format: (row) => {
                return nameUserFormat(row.index, row);
            },
        },
        {
            name: 'Permissions',
            selector: 'permissions',
            sortable: false,
            ignoreRowClick: true,
            format: (row) => {
                return permissionsFormat(row.index, row.permissions);
            },
            center: true,
            button: true,
        },
        {
            name: 'Update Permissions',
            selector: 'Update',
            sortable: false,
            ignoreRowClick: true,
            format: (row) => {
                return updateUserPermissions(row.index, row);
            },
            center: true,
            button: true,
        },
        {
            name: 'Delete',
            selector: 'delete',
            sortable: false,
            ignoreRowClick: true,
            format: (row) => {
                return deleteUserFormat(row.index, row);
            },
            center: true,
            button: true,
        },
    ];

    const mobileColumns: Cells[] = [
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            grow: 1,
            maxWidth: '190px',
        },
        {
            name: 'Permissions',
            selector: 'permissions',
            sortable: false,
            ignoreRowClick: true,
            format: (row) => {
                return permissionsFormat(row.index, row.permissions);
            },
            center: true,
            button: true,
            width: '75px',
        },
        {
            name: 'Delete',
            selector: 'delete',
            sortable: false,
            ignoreRowClick: true,
            format: (row) => {
                return deleteUserFormat(row.index, row);
            },
            center: true,
            button: true,
            width: '40px',
        },
    ];
    return (
        <>
            <AdminManageUsersList>
                <AdminListUsersDataTable
                    data={formatData(props.userData)}
                    columns={isMobileVersion ? mobileColumns : columns}
                />
            </AdminManageUsersList>
            {showDeleteModal && (
                <SoarModal title="Delete User" isOpen={true} toggle={() => setShowDeleteModal(false)}>
                    <StyledModalBody>
                        Are you sure you want to delete <span>{email}</span>?
                    </StyledModalBody>
                    <StyledModalFooter>
                        <StyledDarkButton onClick={() => setShowDeleteModal(false)}>Cancel</StyledDarkButton>
                        <StyledDeleteButton onClick={() => submitUserDelete()}>Delete</StyledDeleteButton>
                    </StyledModalFooter>
                </SoarModal>
            )}

            {showPermissionsModal && (
                <SoarModal
                    title={<span className="small">Update permissions for {email}</span>}
                    isOpen={true}
                    toggle={() => setShowPermissionsModal(false)}
                >
                    <StyledModalBody>
                        <PermissionUpdate>
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <PermissionUpdateButton caret>
                                    {SoarHelper.permissionsEnumToUIText(permission)}
                                </PermissionUpdateButton>
                                <DropdownMenu carat>
                                    <DropdownItem onClick={() => setPermission(Permission.Read)}>
                                        {SoarHelper.permissionsEnumToUIText(Permission.Read)}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => setPermission(Permission.Review)}>
                                        {SoarHelper.permissionsEnumToUIText(Permission.Review)}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => setPermission(Permission.Admin)}>
                                        {SoarHelper.permissionsEnumToUIText(Permission.Admin)}
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </PermissionUpdate>
                        <PermissionUpdateType>({permissionDescription(permission)})</PermissionUpdateType>
                    </StyledModalBody>
                    <StyledModalFooter>
                        {isLoading ? (
                            <Spinner style={{ color: '#EED926', height: '40px', width: '40px' }} />
                        ) : (
                            <React.Fragment>
                                <StyledDarkButton onClick={() => setShowPermissionsModal(false)}>
                                    CANCEL
                                </StyledDarkButton>
                                <StyledButton onClick={() => submitUpdatePermissions()}>UPDATE</StyledButton>
                            </React.Fragment>
                        )}
                    </StyledModalFooter>
                </SoarModal>
            )}

            {showResendModal && (
                <SoarModal title="Invite User" isOpen={true} toggle={() => setShowResendModal(false)}>
                    <StyledModalBody>
                        Resend invite to <span>{email}</span>?
                    </StyledModalBody>
                    <StyledModalFooter>
                        {isLoading ? (
                            <Spinner style={{ color: '#EED926', height: '40px', width: '40px' }} />
                        ) : (
                            <React.Fragment>
                                <StyledDarkButton onClick={() => setShowResendModal(false)}>Cancel</StyledDarkButton>
                                <StyledButton onClick={() => submitResendInvite()}>Resend</StyledButton>
                            </React.Fragment>
                        )}
                    </StyledModalFooter>
                </SoarModal>
            )}
        </>
    );
};

export default ListUsers;

const AdminManageUsersList = styled.div`
    background-color: rgb(0, 0, 0, 0.6);
    border-radius: 6px;
    margin: 10px 0px;

    h4 {
        padding: 20px 10px;
        color: white;
    }
`;

const PendingActionContainer = styled.div`
    display: inline;
`;

const ResendButton = styled.button`
    margin-left: 7px;
    display: inline;
    background: transparent;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-transform: uppercase;
`;

const UpdateButtonContainer = styled.div`
    cursor: pointer;
    text-transform: uppercase;
`;

const DeleteButtonContainer = styled.div`
    cursor: pointer;
    text-transform: uppercase;
`;

const PermissionUpdateButton = styled(DropdownToggle)`
    background-color: #eed926 !important;
    color: black !important;
    font-size: 16px !important;
    padding: 5px 45px !important;
    text-transform: uppercase;
`;

const UpdateIcon = styled.i`
    font-size: 20px;
    color: white;
`;

const ResendIcon = styled.i`
    font-size: 20px;
    color: #eed926;
`;

const PermissionUpdateType = styled.div`
    color: white;
    margin: auto;
    width: fit-content;
    text-align: center;
`;

const PermissionUpdate = styled.div`
    text-align: center;
`;
