import { Input } from 'reactstrap';
import styled from 'styled-components';

interface ImageAnnotationOpacitySliderProps {
    opacity: number;
    handleOpacity: (opacity: number) => void;
}

const ImageAnnotationOpacitySlider = (props: ImageAnnotationOpacitySliderProps) => {
    return (
        <SliderContainer>
            <SliderText>Transparency</SliderText>
            <SliderInputContainer>
                <TransparencyValue>{`${(props.opacity * 100).toFixed()}%`}</TransparencyValue>
                <Slider
                    type="range"
                    value={props.opacity * 100}
                    step={0.01}
                    onChange={(e) => {
                        const opacity = parseInt(e.target.value) / 100;
                        props.handleOpacity(opacity);
                    }}
                />
            </SliderInputContainer>
        </SliderContainer>
    );
};

export default ImageAnnotationOpacitySlider;

const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const SliderText = styled.div`
    color: white;
    text-align: center;
    font-size: 16px;
`;

const TransparencyValue = styled.div`
    color: rgba(255, 255, 255, 0.87);
    width: 30px;
`;

const SliderInputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled<any>(Input)`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 2px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    appearance: none;
    outline: none;
    border-radius: 5px;
    margin-top: 8px;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &:focus {
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;
