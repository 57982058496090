import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListingDTO, ListingType } from '../../../api/model';
import MapViewHistoryHelper from '../../../lib/map-view-history-helper';
import SoarHelper from '../../../lib/soar-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { actionUpdateActiveSearchPosition } from '../../../store/Search/actions';
import { selectSideDrawerCollapse } from '../../../store/SideDrawer/selectors';
import { selectEditComment, selectReplyingToComment } from '../../../store/SocialMapping/selectors';
import ReportMap from '../../Shared/ReportMap/report-map';
import { TruncatedText } from '../../Shared/truncated-text';
import DrawerContent from '../Shared/Drawer/drawer-content';
import { StyledDrawerTitle } from '../Shared/Drawer/drawer-styles';
import ActiveMapLegend from './active-map-legend';
import OpacitySlider from './active-map-opacity-slider';
import ActiveMapSpeed from './active-map-speed';
import ActiveListingCommentInput from './Comments/comment-input';
import { CommentList } from './Comments/comment-list';
import ShareComponent from './Sharing/map-options';

interface ActiveMapProps {
    listing: ListingDTO;
}

const ActiveMap = (props: ActiveMapProps) => {
    const { listing } = props;

    const dispatch = useDispatch();

    const selectedEditingComment = useSelector(selectEditComment);
    const selectedReplyingToComment = useSelector(selectReplyingToComment);
    const sideDrawerCollapse = useSelector(selectSideDrawerCollapse);

    const [commentUpdate, setCommentUpdate] = useState<boolean>(false);
    const [commentCount, setCommentCount] = useState<number | undefined>(undefined);

    const MAX_DESCRIPTION_LENGTH = 400; //increased max description length fixes link  on markdown viewer

    const isSoar = SoarHelper.isSoar();

    useEffect(() => {
        MapViewHistoryHelper.addListingToViewedMapsHistory(props.listing);
    }, [props.listing]);

    const handleShowProfile = () => {
        Analytics.Event('Navigation - SideBar - Opened Map', 'Clicked View Profile', listing.userId);
        UriHelper.navigateToPath(`/profile/${listing.userId}`);
    };

    useEffect(() => {
        // Clears the marker if the user selects a map or has added a marker then leaves the map
        // Note: This is an opinionated choice to have the marker removed when a map is selected
        // and could remain on the map until the search input has actually been cleared.
        const handleClearMarker = () => {
            dispatch(actionUpdateActiveSearchPosition(undefined));
        };

        handleClearMarker();

        return () => {
            handleClearMarker();
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            <DrawerContent
                className={
                    !selectedEditingComment && !selectedReplyingToComment
                        ? 'display-comment-input'
                        : 'hide-display-comment-input'
                }
            >
                <ActiveMapSpeed listing={listing} />
                <StyledDrawerHeader>
                    <StyledDrawerTitle title={listing.title}>{listing.title}</StyledDrawerTitle>
                    <ReportMap />
                </StyledDrawerHeader>
                <StyledAuthorContainer>
                    by&nbsp;
                    {isSoar ? (
                        <>
                            <UserProfileLink
                                onClick={handleShowProfile}
                                title={listing.userName ? listing.userName : 'Soar User'}
                            >
                                {listing.userName ? listing.userName : 'Soar User'}
                            </UserProfileLink>
                            {listing.communityModerator && listing.reputable ? (
                                <>🏆😎</>
                            ) : listing.communityModerator ? (
                                <>😎</>
                            ) : listing.reputable ? (
                                <>🏆</>
                            ) : null}

                        </>
                    ) : (
                        <React.Fragment>{listing.userName ? listing.userName : 'Soar User'}</React.Fragment>
                    )}
                </StyledAuthorContainer>

                {listing.listingType !== ListingType.WMS && (
                    <UploadDate>{moment(listing.dateUploaded).fromNow()}</UploadDate>
                )}
                <Content>
                    <TruncatedText text={listing.description} length={MAX_DESCRIPTION_LENGTH} />
                    <ActiveMapLegend listing={listing} />
                </Content>
                <TagContainer>
                    {listing.tags &&
                        listing.tags.map((tag, index) => {
                            return <Tag key={`${tag}-${index}`}>{tag}</Tag>;
                        })}
                </TagContainer>
                <CommentListContent>
                    <CommentList
                        listingId={listing.id}
                        commentUpdate={commentUpdate}
                        setCommentCount={setCommentCount}
                    />
                </CommentListContent>
            </DrawerContent>
            <DrawerSocialContainer closeDraw={sideDrawerCollapse}>
                <CommentDetails>
                    <CommentInputContainer
                        isReplyOpen={!selectedEditingComment && !selectedReplyingToComment ? true : false}
                    >
                        {!selectedEditingComment && !selectedReplyingToComment && (
                            <ActiveListingCommentInput
                                listingId={listing.id}
                                handleCommentUpdate={setCommentUpdate}
                                commentCount={commentCount}
                            />
                        )}
                    </CommentInputContainer>
                </CommentDetails>
                <ShareLinksContainer>
                    <OpacitySlider tileLayer={listing} />
                    <ShareComponent />
                </ShareLinksContainer>
            </DrawerSocialContainer>
        </React.Fragment>
    );
};

export default ActiveMap;

interface ActiveMapContainerProps {
    closeDraw?: boolean;
    isReplyOpen?: boolean;
}

const DrawerSocialContainer = styled.div<ActiveMapContainerProps>`
    overflow: ${(props) => (props.closeDraw ? 'hidden' : '')};
    user-select: ${(props) => (props.closeDraw ? 'none' : '')};
    opacity: ${(props) => (props.closeDraw ? '0' : '1')};
    height: ${(props) => (props.closeDraw ? '0px' : '100%')};

    transition: all 0.55s;
    -webkit-transition: all 0.55s;
`;

const CommentDetails = styled.div<ActiveMapContainerProps>`
    opacity: ${(props) => (props.closeDraw ? '0' : '1')};
    transition: all 0.55s;
    -webkit-transition: all 0.55s;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: none;
    border-top: none;
    box-shadow: inset 0 2px 1px rgb(255, 255, 255, 0.1), inset 0 1px 0px rgb(255, 255, 255, 0.1);
`;

const StyledAuthorContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0px 0px 0px 12px;
    color: white;
    font-size: 16px;
`;

const UserProfileLink = styled.button`
    color: #eed926;
    background: none !important;
    text-align: left;
    border: none;
    padding: 0 !important;
    margin-bottom: 4px;
    text-decoration: underline;
    cursor: pointer;
    max-width: 435px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: fit-content;
    white-space: nowrap;
`;

const UploadDate = styled.span`
    margin-left: 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    line-height: 22px;
`;

const Content = styled.div`
    flex: 1 1 auto;
    margin: 12px;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
    word-break: break-word;
`;

const TagContainer = styled.div`
    flex: 0 1 auto;
    text-align: left;
    display: flex;
    margin: 0 12px 8px 12px;
    flex-wrap: wrap;
    max-height: auto;
    overflow: visible;
`;

const Tag = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 1px 3px 3px 3px;
    line-height: 14px;
    opacity: 0.5;
    margin: 0 2px 2px 0;
    color: white;
    width: auto;
    word-break: break-all;
    font-size: 12px;
`;

const CommentInputContainer = styled.div<ActiveMapContainerProps>`
    flex: 0 1 0px;
    padding: ${(props) => (props.isReplyOpen ? '15px 0px 16px 0px' : '0px 0px 0px 0px')};
    height: ${(props) => (props.isReplyOpen ? '100%' : '0px')};
    opacity: ${(props) => (props.isReplyOpen ? '1' : '0')};
    transition: all 0.55s;
    -webkit-transition: all 0.55ms;
`;

const ShareLinksContainer = styled.div<ActiveMapContainerProps>`
    flex: 0 1 50px;
    display: flex;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px 0px 10px 0px;
    background: rgb(255, 255, 255, 5%);
    flex-direction: column;

    .active-map-share-container {
        border-bottom: none;
        border-top: none;
        margin-top: 0px;
        max-height: 35px;
        padding: 10px 0 0 15px;
    }

    .opacity-slider {
        border-bottom: none;
        border-top: none;
        margin-top: 0px;
        margin: 0 0 0 0;
        padding: 0px 12px;

        .p {
            color: rgb(255, 255, 255, 0.87);
            font-size: 15px;
        }
    }
`;

const CommentListContent = styled.div`
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    margin-right: 0;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
`;

const StyledDrawerHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
