import L, { DivIcon, LatLng, LeafletMouseEvent } from 'leaflet';
import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import Coordinate, { defaultCoordinateOptions } from './coordinate';
import { CoordinateSvg } from './coordinate-annotation-svg';
import { v4 as uuidv4 } from 'uuid';

interface CoordinateAnnotationBuilderProps {
    onCreateCoordinate: (coordinate: Coordinate) => void;
    coordinateOptions?: L.MarkerOptions;
}

const createCoordinateBuilderElement = (props: CoordinateAnnotationBuilderProps, context: LeafletContextInterface) => {
    const coordinate = new L.Marker(new L.LatLng(0, 0), { ...defaultCoordinateOptions });
    const coordinateElement = createElementObject<L.Marker, CoordinateAnnotationBuilderProps>(coordinate, context);
    const id = uuidv4();
    const handleIcon = (latlng: LatLng) => {
        return new DivIcon({
            iconSize: [20, 20],
            iconAnchor: [280, 27],
            html: CoordinateSvg(id, latlng),
            className: 'coordinate-builder-icon',
        });
    };

    const onMouseDown = (e: LeafletMouseEvent) => {
        coordinateElement.instance.setLatLng(e.latlng);
        context.map.dragging.enable();
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.off('mousemove', onMouseMove);
        context.map.off('mousedown', onMouseDown);

        const coordinate: Coordinate = {
            id: id,
            position: coordinateElement.instance.getLatLng(),
            options: coordinateElement.instance.options,
            ...defaultCoordinateOptions,
        };

        props.onCreateCoordinate(coordinate);
    };

    const onMouseMove = (e: LeafletMouseEvent) => {
        coordinateElement.instance.setIcon(handleIcon(e.latlng));
        coordinateElement.instance.setLatLng(e.latlng);
    };

    coordinateElement.instance.on('add', () => {
        context.map.dragging.disable();
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
        context.map.on('mousemove', onMouseMove);
        context.map.on('mousedown', onMouseDown);
    });

    return coordinateElement;
};

const useCoordinateBuilder = createElementHook<L.Marker, CoordinateAnnotationBuilderProps, LeafletContextInterface>(
    createCoordinateBuilderElement
);

const CoordinateBuilder = (props: CoordinateAnnotationBuilderProps) => {
    const context = useLeafletContext();
    const coordinateBuilder = useCoordinateBuilder(props, context);
    useLayerLifecycle(coordinateBuilder.current, context);
    return null;
};

export default CoordinateBuilder;
