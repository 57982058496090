import React, { useState } from 'react';
import L from 'leaflet';
import styled from 'styled-components';
import LineStylePopup from './line-style-popup';
import LineStylePreview from './line-style-preview';

interface LineStyleProps {
    selectedLineStyle: L.PolylineOptions;
    onSelectLineStyle: (lineStyle: L.PolylineOptions) => void;
}

const LineStyle = (props: LineStyleProps) => {
    const [popupOpen, setPopupOpen] = useState(false);

    return (
        <Container>
            <Text>Line Style</Text>
            <LineStylePreviewContainer onClick={() => setPopupOpen(true)}>
                <LineStylePreview lineStyle={props.selectedLineStyle} width={190} height={20} />
            </LineStylePreviewContainer>

            {popupOpen && (
                <LineStylePopup
                    selectedLineStyle={props.selectedLineStyle}
                    onSelectLineStyle={props.onSelectLineStyle}
                    onClose={() => setPopupOpen(false)}
                />
            )}
        </Container>
    );
};

export default LineStyle;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 8px;
`;

const Text = styled.span`
    color: white;
    font-size: 1rem;
    margin-right: 12px;
    min-width: 78px;
`;

const LineStylePreviewContainer = styled.div`
    width: 100%;
    height: 20px;
    cursor: pointer;
    vertical-align: unset;
`;
