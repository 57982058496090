import L from 'leaflet';

export default interface Path {
    positions: L.LatLng[];
    options: L.PolylineOptions;
}

export class PolylinePath {
    id: string;
    positions: L.LatLng[];
    options: L.PolylineOptions;

    constructor(id: string, positions: L.LatLng[], options: L.PolylineOptions) {
        this.id = id;
        this.positions = positions;
        this.options = options;
    }
}

export class PolygonPath {
    id: string;
    positions: L.LatLng[];
    options: L.PolylineOptions;

    constructor(id: string, positions: L.LatLng[], options: L.PolylineOptions) {
        this.id = id;
        this.positions = positions;
        this.options = options;
    }
}

export const defaultPathOptions: L.PathOptions = {
    interactive: false,
};

export const ghostPathOptions: L.PathOptions = {
    ...defaultPathOptions,
    interactive: true,
    color: 'transparent',
    weight: 30,
    opacity: 1.0,
    fill: false,
    fillColor: 'transparent',
};
