import ColorSwatch from '../Popup/color-swatch';
import AnnotationPopup from '../annotation-popup';
import Path from './path';

interface PathPopupProps {
    path: Path;
    onDelete: () => void;
    onUpdate: (options: L.PolylineOptions) => void;
}

const PathPopup = (props: PathPopupProps) => {
    return (
        <AnnotationPopup
            title="Path"
            onDelete={props.onDelete}
            eventHandlers={{
                add: (e: L.LeafletEvent) => {
                    // Place at the top of the annotation
                    const sourceTarget = e.sourceTarget;
                    const latlngs: L.LatLng[] = e.target._source._latlngs;
                    const top = latlngs.reduce((prev, current) => {
                        return prev.lat > current.lat ? prev : current;
                    });
                    sourceTarget._latlng = top;
                },
            }}
        >
            <ColorSwatch
                selectedColor={props.path.options.color || '#3388ff'}
                onSelectColor={(color) => {
                    props.onUpdate({
                        ...props.path.options,
                        color: color,
                    });
                }}
            />
        </AnnotationPopup>
    );
};

export default PathPopup;
