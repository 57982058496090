import React, { useEffect, useState } from 'react';
import { StyledButton } from '../../../Shared/styled-button';
import SoarModal from '../../../Shared/soar-modal';
import styled from 'styled-components';
import { generateIconMarkers } from './marker-util';
import Marker from './marker';

interface MarkerSelectModalProps {
    marker: Marker;
    onUpdate: (options: L.MarkerOptions) => void;
    icon: L.Icon;
    onIconChange: (icon: L.Icon) => void;
}

const MarkerSelectModal = (props: MarkerSelectModalProps) => {
    const [open, setOpen] = useState(false);
    const [iconMarkers, setIconMarkers] = useState<L.Icon[]>([]);

    useEffect(() => {
        const iconMarkers = generateIconMarkers();
        setIconMarkers(iconMarkers);
    }, []);

    const handleIconChange = (icon: L.Icon) => {
        props.onUpdate({ ...props.marker.options, icon: icon });
        props.onIconChange(icon);
    };

    return (
        <React.Fragment>
            <IconMarkerButton onClick={() => setOpen(true)}>Marker Symbology</IconMarkerButton>
            <SoarModal isOpen={open} toggle={() => setOpen(false)} title={'Select a Symbol'}>
                <MarkerOptions>
                    {iconMarkers?.map((icon) => {
                        return (
                            <MarkerItem
                                key={icon.options.iconUrl}
                                src={icon.options.iconUrl}
                                alt={icon.options.iconUrl}
                                onClick={() => handleIconChange(icon)}
                            />
                        );
                    })}
                </MarkerOptions>
            </SoarModal>
        </React.Fragment>
    );
};

export default MarkerSelectModal;

const IconMarkerButton = styled(StyledButton)`
    margin: 10px auto 8px auto;
    font-size: 14px;
    padding: 5px 10px;
    text-transform: none;

    &:hover {
        opacity: 1;
    }
`;

const MarkerOptions = styled.div``;

const MarkerItem = styled.img`
    cursor: pointer;
    height: 30px;
    width: 30px;
    margin: 5px;
`;
