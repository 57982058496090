import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ApiComments from '../../../../api/api-comments';
import { selectActiveAnnotationString } from '../../../../store/SocialMapping/selectors';
import TextareaAutosize from 'react-textarea-autosize';
import { inputMaxValues } from '../../../../lib/limitation-constants';
import ActiveCommentAnnotationTags from './comment-annotation-tags';
import UserHelper from '../../../../lib/user-helper';
import {
    actionSetDisplayAnnotations,
    actionSetReplyingToComment,
    actionSetSelectedCommentId,
} from '../../../../store/SocialMapping/actions';
import { selectMyProfile } from '../../../../store/Account/selectors';
import { actionShowLoginDialog } from '../../../../store/App/actions';
import { StyledButton } from '../../../Shared/styled-button';
import { isMobileVersion } from '../../../../lib/soar-helper';
import Analytics from '../../../../lib/user-analytics';

interface ActiveListingCommentInputProps {
    handleCommentUpdate: (updateComment: boolean) => void;
    listingId: number;
    commentCount: number | undefined;
}

const ActiveListingCommentInput = (props: ActiveListingCommentInputProps) => {
    const selectedAnnotationString = useSelector(selectActiveAnnotationString);
    const myProfile = useSelector(selectMyProfile);

    const dispatch = useDispatch();

    const setAnnotationString = (annotations: string) => dispatch(actionSetDisplayAnnotations(annotations));
    const setSelectedCommentId = (commentId: string) => dispatch(actionSetSelectedCommentId(commentId));
    const setReplyToComment = (replying: boolean) => dispatch(actionSetReplyingToComment(replying));

    const [userComment, setUserComment] = useState<string>('');

    const handleSubmitComment = () => {
        if (!myProfile) {
            dispatch(actionShowLoginDialog(true));
            return;
        }

        const annotationString =
            UserHelper.getTagsFromAnnotationString(selectedAnnotationString || '').length !== 0
                ? selectedAnnotationString
                : undefined;

        if (isMobileVersion && userComment.length < 1) {
            toast.error('Please add a comment');
            return;
        } else if (userComment.length >= inputMaxValues.MAX_DESCRIPTION_WORDS - 1) {
            toast.error(`Comments can only be ${inputMaxValues.MAX_DESCRIPTION_WORDS - 1} characters`);
            return;
        } else if (userComment.length < 1 && !annotationString) {
            toast.error('Add a comment or annotation on the map to submit');
            return;
        }

        props.handleCommentUpdate(false);
        const comment = {
            text: userComment,
            annotations: annotationString,
            listingId: props.listingId.toString(),
        };
        ApiComments.createComment(comment)
            .then((res) => {
                if (res.id) {
                    toast.dark('The comment has been added');
                    props.handleCommentUpdate(true);
                    setAnnotationString('');
                    setSelectedCommentId(res.id);
                    setReplyToComment(true);
                    Analytics.Event('Comments', 'Created comment', props.listingId);
                }
            })
            .catch((err) => {
                toast.error('Something has gone wrong with adding the comment', err);
            });
        setUserComment('');
    };

    const isCharLimitReached = userComment.length >= inputMaxValues.MAX_DESCRIPTION_WORDS - 1;
    const commentInputPlaceholderText =
        !props.commentCount || (props.commentCount && props.commentCount < 0)
            ? 'Be the first to comment on this map!'
            : 'Say something about this map...';

    // The id `listing-comment-input` is used in active-map-opacity-slider to remove the focus of the input causing the onscreen keyboard to show when changing transparency.
    return (
        <React.Fragment>
            <ListingCommentContainer>
                <CommentInputContainer>
                    <CommentTagContainer isCharLimitReached={isCharLimitReached}>
                        <ListingCommentInput
                            id="listing-comment-input"
                            value={userComment}
                            onChange={(e) => {
                                setUserComment(e.target.value);
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            onFocus={() => blur()}
                            placeholder={commentInputPlaceholderText}
                            maxRows={isMobileVersion ? 3 : 5}
                            minRows={3}
                        />
                        {selectedAnnotationString && (
                            <ActiveCommentAnnotationTags annotationString={selectedAnnotationString} />
                        )}
                    </CommentTagContainer>
                    <ListingCommentButton
                        disabled={isCharLimitReached}
                        onClick={(e) => {
                            handleSubmitComment();
                            e.stopPropagation();
                        }}
                    >
                        SUBMIT
                    </ListingCommentButton>
                </CommentInputContainer>
            </ListingCommentContainer>
            <CharacterCount isCharLimitReached={isCharLimitReached}>
                {userComment.length}/{inputMaxValues.MAX_DESCRIPTION_WORDS - 1}
            </CharacterCount>
        </React.Fragment>
    );
};

export default ActiveListingCommentInput;

interface CharacterCountProps {
    isCharLimitReached: boolean;
}

const ListingCommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    pointer-events: all;
`;

const CommentInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px 12px 2px 2px;
    pointer-events: all;
`;

const CommentTagContainer = styled.div<CharacterCountProps>`
    color: white;
    background: transparent;
    border: ${(props) =>
        props.isCharLimitReached ? '2px solid rgba(255, 0, 0, 0.7);' : '1px solid rgba(255, 255, 255, 0.6);'};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    width: 100%;
    margin: 0px 3px 0px 13px;
`;

const ListingCommentInput = styled(TextareaAutosize)`
    background: none;
    border: none;
    width: 100%;
    outline: none;
    color: white;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.87);
    max-height: 200px;
    min-height: 25px;
    pointer-events: all;
    user-select: text;
    -webkit-user-select: text;

    &::placeholder {
        color: rgba(255, 255, 255, 0.3) !important;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }
    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const ListingCommentButton = styled(StyledButton)`
    margin: 0 auto;
    padding: 5px 8px !important;
    font-size: 12px !important;
    border-radius: 6px;
    width: 60px;
    height: 30px;

    :focus {
        outline: none !important;
    }
`;

const CharacterCount = styled.span<CharacterCountProps>`
    color: ${(props) => (props.isCharLimitReached ? 'rgba(255, 0, 0, 0.7);' : 'rgba(255, 255, 255, 0.5);')};
    font-size: 10px;
    text-align: right;
    display: block;
    height: 0px;
    float: right;
    margin-right: 15px;
    margin-top: -15px;

    @media only screen and (max-width: 600px) {
        padding-bottom: 15px;
    }
`;
