import L from 'leaflet';
import { LeafletContextInterface, createElementObject, extendContext } from '@react-leaflet/core';
import { HOVER_WEIGHT, circleGhostOptions } from './circle';

interface CircleGhostElementProps {
    focusElement: Readonly<{ instance: L.Circle; context: Readonly<{ map: L.Map }> }>;
}

export const createGhostCircleElement = (props: CircleGhostElementProps, context: LeafletContextInterface) => {
    const circleGhost = new L.Circle(
        props.focusElement.instance.getLatLng(),
        props.focusElement.instance.getRadius(),
        circleGhostOptions
    );
    const circleGhostElement = createElementObject<L.Circle>(
        circleGhost,
        extendContext(context, { overlayContainer: circleGhost })
    );

    circleGhostElement.instance.on('mouseover', (e: L.LeafletMouseEvent) => {
        // Add the focus element to the map and fire its mouseover event
        props.focusElement.instance.fireEvent('mouseover', e);
        props.focusElement.instance.setStyle({ ...props.focusElement.instance.options, weight: HOVER_WEIGHT * 2 });
    });

    circleGhostElement.instance.on('mouseout', (e: L.LeafletMouseEvent) => {
        // Remove the focus element from the map
        props.focusElement.instance.fireEvent('mouseout', e);
        props.focusElement.instance.setStyle({ ...props.focusElement.instance.options, weight: HOVER_WEIGHT });
    });

    circleGhostElement.instance.on('click', (e: L.LeafletMouseEvent) => {
        // Propagate the on click event to the focus element
        props.focusElement.instance.fireEvent('click', e);
    });

    circleGhostElement.instance.on('resize', (e: L.LeafletMouseEvent) => {
        // Resize the focus element
        circleGhostElement.instance.setRadius(e.latlng.distanceTo(props.focusElement.instance.getLatLng()));
        props.focusElement.instance.fireEvent('resize', e);
    });

    circleGhostElement.instance.on('drag', (e: L.LeafletMouseEvent) => {
        // Drag the focus element
        circleGhostElement.instance.setLatLng(e.latlng);
        props.focusElement.instance.fireEvent('drag', e);
    });

    return circleGhostElement;
};
