import L from 'leaflet';

import {
    createContainerComponent,
    createElementHook,
    createElementObject,
    createPathHook,
    extendContext,
    LeafletContextInterface,
    PathProps,
} from '@react-leaflet/core';

import Highlighter from './highlighter';

interface HighlighterProps extends PathProps {
    lock: boolean;
    highlighter: Highlighter;
    children: React.ReactNode;
}

const createHighlighterAnnotation = (props: HighlighterProps, context: LeafletContextInterface) => {
    const highlighter = new L.Polyline(props.highlighter.positions, props.highlighter.options);
    let corridor = 0;

    const highlighterElement = createElementObject<L.Polyline, PathProps>(
        highlighter,
        extendContext(context, { overlayContainer: highlighter })
    );

    const metersPerPixel = () => {
        const centerLatLng = context.map.getCenter();
        const pointC = context.map.latLngToContainerPoint(centerLatLng);
        const pointX = L.point(pointC.x + 10, pointC.y);
        const latLngX = context.map.containerPointToLatLng(pointX);
        return centerLatLng.distanceTo(latLngX) / 10;
    };

    highlighterElement.instance.on('add', () => {
        corridor = metersPerPixel();
    });

    const onMouseOver = () => {
        const newWeight = highlighterElement.instance.options.weight
            ? highlighterElement.instance.options.weight + 3
            : 6;
        highlighterElement.instance.setStyle({ weight: newWeight });
    };

    const onMouseOut = () => {
        const newWeight = highlighterElement.instance.options.weight
            ? highlighterElement.instance.options.weight - 3
            : 3;
        highlighterElement.instance.setStyle({ weight: newWeight });
    };

    highlighterElement.instance.on('mouseover', onMouseOver);
    highlighterElement.instance.on('mouseout', onMouseOut);

    highlighterElement.instance.on('lock', () => {
        highlighterElement.instance.off('mouseover', onMouseOver);
        highlighterElement.instance.off('mouseout', onMouseOut);
        highlighterElement.instance.setStyle({ ...highlighterElement.instance.options, interactive: false });
        const element = highlighterElement.instance.getElement() as HTMLElement;
        L.DomUtil.removeClass(element, 'leaflet-interactive');
    });

    highlighterElement.instance.on('unlock', () => {
        highlighterElement.instance.on('mouseover', onMouseOver);
        highlighterElement.instance.on('mouseout', onMouseOut);
        highlighterElement.instance.setStyle({ ...highlighterElement.instance.options, interactive: true });
        const element = highlighterElement.instance.getElement() as HTMLElement;
        L.DomUtil.addClass(element, 'leaflet-interactive');
    });

    const onMapZoomEnd = (_: L.LeafletMouseEvent) => {
        const calculateWeight = () => {
            const meters = metersPerPixel();
            // I am unsure where this magic number comes from or why it works.
            // It was found by trial and error to keep the weight of the polyline continuous during zoom
            return ((corridor * 2) / meters) * 13.3333;
        };

        const weight = calculateWeight();
        highlighterElement.instance.setStyle({ weight: weight });
    };

    context.map.on('zoomend', onMapZoomEnd);

    return highlighterElement;
};

const hexToRGBA = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const updateHighlighterAnnotation = (instance: L.Polyline, props: HighlighterProps, _: HighlighterProps) => {
    instance.setStyle({
        color: hexToRGBA(props.highlighter.options.color || '#3388ff', 1.0),
        fillOpacity: 0.3,
    });

    if (props.lock) {
        instance.fireEvent('lock');
    } else {
        instance.fireEvent('unlock');
    }
};

const useHighlighterElement = createElementHook(createHighlighterAnnotation, updateHighlighterAnnotation);
const useHighlighterPath = createPathHook(useHighlighterElement);

const HighlighterAnnotation = createContainerComponent(useHighlighterPath);

export default HighlighterAnnotation;
