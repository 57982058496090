import L, { LeafletMouseEvent } from 'leaflet';
import {
    LeafletContextInterface,
    createElementHook,
    createElementObject,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';

import { boundsForText, svgForText, svgSize } from './text-annotation-util';

interface TextBoxBuilderProps {
    onPlaceTextBox: (bounds: L.LatLngBounds) => void;
}

const createTextBoxBuilder = (props: TextBoxBuilderProps, context: LeafletContextInterface) => {
    L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');

    // Initial properties
    const mockBounds = new L.LatLngBounds(new L.LatLng(0, 0), new L.LatLng(0, 0));
    const text = 'Enter text...';
    const fontSize = '32px';
    const textColor = 'white';
    const fillColor = 'black';
    const padding = 0;

    // Overlay for selecting text placement
    const textSize = svgSize(text, fontSize);
    const svg = svgForText(text, fontSize, textColor, fillColor, textSize.width, textSize.height, padding);
    const svgOverlay = new L.SVGOverlay(svg, mockBounds, { interactive: false, className: 'text-svg-overlay' });
    const svgOverlayElement = createElementObject<L.SVGOverlay, TextBoxBuilderProps>(svgOverlay, context);

    // While the user is placing the text, update the overlay
    const onMouseMove = (e: LeafletMouseEvent) => {
        if (e.latlng) {
            const bounds = boundsForText(context, e.latlng, [textSize.width, textSize.height]);
            svgOverlayElement.instance.setBounds(bounds);
        }
    };

    // Place the textarea
    const onClick = () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
        context.map.off('mousemove', onMouseMove);
        context.map.off('click', onClick);
        context.map.removeLayer(svgOverlayElement.instance);
        props.onPlaceTextBox(svgOverlayElement.instance.getBounds());
    };

    context.map.on('mousemove', onMouseMove);
    context.map.on('click', onClick);

    return svgOverlayElement;
};

const useTextBoxBuilder = createElementHook<L.SVGOverlay, TextBoxBuilderProps, LeafletContextInterface>(
    createTextBoxBuilder
);

const TextBoxBuilder = (props: TextBoxBuilderProps) => {
    const context = useLeafletContext();
    const textBuilder = useTextBoxBuilder(props, context);
    useLayerLifecycle(textBuilder.current, context);
    return null;
};

export default TextBoxBuilder;
