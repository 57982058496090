import L, { LeafletMouseEvent } from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import Marker, { defaultMarkerOptions } from './marker';
import { v4 as uuidv4 } from 'uuid';

interface MarkerAnnotationBuilderProps {
    icon: L.Icon;
    onCreateMarker: (marker: Marker) => void;
    markerOptions?: L.MarkerOptions;
}

const createMarkerBuilderElement = (props: MarkerAnnotationBuilderProps, context: LeafletContextInterface) => {
    const marker = new L.Marker(new L.LatLng(0, 0), { ...defaultMarkerOptions, icon: props.icon });
    const markerElement = createElementObject<L.Marker, MarkerAnnotationBuilderProps>(marker, context);

    const onMouseDown = (e: LeafletMouseEvent) => {
        markerElement.instance.setLatLng(e.latlng);
        context.map.dragging.enable();
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.off('mousemove', onMouseMove);
        context.map.off('mousedown', onMouseDown);

        const marker: Marker = {
            id: uuidv4(),
            position: markerElement.instance.getLatLng(),
            options: markerElement.instance.options,
            ...defaultMarkerOptions,
        };

        props.onCreateMarker(marker);
    };

    const onMouseMove = (e: LeafletMouseEvent) => {
        markerElement.instance.setLatLng(e.latlng);
    };

    markerElement.instance.on('add', () => {
        context.map.dragging.disable();
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
        context.map.on('mousemove', onMouseMove);
        context.map.on('mousedown', onMouseDown);
    });

    return markerElement;
};

const useMarkerBuilder = createElementHook<L.Marker, MarkerAnnotationBuilderProps, LeafletContextInterface>(
    createMarkerBuilderElement
);

const MarkerBuilder = (props: MarkerAnnotationBuilderProps) => {
    const context = useLeafletContext();
    const markerBuilder = useMarkerBuilder(props, context);
    useLayerLifecycle(markerBuilder.current, context);
    return null;
};

export default MarkerBuilder;
