import styled from 'styled-components';

interface ImageryAddCardProps {
    onAddClick: () => void;
}

const ImageryAddCard = (props: ImageryAddCardProps) => {
    return (
        <AddImageryContainer>
            <AddImageryActionContainer onClick={props.onAddClick}>
                <AddIcon className="fa fa-plus" />
                <OverlaidDiv>
                    <CardOverlayDetails>
                        <Description>Add new map or image</Description>
                    </CardOverlayDetails>
                </OverlaidDiv>
            </AddImageryActionContainer>
        </AddImageryContainer>
    );
};

export default ImageryAddCard;

const AddImageryContainer = styled.div`
    cursor: pointer;
`;

const AddImageryActionContainer = styled.div`
    position: relative;
    border-radius: 6px;
    text-align: center;
    border: 2px dashed rgb(255 255 255 / 67%);
    margin: auto;
`;

const AddIcon = styled.i`
    font-size: 80px;
    color: #eed926;
    margin: 75px auto;
`;

const OverlaidDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 6px;
    background: transparent;
    color: #ffffff;

    ${AddImageryActionContainer}:hover & {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: rgba(0, 0, 0, 0.4);
        -webkit-transition: all 0.2s 0s ease-in-out;
        -moz-transition: all 0.2s 0s ease-in-out;
        -o-transition: all 0.2s 0s ease-in-out;
        transition: all 0.2s 0s ease-in-out;
    }
`;

const CardOverlayDetails = styled.div`
    padding-left: 5px;
    bottom: 0px;
    position: absolute;
    width: 100%;
`;

const Description = styled.p`
    bottom: 10px;
    opacity: 0.8;
    display: flex;
    flex-wrap: wrap;
    color: #fff;
    padding-bottom: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
    text-align: center;
`;
