import L from 'leaflet';
import { Popup } from 'react-leaflet';
import styled from 'styled-components';
import { IMAGE_ANNOTATION_BOUNDS_CHANGED_EVENT } from './image-annotation';
import ImageAnnotationOpacitySlider from './image-annotation-opacity-slider';
import Image from './image';

interface ImageAnnotationPopupProps {
    image: Image;
    onDeleteImage: (image: Image) => void;
    opacity: number;
    handleOpacity: (opacity: number) => void;
}

const ImageAnnotationPopup = (props: ImageAnnotationPopupProps) => {
    return (
        <StyledPopup
            keepInView={true}
            closeButton={false}
            autoClose={false}
            closeOnClick={false}
            eventHandlers={{
                add: (e: L.LeafletEvent) => {
                    const sourceRectangle = e.target._source as L.ImageOverlay;
                    const sourceTarget = e.sourceTarget;
                    sourceRectangle.addEventListener(IMAGE_ANNOTATION_BOUNDS_CHANGED_EVENT, (e) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const bounds = (e as any).boundingBox;
                        const popupPosition = new L.LatLng(
                            bounds.getNorth(),
                            (bounds.getEast() + bounds.getWest()) / 2
                        );
                        sourceTarget._latlng = popupPosition;
                    });
                    const bounds = props.image.bounds;
                    const popupPosition = new L.LatLng(bounds.getNorth(), (bounds.getEast() + bounds.getWest()) / 2);
                    sourceTarget._latlng = popupPosition;
                },
            }}
        >
            <Title>Edit Image</Title>
            <DeleteButton
                title="Delete Rectangle"
                onClick={() => {
                    props.onDeleteImage(props.image);
                }}
            >
                <DeleteIcon className="fa fa-trash" />
            </DeleteButton>
            <ImageAnnotationOpacitySlider opacity={props.opacity} handleOpacity={props.handleOpacity} />
        </StyledPopup>
    );
};

export default ImageAnnotationPopup;

const StyledPopup = styled(Popup)`
    &.leaflet-popup > .leaflet-popup-content-wrapper {
        background-color: rgba(0, 0, 0, 0.8);
        border: solid;
        margin: 0;
        padding: 0 12px 0 12px;
        border-radius: 2px;
        border-color: black;
        border-width: 1px;
    }

    &.leaflet-popup > .leaflet-popup-content-wrapper > .leaflet-popup-content {
        margin: 3px !important;
        padding: 0 !important;
        width: 200px !important;
    }

    &.leaflet-popup > .leaflet-popup-tip-container > .leaflet-popup-tip {
        background-color: rgba(0, 0, 0, 0.8);
        border: solid;
        border-width: 1px;
        z-index: 10;
    }
`;

const Title = styled.h4`
    color: white;
    display: block;
    text-align: center;
`;

const DeleteButton = styled.button`
    position: fixed;
    top: 10px;
    right: 2px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
`;

const DeleteIcon = styled.i`
    color: white;
    font-size: 1.1rem;
`;
