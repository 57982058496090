import { useDispatch, useSelector } from 'react-redux';
import { selectShowUploadDialog } from '../../store/App/selectors';
import { actionShowUploadDialog } from '../../store/App/actions';
import UploadMapWorkflow, { Workflow } from './upload-map-workflow';
import styled from 'styled-components';
import { ModalBody } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { ListingType } from '../../api/model';
import MapUploadStatus from './map-upload-status';
import Analytics from '../../lib/user-analytics';
import UriHelper from '../../lib/uri-helper';
import { SideDrawerMode } from '../../store/SideDrawer/model';

const UploadDialog = () => {
    const showUploadDialog = useSelector(selectShowUploadDialog);
    const dispatch = useDispatch();
    const [workflow, setWorkflow] = useState<Workflow>(Workflow.ChooseFile);
    const [listingType, setListingType] = useState(ListingType.TILE_LAYER);
    const [headerText, setHeaderText] = useState('Upload Your Maps');

    const onClickClose = () => {
        dispatch(actionShowUploadDialog(false));
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
        Analytics.Event('Upload', 'Closed Upload Modal');
    };

    useEffect(() => {
        const searchInput = document.getElementById('search-input');

        if (showUploadDialog && searchInput) {
            searchInput.style.visibility = 'hidden';
        } else if (!showUploadDialog && searchInput) {
            searchInput.style.visibility = 'unset';
        }
    }, [showUploadDialog]);

    const showFiller = workflow === Workflow.Location || workflow === Workflow.NoLocation ? 'closed' : 'open';

    const updateHeaderText = (uploading: boolean, uploadComplete: boolean) => {
        if (uploadComplete) {
            setHeaderText('Upload complete');
        } else if (uploading) {
            setHeaderText('Uploading...');
        } else {
            setHeaderText('Upload Your Maps');
        }
    };

    return (
        <React.Fragment>
            {showUploadDialog && (
                <React.Fragment>
                    <PageFillContainer className={showFiller} />
                    <ModalContainer className={`workflow${workflow} type_${listingType}`}>
                        <Header>
                            <CloseIcon
                                onClick={() => {
                                    onClickClose();
                                }}
                            />

                            <h5>{headerText}</h5>
                        </Header>
                        <Body>
                            <UploadMapWorkflow
                                onWorkflowChange={setWorkflow}
                                onListingTypeChange={setListingType}
                                updateHeaderText={updateHeaderText}
                            />
                        </Body>
                    </ModalContainer>
                </React.Fragment>
            )}
            <MapUploadStatus />
        </React.Fragment>
    );
};

export default UploadDialog;

const ModalContainer = styled.div`
    background-color: rgba(0, 0, 0, 0.85);
    border: 1px solid rgb(255 255 255 / 0.3);
    border-radius: 6px;
    width: 550px;
    position: absolute;
    min-height: 300px;
    left: calc(50vw - 240px);
    top: 150px;
    z-index: 1000;
    transition: left 200ms;

    transition-timing-function: ease-in-out;

    &.type_IMAGE {
        left: 30px;
    }

    &.workflow2 > .modal-body {
        overflow-x: visible;
        overflow-y: visible;
        max-height: none;
    }
`;

const Header = styled.div`
    color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid rgb(255 255 255 / 0.3);
    padding: 10px 15px;
    h5 {
        color: inherit;
        font-size: 25px;
        width: 100%;
        text-align: center;
    }
`;

const CloseIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin-top: 10px;
    cursor: pointer;
    float: right;
`;

const Body = styled(ModalBody)`
    color: white;
    font-size: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 200px);

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const PageFillContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0%;
    bottom: 0;
    opacity: 0;
    z-index: 999;
    backdrop-filter: blur(2px);
    background: rgba(125, 125, 125, 0.3);
    transition: opacity 200ms ease-in-out 0ms;

    &.open {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        opacity: 1;
    }

    &.closed {
        border-top: none;
        opacity: 0;
        user-select: none;
        pointer-events: none;
    }
`;
