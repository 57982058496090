import ColorSwatch from '../Popup/color-swatch';
import AnnotationPopup from '../annotation-popup';
import FreehandDraw from './freehand-draw';

interface FreehandDrawPopupProps {
    freehandDraw: FreehandDraw;
    onDelete: () => void;
    onUpdate: (options: L.PolylineOptions) => void;
}

const FreehandDrawPopup = (props: FreehandDrawPopupProps) => {
    return (
        <AnnotationPopup
            title="Freehand Draw"
            onDelete={props.onDelete}
            eventHandlers={{
                add: (e: L.LeafletEvent) => {
                    // Place at the top of the annotation
                    const sourceTarget = e.sourceTarget;
                    const latlngs: L.LatLng[] = e.target._source._latlngs;
                    const top = latlngs.reduce((prev, current) => {
                        return prev.lat > current.lat ? prev : current;
                    });
                    sourceTarget._latlng = top;
                },
            }}
        >
            <ColorSwatch
                selectedColor={props.freehandDraw.options.color || '#3388ff'}
                onSelectColor={(color) => {
                    props.onUpdate({
                        ...props.freehandDraw.options,
                        color: color,
                    });
                }}
            />
        </AnnotationPopup>
    );
};

export default FreehandDrawPopup;
