import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Redirect, RouteComponentProps, useLocation } from 'react-router-dom';

import UriHelper, { useAnnotationQuery, usePositionQuery, useQuery } from './lib/uri-helper';
import { actionFlyTo, actionShowUploadDialog } from './store/App/actions';
import { selectConfig } from './store/App/selectors';
import { actionActiveMapCleared, actionActiveMapFetchById } from './store/Map/SuperMap/actions';

import SoarHelper from './lib/soar-helper';
import UserHelper from './lib/user-helper';

import { DashboardMode } from './store/Dashboard/model';
import { SideDrawerMode } from './store/SideDrawer/model';

import Dashboard from './components/Dashboard/dashboard';
import Explore from './components/Explore/explore';
import LandingPage from './components/LandingPage/landing-page';
import MapContainer from './components/MapView/map-container';
import SubdomainPricing from './components/Pricing/SubdomainPricing';
import CGSTLArchiveTermsAndConditions from './components/PrivacyAgreement/cgstl-archive-terms-and-conditions';
import PrivacyAgreement from './components/PrivacyAgreement/privacy-agreement';
import Skymap50TermsAndConditions from './components/PrivacyAgreement/skymap50-satellite-terms-and-conditions';
import Terms from './components/PrivacyAgreement/terms';
import LoginRegister from './components/Registration';
import { LoginModalMode } from './components/Registration/login-enum';
import LoginRegisterDialog from './components/Registration/login-register-dialog';
import PageNotFoundError from './error-page-not-found';
import { actionReuploadMapId } from './store/Dashboard/actions';
import { actionSetAnimatedLayerActive } from './store/Map/AnimatedLayer/actions';
import { HARD_CODED_ANIMATED_LAYER_START_ID } from './store/Map/AnimatedLayer/animated-layer-datasource';
import { selectAnimatedLayers } from './store/Map/AnimatedLayer/selector';
import { actionSatelliteResetAOI } from './store/Map/SatelliteArchive/actions';
import { actionSetActiveOrder, actionSetActiveOrderFetchById } from './store/Map/Tasks/actions';
import { actionSetCategoryMode } from './store/SideDrawer/actions';
import OrderPayment from './components/Dashboard/SatelliteOrders/order-payment';

const Routes = () => {
    const token = UserHelper.validateAndGetIdToken();
    const query = useQuery();
    const config = useSelector(selectConfig);
    const url = useLocation();
    const [position, zoom] = usePositionQuery();
    const annotationQuery = useAnnotationQuery();
    const animatedLayers = useSelector(selectAnimatedLayers);

    const dispatch = useDispatch();

    const clearMap = () => {
        dispatch(actionActiveMapCleared());
        dispatch(actionSetAnimatedLayerActive(undefined));
        dispatch(actionSatelliteResetAOI());
        dispatch(actionSetCategoryMode(undefined));
        dispatch(actionShowUploadDialog(false));
        dispatch(actionSetActiveOrder(undefined));
    };

    const handlePositionQueryParameter = useCallback(
        (immediately: boolean) => {
            if (position && zoom) {
                dispatch(actionFlyTo(position, zoom, immediately));
            }
        },
        [position, zoom, dispatch]
    );

    // A link was followed to an explicit location and zoom level eg.  ?pos=-31.22,114.3,3
    useEffect(() => {
        handlePositionQueryParameter(true);
        // TODO Disabled `handlePositionQueryParameter` as dependency, causes back in browser to reset
        // not sure what this will break if anything as looks like it may be handled differently now
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Soar+ configured for forced login and the user requested a password reset
    if (config.BEHAVIOURS.FORCED_LOGIN) {
        if (url.pathname.includes('/password_reset')) {
            return (
                <Route
                    render={(_) => (
                        <LoginRegisterDialog
                            isOpen={true}
                            initialMode={LoginModalMode.PASSWORD_RESET}
                            onClose={() => UriHelper.navigateToPath('/')}
                        />
                    )}
                />
            );
        }
    }

    // Soar+ configured for forced login
    if (config.BEHAVIOURS.FORCED_LOGIN && !token) {
        return <Route render={(_) => <LoginRegister subDomain={config.SUBDOMAIN} />} />;
    }

    return (
        <Switch>
            <Redirect exact path="/home" to="/" />
            <Redirect exact path="/index.php" to="/" />
            <Redirect exact path="/index.html" to="/" />
            <Route
                exact
                path="/"
                render={(_) => {
                    // Handle any old tilelayer links eg. https://soar.earth?tileLayer=3000
                    const oldTileLayerUrlParameter = query.get('tileLayer');
                    if (oldTileLayerUrlParameter) {
                        return <Redirect to={`/maps/${oldTileLayerUrlParameter}`} />;
                    }

                    const basemapParameter = query.get(UriHelper.BASEMAP_URI_KEY);
                    const addBasemapParameter = basemapParameter
                        ? `&${UriHelper.BASEMAP_URI_KEY}=${basemapParameter}`
                        : '';

                    if (annotationQuery) {
                        return <Redirect to={`/maps/?annotations=${annotationQuery}${addBasemapParameter}`} />;
                    }

                    // Don't display landing page in Soar+
                    if (!SoarHelper.isSoar()) {
                        return <Redirect to={'/maps'} />;
                    }

                    // Follow share link
                    const sentinelWMSParameter = query.get('service');
                    if (sentinelWMSParameter) {
                        return <Redirect to={`${query.toString()}`} />;
                    }

                    dispatch(actionShowUploadDialog(false));
                    return <LandingPage />;
                }}
            />

            <Route
                exact
                path="/animated-maps/:animatedLayerId"
                render={(props: RouteComponentProps<{ animatedLayerId: string }>) => {
                    const animatedLayerId = props.match.params.animatedLayerId;
                    if (Number(animatedLayerId) > HARD_CODED_ANIMATED_LAYER_START_ID) {
                        const animatedLayer = animatedLayers.find((t) => t.id === Number(animatedLayerId));
                        dispatch(actionSetAnimatedLayerActive(animatedLayer));
                        if (animatedLayer) {
                            dispatch(actionFlyTo(animatedLayer.boundingBox, undefined, false));
                        }
                    }

                    return <MapContainer drawerMode={SideDrawerMode.MAPS} />;
                }}
            />

            <Redirect exact path="/animated-maps" to="/maps" />

            <Route
                exact
                path="/my-maps"
                render={(_) => {
                    if (!token || !SoarHelper.isSoar()) {
                        return <Redirect to="/maps" />;
                    }
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.YOUR_MAPS} />;
                }}
            />

            <Route
                exact
                path="/my-maps/:tileLayer"
                render={(props: RouteComponentProps<{ tileLayer: string }>) => {
                    clearMap();
                    const tileLayerId = props.match.params.tileLayer;

                    if (Number(tileLayerId) < HARD_CODED_ANIMATED_LAYER_START_ID) {
                        const pos = UriHelper.tryGetParam(UriHelper.POSITION_URI_KEY);
                        dispatch(actionActiveMapFetchById(tileLayerId, pos === null));
                    }
                    return <MapContainer drawerMode={SideDrawerMode.YOUR_MAPS} />;
                }}
            />

            <Route
                exact
                path="/my-bookmarks"
                render={(_) => {
                    if (!token) {
                        return <Redirect to="/maps" />;
                    }
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.MY_BOOKMARKS} />;
                }}
            />

            <Route
                exact
                path="/task/:taskId"
                render={(props: RouteComponentProps<{ taskId: string }>) => {
                    clearMap();
                    const taskId = props.match.params.taskId;
                    dispatch(actionSetActiveOrderFetchById(Number(taskId)));
                    return <MapContainer drawerMode={SideDrawerMode.YOUR_MAPS} />;
                }}
            />

            <Route
                exact
                path="/maps"
                render={(_) => {
                    clearMap();
                    if (SoarHelper.isSoar()) {
                        return <MapContainer drawerMode={SideDrawerMode.MAPS} />;
                    } else {
                        return <MapContainer drawerMode={SideDrawerMode.SUBDOMAIN_MAPS} />;
                    }
                }}
            />

            <Route
                exact
                path="/maps/:tileLayer"
                render={(props: RouteComponentProps<{ tileLayer: string }>) => {
                    const tileLayerId = props.match.params.tileLayer;
                    clearMap();
                    dispatch(actionSetAnimatedLayerActive(undefined));
                    handlePositionQueryParameter(true);

                    if (Number(tileLayerId) < HARD_CODED_ANIMATED_LAYER_START_ID) {
                        const pos = UriHelper.tryGetParam(UriHelper.POSITION_URI_KEY);
                        dispatch(actionActiveMapFetchById(tileLayerId, pos === null));
                    }

                    if (SoarHelper.isSoar()) {
                        return <MapContainer drawerMode={SideDrawerMode.MAPS} />;
                    } else {
                        return <MapContainer drawerMode={SideDrawerMode.SUBDOMAIN_MAPS} />;
                    }
                }}
            />

            <Route
                exact
                path="/satellites"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.SATELLITE} />;
                }}
            />

            <Route
                exact
                path="/satellites/skymap50"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.SATELLITE_SKYMAP50} />;
                }}
            />

            <Route
                exact
                path="/satellites/skymap50/new-collect"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.SATELLITE_SKYMAP50_IMAGE_THE_FUTURE} />;
                }}
            />

            <Route
                exact
                path="/satellites/skymap50/archive"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.SATELLITE_SKYMAP50_SEARCH_ARCHIVE} />;
                }}
            />

            <Route
                exact
                path="/satellites/sentinel"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.SATELLITE_SENTINEL} />;
                }}
            />

            <Route
                exact
                path="/satellites/landsat"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.SATELLITE_LANDSAT} />;
                }}
            />

            <Route
                exact
                path="/satellites/cgstl"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.SATELLITE_CGSTL} />;
                }}
            />

            <Route
                exact
                path="/satellites/cgstl/archive50"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_50} />;
                }}
            />

            <Route
                exact
                path="/satellites/cgstl/archive75"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_75} />;
                }}
            />

            <Route
                exact
                path="/satellites/cgstl/archive100"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.SATELLITE_CGSTL_ARCHIVE_100} />;
                }}
            />

            <Route
                exact
                path="/upload"
                render={(_) => {
                    clearMap();
                    dispatch(actionShowUploadDialog(true));
                    dispatch(actionReuploadMapId(undefined));
                    return <MapContainer drawerMode={SideDrawerMode.SHARE_MAP} />;
                }}
            />

            <Route
                exact
                path="/reupload/:listingId"
                render={(props: RouteComponentProps<{ listingId: string }>) => {
                    const listingId = props.match.params.listingId;
                    clearMap();
                    dispatch(actionReuploadMapId(listingId));
                    dispatch(actionShowUploadDialog(true));
                    return <MapContainer drawerMode={SideDrawerMode.SHARE_MAP} />;
                }}
            />

            <Route
                exact
                path="/profile/:wallet"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.PROFILE} />;
                }}
            />

            <Route
                exact
                path="/profile/:wallet/all-maps"
                render={(_) => {
                    clearMap();
                    return <MapContainer drawerMode={SideDrawerMode.PROFILE_ALL_MAPS} />;
                }}
            />

            <Redirect exact path="/profile" to="/dashboard" />
            <Redirect exact path="/my_profile" to="/dashboard" />
            <Redirect exact path="/dashboard" to="/dashboard/profile" />
            <Route
                exact
                path="/dashboard/account-settings"
                render={(_) => <Dashboard dashboardMode={DashboardMode.ACCOUNT_SETTINGS} />}
            />

            <Route
                exact
                path="/dashboard/profile"
                render={(_) => {
                    clearMap();
                    return <Dashboard dashboardMode={DashboardMode.PROFILE} />;
                }}
            />
            <Route
                exact
                path="/dashboard/my-imagery"
                render={(_) => {
                    clearMap();
                    return <Dashboard dashboardMode={DashboardMode.MY_IMAGERY} />;
                }}
            />
            <Route
                exact
                path="/dashboard/my-bookmarks"
                render={(_) => <Dashboard dashboardMode={DashboardMode.MY_BOOKMARKS} />}
            />
            <Route
                exact
                path="/dashboard/satellite-queue"
                render={(_) => <Dashboard dashboardMode={DashboardMode.SATELLITE_QUEUE} />}
            />
            <Route exact path="/dashboard/payout" render={(_) => <Dashboard dashboardMode={DashboardMode.PAYOUT} />} />

            <Route
                exact
                path="/dashboard/purchase-history"
                render={(_) => <Dashboard dashboardMode={DashboardMode.PURCHASE_HISTORY} />}
            />

            <Route
                exact
                path="/dashboard/sales-history"
                render={(_) => <Dashboard dashboardMode={DashboardMode.SALES_HISTORY} />}
            />

            <Route
                exact
                path="/dashboard/terms-and-condition"
                render={(_) => <Dashboard dashboardMode={DashboardMode.TERMS_AND_CONDITION} />}
            />
            <Route
                exact
                path="/dashboard/overview"
                render={(_) => <Dashboard dashboardMode={DashboardMode.SUBDOMAIN_OVERVIEW} />}
            />
            <Route
                exact
                path="/dashboard/users"
                render={(_) => <Dashboard dashboardMode={DashboardMode.USER_MANAGEMENT} />}
            />
            <Route
                exact
                path="/dashboard/data"
                render={(_) => <Dashboard dashboardMode={DashboardMode.DATA_MANAGEMENT} />}
            />
            <Route
                exact
                path="/dashboard/account"
                render={(_) => <Dashboard dashboardMode={DashboardMode.ACCOUNT_MANAGEMENT} />}
            />

            <Route path="/pricing" component={SubdomainPricing} />
            <Route path="/plus" component={SubdomainPricing} />
            <Route path="/terms" component={Terms} />
            <Route path="/skymap50-terms" component={Skymap50TermsAndConditions} />
            <Route path="/cgstl-archive-terms" component={CGSTLArchiveTermsAndConditions} />
            <Route exact path="/privacy" component={PrivacyAgreement} />
            <Route path="/explore" component={Explore} />
            <Redirect from="/browse/:filehash" to="/maps/:filehash" />
            <Route
                exact
                path="/order/payment"
                render={(_) => {
                    if (!token) {
                        return <Redirect to="/login" />;
                    }
                    return <OrderPayment />;
                }}
            />
            <Redirect exact path="/task_payment" to="/order/payment" />
            <Route
                exact
                path="/login"
                render={(_) => <MapContainer loginMode={!token ? LoginModalMode.LOGIN : undefined} />}
            />
            <Route path="/password_reset" render={(_) => <MapContainer loginMode={LoginModalMode.PASSWORD_RESET} />} />
            <Route
                exact
                path="/register"
                render={(_) => <MapContainer loginMode={!token ? LoginModalMode.REGISTER : undefined} />}
            />
            <Route
                path="/forgot_password"
                render={(_) => <MapContainer loginMode={!token ? LoginModalMode.FORGOT_PASSWORD : undefined} />}
            />
            <Route path="/" component={PageNotFoundError} />
        </Switch>
    );
};

export default Routes;
