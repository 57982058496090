import React from 'react';
import PrivacyAgreement from '../PrivacyAgreement/privacy-agreement';
import TermsAndConditions from '../PrivacyAgreement/terms-and-conditions';
import DashboardContainer from './Shared/dashboard-container';
import NavigationPanel from './Shared/navigation-panel';
import { isMobileVersion } from '../../lib/soar-helper';

const DashboardTermsAndCondition = () => {
    return (
        <React.Fragment>
            {isMobileVersion && <NavigationPanel label="Terms & Conditions" />}
            <DashboardContainer marginTop={isMobileVersion ? 0 : 60}>
                <TermsAndConditions />
                <PrivacyAgreement />
            </DashboardContainer>
        </React.Fragment>
    );
};

export default DashboardTermsAndCondition;
