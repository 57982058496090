import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiAnalytics from '../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote } from '../../../api/model';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { actionSetHighlightedListingId } from '../../../store/Map/MapSelection/actions';
import { ListingGroup } from '../../../store/Map/MapSelection/model';
import { selectHighlightedListingGroup } from '../../../store/Map/MapSelection/selectors';
import MultiSelectModal from '../../MapView/TileLayers/PolygonCluster/multi-select-modal';
import PreviewTile from '../../Shared/preview-tile';
import { PreviewList } from './category-maps-list';

interface CategoryPreviewMultiselectProps {
    tileLayerGroup: ListingGroup;
}

const CategoryPreviewMultiselect = (props: CategoryPreviewMultiselectProps) => {
    const dispatch = useDispatch();
    const [showMultiSelectModal, setShowMultiSelectModal] = useState(false);
    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);

    const setTileLayerHighlighted = (tileLayerId: number | undefined) =>
        dispatch(actionSetHighlightedListingId(tileLayerId));

    const onMouseEnter = (tileLayerId: number) => {
        setTileLayerHighlighted(tileLayerId);
        Analytics.Event('Category', 'Mouse ms tile layer', tileLayerId);
    };

    const onMouseLeave = () => {
        setTileLayerHighlighted(undefined);
    };

    const onClick = () => {
        setShowMultiSelectModal(true);
        Analytics.Event('Category', 'Clicked show ms');
    };

    const onSelectListing = (listingId: number) => {
        UriHelper.navigateToMap(listingId);
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.CLUSTER, listingId);
        Analytics.Event('Category', 'Clicked ms tile layer', listingId);
    };

    if (!props.tileLayerGroup.tileLayers) return <React.Fragment />;

    const firstTileLayer = props.tileLayerGroup.tileLayers.sort((a, b) => b.id - a.id)[0];
    const isHighlighted =
        (highlightedTileLayerGroup &&
            highlightedTileLayerGroup.tileLayers.sort((a, b) => b.id - a.id)[0].id === firstTileLayer.id) ||
        false;
    return (
        <React.Fragment>
            {showMultiSelectModal ? (
                <MultiSelectModal
                    tileLayerGroup={props.tileLayerGroup}
                    toggle={() => setShowMultiSelectModal(false)}
                    onSelectTileLayer={(id) => onSelectListing(id)}
                />
            ) : null}

            <PreviewList
                key={firstTileLayer.id}
                onMouseEnter={() => onMouseEnter(firstTileLayer.id)}
                onMouseLeave={() => onMouseLeave()}
                onClick={() => onClick()}
                title={firstTileLayer.title}
                isHighlighted={isHighlighted}
            >
                <PreviewTile
                    key={firstTileLayer.id}
                    title={firstTileLayer.title}
                    previewUrl={ListingHelper.getPreviewUrlForListing(firstTileLayer.id, 'small')}
                    subText={`and ${props.tileLayerGroup.tileLayers.length - 1} other map${
                        props.tileLayerGroup.tileLayers.length > 2 ? 's' : ''
                    }`}
                />
            </PreviewList>
        </React.Fragment>
    );
};

export default CategoryPreviewMultiselect;
