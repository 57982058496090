import {
    LeafletContextInterface,
    MediaOverlayProps,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createLayerHook,
    extendContext,
} from '@react-leaflet/core';
import L from 'leaflet';
import { createSVGTextElement } from './svg-text';
import { TextBox } from './text-control';
import { svgTextBoxResizeElement } from './svg-text-box-resize';

export const UPDATE_TEXT_BOX_EVENT = 'update-textbox';

interface TextBoxAnnotationProps extends MediaOverlayProps {
    text: TextBox;
    onUpdateTextBox: (text: TextBox) => void;
    children?: React.ReactNode;
}

const textBoxOptions: L.PolylineOptions = {
    interactive: true,
    fill: true,
    fillColor: 'transparent',
    color: 'transparent',
    bubblingMouseEvents: false,
};

const createTextBoxAnnotation = (props: TextBoxAnnotationProps, context: LeafletContextInterface) => {
    const svgText = createSVGTextElement(props, context);
    const svgOverlayElement = createElementObject<L.SVGOverlay, TextBoxAnnotationProps>(svgText.instance, context);

    const textBox = new L.Rectangle(props.text.bounds, textBoxOptions);

    const textBoxElement = createElementObject<L.Rectangle, TextBoxAnnotationProps>(
        textBox,
        extendContext(context, { overlayContainer: textBox })
    );

    const editRectangleElement = svgTextBoxResizeElement(
        {
            bounds: props.text.bounds,
            context: context,
            rectangleElement: textBoxElement,
        },
        context
    );

    textBoxElement.instance.on('add', () => {
        context.map.addLayer(svgOverlayElement.instance);
    });

    textBoxElement.instance.on('remove', () => {
        context.map.removeLayer(svgOverlayElement.instance);
        context.map.removeLayer(editRectangleElement.instance);
    });

    textBoxElement.instance.on('mouseover', () => {
        textBoxElement.instance.setStyle({ color: '#3388ff', weight: 3 });
    });

    textBoxElement.instance.on('mouseout', () => {
        textBoxElement.instance.setStyle({ color: 'transparent' });
    });

    textBoxElement.instance.on('click', () => {
        context.map.dragging.disable();
        context.map.addLayer(editRectangleElement.instance);

        context.map.on('click', () => {
            context.map.removeLayer(editRectangleElement.instance);
            context.map.dragging.enable();
        });
    });

    textBoxElement.instance.on('update', () => {
        console.log('update');
        const bounds = textBoxElement.instance.getBounds();
        svgOverlayElement.instance.setBounds(bounds);
        editRectangleElement.instance.fireEvent('update');

        if (!bounds.equals(props.text.bounds)) {
            console.log('Bounds update');
            props.onUpdateTextBox({ ...props.text, bounds: bounds });
        }
    });

    textBoxElement.instance.on('resize-end', (e) => {
        console.log('resize-end');
        const bounds = (e as any).bounds as L.LatLngBounds;
        if (!bounds.equals(props.text.bounds)) {
            console.log('Bounds difference');
            svgOverlayElement.instance.fireEvent('update-bounds', { bounds: bounds });
        }
    });

    textBoxElement.instance.on('text-update', (e) => {
        console.log('text-update');
        svgOverlayElement.instance.fireEvent('text-update', e);
    });

    textBoxElement.instance.on('resize', (e) => {
        console.log('resize');
        const bounds = (e as any).bounds as L.LatLngBounds;
        svgOverlayElement.instance.setBounds(bounds);
        textBoxElement.instance.setBounds(bounds);
    });

    svgOverlayElement.instance.on('update-bounds', (e) => {
        console.log('update-bounds');
        const bounds = (e as any).bounds as L.LatLngBounds;
        svgOverlayElement.instance.setBounds(bounds);
        textBoxElement.instance.setBounds(bounds);
    });

    return textBoxElement;
};

const updateTextBoxAnnotation = (
    instance: L.Rectangle,
    props: TextBoxAnnotationProps,
    prevProps: TextBoxAnnotationProps
) => {
    instance.fireEvent('text-update', { text: props.text.text });
    instance.fireEvent('update');
};

const useTextBoxAnnotationElement = createElementHook<L.Rectangle, TextBoxAnnotationProps, LeafletContextInterface>(
    createTextBoxAnnotation,
    updateTextBoxAnnotation
);

const useTextBoxAnnotation = createLayerHook<L.Rectangle, TextBoxAnnotationProps>(useTextBoxAnnotationElement);

const TextBoxAnnotation = createContainerComponent(useTextBoxAnnotation);

export default TextBoxAnnotation;
