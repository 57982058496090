import React from 'react';
import L from 'leaflet';
import styled from 'styled-components';
import AnnotationPopup from '../annotation-popup';
import { MarkdownTextArea } from '../../../Shared/markdown-textarea';
import { TextBox } from './text-control';
import { UPDATE_TEXT_BOX_EVENT } from './svg-text-box';

interface TextBoxPopupProps {
    text: TextBox;
    onSetText: (text: string) => void;
    onDeleteText: () => void;
}

const TextBoxPopup = (props: TextBoxPopupProps) => {
    return (
        <AnnotationPopup
            title="Text"
            keepInView={false}
            closeButton={false}
            autoClose={true}
            closeOnClick={true}
            onDelete={props.onDeleteText}
            eventHandlers={{
                add: (e: L.LeafletEvent) => {
                    const source = e.target._source as L.Rectangle;
                    const sourceTarget = e.sourceTarget;
                    source.addEventListener('update', (e) => {
                        const bounds = e.target._bounds;
                        const updatedLatLng = new L.LatLng(
                            bounds.getNorth(),
                            (bounds.getEast() + bounds.getWest()) / 2
                        );
                        requestAnimationFrame(() => {
                            sourceTarget.setLatLng(updatedLatLng);
                        });
                    });
                    const bounds = props.text.bounds;
                    const popupPosition = new L.LatLng(bounds.getNorth(), (bounds.getEast() + bounds.getWest()) / 2);
                    requestAnimationFrame(() => {
                        sourceTarget.setLatLng(popupPosition);
                    });
                },
            }}
        >
            <MarkdownTextareaContainer>
                <MarkdownTextArea
                    initialEditorHeight={120}
                    value={props.text.text}
                    setInputValue={(value) => props.onSetText(value)}
                />
            </MarkdownTextareaContainer>
        </AnnotationPopup>
    );
};

export default TextBoxPopup;

const MarkdownTextareaContainer = styled.div`
    width: 300px;
`;
