import L from 'leaflet';
import React from 'react';
import { Marker } from 'react-leaflet';
import ListingHelper from '../../../../lib/listing-helper';
import { ListingGroup } from '../../../../store/Map/MapSelection/model';
import './mobile-clustering.css';

interface MobileClusteringProps {
    sortedTileLayerDictionary: ListingGroup[];
    onSelectTileLayer: (tileLayerId: number) => void;
}

const NUMBER_OF_MARKERS_TO_SHOW = 10;

const MobileClustering = (props: MobileClusteringProps) => {
    return (
        <React.Fragment>
            {props.sortedTileLayerDictionary.slice(0, NUMBER_OF_MARKERS_TO_SHOW).map((t) => {
                const icon = new L.DivIcon({
                    iconSize: [64.0, 52.0],
                    iconAnchor: [21.0, 45.0],
                    className: 'leaflet-mobile-icon',
                    html: `
                        <div class="leaflet-mobile-icon" style="background: 'transparent'">
                            <img src="/assets/marker_photo.png" width="64px" height="52px" />
                            <div style="width: 42px; height: 31px; margin-top: -49px; margin-left: 3px; background: black">
                            <img src="${ListingHelper.getPreviewUrlForListing(
                                t.tileLayers[0].id
                            )}" style="height: 100%; width: 100%; object-fit: contain; background: #3e3e3e" />
                            </div>
                        </div>
                    `,
                });

                return (
                    <Marker
                        eventHandlers={{
                            click: () => {
                                props.onSelectTileLayer(t.tileLayers[0].id);
                            },
                        }}
                        position={t.latlngBounds.getCenter()}
                        icon={icon}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MobileClustering;
