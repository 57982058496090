import { useWindowHeight } from '@react-hook/window-size';
import React from 'react';
import styled, { css } from 'styled-components';
import { isMobileVersion } from '../../../lib/soar-helper';

interface Props {
    marginTop?: number;
    children: React.ReactNode;
}

const DashboardContainer = ({ children, marginTop = 0 }: Props) => {
    const screenHeight = useWindowHeight();
    return <Container height={screenHeight - 60 - marginTop}>{children}</Container>;
};

export default DashboardContainer;

interface ContainerProps {
    height: number;
}

const Container = styled.div<ContainerProps>`
    ${(props) =>
        isMobileVersion &&
        css`
            height: ${props.height}px;
            width: 100vw;
            overflow-x: hidden;
            overflow-y: auto;
        `}
`;
