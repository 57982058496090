import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    actionSetHighlightedListingGroup,
    actionSetHighlightedListingId,
} from '../../../store/Map/MapSelection/actions';
import { selectHighlightedListingGroup } from '../../../store/Map/MapSelection/selectors';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import PreviewTile from '../../Shared/preview-tile';
import { actionFetchBookmarkedMaps } from '../../../store/Map/BookMarks/actions';
import NoBookmarkedMaps from './no-bookmarked-maps';
import Analytics from '../../../lib/user-analytics';
import { selectBookmarkedMaps, selectBookmarkedMapsIsLoading } from '../../../store/Map/BookMarks/selectors';
import { PulseLoader } from '../../Shared/pulse-loader';
import ApiAnalytics from '../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote } from '../../../api/model';

const BookmarkedMaps = () => {
    const dispatch = useDispatch();

    const setTileLayerHighlighted = (tileLayerId: number | undefined) =>
        dispatch(actionSetHighlightedListingId(tileLayerId));
    const invalidateHighlightedTileLayerGroup = () => dispatch(actionSetHighlightedListingGroup(undefined));

    const visibleListingGroups = useSelector(selectBookmarkedMaps);
    const bookmarkMapsIsLoading = useSelector(selectBookmarkedMapsIsLoading);

    const handleFetchBookmarks = useCallback(() => {
        dispatch(actionFetchBookmarkedMaps());
    }, [dispatch]);

    useEffect(() => {
        if (!visibleListingGroups?.length) {
            handleFetchBookmarks();
        }
    }, [handleFetchBookmarks, visibleListingGroups?.length]);

    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);

    const onMouseEnter = (tileLayerId: number) => {
        setTileLayerHighlighted(tileLayerId);
        invalidateHighlightedTileLayerGroup();
    };

    const onMouseLeave = () => {
        setTileLayerHighlighted(undefined);
    };

    const onClickListing = (listingId: number) => {
        UriHelper.navigateToMap(listingId);
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.SEARCH, listingId);
        Analytics.Event('Side Drawer - Your Bookmarks', 'Clicked To View', listingId);
    };

    return (
        <React.Fragment>
            {!visibleListingGroups?.length && bookmarkMapsIsLoading ? <PulseLoader iconSize="2rem" /> : null}
            {!visibleListingGroups?.length && !bookmarkMapsIsLoading ? <NoBookmarkedMaps /> : null}
            {visibleListingGroups?.map((tileLayer) => {
                const isHighlighted =
                    (highlightedTileLayerGroup &&
                        highlightedTileLayerGroup.tileLayers.length === 1 &&
                        highlightedTileLayerGroup.tileLayers[0].id === tileLayer.id) ||
                    false;
                return (
                    <BookmarkContent
                        key={tileLayer.id}
                        onMouseEnter={() => onMouseEnter(tileLayer.id)}
                        onMouseLeave={() => onMouseLeave()}
                        onClick={() => onClickListing(tileLayer.id)}
                        title={tileLayer.title}
                        isHighlighted={isHighlighted}
                    >
                        <PreviewTile
                            title={tileLayer.title}
                            previewUrl={ListingHelper.getPreviewUrlForListing(tileLayer.id, 'small')}
                            subText={`by ${tileLayer.userName}`}
                        />
                    </BookmarkContent>
                );
            })}
        </React.Fragment>
    );
};

export default BookmarkedMaps;

const BookmarkContent = styled.div<{ isHighlighted: boolean }>`
    cursor: pointer;
    height: 48px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin: 0 0 3px 0;
    border: ${(props) => (props.isHighlighted ? '1px solid #eed309' : '1px solid rgba(0, 0, 0, 0.3)')};

    &:hover {
        border: 1px solid #eed309;
    }
`;
