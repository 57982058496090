import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectSideDrawerCollapse } from '../../../../store/SideDrawer/selectors';
import { actionSetSideDrawerCollapseAction } from '../../../../store/SideDrawer/actions';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';

interface DrawerHeaderProps {
    handleBack?: () => void;
    title?: string;
    icon?: string;
    collapsible?: boolean;
}

/**
 * DrawerHeader
 * Standardized header for wide drawer
 * @optional handleBack () => void
 * @optional title string
 * @optional icon boolean
 * @optional collapsible boolean
 * Turns on closing drawer functionality and activates icon
 */

const DrawerHeader = (props: DrawerHeaderProps) => {
    const sideDrawerCollapse = useSelector(selectSideDrawerCollapse);

    const dispatch = useDispatch();
    const handleSideDrawerCollapse = (collapse: boolean) => dispatch(actionSetSideDrawerCollapseAction(collapse));

    return (
        <Header closeDraw={sideDrawerCollapse}>
            {props.collapsible ? (
                <ChevronIconContainer>
                    <ChevronIcon
                        onClick={() => handleSideDrawerCollapse(!sideDrawerCollapse)}
                        className={sideDrawerCollapse ? 'fa fa-chevron-down' : 'fa fa-chevron-up'}
                    />
                </ChevronIconContainer>
            ) : null}
            <HeaderContent alignTitle={!props.handleBack}>
                {props.icon ? <Icon src={props.icon} /> : null}
                {props.title ? <Title>{props.title}</Title> : null}
            </HeaderContent>
            <CloseIconContainer>
                <StyledCloseIcon
                    onClick={() => {
                        UriHelper.navigateToPath('/maps');
                        Analytics.Event('Active Map Drawer', 'Clicked close');
                    }}
                />
            </CloseIconContainer>
        </Header>
    );
};

export default DrawerHeader;

interface CloseDrawProps {
    closeDraw?: boolean;
    alignTitle?: boolean;
}

const Header = styled.div<CloseDrawProps>`
    border-bottom: ${(props) => (props.closeDraw ? '' : '1px solid rgba(255, 255, 255, 0.3)')};
    height: 49px;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: hidden;
`;

const HeaderContent = styled.div<CloseDrawProps>`
    margin: ${(props) => (props.alignTitle ? 'auto' : '6px 0px 0px 40px')};
`;
const ChevronIconContainer = styled.div`
    user-select: none;
`;

const ChevronIcon = styled.i`
    float: right;
    padding: 15px 0px 0px 10px;
    color: rgb(255 255 255 / 80%);
    font-size: 18px;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 32px;
    height: 32px;
`;

const Title = styled.span`
    font-size: 24px;
    color: white;
    text-transform: uppercase;
    margin-left: 10px;
    vertical-align: middle;
`;
const StyledCloseIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin: 12px 10px 0px 0px;
    cursor: pointer;
    float: right;
`;

const CloseIconContainer = styled.div`
    width: 100%;
    user-select: none;
`;
