import { Masonry } from 'masonic';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import UriHelper from '../../../lib/uri-helper';
import MobileMasonryCard from '../../Mobile/Shared/mobile-masonry-card';
import { PulseLoader } from '../../Shared/pulse-loader';
import { StyledButton } from '../../Shared/styled-button';
import NoImageryModal from '../Imagery/Shared/no-imagery-modal';
import profileIconUrl from '../profile-icon-url';
import BookmarkMasonaryCard from './bookmark-masonary-card';
import { isMobileVersion } from '../../../lib/soar-helper';

interface BookmarkMasonryProps {
    listings: ListingDTO[];
}

const BookmarkMasonary = (props: BookmarkMasonryProps) => {
    if (props.listings.length === 0) {
        return (
            <React.Fragment>
                <NoImageryModal
                    icon={profileIconUrl.noImagesUrl}
                    title={"It looks like you haven't bookmarked any maps"}
                    text={'Any maps that you bookmark will be available here for quick access'}
                    mobileText={''}
                />
                <Button onClick={() => UriHelper.navigateToPath('/maps')}>Find Maps To Bookmark</Button>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {isMobileVersion ? (
                <MobileInfiniteScroll
                    dataLength={props.listings.length}
                    next={() => ''}
                    height={'100%'}
                    hasMore={props.listings.length === 0 ? false : true}
                    loader={<PulseLoader />}
                >
                    {props.listings?.map((listing) => {
                        return <MobileMasonryCard listing={listing} />;
                    })}
                </MobileInfiniteScroll>
            ) : (
                <Masonry
                    items={props.listings.map((value, index) => {
                        return { id: index, listing: value };
                    })}
                    columnGutter={isMobileVersion ? 0 : 16}
                    columnWidth={isMobileVersion ? 140 : 280}
                    render={MasonaryCard}
                />
            )}
        </React.Fragment>
    );
};

export default BookmarkMasonary;

interface MasonaryCardProps {
    index: number;
    data: {
        id: number;
        listing: ListingDTO;
    };
    width: number;
}

const MasonaryCard = (props: MasonaryCardProps) => {
    return <BookmarkMasonaryCard listing={props.data.listing} />;
};

const Button = styled(StyledButton)`
    margin: 12px auto;
    display: block;
    z-index: 1000;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

const MobileInfiniteScroll = styled(InfiniteScroll)`
    overflow-x: hidden !important;
`;
