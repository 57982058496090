import React from 'react';
import { Popup, PopupProps } from 'react-leaflet';
import styled from 'styled-components';

interface AnnotationPopupProps extends PopupProps {
    title: string;
    onDelete: () => void;
    children?: React.ReactNode;
}

const AnnotationPopup = (props: AnnotationPopupProps) => {
    return (
        <StyledPopup keepInView={true} closeButton={false} {...props}>
            <TitleContainer>
                <Title>{props.title}</Title>
                <DeleteButton
                    title="Rectangle"
                    onClick={() => {
                        props.onDelete();
                    }}
                >
                    <DeleteIcon className="fa fa-trash" title="Delete" />
                </DeleteButton>
            </TitleContainer>

            <Container>{props.children}</Container>
        </StyledPopup>
    );
};

export default AnnotationPopup;

const StyledPopup = styled(Popup)`
    &.leaflet-popup > .leaflet-popup-content-wrapper {
        background-color: rgba(20, 20, 19, 1);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 6px;
        margin: 0;
        padding: 0;
        z-index: 10;
        width: auto;
    }

    &.leaflet-popup > .leaflet-popup-content-wrapper {
        padding: 0 !important;
    }

    &.leaflet-popup > .leaflet-popup-content-wrapper > .leaflet-popup-content {
        margin: 0px !important;
        padding: 0 !important;
        width: auto !important;
    }

    &.leaflet-popup > .leaflet-popup-tip-container > .leaflet-popup-tip {
        background-color: rgba(20, 20, 19, 1);
        border: 1px solid rgba(255, 255, 255, 0.5);

        z-index: 10;
    }
`;

const TitleContainer = styled.div`
    padding: 7px 0 7px 0;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

const Title = styled.h4`
    color: white;
    display: block;
    text-align: left;
    font-size: 1.1rem;
    padding: 0 0 0 5px;
    margin: 0;
`;

const Container = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
`;

const DeleteButton = styled.button`
    position: fixed;
    top: 11px;
    right: 6px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
`;

const DeleteIcon = styled.i`
    color: white;
    font-size: 1.1rem;
    color: rgba(241, 13, 0, 0.7);
`;
