import L from 'leaflet';
import { LeafletContextInterface, createElementObject, extendContext } from '@react-leaflet/core';

/**
 * The ghost element is an invisible rectangle that floats over the images border.
 * It is used to detect when the cursor is close to the image
 */
interface GhostElementProps {
    bounds: L.LatLngBounds;
    focusElement: Readonly<{ instance: L.Rectangle; context: Readonly<{ map: L.Map }> }>;
    context: LeafletContextInterface;
}

const ghostOptions: L.PolylineOptions = {
    color: 'transparent',
    weight: 30,
    opacity: 1.0,
    fill: false,
    interactive: true,
    bubblingMouseEvents: false,
};

export const createGhostElement = (props: GhostElementProps, context: LeafletContextInterface) => {
    const ghost = new L.Rectangle(props.bounds, ghostOptions);
    const ghostElement = createElementObject<L.Rectangle>(ghost, extendContext(context, { overlayContainer: ghost }));

    ghostElement.instance.on('mouseover', (e: L.LeafletMouseEvent) => {
        // Add the focus element to the map and fire its mouseover event
        context.map.addLayer(props.focusElement.instance);
        props.focusElement.instance.fireEvent('mouseover', e);
    });

    ghostElement.instance.on('mouseout', (e: L.LeafletMouseEvent) => {
        // Remove the focus element from the map
        props.focusElement.instance.fireEvent('mouseout', e);
        context.map.removeLayer(props.focusElement.instance);
    });

    ghostElement.instance.on('click', (e: L.LeafletMouseEvent) => {
        // Propagate the on click event to the focus element
        props.focusElement.instance.fireEvent('click', e);
    });

    return ghostElement;
};
