import React from 'react';
import { useEffect } from 'react';
import { isMobileVersion } from '../../../lib/soar-helper';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import styled from 'styled-components';
import { actionUpdatePurchasedListings } from '../../../store/Purchases/actions';
import { selectPurchasedListings } from '../../../store/Purchases/selectors';
import NoImageryModal from '../Imagery/Shared/no-imagery-modal';
import NavigationPanel from '../Shared/navigation-panel';
import { PurchaseListItem } from './purchase-list-item';
import DashboardContainer from '../Shared/dashboard-container';

// TODO The no imagery may not make sense anymore as drones removed #4414 - DP
const PurchaseHistory = () => {
    const dispatch = useDispatch();
    const myPurchases = useSelector(selectPurchasedListings);

    useEffect(() => {
        const updatePurchases = () => dispatch(actionUpdatePurchasedListings());
        updatePurchases();
    }, [dispatch]);

    return (
        <div>
            <NavigationPanel label="Purchase History" />
            <DashboardContainer>
                {myPurchases.length > 0 ? (
                    <PurchaseTable>
                        <thead>
                            <tr>
                                {!isMobileVersion && (
                                    <>
                                        <th>Purchase Details</th>
                                        <th>Purchase Date</th>
                                        <th>Download Receipt</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {myPurchases.map((purchase) => {
                                return <PurchaseListItem purchase={purchase}></PurchaseListItem>;
                            })}
                        </tbody>
                    </PurchaseTable>
                ) : (
                    <NoImageryModal
                        icon={'/assets/floating-drawer-icons/drone-icon-yellow.svg'}
                        title={'Looks like you have not made any purchases'}
                    />
                )}
            </DashboardContainer>
        </div>
    );
};

export default PurchaseHistory;

const PurchaseTable = styled(Table)`
    background-color: transparent;
    color: white;

    th {
        color: white;
        border-top: none;
        border-bottom: none !important;
    }
`;
