import L from 'leaflet';
import { createElementObject, LeafletContextInterface } from '@react-leaflet/core';

interface ArrowGhostElementProps extends L.PolylineOptions {
    arrowShaftElement: Readonly<{ instance: L.Polyline; context: Readonly<{ map: L.Map }> }>;
}

const arrowGhostOptions: L.PolylineOptions = {
    color: 'transparent',
    weight: 30,
    bubblingMouseEvents: false,
};

export const createArrowGhostElement = (props: ArrowGhostElementProps, context: LeafletContextInterface) => {
    const startLatLng = props.arrowShaftElement.instance.getLatLngs()[0] as L.LatLng;
    const endLatLng = props.arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;

    const arrowGhost = new L.Polyline([startLatLng, endLatLng], arrowGhostOptions);
    const arrowGhostElement = createElementObject<L.Polyline, ArrowGhostElementProps>(arrowGhost, context);

    arrowGhostElement.instance.on('mouseover', (e: L.LeafletMouseEvent) => {
        props.arrowShaftElement.instance.fireEvent('mouseover', e);
    });

    arrowGhostElement.instance.on('mouseout', (e: L.LeafletMouseEvent) => {
        props.arrowShaftElement.instance.fireEvent('mouseout', e);
    });

    arrowGhostElement.instance.on('click', (e: L.LeafletMouseEvent) => {
        props.arrowShaftElement.instance.fireEvent('click', e);
    });

    props.arrowShaftElement.instance.on('remove', () => {
        context.map.removeLayer(arrowGhostElement.instance);
    });

    props.arrowShaftElement.instance.on('update', () => {
        const startLatLng = props.arrowShaftElement.instance.getLatLngs()[0] as L.LatLng;
        const endLatLng = props.arrowShaftElement.instance.getLatLngs()[1] as L.LatLng;
        arrowGhostElement.instance.setLatLngs([startLatLng, endLatLng]);
    });

    return arrowGhostElement;
};
