import { Carousel } from '@trendyol-js/react-carousel';
import styled from 'styled-components';
import { isMobileVersion } from '../../lib/soar-helper';

const LandingPageCarousel = () => {
    return (
        <SliderContainer>
            <Carousel
                show={isMobileVersion ? 1.25 : 4}
                slide={isMobileVersion ? 1.25 : 4}
                swiping={true}
                infinite={true}
                responsive={true}
                rightArrow={<NextArrow />}
                leftArrow={<PreviousArrow />}
            >
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/climate.png" />
                    <SliderImageText>
                        <SliderImageHeader>Climate</SliderImageHeader>
                        The Earth’s climate is at a constant state of change. Find the worlds most comprehensive updated
                        climate maps from the likes of NASA, the EU and NGOs.
                    </SliderImageText>
                </SliderCard>
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/history.png" />
                    <SliderImageText>
                        <SliderImageHeader>History</SliderImageHeader>
                        See how history is an ever continuing story with maps including ancient human migration routes,
                        the distribution of native peoples through to archived aerial images.
                    </SliderImageText>
                </SliderCard>
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/earth-art.png" />
                    <SliderImageText>
                        <SliderImageHeader>Earth Art</SliderImageHeader>
                        Discover the rich tapestry of colors, patterns and shapes that is planet Earth with maps as art
                        from thousands of users across the world.
                    </SliderImageText>
                </SliderCard>
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/transport.png" />
                    <SliderImageText>
                        <SliderImageHeader>Transport</SliderImageHeader>
                        The efficiency of the global economy is built on transport systems. Identify, visualise and
                        study maps and satellite images showing the impact of the worlds integrated transport systems.
                    </SliderImageText>
                </SliderCard>
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/environment.png" />
                    <SliderImageText>
                        <SliderImageHeader>Environment</SliderImageHeader>
                        Maps are the best way to visualise landscape change. There are thousands of maps and satellite
                        images from users globally showing how the Earth’s environments have changed both in the past,
                        present and perhaps in the future.
                    </SliderImageText>
                </SliderCard>
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/marine.png" />
                    <SliderImageText>
                        <SliderImageHeader>Marine</SliderImageHeader>
                        Over 70% of the world is covered by water. Discover this submerged world by diving into the
                        thousands of maps provided by the likes of NOAA, universities and NGOs.
                    </SliderImageText>
                </SliderCard>
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/urban.png" />
                    <SliderImageText>
                        <SliderImageHeader>Urban</SliderImageHeader>
                        Almost 60% of the worlds population lives in urban areas. Find out how maps are being used by
                        many of the worlds governments and organisations in-order to efficiently study and manage
                        urbanisation.
                    </SliderImageText>
                </SliderCard>
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/agriculture.png" />
                    <SliderImageText>
                        <SliderImageHeader>Agriculture</SliderImageHeader>
                        No other industry has impacted as much of the Earth’s surface as agriculture has. Study and
                        analyse the thousands of satellite images and maps demonstrating crop yields, through to
                        seasonal variability in farmland output.
                    </SliderImageText>
                </SliderCard>
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/geology.png" />
                    <SliderImageText>
                        <SliderImageHeader>Geology</SliderImageHeader>
                        Observe and identify the impact of geology in various global contexts by accessing content from
                        the world's best institutions. View and share tens of thousands of maps ranging from elevation,
                        terrain and even the location of earthquake prone areas.
                    </SliderImageText>
                </SliderCard>
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/political.png" />
                    <SliderImageText>
                        <SliderImageHeader>Political</SliderImageHeader>
                        To understand politics, one needs to understand maps. Access tens of thousands of maps and
                        satellite images from across the world documenting everything from current conflict zones
                        through to ethno-linguistic distribution of peoples.
                    </SliderImageText>
                </SliderCard>
                <SliderCard>
                    <SliderImageBackground src="/assets/landing-page/slider-images/economic.png" />
                    <SliderImageText>
                        <SliderImageHeader>Economic</SliderImageHeader>
                        Discover thousands of economic maps from libraries such as Stanford University, the EU, USGS and
                        various organisations. These maps show everything from the building of new cities, through to
                        how key mines are being built to extract rare minerals.
                    </SliderImageText>
                </SliderCard>
            </Carousel>
        </SliderContainer>
    );
};

export default LandingPageCarousel;

const SliderContainer = styled.div`
    margin: 50px 0px;

    @media only screen and (max-width: 600px) {
        width: 90%;
        margin: 20px auto;
    }
`;

const SliderCard = styled.div`
    width: 100%;
    height: 204px;
    position: relative;
    padding: 6px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(34, 34, 34, 0.5);
    }
`;

const SliderImageBackground = styled.img`
    object-fit: cover;
    position: relative;
    width: 100%;
    height: 100%;
`;

const SliderImageText = styled.div`
    position: absolute;
    top: 0;
    font-size: 14px;
    color: white;
    z-index: 1;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.8);
    padding: 10px;
    word-break: break-word;
`;

const SliderImageHeader = styled.h3`
    font-size: 20px;
    color: white;
    font-weight: 600;
    text-align: left;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.8);
    z-index: 1;
`;

const SliderArrow = styled.button`
    position: absolute;
    top: 42%;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: none;
    z-index: 1;
    height: 28px;
    border-radius: 50%;
    line-height: 5px;
    font-size: 20px;
    background: none;
    background-color: rgba(255, 255, 255, 0.67);
    text-shadow: 2px 2px rgba(66, 66, 66, 0.8);
    width: 30px;
    color: white;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:focus,
    &:active {
        outline: none;
        border: none;
    }
`;

const NextArrow = styled(SliderArrow)`
    right: 20px;

    @media only screen and (max-width: 600px) {
        right: 10px;
    }

    &:after {
        content: '▶';
    }
`;

const PreviousArrow = styled(SliderArrow)`
    left: 20px;

    @media only screen and (max-width: 600px) {
        left: 10px;
    }

    &:after {
        content: '◀';
    }
`;
