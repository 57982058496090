import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

interface SearchContainerProps {
    hasFocus: boolean;
    children: React.ReactElement;
    onClose: () => void;
}

const OPEN_ANIMATION_TIME = 300; //ms
const OPEN_ANIMATION_DELAY = 100; //ms
const CLOSE_ANIMATION_TIME = 200; //ms

const SearchContainer = (props: SearchContainerProps) => {
    const [containerClassName, setContainerClassName] = useState<'opening' | 'open' | 'closing' | 'closed'>('closed');

    useEffect(() => {
        if (props.hasFocus) {
            setContainerClassName('opening');
            setTimeout(() => setContainerClassName('open'), OPEN_ANIMATION_TIME);
        } else {
            if (containerClassName === 'open') {
                setContainerClassName('closing');
                setTimeout(() => setContainerClassName('closed'), CLOSE_ANIMATION_TIME);
            }
        }
    }, [containerClassName, props.hasFocus]);

    const isOpen = containerClassName === 'open';

    return (
        <Container className={containerClassName}>
            {isOpen ? (
                <React.Fragment>
                    <ContainerBorderRemove />
                    <CloseIcon src="/assets/close.png" onClick={() => props.onClose()} />
                    {props.children}
                </React.Fragment>
            ) : null}
        </Container>
    );
};

export default SearchContainer;

const openAnimation = keyframes`
    from {
        min-height: 0;
        width: 40vw;
        left: 35vw;
    }
    to {
        min-height: 75vh;
        width: 74vw;
        left: 17vw;
    }
`;

const closeAnimation = keyframes`
    from {
        min-height: 75vh;
        width: 74vw;
        left: 17vw;

    }
    to {
        min-height: 0vh;
        width: 40vw;
        left: 35vw;
    }
`;

const CloseIcon = styled.img`
    position: fixed;
    top: 150px;
    left: calc(91vw - 26px);
    width: 16px;
    height: 16px;
    cursor: pointer;
`;

const Container = styled.div`
    width: 74vw;
    margin: 0 auto;
    padding: 0;
    display: block;
    background: rgba(0, 0, 0, 0.9);
    transition: min-height ${OPEN_ANIMATION_TIME}ms ease-in-out 0ms;
    border-radius: 6px;
    position: fixed;
    top: 143px;
    left: 17vw;
    z-index: 10000;
    min-height: 0;
    max-height: 75vh;
    overflow-y: scroll;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;

    &::-webkit-scrollbar-track {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        margin: 3px 0;
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #eed926;
        border-radius: 4px;
    }

    &.opening {
        animation: ${openAnimation} ${OPEN_ANIMATION_TIME}ms forwards;
        animation-delay: ${OPEN_ANIMATION_DELAY}ms;
    }

    &.open {
        min-height: 75vh;
        -webkit-box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
            0px 9px 44px 8px rgba(0, 0, 0, 0.22);
        box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
            0px 9px 44px 8px rgba(0, 0, 0, 0.22);
        border: 1px solid #515151;
    }

    &.closing {
        animation: ${closeAnimation} ${CLOSE_ANIMATION_TIME}ms forwards;
        min-height: 0;
    }

    &.closed {
        min-height: 0;
        display: none;
    }
`;

const ContainerBorderRemove = styled.div`
    margin: 0 auto;
    padding: 0;
    display: block;
    background: #0d0d0d;
    transition: min-height ${OPEN_ANIMATION_TIME}ms ease-in-out 0ms;
    border-radius: 6px;
    position: fixed;
    top: 140px;
    width: calc(40vw - 2px);
    left: calc(35vw + 1px);
    height: 5px;
    z-index: 10001;
`;

export const SegmentContainer = styled.div`
    margin: 60px 3vw 0 3vw;
    padding: 16px;
    background: #181919;
    border-radius: 10px;
`;

export const SegmentTitle = styled.h4`
    color: white;
    text-align: left;
    font-size: 1.6rem;

    span {
        color: #eed923;
    }
`;

export const SegmentTitleIcon = styled.img`
    width: 32px;
    height: 32px;
    margin: -4px 8px 0 8px;
`;
