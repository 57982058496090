import React, { useState } from 'react';
import styled from 'styled-components';
import ColorHelper from './color-helper';
import CustomColorPopup from './custom-color-popup';

interface ColorSwatchProps {
    selectedColor: string;
    onSelectColor: (color: string) => void;
}

const defaultColors = [
    '#e75554',
    '#f99441',
    '#e8d614',
    '#25b158',
    '#54cefe',
    '#3388ff',
    '#a150d8',
    '#ffffff',
    '#000000',
];

const ColorSwatch = (props: ColorSwatchProps) => {
    const [isCustomColorPickerOpen, setIsCustomColorPickerOpen] = useState(false);
    return (
        <React.Fragment>
            <Container>
                <Row>
                    {defaultColors.map((color) => (
                        <ColorSwatchContainer
                            selected={props.selectedColor === color}
                            onClick={() => props.onSelectColor(color)}
                        >
                            <Color color={color} />
                        </ColorSwatchContainer>
                    ))}
                </Row>
                <Row>
                    {ColorHelper.getUserColors()
                        .slice(0, 7)
                        .map((color) => {
                            return (
                                <ColorSwatchContainer
                                    selected={props.selectedColor === color}
                                    onClick={() => props.onSelectColor(color)}
                                >
                                    <Color color={color} />
                                </ColorSwatchContainer>
                            );
                        })}
                    <AddNewColorContainer onClick={() => setIsCustomColorPickerOpen(true)}>
                        <AddNewColorIcon className="fa fa-plus" />
                    </AddNewColorContainer>
                </Row>
            </Container>
            {isCustomColorPickerOpen && (
                <CustomColorPopup
                    onClose={() => setIsCustomColorPickerOpen(false)}
                    color={props.selectedColor}
                    setColor={props.onSelectColor}
                />
            )}
        </React.Fragment>
    );
};

export default ColorSwatch;

const Container = styled.div``;

const AddNewColorContainer = styled.div`
    width: 32px;
    height: 32px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    cursor: pointer;
`;

const AddNewColorIcon = styled.i`
    margin: 8px 8px;
    font-size: 1rem;
    padding: 0;
    color: rgba(255, 255, 255, 0.7);
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 8px;
`;

const ColorSwatchContainer = styled.div<{ selected: boolean }>`
    width: 32px;
    height: 32px;
    border: ${(props) =>
        props.selected ? '1px solid rgba(255, 255, 255, 0.7)' : '1px solid rgba(255, 255, 255, 0.2)'};
    border-radius: 3px;
    cursor: pointer;
`;

const Color = styled.div<{ color: string }>`
    height: 18px;
    width: 18px;
    margin: 6px 6px;
    padding: 0;
    border-radius: 9px;
    background-color: ${(props) => props.color};
`;
