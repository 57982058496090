import L from 'leaflet';
import './area-measurement-annotation.css';
import Geometry from './area-measurement-geometry-util';
import { createControlComponent } from '@react-leaflet/core';

import {
    _pixelDistance,
    _manageScale,
    _manageDistanceLabels,
    removePolygon,
    _deleteDeleteMarker,
    _updateBounds,
    setDeleteMarker,
    deleteMarkerOnLifeEnd,
    _addCorner,
    _addMidpoint,
    findCorners,
    _twoPointsCenter,
    svgTextDisplay,
} from './area-measurement-lib';

const AreaMeasurementAnnotation = createControlComponent((props) => {
    return new L.Control.boxSelectControl({
        position: 'topright',
        onClick: props.onClick,
        sticky: props.sticky,
    });
});

export default AreaMeasurementAnnotation;

L.Control.boxSelectControl = L.Control.extend({
    _style: null,
    _activeStyle: null,
    _isActive: null,
    _startLatLng: null,
    _boxSelectButton: null,
    _boxSelectLabel: null,

    initialize: function (element) {
        this.options.position = element.position;
        this._buttonClicked = element.onClick;
        this._onAllRemoved = element.onAllRemoved;
        this._scaleActive = element._scaleActive;
    },

    onAdd: function (map) {
        const boxSelectButton = L.DomUtil.create('button');
        boxSelectButton.setAttribute('id', 'area-measurement-button');
        boxSelectButton.setAttribute('title', 'Area Measurement');
        boxSelectButton.setAttribute('aria-label', 'Area Measurement');
        boxSelectButton.setAttribute('type', 'button');
        boxSelectButton.className = 'area-measurement-button';

        boxSelectButton.onclick = (_) => {
            if (this._buttonClicked) this._buttonClicked();
            if (this._isActive) {
                this._stop(map, boxSelectButton);
            } else {
                this._start(map, boxSelectButton);
            }
        };
        this._boxSelectButton = boxSelectButton;
        this.boxSelectCollection = [];
        this.collectionSize = 0;

        map.on('zoomend', () => {
            this.boxSelectCollection.forEach((collection, index) => {
                this.manageLabelsAndScale(collection, index, true);
            });
        });

        return boxSelectButton;
    },
    manageLabelsAndScale(polygon, index, disableCreate) {
        if (polygon && polygon['_bounds'] && polygon['drawPolygon']) {
            const ne = polygon['_bounds']['ne'];
            const nw = polygon['_bounds']['nw'];
            const sw = polygon['_bounds']['sw'];
            const se = polygon['_bounds']['se'];

            for (let i = 0; i < polygon['_points'].length; i++) {
                const from = polygon['_points'][i];
                const to = polygon['_points'][(i + 1) % polygon['_points'].length];
                // TODO manage distance label is cooked handled differently from the update ....
                polygon = _manageDistanceLabels(
                    this._map,
                    polygon,
                    from.label,
                    to.label,
                    {
                        offsetX: from.offsetX,
                        offsetY: from.offsetY,
                        transform: '0% 0%',
                    },
                    disableCreate
                );
            }

            polygon = _manageScale(this._scaleActive, this._map, polygon, 'nenw', ne, nw, 0);
            polygon = _manageScale(this._scaleActive, this._map, polygon, 'nese', ne, se, 180);

            polygon['drawPolygon'].setLatLngs(polygon['_pointsSimple']);

            polygon = _deleteDeleteMarker(undefined, this._map, polygon, 'trash');

            this._manageAreaLabel(polygon, ne, nw, sw, se);
        }
        return polygon;
    },
    onRemove: function (_) {
        // Do nothing
    },
    _stop: function (map, boxSelectButton) {
        map.dragging.enable();
        boxSelectButton.setAttribute('style', this._style);
        L.DomUtil.removeClass(map._container, 'leaflet-crosshair');
        this._isActive = false;
        this._startLatLng = null;
        if (this._onAllRemoved && this.collectionSize === 0) this._onAllRemoved();

        L.DomEvent.off(map, 'mousemove', this._handleMouseMove, this);
        L.DomEvent.off(map, 'mouseup', this._handleMouseUp, this);
    },
    _handleMouseDown: function (e) {
        this.boxSelectCollection.push({});
        this._startLatLng = e.latlng;
        this._mouseMoved = false;
    },
    _handleMouseMove: function (e) {
        if (this._startLatLng === null || this._startLatLng === undefined) {
            return;
        }

        this._mouseMoved = true;

        let ne = this._startLatLng;
        let nw = new L.LatLng(this._startLatLng.lat, e.latlng.lng);
        let sw = e.latlng;
        let se = new L.LatLng(e.latlng.lat, this._startLatLng.lng);

        const corners = findCorners(ne, nw, sw, se);
        ne = corners[0];
        nw = corners[1];
        sw = corners[2];
        se = corners[3];

        this.currMasterIndex = this.boxSelectCollection.length - 1;
        const currIndex = this.currMasterIndex;
        let polygon = this.boxSelectCollection[currIndex];

        if (polygon['drawPolygon'] === null || polygon['drawPolygon'] === undefined) {
            polygon['drawPolygon'] = new L.Polygon([ne, nw, sw, se]);
            polygon['drawPolygon'].addTo(this._map);
        } else {
            polygon['drawPolygon'].setLatLngs([ne, nw, sw, se]);

            polygon = this.setDefaultPoints(polygon, ne, nw, sw, se);

            polygon = _manageScale(this._scaleActive, this._map, polygon, 'nenw', ne, nw, 0);
            polygon = _manageScale(this._scaleActive, this._map, polygon, 'nese', ne, se, 180);

            polygon = this._manageAreaLabel(polygon, ne, nw, sw, se);

            polygon = _updateBounds(polygon, ne, nw, sw, se);
            polygon = _manageDistanceLabels(this._map, polygon, 'ne', 'nw', {
                css: 'area-measurement-label',
                offsetX: 0,
                offsetY: 30,
                transform: '0% 0%',
            });
        }
        this.boxSelectCollection[currIndex] = polygon;
    },
    _removeBox(index) {
        this._isActive = false;
        this._startLatLng = null;
        this.boxSelectCollection[index] = removePolygon(this._map, this.boxSelectCollection[index]);
        if (this.collectionSize > 0) this.collectionSize--;
        if (this._onAllRemoved && this.collectionSize === 0) this._onAllRemoved();
    },
    _removeAll() {
        this.boxSelectCollection.forEach((collection, index) => {
            this._removeBox(index);
        });
    },
    _handleMouseUp: function (e) {
        if (!this._mouseMoved) {
            this._stop(this._map, this._boxSelectButton);
            return;
        }

        const map = this._map;
        if (
            this._startLatLng === null ||
            this._startLatLng === undefined ||
            this._startLatLng.lat === e.latlng.lat ||
            this._startLatLng.lng === e.latlng.lng ||
            this._isActive === false
        ) {
            return;
        }

        this.collectionSize++;

        let ne = this._startLatLng;
        let nw = new L.LatLng(this._startLatLng.lat, e.latlng.lng);
        let sw = e.latlng;
        let se = new L.LatLng(e.latlng.lat, this._startLatLng.lng);
        const corners = findCorners(ne, nw, sw, se);
        ne = corners[0];
        nw = corners[1];
        sw = corners[2];
        se = corners[3];

        const master = this.currMasterIndex;
        let polygon = this.boxSelectCollection[master];

        //var center = this.polygonCenter(polygon['_pointsSimple']);
        setDeleteMarker(this, polygon, master, 'trash', 'calc position from polygon center');
        deleteMarkerOnLifeEnd(this, polygon, 'delete_corner');

        polygon['_corners'] = {};
        polygon['_midPointCorners'] = {};
        polygon['_midPointCornerActive'] = {};
        polygon['_bounds'] = {};
        polygon['_cornerActive'] = {};
        polygon = this.setDefaultPoints(polygon, ne, nw, sw, se);

        this.boxSelectCollection[master] = polygon;
        for (let i = 0; i < polygon['_points'].length; i++) {
            const point = polygon['_points'][i];
            const nextPoint = polygon['_points'][(i + 1) % polygon['_points'].length];

            _addCorner(this, master, point.label, point.latLng);
            _addMidpoint(this, master, point.label, nextPoint.label);
        }

        map.dragging.enable();
        this._boxSelectButton.setAttribute('style', this._style);
        L.DomUtil.removeClass(map._container, 'leaflet-crosshair');
        this._isActive = false;

        L.DomEvent.off(map, 'mousemove', this._handleMouseMove, this);
        L.DomEvent.off(map, 'mouseup', this._handleMouseUp, this);
    },

    setDefaultPoints(polygon, ne, nw, sw, se) {
        polygon['_points'] = [
            { label: 'ne', latLng: ne, css: 'area-measurement-label', offsetX: 0, offsetY: 30 },
            { label: 'nw', latLng: nw, css: 'area-measurement-label', offsetX: 30, offsetY: 0 },
            { label: 'sw', latLng: sw, css: 'area-measurement-label', offsetX: 0, offsetY: 0 },
            { label: 'se', latLng: se, css: 'area-measurement-label', offsetX: 0, offsetY: 0 },
        ];

        polygon['_pointsSimple'] = [];
        polygon['_points'].map((point) => {
            polygon['_pointsSimple'].push(point.latLng);
        });
        polygon['_pointsMap'] = {};
        polygon['_points'].map((point, index) => {
            polygon['_pointsMap'][point.label] = index;
        });
        return polygon;
    },

    _manageAreaLabel: function (polygon, ne, nw, sw, se) {
        if (polygon['drawPolygon'] && polygon['_pointsSimple']) {
            if (
                _pixelDistance(this._map, ne, nw) > 140 &&
                _pixelDistance(this._map, ne, se) > 50 &&
                _pixelDistance(this._map, ne, sw) > 200
            ) {
                if (polygon['_areaLabel']) {
                    this._map.removeLayer(polygon['_areaLabel']);
                    polygon['_areaLabel'] = null;
                }
                const area = this.geodesicArea(polygon['_pointsSimple']);
                const readableArea = this.readableArea(area, true, 2);

                const label = 'Area: ' + readableArea;
                const fromWidthNE = this._map.latLngToLayerPoint(ne);
                const toWidthNW = this._map.latLngToLayerPoint(nw);
                const lineWidthAsPixels = fromWidthNE.distanceTo(toWidthNW);

                const fromHeightNE = this._map.latLngToLayerPoint(ne);
                const toHeightSE = this._map.latLngToLayerPoint(se);
                const lineHeightPixels = fromHeightNE.distanceTo(toHeightSE);

                const display = svgTextDisplay(label, lineWidthAsPixels, lineHeightPixels);
                const icon = L.divIcon({
                    className: 'area-measurement-label',
                    iconSize: [0, 0],
                    html: display,
                });
                const center = this.polygonCenter(polygon['_pointsSimple']);

                polygon['_areaLabel'] = new L.marker(center, { icon, interactive: false }).addTo(this._map);
            } else {
                if (polygon['_areaLabel']) {
                    this._map.removeLayer(polygon['_areaLabel']);
                    polygon['_areaLabel'] = null;
                }
            }
        }
        return polygon;
    },

    _start: function (map, boxSelectButton) {
        boxSelectButton.setAttribute('style', this._style + this._activeStyle);
        map.dragging.disable();
        L.DomUtil.addClass(map._container, 'leaflet-crosshair');
        this._isActive = true;
        this._startLatLng = null;

        L.DomEvent.on(map, 'mousedown', this._handleMouseDown, this);
        L.DomEvent.on(map, 'mousemove', this._handleMouseMove, this);
        L.DomEvent.on(map, 'mouseup', this._handleMouseUp, this);
    },

    forceStop: function () {
        const map = this._map;
        const boxSelectButton = L.DomUtil.get('area-measurement-button');
        this._stop(map, boxSelectButton);
    },

    forceStart: function () {
        const map = this._map;
        const boxSelectButton = L.DomUtil.get('area-measurement-button');
        this._start(map, boxSelectButton);
    },

    geodesicArea: function (latLngs) {
        const points = [];
        latLngs.forEach((point) => {
            points.push([point.lng, point.lat]);
        });
        points.push([latLngs[0].lng, latLngs[0].lat]);

        // TODO changes but needs checking
        return Geometry.area(points);
    },

    polygonCenter: function (latLngs) {
        const points = [];
        latLngs.forEach((point) => {
            points.push([point.lng, point.lat]);
        });
        points.push([latLngs[0].lng, latLngs[0].lat]);

        const center = Geometry.center(points);
        return L.latLng(center[1], center[0]);
    },

    readableArea: function (area) {
        if (area >= 1000000) {
            return (area * 0.000001).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' km²';
        } else {
            return area.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' m²';
        }
    },
});

L.control.boxSelectControl = (opts) => {
    return new L.Control.boxSelectControl({ ...opts });
};

// Exported to use as a map maker tool

export const boxSelectControlTool = (opts) => {
    return new L.Control.boxSelectControl({ ...opts });
};
