/* Action types */
export const actionTypes = {
    API_GET_ADMIN_USER_DATA: 'API/GET_ADMIN_USER_DATA',
    API_GET_ADMIN_USER_DATA_SUCCESS: 'API/GET_ADMIN_USER_DATA_SUCCESS',
    API_GET_ADMIN_USER_DATA_ERROR: 'API/ADMIN_USER_DATA_ERROR',

    API_GET_SUBDOMAIN_LISTINGS: 'API/SUBDOMAIN_LISTINGS',
    API_GET_SUBDOMAIN_LISTINGS_SUCCESS: 'API/SUBDOMAIN_LISTINGS_SUCCESS',
    API_GET_SUBDOMAIN_LISTINGS_ERROR: 'API/SUBDOMAIN_LISTINGS_ERROR',

    API_GET_SUBDOMAIN: 'API/GET_SUBDOMAIN',
    API_GET_SUBDOMAIN_SUCCESS: 'API/GET_SUBDOMAIN_SUCCESS',

    API_UPLOAD_TO_SUPERMAP_STATUS_REPORT: 'API/UPLOAD_TO_SUPERMAP_STATUS_REPORT',
    API_UPLOAD_TO_SUPERMAP_ERROR: 'API/UPLOAD_TO_SUPERMAP_ERROR',

    API_REUPLOAD_MAP_ID: 'API/REUPLOAD_MAP_ID',
};
