export const HOVER_WEIGHT = 3;

export default class Circle {
    id: string;
    center: L.LatLng;
    radius: number;
    options: L.CircleOptions;

    constructor(id: string, center: L.LatLng, radius: number, options: L.CircleOptions) {
        this.id = id;
        this.center = center;
        this.radius = radius;
        this.options = options;
    }
}

export const defaultCircleOptions: L.CircleOptions = {
    radius: 0,
    stroke: true,
    color: '#3388ff',
    weight: 3,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: false,
    fillColor: undefined,
    fillOpacity: 0.2,
    fillRule: 'evenodd',
    interactive: false,
    bubblingMouseEvents: false,
};

export const circleGhostOptions: L.CircleOptions = {
    radius: 0,
    color: 'transparent',
    weight: 15,
    fill: false,
    interactive: true,
};

export const circleMarkerOptions: L.CircleMarkerOptions = {
    radius: 5,
    stroke: true,
    color: '#3388ff',
    weight: 3,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: true,
    fillColor: '#3388ff',
    fillOpacity: 1,
    fillRule: 'evenodd',
    interactive: true,
    bubblingMouseEvents: false,
};
