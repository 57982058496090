import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { LatLng } from 'leaflet';
import { useSelector, useDispatch } from 'react-redux';
import Geocoder, { GeocoderResult } from '../../lib/geocoder-service';
import Analytics from '../../lib/user-analytics';
import { actionFlyToOnMap } from '../../store/App/actions';
import { actionSetDroneImageManualPosition } from '../../store/Map/Uploads/actions';
import { selectDroneImageManualPosition } from '../../store/Map/Uploads/selectors';
import DrawerHint from '../Drawer/drawer-hint';
import SoarAutocomplete from '../SearchLocation/soar-autocomplete';
import { StyledButton, StyledTransButton } from '../Shared/styled-button';

interface UploadMapImageNoLocationProps {
    onClickBack: () => void;
    onClickConfirmPosition: (position: LatLng) => void;
}

const UploadMapImageNoLocation = (props: UploadMapImageNoLocationProps) => {
    const droneImageManualPosition = useSelector(selectDroneImageManualPosition);

    const dispatch = useDispatch();
    const setDroneImagePosition = useCallback(
        (position: LatLng | undefined) => {
            dispatch(actionSetDroneImageManualPosition(position));
        },
        [dispatch]
    );

    const flyTo = useCallback((latlng: LatLng) => dispatch(actionFlyToOnMap(latlng)), [dispatch]);

    const [searchedLocation, setSearchedLocation] = useState<LatLng | undefined>(undefined);
    const [address, setAddress] = useState<string>();

    useEffect(() => {
        if (droneImageManualPosition && searchedLocation) {
            if (!searchedLocation.equals(droneImageManualPosition)) {
                Geocoder.fromLatLng(droneImageManualPosition).then((res: GeocoderResult[]) => {
                    if (res && res[0]) {
                        setAddress(res[0].address);
                    }
                });
            }
        }
    }, [droneImageManualPosition, searchedLocation]);

    useEffect(() => {
        if (!searchedLocation) {
            setDroneImagePosition(undefined);
        } else {
            flyTo(searchedLocation);
        }
    }, [searchedLocation, flyTo, setDroneImagePosition]);

    return (
        <Container>
            <Content className="d-flex justify-content-between">
                <Title>Choose Location</Title>
            </Content>

            <Content>
                <DrawerHint error>
                    This file does not have exif and we cannot automatically place it on the map. If you use the
                    original unedited file you won't need to perform this step
                </DrawerHint>
                <DrawerHint>
                    Enter the closest street address below and then move the marker to be as close as possible to where
                    the image was taken
                </DrawerHint>

                <LocationSearchContainer>
                    <SoarAutocomplete
                        autocompleteType={['ADDRESS']}
                        onLatlngSelected={(latlng: LatLng) => {
                            setSearchedLocation(latlng);
                            setDroneImagePosition(latlng);
                        }}
                        placeholderAddress={address}
                    />
                </LocationSearchContainer>

                <div className="d-flex mt-4 justify-content-between">
                    <StyledTransButton
                        onClick={() => {
                            setDroneImagePosition(undefined);
                            props.onClickBack();
                            Analytics.Event('Upload', 'Clicked back arrow at choose location');
                        }}
                    >
                        <i className="fa fa-chevron-left mr-2"></i>BACK
                    </StyledTransButton>

                    <StyledButton
                        className="mr-0 pl-4 pr-4"
                        disabled={!droneImageManualPosition}
                        onClick={() => {
                            if (droneImageManualPosition) {
                                props.onClickConfirmPosition(droneImageManualPosition);
                                setDroneImagePosition(undefined);
                                Analytics.Event('Upload', 'Clicked confirm position at choose location');
                            }
                        }}
                    >
                        CONFIRM POSITION
                        <i className="fa fa-chevron-right ml-2"></i>
                    </StyledButton>
                </div>
            </Content>
        </Container>
    );
};

export default UploadMapImageNoLocation;

const Container = styled.div`
    margin: 5px 5px 15px 5px;
`;

const Title = styled.h3`
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
`;

const Content = styled.div`
    flex: 1 1 auto;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
`;

const LocationSearchContainer = styled.div`
    margin-left: 35px;
    margin-top: 40px;
    margin-bottom: 30px;

    .react-autosuggest__suggestions-container {
        top: 32px !important;
    }
    .react-autosuggest__suggestions-list {
        max-height: 250px;
        overflow-y: auto;
    }
`;
