import L from 'leaflet';

const COLOR_MARKER_ICON_PATH = '/assets/annotations/marker/default-markers';
const MARKER_ICON_PATH = '/assets/annotations/marker/icon-markers/marker_icon';

export interface Marker {
    name: string;
    iconUrl: string;
    iconRetinaUrl?: string;
}

export enum ColoredMarkerType {
    Default = 'default',
    Black = 'black',
    Gold = 'gold',
    Green = 'green',
    Grey = 'grey',
    Orange = 'orange',
    Red = 'red',
    Violet = 'violet',
    Yellow = 'yellow',
}

export const coloredMarkers: Record<ColoredMarkerType, Marker> = {
    [ColoredMarkerType.Default]: {
        name: 'Blue',
        iconUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-blue.png`,
        iconRetinaUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-2x-blue.png`,
    },
    [ColoredMarkerType.Black]: {
        name: 'Black',
        iconUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-black.png`,
        iconRetinaUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-2x-black.png`,
    },
    [ColoredMarkerType.Gold]: {
        name: 'Gold',
        iconUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-gold.png`,
        iconRetinaUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-2x-gold.png`,
    },
    [ColoredMarkerType.Green]: {
        name: 'Green',
        iconUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-green.png`,
        iconRetinaUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-2x-green.png`,
    },
    [ColoredMarkerType.Grey]: {
        name: 'Grey',
        iconUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-grey.png`,
        iconRetinaUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-2x-grey.png`,
    },
    [ColoredMarkerType.Orange]: {
        name: 'Orange',
        iconUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-orange.png`,
        iconRetinaUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-2x-orange.png`,
    },
    [ColoredMarkerType.Red]: {
        name: 'Red',
        iconUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-red.png`,
        iconRetinaUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-2x-red.png`,
    },
    [ColoredMarkerType.Violet]: {
        name: 'Violet',
        iconUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-violet.png`,
        iconRetinaUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-2x-violet.png`,
    },
    [ColoredMarkerType.Yellow]: {
        name: 'Yellow',
        iconUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-yellow.png`,
        iconRetinaUrl: `${COLOR_MARKER_ICON_PATH}/marker-icon-2x-yellow.png`,
    },
};

const leafColoredIcon = (name: string, iconUrl: string, iconRetinaUrl?: string) =>
    L.Icon.extend({
        options: {
            name: name,
            iconUrl: iconUrl,
            iconRetinaUrl: iconRetinaUrl ? iconRetinaUrl : iconUrl,
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize: [41, 41],
        },
    });

export const generateColorMarkers = () => {
    const coloredMarkerArray = Object.entries(coloredMarkers).map(([_, marker]) => {
        return new (leafColoredIcon(marker.name, marker.iconUrl, marker.iconRetinaUrl))();
    });

    return coloredMarkerArray;
};

enum MarkerType {
    AirPort = 'air-port',
    Ambulance = 'ambulance',
    BusStop = 'bus-stop',
    Circle = 'circle',
    CircleCross = 'circle-cross',
    CircleFull = 'circle-full',
    CircleHalfFull = 'circle-half-full',
    CircleSquareDot = 'circle-square-dot',
    CircleTriangle = 'circle-triangle',
    Construction = 'construction',
    Cycle = 'cycle',
    Default = 'default',
    Diamond = 'diamond',
    DiamondFull = 'diamond-full',
    Dot = 'dot',
    FireEngine = 'fire-engine',
    FuelPump = 'fuel-pump',
    Geology1 = 'geology-1',
    Geology2 = 'geology-2',
    Geology3 = 'geology-3',
    Geology4 = 'geology-4',
    Geology5 = 'geology-5',
    Harbour = 'harbour',
    MiniDot = 'mini-dot',
    NoEntry = 'no-entry',
    PublicPhone = 'public-phone',
    Question = 'question',
    Rail = 'rail',
    Ring = 'ring',
    SquareDot = 'square-dot',
    SquareHalfFull = 'square-half-full',
    SquareTriangles = 'square-triangles',
    Squarex = 'squarex',
    SteepRoad = 'steep-road',
    StopHand = 'stop-hand',
    StopSign = 'stop-sign',
    StrikeDip90 = 'strike-dip-90',
    StrikeDip = 'strike-dip',
    Target = 'target',
    TargetCross = 'target-cross',
    TrafficLights = 'traffic-lights',
    Walking = 'walking',
    WheerChair = 'wheel-chair',
    X = 'x',
}

export const iconMarkers: Record<MarkerType, Marker> = {
    [MarkerType.AirPort]: {
        name: 'Air Port',
        iconUrl: `${MARKER_ICON_PATH}_airport.png`,
    },
    [MarkerType.Ambulance]: {
        name: 'Ambulance',
        iconUrl: `${MARKER_ICON_PATH}_ambulance.png`,
    },
    [MarkerType.BusStop]: {
        name: 'Bus Stop',
        iconUrl: `${MARKER_ICON_PATH}_busstop.png`,
    },
    [MarkerType.Circle]: {
        name: 'Circle',
        iconUrl: `${MARKER_ICON_PATH}_circle.png`,
    },
    [MarkerType.CircleCross]: {
        name: 'Circle Cross',
        iconUrl: `${MARKER_ICON_PATH}_circlecross.png`,
    },
    [MarkerType.CircleFull]: {
        name: 'Circle Full',
        iconUrl: `${MARKER_ICON_PATH}_circlefull.png`,
    },
    [MarkerType.CircleHalfFull]: {
        name: 'Circle Half Full',
        iconUrl: `${MARKER_ICON_PATH}_circlehalffull.png`,
    },
    [MarkerType.CircleSquareDot]: {
        name: 'Circle Square Dot',
        iconUrl: `${MARKER_ICON_PATH}_circlesquaredot.png`,
    },
    [MarkerType.CircleTriangle]: {
        name: 'Circle Triangle',
        iconUrl: `${MARKER_ICON_PATH}_circletriangle.png`,
    },
    [MarkerType.Construction]: {
        name: 'Construction',
        iconUrl: `${MARKER_ICON_PATH}_construction.png`,
    },
    [MarkerType.Cycle]: {
        name: 'Cycle',
        iconUrl: `${MARKER_ICON_PATH}_cycle.png`,
    },
    [MarkerType.Default]: {
        name: 'Default',
        iconUrl: `${MARKER_ICON_PATH}_default.png`,
    },
    [MarkerType.Diamond]: {
        name: 'Diamond',
        iconUrl: `${MARKER_ICON_PATH}_diamond.png`,
    },
    [MarkerType.DiamondFull]: {
        name: 'Diamond Full',
        iconUrl: `${MARKER_ICON_PATH}_diamondfull.png`,
    },
    [MarkerType.Dot]: {
        name: 'Dot',
        iconUrl: `${MARKER_ICON_PATH}_dot.png`,
    },
    [MarkerType.FireEngine]: {
        name: 'Fire Engine',
        iconUrl: `${MARKER_ICON_PATH}_fireengine.png`,
    },
    [MarkerType.FuelPump]: {
        name: 'Fuel Pump',
        iconUrl: `${MARKER_ICON_PATH}_fuelpump.png`,
    },
    [MarkerType.Geology1]: {
        name: 'Geology 1',
        iconUrl: `${MARKER_ICON_PATH}_geology_1.png`,
    },
    [MarkerType.Geology2]: {
        name: 'Geology 2',
        iconUrl: `${MARKER_ICON_PATH}_geology_2.png`,
    },
    [MarkerType.Geology3]: {
        name: 'Geology 3',
        iconUrl: `${MARKER_ICON_PATH}_geology_3.png`,
    },
    [MarkerType.Geology4]: {
        name: 'Geology 4',
        iconUrl: `${MARKER_ICON_PATH}_geology_4.png`,
    },
    [MarkerType.Geology5]: {
        name: 'Geology 5',
        iconUrl: `${MARKER_ICON_PATH}_geology_5.png`,
    },
    [MarkerType.Harbour]: {
        name: 'Harbour',
        iconUrl: `${MARKER_ICON_PATH}_harbour.png`,
    },
    [MarkerType.MiniDot]: {
        name: 'Mini Dot',
        iconUrl: `${MARKER_ICON_PATH}_minidot.png`,
    },
    [MarkerType.NoEntry]: {
        name: 'No Entry',
        iconUrl: `${MARKER_ICON_PATH}_noentry.png`,
    },
    [MarkerType.PublicPhone]: {
        name: 'Public Phone',
        iconUrl: `${MARKER_ICON_PATH}_publicphone.png`,
    },
    [MarkerType.Question]: {
        name: 'Question',
        iconUrl: `${MARKER_ICON_PATH}_question.png`,
    },
    [MarkerType.Rail]: {
        name: 'Rail',
        iconUrl: `${MARKER_ICON_PATH}_rail.png`,
    },
    [MarkerType.Ring]: {
        name: 'Ring',
        iconUrl: `${MARKER_ICON_PATH}_ring.png`,
    },
    [MarkerType.SquareDot]: {
        name: 'Square Dot',
        iconUrl: `${MARKER_ICON_PATH}_squaredot.png`,
    },
    [MarkerType.SquareHalfFull]: {
        name: 'Square Half Full',
        iconUrl: `${MARKER_ICON_PATH}_squarehalffull.png`,
    },
    [MarkerType.SquareTriangles]: {
        name: 'Square Triangles',
        iconUrl: `${MARKER_ICON_PATH}_squaretriangles.png`,
    },
    [MarkerType.Squarex]: {
        name: 'Square x',
        iconUrl: `${MARKER_ICON_PATH}_squarex.png`,
    },
    [MarkerType.SteepRoad]: {
        name: 'Steep Road',
        iconUrl: `${MARKER_ICON_PATH}_steeproad.png`,
    },
    [MarkerType.StopHand]: {
        name: 'Stop Hand',
        iconUrl: `${MARKER_ICON_PATH}_stophand.png`,
    },
    [MarkerType.StopSign]: {
        name: 'Stop Sign',
        iconUrl: `${MARKER_ICON_PATH}_stopsign.png`,
    },
    [MarkerType.StrikeDip90]: {
        name: 'Strike Dip 90',
        iconUrl: `${MARKER_ICON_PATH}_strike_dip_90.png`,
    },
    [MarkerType.StrikeDip]: {
        name: 'Strike Dip',
        iconUrl: `${MARKER_ICON_PATH}_strike_dip.png`,
    },
    [MarkerType.Target]: {
        name: 'Target',
        iconUrl: `${MARKER_ICON_PATH}_target.png`,
    },
    [MarkerType.TargetCross]: {
        name: 'Target Cross',
        iconUrl: `${MARKER_ICON_PATH}_targetcross.png`,
    },
    [MarkerType.TrafficLights]: {
        name: 'Traffic Lights',
        iconUrl: `${MARKER_ICON_PATH}_trafficlights.png`,
    },
    [MarkerType.Walking]: {
        name: 'Walking',
        iconUrl: `${MARKER_ICON_PATH}_walking.png`,
    },
    [MarkerType.WheerChair]: {
        name: 'Wheel Chair',
        iconUrl: `${MARKER_ICON_PATH}_wheelchair.png`,
    },
    [MarkerType.X]: {
        name: 'X',
        iconUrl: `${MARKER_ICON_PATH}_x.png`,
    },
};

const leafIcon = (name: string, iconUrl: string, iconRetinaUrl?: string) =>
    L.Icon.extend({
        options: {
            name: name,
            iconUrl: iconUrl,
            iconRetinaUrl: iconRetinaUrl ? iconRetinaUrl : iconUrl,
            iconSize: [32, 32],
            iconAnchor: [16, 32],
            popupAnchor: [1, -34],
            tooltipAnchor: [16, -28],
            shadowSize: [32, 32],
        },
    });

export const generateIconMarkers = () => {
    const iconMarkerArray = Object.entries(iconMarkers).map(([_, marker]) => {
        return new (leafIcon(marker.name, marker.iconUrl))();
    });

    return iconMarkerArray;
};
