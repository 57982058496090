import ApiUser from '../../api/api-user';
import { ListingDTO, UserDTO } from '../../api/model';
import UserHelper from '../../lib/user-helper';
import { actionTypes as at } from './constants';
import { clarity } from 'react-microsoft-clarity';
import * as Sentry from '@sentry/react';

export const actionGetMyProfile = () => {
    return async (dispatch, getState) => {
        try {
            const result: UserDTO = await ApiUser.getMyUserProfile();
            dispatch(actionGetMyProfileSuccess(result));
            // Check if Clarity has been initialized before calling its methods
            if (clarity.hasStarted()) {
                clarity.identify(result.userId, { name: result.name || '', email: result.email || '' });
            }
            Sentry.setUser({ email: result.email, id: result.userId, username: result.name });
        } catch (err) {
            // dispatch(actionGetMyProfileError());
        }
    };
};

export const actionGetMyProfileSuccess = (user: UserDTO) => {
    return {
        type: at.GET_MY_PROFILE_SUCCESS,
        payload: user,
    };
};

export const actionLoginSuccess = (idToken: string) => {
    return async (dispatch, getState) => {
        UserHelper.saveIdToken(idToken);
        dispatch(actionGetMyProfile());
    };
};

export const actionGetMyListings = () => {
    return async (dispatch, getState) => {
        try {
            const result: ListingDTO[] = await ApiUser.getUserListings();
            dispatch(actionGetPilotListingsSuccess(result));
        } catch (err) {
            //  dispatch(actionGetPilotListingsError());
        }
    };
};

export const actionGetPilotListingsSuccess = (listings: ListingDTO[]) => {
    return {
        type: at.GET_MY_LISTINGS_SUCCESS,
        payload: listings,
    };
};

export const actionGetMyPointsSuccess = (points: number) => {
    return {
        type: at.GET_MY_POINTS_SUCCESS,
        payload: points,
    };
};

export const actionLogoutUser = () => {
    return {
        type: at.LOGOUT_USER,
    };
};
