import React, { useState } from 'react';
import AnnotationControl from '../annotation-control';
import AnnotationPopup from '../annotation-popup';
import { PolygonPath, PolylinePath, defaultPathOptions } from './path';
import PathAnnotation from './path-annotation';
import PathBuilder from './path-builder';
import PolygonAnnotation from './polygon-annotation';
import PathPopup from './path-popup';
import PolygonPopup from './polygon-popup';
import { Polyline as LeafletPolyline, Polygon as LeafletPolygon } from 'react-leaflet';

interface PathControlProps {
    hideControl: boolean;
}

const PathControl = (props: PathControlProps) => {
    const [pathBuilderActive, setPathBuilderActive] = useState(false);

    const [polylines, setPolylines] = useState<PolylinePath[]>([]);
    const [polygons, setPolygons] = useState<PolygonPath[]>([]);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="start"
                hideControl={props.hideControl}
                imageUrl="/assets/annotations/icon-path.svg"
                name="Path"
                onClick={() => setPathBuilderActive(true)}
            />

            {pathBuilderActive ? (
                <PathBuilder
                    onCreatePolygon={(polygon: PolygonPath) => {
                        setPathBuilderActive(false);
                        setPolygons([...polygons, polygon]);
                    }}
                    onCreatePolyline={(polyline: PolylinePath) => {
                        setPathBuilderActive(false);
                        setPolylines([...polylines, polyline]);
                    }}
                />
            ) : null}

            {props.hideControl &&
                polylines.map((polyline) => {
                    return (
                        <LeafletPolyline
                            {...polyline.options}
                            key={polyline.id}
                            positions={polyline.positions}
                            interactive={false}
                        />
                    );
                })}

            {!props.hideControl &&
                polylines.map((polyline) => {
                    return (
                        <PathAnnotation key={polyline.id} path={polyline}>
                            <PathPopup
                                path={polyline}
                                onDelete={() => {
                                    setPolylines((current) => {
                                        return current.filter((currentPolyline) => {
                                            return currentPolyline.id !== polyline.id;
                                        });
                                    });
                                }}
                                onUpdate={(polylineOptions) => {
                                    setPolylines((current) => {
                                        const index = current.findIndex((c) => c.id === polyline.id);
                                        const newPolylines = [...current];
                                        newPolylines[index].options = polylineOptions;
                                        return newPolylines;
                                    });
                                }}
                            />
                        </PathAnnotation>
                    );
                })}

            {props.hideControl &&
                polygons.map((polygon) => {
                    console.log(polygon);
                    return (
                        <LeafletPolygon
                            {...polygon.options}
                            key={polygon.id}
                            positions={polygon.positions}
                            interactive={false}
                        />
                    );
                })}

            {!props.hideControl &&
                polygons.map((polygon) => {
                    return (
                        <PolygonAnnotation key={polygon.id} path={polygon}>
                            <PolygonPopup
                                polygon={polygon}
                                onDelete={() => {
                                    setPolygons((current) => {
                                        return current.filter((currentPolygon) => {
                                            return currentPolygon.id !== polygon.id;
                                        });
                                    });
                                }}
                                onUpdate={(polygonOptions) => {
                                    setPolygons((current) => {
                                        const index = current.findIndex((c) => c.id === polygon.id);
                                        const newPolygons = [...current];
                                        newPolygons[index].options = polygonOptions;
                                        return newPolygons;
                                    });
                                }}
                            />
                        </PolygonAnnotation>
                    );
                })}
        </React.Fragment>
    );
};

export default PathControl;
