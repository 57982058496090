const SELECTED_COLORS = 'soar-annotation-user-colors';

export const PRESET_COLORS = ['#3388ff', '#eed926'];

export enum COLOR_PICKER_TYPE {
    LINE = 'LINE',
    FILL = 'FILL',
}
export default class ColorHelper {
    // Get user colors from local storage
    // If not found, return predefined colors
    public static getUserColors = (): string[] => {
        const storage = localStorage.getItem(SELECTED_COLORS);
        if (!storage) {
            // Return predefined colors
            return PRESET_COLORS;
        }

        try {
            const colors: string[] = JSON.parse(storage);
            return colors;
        } catch (err) {
            // If it fails give them the default colors
            return PRESET_COLORS;
        }
    };

    // Get last preset color
    public static getLastUsedColor = (): string => {
        const colors = ColorHelper.getUserColors();
        return colors[0];
    };

    // Save user colors to local storage
    public static setUserColors = (colors: string[]) => {
        localStorage.setItem(SELECTED_COLORS, JSON.stringify(colors));
    };
}
