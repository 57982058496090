import {
    LeafletContextInterface,
    PathProps,
    createElementHook,
    createElementObject,
    createLayerHook,
} from '@react-leaflet/core';
import L, { SVGOverlay } from 'leaflet';
import { TextBox } from './text-control';

interface TextAnnotationProps extends PathProps {
    text: TextBox;
}

export const createSVGTextElement = (props: TextAnnotationProps, context: LeafletContextInterface) => {
    const createSVG = (width: number, height: number) => {
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
        svg.setAttribute('overflow', 'visible');
        return svg;
    };

    const svg = createSVG(0, 0);
    const svgOverlay = new L.SVGOverlay(svg, props.text.bounds);
    const svgOverlayElement = createElementObject<SVGOverlay, TextAnnotationProps>(svgOverlay, context);

    const render = (element: SVGSVGElement) => {
        const lines = props.text.text.split('\n');
        const tspans = lines.map((line) => {
            const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
            tspan.setAttribute('x', '0');
            tspan.setAttribute('dy', '1em');
            tspan.innerHTML = line.length > 0 ? line : '&nbsp;';
            return tspan;
        });

        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', '0');
        text.setAttribute('y', '0');
        text.setAttribute('style', 'font-size: 32px;');
        text.setAttribute('fill', 'white');
        text.setAttribute('stroke', 'white');
        text.setAttribute('class', 'content');
        tspans.forEach((tspan) => text.appendChild(tspan));

        const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        rect.setAttribute('x', '0');
        rect.setAttribute('y', '0');
        rect.setAttribute('width', `100%`);
        rect.setAttribute('height', `100%`);
        rect.setAttribute('fill', 'black');

        element.innerHTML = rect.outerHTML + text.outerHTML;
    };

    const redraw = () => {
        const element = svgOverlayElement.instance.getElement() as SVGSVGElement;
        render(element);
        const sizes = calculateSize();
        element.setAttribute('viewBox', `0 0 ${sizes.x} ${sizes.y}`);

        const bounds = svgOverlayElement.instance.getBounds();
        const northWest = bounds.getNorthWest();
        const topLeft = context.map.latLngToLayerPoint(northWest);
        const bottomRight = L.point(topLeft.x + sizes.x, topLeft.y + sizes.y);
        const southEast = context.map.layerPointToLatLng(bottomRight);
        const updatedBounds = L.latLngBounds(northWest, southEast);

        // Fire a synthetic event to inform the text box it needs to update to accommodate the new text size
        svgOverlayElement.instance.fireEvent('update-bounds', { bounds: updatedBounds });
    };

    const calculateSize = () => {
        // Construct a temporary SVG element to calculate the size of the text using the browser's layout engine
        const temporaryElement = createSVG(0, 0);
        temporaryElement.setAttribute('visibility', 'hidden');

        // The map container seems to be more accurate for calculating the size of the text. Not sure why
        const container = context.map.getContainer();
        container.appendChild(temporaryElement);
        render(temporaryElement);
        const bbox = temporaryElement.getBBox();
        container.removeChild(temporaryElement);
        return L.point(bbox.width, bbox.height);
    };

    svgOverlayElement.instance.on('text-update', () => {
        redraw();
    });

    svgOverlayElement.instance.on('add', () => {
        redraw();
    });

    return svgOverlayElement;
};

const useSVGTextAnnotation = createElementHook<L.SVGOverlay, TextAnnotationProps, LeafletContextInterface>(
    createSVGTextElement
);
const useSVGText = createLayerHook(useSVGTextAnnotation);

const TextAnnotation = (props: TextAnnotationProps) => {
    useSVGText(props);
    return null;
};

export default TextAnnotation;
