import styled from 'styled-components';
import { generateColorMarkers } from './marker-util';
import { useEffect, useState } from 'react';
import Marker from './marker';

interface MarkerColorSelectProps {
    marker: Marker;
    onUpdate: (options: L.MarkerOptions) => void;
    icon: L.Icon;
    onIconChange: (icon: L.Icon) => void;
}

const MarkerColorSelect = (props: MarkerColorSelectProps) => {
    const [coloredMarkers, setColoredMarkers] = useState<L.Icon[]>([]);

    useEffect(() => {
        const newMarkers = generateColorMarkers();
        setColoredMarkers(newMarkers);
    }, []);

    const handleIconChange = (newIcon: L.Icon) => {
        const newMarkerOptions = { ...props.marker.options, icon: newIcon };
        props.onUpdate(newMarkerOptions);
        props.onIconChange(newIcon);
    };

    return (
        <MarkerColorSelectContainer>
            <MarkerOptions>
                {coloredMarkers?.map((newIcon) => {
                    return (
                        <MarkerItem
                            key={newIcon.options.iconUrl}
                            src={newIcon.options.iconUrl}
                            alt={newIcon.options.iconUrl}
                            onClick={() => handleIconChange(newIcon)}
                        />
                    );
                })}
            </MarkerOptions>
        </MarkerColorSelectContainer>
    );
};

export default MarkerColorSelect;

const MarkerColorSelectContainer = styled.div`
    color: white;
    text-align: center;
    padding-top: 5px;
`;

const MarkerOptions = styled.div`
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

const MarkerItem = styled.img`
    cursor: pointer;
    max-height: 30px;
    margin-right: 2px;
`;
