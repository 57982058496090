import React from 'react';
import styled from 'styled-components';
import AnnotationControl from './annotation-control';
import ArrowControl from './Arrow/arrow-control';
import CircleControl from './Circle/circle-control';
import CoordinateControl from './Coordinate/coordinate-control';
import HighlighterControl from './Highlighter/highlighter-control';
import ImageControl from './ImageTool/image-control';
import MarkerControl from './Marker/marker-control';
import PathControl from './Path/path-control';
import RectangleControl from './Rectangle/rectangle-control';
import TextControl from './Text/text-control';
import FreehandDrawControl from './FreehandDraw/freehand-draw-control';
import AreaMeasurementAnnotation from './AreaMeasurement/area-measurement-annotation';
import LeafletScreenShot from './Screenshot/screenshot-control';

import './annotations.css';
import RulerControl from './Ruler/ruler-control';
import { StyledButton } from '../../Shared/styled-button';

interface AnnotationProps {
    editMode: boolean;
    setEditMode: (isEditMode: boolean) => void;
}

const Annotations = (props: AnnotationProps) => {
    return (
        // Do not remove the className it is used to remove the element from the screen shot
        <AnnotationControlContainer className="leaflet-snapshot-ignore">
            <AnnotationFlexContainer>
                <PathControl hideControl={!props.editMode} />
                <ArrowControl hideControl={!props.editMode} />
                <CircleControl hideControl={!props.editMode} />
                <RectangleControl hideControl={!props.editMode} />
                <ImageControl hideControl={!props.editMode} />

                <FreehandDrawControl hideControl={!props.editMode} />
                <TextControl hideControl={!props.editMode} />
                <HighlighterControl hideControl={!props.editMode} />

                <MarkerControl hideControl={!props.editMode} />
                <RulerControl hideControl={!props.editMode} />
                <AreaMeasurementAnnotation />
                <CoordinateControl hideControl={!props.editMode} />

                {props.editMode ? (
                    <React.Fragment>
                        <LeafletScreenShot />
                        <ExitButton onClick={() => props.setEditMode(false)}>Exit</ExitButton>
                    </React.Fragment>
                ) : null}

                <AnnotationControl
                    layout="isolated"
                    hideControl={props.editMode}
                    imageUrl="/assets/annotations/icon-edit.svg"
                    name="Edit map"
                    onClick={() => props.setEditMode(true)}
                />
            </AnnotationFlexContainer>
        </AnnotationControlContainer>
    );
};

export default Annotations;

const ExitButton = styled(StyledButton)`
    background-color: rgba(0, 0, 0, 0.85) !important;
    -webkit-text-fill-color: white !important;
    height: 47px;
    width: 102px;

    &:hover {
        opacity: 1;
    }
`;

const AnnotationControlContainer = styled.div`
    position: fixed;
    background: transparent;
    width: auto;
    bottom: 30px;
    z-index: 100000;
    user-select: none;
    left: 50%;
    transform: translateX(-50%);
`;

const AnnotationFlexContainer = styled.div`
    display: flex;
    justify-content: center;

    width: fit-content;
    margin: 0 auto;
`;
