import Api from './api';
import ApiListings from './api-listings';
import { ListingDTO } from './model';

export default class ApiFeaturedContent extends Api {
    public static getHottestMaps(): Promise<ListingDTO[]> {
        return this.axios.get('/v1/listings?featured=best').then((res) => {
            return res.data.listings.map((t) => ApiListings.toListingDTO(t));
        });
    }

    public static getCurrentEventsListing(): Promise<ListingDTO> {
        return this.axios.get('/v1/listings?featured=news').then((res) => {
            return res.data.listings.map((t) => ApiListings.toListingDTO(t))[0];
        });
    }

    public static getGlobalMaps(): Promise<ListingDTO[]> {
        return this.axios.get('v1/listings?featured=global-maps').then((res) => {
            return res.data.listings.map((t) => ApiListings.toListingDTO(t));
        });
    }
}
