import L from 'leaflet';
import AnnotationPopup from '../annotation-popup';
import Arrow from './arrow';
import ColorSwatch from '../Popup/color-swatch';

interface ArrowAnnotationPopupProps {
    arrow: Arrow;
    onDelete: () => void;
    onUpdate: (options: L.PolylineOptions) => void;
}

const ArrowAnnotationPopup = (props: ArrowAnnotationPopupProps) => {
    return (
        <AnnotationPopup
            position={props.arrow.startLatLng}
            title="Arrow"
            keepInView={false}
            closeButton={false}
            autoClose={false}
            closeOnClick={false}
            onDelete={props.onDelete}
            eventHandlers={{
                add: (e: L.LeafletEvent) => {
                    const source = e.target._source as L.Polyline;
                    const sourceTarget = e.sourceTarget;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    source.addEventListener('update', (e) => {
                        const startLatLng = source.getLatLngs()[0] as L.LatLng;
                        const endLatLng = source.getLatLngs()[1] as L.LatLng;
                        const arrowBounds = new L.LatLngBounds(startLatLng, endLatLng);
                        const arrowTopCenter = new L.LatLng(arrowBounds.getNorth(), arrowBounds.getCenter().lng);

                        requestAnimationFrame(() => {
                            sourceTarget.setLatLng(arrowTopCenter);
                        });
                    });

                    const startLatLng = source.getLatLngs()[0] as L.LatLng;
                    const endLatLng = source.getLatLngs()[1] as L.LatLng;
                    const arrowBounds = new L.LatLngBounds(startLatLng, endLatLng);
                    const arrowTopCenter = new L.LatLng(arrowBounds.getNorth(), arrowBounds.getCenter().lng);
                    requestAnimationFrame(() => {
                        sourceTarget.setLatLng(arrowTopCenter);
                    });
                },
            }}
        >
            <ColorSwatch
                selectedColor={props.arrow.options.color || '#3388ff'}
                onSelectColor={(color) => {
                    props.onUpdate({
                        ...props.arrow.options,
                        color: color,
                    });
                }}
            />
        </AnnotationPopup>
    );
};

export default ArrowAnnotationPopup;
