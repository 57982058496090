import L from 'leaflet';
import Ruler from './ruler';
import {
    createContainerComponent,
    createElementHook,
    createElementObject,
    createPathHook,
    extendContext,
    LeafletContextInterface,
} from '@react-leaflet/core';
import { createRulerEditElement } from './ruler-edit-element';
import { createRulerFocusElement } from './ruler-focus-element';
import { createRulerGhostElement } from './ruler-ghost-element';
import { createRulerTextElement } from './ruler-text-element';
interface RulerAnnotationProps extends L.PolylineOptions {
    ruler: Ruler;
    children: React.ReactNode;
}

const createRulerElement = (props: RulerAnnotationProps, context: LeafletContextInterface) => {
    const ruler = new L.Polyline([props.ruler.startLatLng, props.ruler.endLatLng], props.ruler.options);
    const rulerElement = createElementObject<L.Polyline, RulerAnnotationProps>(
        ruler,
        extendContext(context, { overlayContainer: ruler })
    );

    const rulerFocusElement = createRulerFocusElement({ rulerElement: rulerElement }, context);
    const rulerGhostElement = createRulerGhostElement(
        { rulerElement: rulerElement, focusElement: rulerFocusElement },
        context
    );
    const rulerEditElement = createRulerEditElement({ rulerElement: rulerElement }, context);
    const rulerTextMarker = createRulerTextElement({ rulerElement: rulerElement }, context);

    rulerElement.instance.on('add', () => {
        context.map.addLayer(rulerGhostElement.instance);
        context.map.addLayer(rulerTextMarker.instance);
        rulerTextMarker.instance.setLatLng(rulerElement.instance.getCenter());
    });

    rulerElement.instance.on('remove', () => {
        context.map.off('click', onDeselect);
        context.map.removeLayer(rulerEditElement.instance);
        context.map.removeLayer(rulerFocusElement.instance);
        context.map.removeLayer(rulerGhostElement.instance);
        context.map.removeLayer(rulerTextMarker.instance);
    });

    rulerElement.instance.on('update', () => {
        rulerTextMarker.instance.setLatLng(rulerElement.instance.getCenter());
    });

    const onSelect = () => {
        context.map.addLayer(rulerEditElement.instance);
        context.map.removeLayer(rulerGhostElement.instance);
        context.map.removeLayer(rulerFocusElement.instance);
        context.map.on('click', onDeselect);
        rulerTextMarker.instance.setLatLng(rulerElement.instance.getCenter());
    };

    const onDeselect = () => {
        context.map.closePopup();
        context.map.removeLayer(rulerEditElement.instance);
        context.map.addLayer(rulerGhostElement.instance);
        context.map.addLayer(rulerFocusElement.instance);
        context.map.off('click', onDeselect);
        rulerGhostElement.instance.fireEvent('mouseout');
    };

    rulerElement.instance.on('click', onSelect);

    return rulerElement;
};

const updateRulerElement = (instance: L.Polyline, props: RulerAnnotationProps, _: RulerAnnotationProps) => {
    instance.setStyle({ ...instance.options, ...props.ruler.options });
    instance.fireEvent('update-ruler-color', { ...props });
};

const useRulerAnnotation = createElementHook<L.Polyline, RulerAnnotationProps, LeafletContextInterface>(
    createRulerElement,
    updateRulerElement
);
const useRuler = createPathHook<L.Polyline, RulerAnnotationProps>(useRulerAnnotation);
const RectangleAnnotation = createContainerComponent(useRuler);

export default RectangleAnnotation;
