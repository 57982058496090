import L from 'leaflet';

export default class Rectangle {
    id: string;
    boundingBox: L.LatLngBounds;
    options: L.PolylineOptions;

    constructor(boundingBox: L.LatLngBounds, options: L.PolylineOptions) {
        this.boundingBox = boundingBox;
        this.options = options;
    }
}

export const defaultRectangleOptions: L.PolylineOptions = {
    smoothFactor: 1.0,
    noClip: false,
    stroke: true,
    color: '#3388ff',
    weight: 3,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: true,
    fillColor: '#3388ff',
    fillOpacity: 0.2,
    fillRule: 'evenodd',
    interactive: true,
    bubblingMouseEvents: false,
};
