import React from 'react';
import styled from 'styled-components';

interface AnnotationControlProps {
    onClick: () => void;
    imageUrl: string;
    name: string;
    hideControl: boolean;
    layout: 'start' | 'middle' | 'end' | 'isolated';
}

const AnnotationControl = (props: AnnotationControlProps) => {
    if (props.hideControl) return <React.Fragment />;

    return (
        <AnnotationButton onClick={() => props.onClick()} title={props.name} layout={props.layout}>
            <AnnotationButtonIcon src={props.imageUrl} />
        </AnnotationButton>
    );
};

export default AnnotationControl;

const AnnotationButton = styled.button<{ layout: 'start' | 'middle' | 'end' | 'isolated' }>`
    width: 45px;
    height: 47px;
    border-top-left-radius: ${(props) => (props.layout === 'start' || props.layout === 'isolated' ? '4px' : '0px')};
    border-bottom-left-radius: ${(props) => (props.layout === 'start' || props.layout === 'isolated' ? '4px' : '0px')};
    border-top-right-radius: ${(props) => (props.layout === 'end' || props.layout === 'isolated' ? '4px' : '0px')};
    border-bottom-right-radius: ${(props) => (props.layout === 'end' || props.layout === 'isolated' ? '4px' : '0px')};
    margin: 0 auto;
    margin-right: ${(props) => (props.layout === 'end' || props.layout === 'isolated' ? '10px' : '0')};

    border: none;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    outline: none;
`;

const AnnotationButtonIcon = styled.img`
    margin: 0 auto;
    display: block;
    width: 100%;
    height: 100%;
`;
