import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';

const RulerAnnotationHandle = (color: string) => {
    return ReactDOMServer.renderToString(
        <RulerAnnotationGhost>
            <RulerAnnotationLineHandle color={color} />
        </RulerAnnotationGhost>
    );
};

export default RulerAnnotationHandle;

const RulerAnnotationGhost = styled.div`
    width: 16px;
    height: 20px;
    padding: 0px 8px;
`;

const RulerAnnotationLineHandle = styled.div<{ color: string }>`
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    margin-left: -12px;
    margin-top: 3px;
    border-radius: 260px;
`;
