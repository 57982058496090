import React, { useEffect } from 'react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { CSWTaskStatus, AnalyticsAction, AnalyticsNote, OrderDTO, TaskFile } from '../../../api/model';
import Analytics from '../../../lib/user-analytics';
import PriceWithCurrency from '../../Shared/price-with-currency';
import OrderStatus from './order-status';
import styled from 'styled-components';
import profileIconUrl from '../profile-icon-url';
import ApiAnalytics from '../../../api/api-analytics';
import UriHelper from '../../../lib/uri-helper';
import ApiOrder from '../../../api/api-order';

const moment = require('moment');

interface ProfileTaskItemProps {
    task: OrderDTO;
}

const OrderItemMobile = (props: ProfileTaskItemProps) => {
    const { task } = props;
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const { id } = task;
    const { paidByOtherMethod, paidByStripe, paidByVoucher, tax, amount } = task.payment;
    const [files, setFiles] = React.useState<TaskFile[] | undefined>(undefined);

    const toggle = () => setDropdownOpen(!dropdownOpen);

    useEffect(() => {
        if (dropdownOpen && files === undefined) {
            ApiOrder.getOrderDetails(id)
                .then((dto) => setFiles(dto.files))
                .catch((err) => console.log(err));
        }
    }, [dropdownOpen, files, id]);

    const handleClickDownload = (taskFileId: string) => {
        Analytics.Event('My Dashboard', 'Clicked to download satellite Task');
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.DOWNLOAD, AnalyticsNote.DASHBOARD_TASK, task.id, true);
        ApiOrder.getOrderDownloadLink(id, taskFileId)
            .then((downloadLink) => {
                const link = document.createElement('a');
                link.href = downloadLink;
                link.download = 'true';
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(() => console.error);
    };

    const typeStringForProvider = (type: string): string => {
        if (type === 'CSW' || type === 'CSW_CUSTOM') return 'New Collection';
        if (type === 'CSW_ARCHIVE' || type === 'CSW_CUSTOM_ARCHIVE') return 'Archival Imagery';
        if (type === 'CSW_CUSTOM_URGENT') return 'Urgent Collection';
        if (type === 'CSW_CUSTOM_STEREO') return 'Stereo Collection';
        if (type === 'CSW_CUSTOM_MONITORING') return 'Monitoring Collection';
        return 'Collection';
    };

    const totalPaid = paidByOtherMethod + paidByStripe + paidByVoucher;

    const onClickPayment = () => {
        Analytics.Event('My Dashboard', 'Clicked to open task payment', id);
        UriHelper.navigateToPath(`/order/payment?id=${id}`);
    };

    return (
        <>
            <MobileTaskItemDiv>
                <HeaderDiv>
                    <TaskOrderNumber>{task.orderNumber}</TaskOrderNumber>
                    <TaskStatus>{task.status && <OrderStatus status={task.status} />}</TaskStatus>
                </HeaderDiv>
                <DescriptionDiv>
                    <div>
                        <TaskIcon src={profileIconUrl.satelliteDateUrl} />
                        {moment(task.created).format('YYYY-MM-DD')}
                    </div>
                    <div>
                        <TaskIcon src={profileIconUrl.satelliteTypeUrl} />
                        {typeStringForProvider(task.provider)}
                    </div>
                    <div>
                        <TaskIcon src={profileIconUrl.satelliteAreaUrl} />
                        {task.effectiveAreaInKm?.toFixed(2)} km²
                    </div>
                </DescriptionDiv>

                <PriceDiv>
                    <PriceWithCurrency value={task.payment.amount / 100} currency={task.payment.currency} />
                </PriceDiv>
                <TaskButtons>
                    {task.status === CSWTaskStatus.DELIVERED && (
                        <TaskButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                            <TaskDropdownToggle caret>
                                <i className="fa fa-download" />
                                &nbsp; Download
                            </TaskDropdownToggle>
                            <DropdownMenu>
                                {files?.map((item) => {
                                    const extension = item.fileName.substr(item.fileName.lastIndexOf('.') + 1);
                                    return (
                                        <DropdownItem onClick={() => handleClickDownload(item.id)}>
                                            .{extension}
                                        </DropdownItem>
                                    );
                                })}
                            </DropdownMenu>
                        </TaskButtonDropdown>
                    )}
                    {task.status !== CSWTaskStatus.REJECTED && (
                        <>
                            {!task.payment.invoiceUrl && task.payment.receiptUrl && (
                                <TaskButtonInvoice href={task.payment.receiptUrl} target="_blank">
                                    <i className="fa fa-download" />
                                    &nbsp; Receipt
                                </TaskButtonInvoice>
                            )}
                            {task.payment.invoiceUrl && (
                                <TaskButtonInvoice href={task.payment.invoiceUrl} target="_blank">
                                    <i className="fa fa-download" />
                                    &nbsp; Invoice
                                </TaskButtonInvoice>
                            )}
                        </>
                    )}
                    {task.status === CSWTaskStatus.REVIEW_SOAR && paidByStripe === 0 && totalPaid !== tax + amount && (
                        <TaskButtonInvoice onClick={onClickPayment} type="button" className="btn-small" target="_blank">
                            <i className="fa fa-cc-stripe" />
                            &nbsp; Pay via Stripe
                        </TaskButtonInvoice>
                    )}
                </TaskButtons>

                {task.status === CSWTaskStatus.REJECTED && task.noteRejected ? (
                    <TaskRejectionNotes>Note: {task.noteRejected}</TaskRejectionNotes>
                ) : null}
            </MobileTaskItemDiv>
        </>
    );
};

export default OrderItemMobile;

const MobileTaskItemDiv = styled.div`
    color: white;
    border-bottom: 1px solid white;
    padding: 5px 8px;
`;

const HeaderDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const TaskOrderNumber = styled.div`
    font-size: 16px;
    color: white;
    padding-right: 5px;
`;

const TaskStatus = styled.div``;

const DescriptionDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0px;

    div {
        color: rgba(255, 255, 255, 0.87);
        display: flex;
    }
`;

const PriceDiv = styled.div`
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    color: #eed926;
    font-size: 18px;
    text-align: space-between;
`;

const TaskButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
`;

const TaskDropdownToggle = styled(DropdownToggle)`
    background-color: #eed926 !important;
    color: black !important;
    margin: 5px !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
    text-transform: uppercase;
`;

const TaskButtonInvoice = styled.a`
    background-color: #eed926 !important;
    color: black !important;
    margin: 5px 0px !important;
    padding: 5px 8px !important;
    font-size: 12px !important;
    border-radius: 4px !important;
`;

const TaskRejectionNotes = styled.div`
    overflow: auto;
    color: white;
`;

const TaskButtonDropdown = styled(ButtonDropdown)``;

const TaskIcon = styled.img`
    margin-right: 5px;
    opacity: 0.87;
`;
