/* eslint-disable */
// Ignore for now takes too long
import React from "react";

import "./leaflet-distortableimage";
import "./leaflet-path-transform";
import L from "leaflet";

class ReactDistortableImageOverlayMapLayer extends React.Component {
  createLeafletElement(props) {
    this.distortableImage = new L.DistortableImageOverlay(props.url, {
      ...props,
    });
    this.originalCorners = props.corners;
    L.DomEvent.on(
      this.distortableImage,
      "load",
      () => {
        this.distortableImage._image.style.opacity = this.props.opacity;
        this.handleEditModeState(props.editMode);
      },
      this.distortableImage
    );

    this.distortableImage.on(
      "edit",
      (update) => {
        this.props.onUpdate(update.sourceTarget._corners);
      },
      this.distortableImage
    );

    return this.distortableImage;
  }

  updateLeafletElement(toProps) {
    var map = this.distortableImage._map;
    if (!map) {
      return;
    }

    this.distortableImage.onRemove();
    if (this.translateUpdateCorners !== undefined) {
      this.distortableImage = new L.DistortableImageOverlay(toProps.url, {
        corners: this.translateUpdateCorners,
      });
      this.translateUpdateCorners = undefined;
    } else {
      this.distortableImage = new L.DistortableImageOverlay(toProps.url, {
        ...toProps,
      });
    }

    // Apply opacity after the image loads
    L.DomEvent.on(
      this.distortableImage,
      "load",
      () => {
        this.distortableImage._image.style.opacity = this.props.opacity;
      },
      this.distortableImage
    );

    // Re-add the update listener
    this.distortableImage.on(
      "edit",
      (update) => {
        this.props.onUpdate(update.sourceTarget._corners);
      },
      this.distortableImage
    );

    if (!this.distortableImage) {
      return;
    }
    map.addLayer(this.distortableImage);

    this.endTranslate();
    this.handleEditModeState(toProps.editMode);

    if (this.props.remove) {
      map.remove(this.distortableImage);
    }
  }

  handleEditModeState(editMode) {
    switch (editMode) {
      case "rotate":
        this.distortableImage.editing.enable();
        this.distortableImage.editing._toggleRotateDistort();
        this.distortableImage._image.style.opacity = this.props.opacity;
        break;

      case "distort":
        this.distortableImage.editing.enable();
        this.distortableImage._image.style.opacity = this.props.opacity;
        break;

      case "translate":
        this.startTranslate();
        break;

      case "scale":
        this.distortableImage.editing.enable();
        this.distortableImage.editing._toggleScale();
        this.distortableImage._image.style.opacity = this.props.opacity;
        break;

      case "remove":
        const currentMap = this.props.map;
        currentMap.eachLayer( function(layer) {
            if (layer instanceof  L.DistortableImageOverlay ) {
              currentMap.removeLayer(layer);
            }
        } );
        break
      default:
        this.distortableImage.editing.disable();
        this.distortableImage._image.style.opacity = this.props.opacity;
    }
  }

  startTranslate() {
    var map = this.distortableImage._map;
    var corners = this.distortableImage.getCorners();

    if (!map || !corners) {
      return;
    }

    // DistortableImageOverlay corners have different ordering from L.Polygon bounds
    var polygonDragCorners = [corners[0], corners[1], corners[3], corners[2]];

    this.translatePolygon = new L.Polygon(polygonDragCorners, {
      draggable: true,
      transform: true,
      color: "red",
      fill: true,
    });
    this.distortableImage._image.style.opacity = this.props.opacity;
    this.translatePolygon.transform.enable();

    this.translatePolygon.on("dragend", (event) => {
      var latlngs = event.target.getLatLngs()[0];
      var newImageCorners = [latlngs[0], latlngs[1], latlngs[3], latlngs[2]];

      // Without this timeout it crashes trying to access the map ref of a removed element.
      // Not sure why..
      setTimeout(() => {
        this.props.onUpdate(newImageCorners);
      }, 10);
      map.removeLayer(this.distortableImage);

      this.distortableImage = new L.DistortableImageOverlay(this.props.url, {
        corners: newImageCorners,
      });
      this.distortableImage.addTo(map);
      this.distortableImage._image.style.opacity = this.props.opacity;

      this.translateUpdateCorners = newImageCorners;
    });

    this.translatePolygon.addTo(map);
  }

  endTranslate() {
    var map = this.distortableImage._map;
    if (this.translatePolygon) {
      map.removeLayer(this.translatePolygon);
    }
  }

  componentDidMount() {
    const { map } = this.props;
    const control = this.createLeafletElement(this.props);
    this.ref = this.distortableImage;
    control.addTo(map);
  }

  componentDidUpdate() {
    this.updateLeafletElement(this.props);
  }

  render() {
    return null;
  }
}

function withMap(Component) {
  return function WrappedComponent(props) {
    return <Component {...props} id="the-distortable-overlay"/>;
  };
}

export default withMap(ReactDistortableImageOverlayMapLayer);
