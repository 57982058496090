import React, { useState } from 'react';
import L from 'leaflet';
import AnnotationControl from '../annotation-control';
import MarkerBuilder from './marker-builder';
import Marker, { defaultIcon } from './marker';
import MarkerAnnotation from './marker-annotation';
import MarkerControlPopup from './marker-control-popup';
import { Marker as LeafletMarker } from 'react-leaflet';

interface MarkerControlProps {
    hideControl: boolean;
}

const MarkerControl = (props: MarkerControlProps) => {
    const [isCreateActive, setIsCreateActive] = useState(false);
    const [markers, setMarkers] = useState<Marker[]>([]);
    const [icon, setIcon] = useState<L.Icon>(defaultIcon);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="start"
                hideControl={props.hideControl}
                imageUrl="/assets/annotations/icon-marker.svg"
                name="Marker"
                onClick={() => setIsCreateActive(true)}
            />

            {isCreateActive ? (
                <MarkerBuilder
                    icon={icon}
                    onCreateMarker={(marker: Marker) => {
                        setIsCreateActive(false);
                        setMarkers([...markers, marker]);
                    }}
                />
            ) : null}

            {props.hideControl &&
                markers.map((marker) => {
                    return (
                        <LeafletMarker
                            key={marker.id}
                            position={marker.position}
                            icon={(marker.options.icon as L.Icon) || defaultIcon}
                            interactive={false}
                        />
                    );
                })}

            {!props.hideControl &&
                markers.map((marker) => {
                    return (
                        <MarkerAnnotation
                            key={marker.id}
                            editMode={!props.hideControl}
                            marker={marker}
                            onUpdateMarker={(marker) => {
                                setMarkers((current) => {
                                    const index = current.findIndex((m) => m.id === marker.id);
                                    const newMarkers = [...current];
                                    newMarkers[index] = marker;
                                    return newMarkers;
                                });
                            }}
                            icon={(marker.options.icon as L.Icon) || defaultIcon}
                        >
                            <MarkerControlPopup
                                marker={marker}
                                icon={icon}
                                setIcon={setIcon}
                                onDelete={() => {
                                    setMarkers((current) => {
                                        return current.filter((m) => m.id !== marker.id);
                                    });
                                }}
                                onUpdate={(markerOptions) => {
                                    setMarkers((current) => {
                                        const index = current.findIndex((m) => m.id === marker.id);
                                        const newMarkers = [...current];
                                        newMarkers[index].options = markerOptions;
                                        return newMarkers;
                                    });
                                }}
                            />
                        </MarkerAnnotation>
                    );
                })}
        </React.Fragment>
    );
};

export default MarkerControl;
