import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import ImageryMasonaryCard from './imagery-masonary-card';

import { MasonryScroller, useContainerPosition, usePositioner, useResizeObserver } from 'masonic';
import { useWindowSize } from '@react-hook/window-size';
import ImageryAddCard from './imagery-add-card';
import ReportProblem from '../../Contact/contact-us';
import ImageryEditModal from './imagery-edit-modal';
import ImageryDeleteModal from './imagery-delete-modal';
import ListingHelper from '../../../lib/listing-helper';
import UriHelper from '../../../lib/uri-helper';
import NoImageryModal from './Shared/no-imagery-modal';
import profileIconUrl from '../profile-icon-url';
import { StyledButton } from '../../Shared/styled-button';
import { PulseLoader } from '../../Shared/pulse-loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageryNoResultsMobile from './imagery-no-results-mobile';
import { isMobileVersion } from '../../../lib/soar-helper';

enum Mode {
    EDIT,
    DELETE,
    REPORT,
    RESUBMIT,
    REUPLOAD,
}

interface ImageryMasonaryProps {
    listings: ListingDTO[];
    onRefresh: () => void;
    sortBy?: string;
    hasSearched?: boolean;
}

const ImageryMasonary = (props: ImageryMasonaryProps) => {
    const { listings, onRefresh, sortBy } = props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [masonaryListings, setMasonaryListings] = useState<any>([]);
    const [masonaryKey, setMasonaryKey] = useState<string | undefined>(undefined);
    const containerRef = useRef(null);
    const [selectedListing, setSelectedListing] = useState<ListingDTO | undefined>(undefined);
    const [mode, setMode] = useState<Mode | undefined>(undefined);

    const [windowWidth, windowHeight] = useWindowSize();
    const { offset, width } = useContainerPosition(containerRef, [windowWidth, windowHeight]);

    const masonryWidth = width || (isMobileVersion ? windowWidth - 10 : windowWidth - 350); //This makes image masonry adjust along with the window size for both  desktop and mobile
    const positioner = usePositioner(
        { width: masonryWidth, columnWidth: 270, columnGutter: 16 },
        // This is our dependencies array. When these dependencies
        // change, the positioner cache will be cleared and the
        // masonry component will reset as a result.
        [masonaryListings]
    );

    const resizeObserver = useResizeObserver(positioner);

    useEffect(() => {
        setMasonaryKey(`${listings.length.toString()}-${sortBy}`);
        setMasonaryListings(listings);
    }, [listings, sortBy]);

    const onAdd = () => {
        UriHelper.navigateToPath('/upload');
    };

    const onEdit = (id: number) => {
        setSelectedListing(listings.find((l) => l.id === id));
        setMode(Mode.EDIT);
    };

    const onDelete = (id: number) => {
        setSelectedListing(listings.find((l) => l.id === id));
        setMode(Mode.DELETE);
    };

    const onReport = (id: number) => {
        setSelectedListing(listings.find((l) => l.id === id));
        setMode(Mode.REPORT);
    };

    if (listings.length === 0) {
        return (
            <>
                <NoImageryModal
                    icon={profileIconUrl.noImagesUrl}
                    title={"It looks like you haven't uploaded any maps yet"}
                    text={
                        '  What are you waiting for!? Check the tutorials on how to create compelling content and keep those images Soaring!'
                    }
                    mobileText={''}
                />
                {!isMobileVersion && <Button onClick={onAdd}>Upload Maps</Button>}
            </>
        );
    }

    return (
        <ProfileImageryMasonaryContainer>
            {!isMobileVersion ? (
                <MasonryScroller
                    positioner={positioner}
                    resizeObserver={resizeObserver}
                    key={masonaryKey}
                    items={[
                        ...masonaryListings.map((value, index) => {
                            return { id: index, listing: value };
                        }),
                        { isAdd: true },
                    ]}
                    render={(item) =>
                        item.data.isAdd ? (
                            <React.Fragment>
                                {!isMobileVersion && masonaryListings.length > 1 && (
                                    <ImageryAddCard onAddClick={onAdd} />
                                )}
                            </React.Fragment>
                        ) : (
                            <ImageryMasonaryCard
                                listing={item.data.listing}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                onReport={onReport}
                                handleAction={(action) => {
                                    if (action === 'RESUBMIT') {
                                        setSelectedListing(item.data.listing);
                                        setMode(Mode.RESUBMIT);
                                    } else if (action === 'REUPLOAD') {
                                        setMode(Mode.REUPLOAD);
                                        UriHelper.navigateToPath(`/reupload/${item.data.listing.id}`);
                                    } else if (action === 'PUBLIC' || action === 'PRIVATE') {
                                        onRefresh();
                                    }
                                }}
                            />
                        )
                    }
                    containerRef={containerRef}
                    overscanBy={5}
                    tabIndex={-1}
                    offset={offset}
                    height={windowHeight}
                />
            ) : (
                <MobileInfiniteScroll
                    dataLength={listings.length}
                    next={() => ''}
                    height={'100%'}
                    hasMore={listings.length === 0 ? false : true}
                    loader={<PulseLoader />}
                    endMessage={
                        <ImageryNoResultsMobile
                            listingsLength={listings.length}
                            hasSearched={props.hasSearched ? true : false}
                        />
                    }
                >
                    {listings?.map((listing, index) => {
                        return (
                            <ImageryMasonaryCard
                                key={index}
                                listing={listing}
                                onEdit={onEdit}
                                onDelete={onDelete}
                                onReport={onReport}
                                handleAction={(action) => {
                                    if (action === 'RESUBMIT') {
                                        setSelectedListing(listing);
                                        setMode(Mode.RESUBMIT);
                                    } else if (action === 'REUPLOAD') {
                                        setMode(Mode.REUPLOAD);
                                        UriHelper.navigateToPath(`/reupload/${listing.id}`);
                                    } else if (action === 'PUBLIC' || action === 'PRIVATE') {
                                        onRefresh();
                                    }
                                }}
                            />
                        );
                    })}
                </MobileInfiniteScroll>
            )}
            {mode === Mode.EDIT && selectedListing && (
                <ImageryEditModal
                    listingId={selectedListing.id}
                    listing={selectedListing}
                    handleSave={() => {
                        onRefresh();
                        setMode(undefined);
                    }}
                    editDetailsOpen={true}
                    setEditDetailsOpen={() => setMode(undefined)}
                />
            )}
            {mode === Mode.DELETE && selectedListing && (
                <ImageryDeleteModal
                    refreshMasonary={() => {
                        onRefresh();
                        setMode(undefined);
                    }}
                    showDelete={true}
                    toggleShowDelete={() => setMode(undefined)}
                    listingId={selectedListing.id}
                />
            )}

            {mode === Mode.REPORT && selectedListing && (
                <ReportProblem
                    listingId={selectedListing.id}
                    isOpen={true}
                    toggle={() => setMode(undefined)}
                    customMessage={`What is the problem with ${ListingHelper.getTitleFromListing(selectedListing)}?`}
                />
            )}

            {mode === Mode.RESUBMIT && selectedListing && (
                <ImageryEditModal
                    listingId={selectedListing.id}
                    listing={selectedListing}
                    handleSave={() => {
                        onRefresh();
                        setMode(undefined);
                    }}
                    editDetailsOpen={true}
                    setEditDetailsOpen={() => setMode(undefined)}
                    reSubmit={true}
                />
            )}
        </ProfileImageryMasonaryContainer>
    );
};

export default ImageryMasonary;

const ProfileImageryMasonaryContainer = styled.div`
    background-color: #191a1a;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const Button = styled(StyledButton)`
    margin: 12px auto;
    display: block;
    z-index: 1000;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

const MobileInfiniteScroll = styled(InfiniteScroll)`
    overflow-x: hidden !important;
    scroll-behavior: smooth;
`;
