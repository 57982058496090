import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import L, { LeafletMouseEvent } from 'leaflet';
import { createCircleDragElement } from './circle-drag-element';
import { createCirclePathElement } from './circle-annotation-edit-hologram-path';

interface CircleEditElementProps {
    circleElement: Readonly<{ instance: L.Circle; context: Readonly<{ map: L.Map }> }>;
    color?: string;
}

export const createCircleEditElement = (props: CircleEditElementProps, context: LeafletContextInterface) => {
    const circleEditLayer = new L.LayerGroup();
    const circleEditLayerElement = createElementObject<L.LayerGroup>(circleEditLayer, context);
    const circleEdit = new L.Circle(props.circleElement.instance.getLatLng(), {
        ...props.circleElement.instance.options,
        radius: props.circleElement.instance.getRadius(),
    });
    const dragElement = createCircleDragElement({ circleElement: props.circleElement, color: props.color }, context);
    const pathElement = createCirclePathElement({ circleElement: props.circleElement, color: props.color }, context);

    const circleEditElement = createElementObject<L.Circle>(circleEdit, context);

    circleEditLayerElement.instance.on('add', () => {
        circleEditLayerElement.instance.addLayer(circleEditElement.instance);
        circleEditLayerElement.instance.addLayer(dragElement.instance);

        const mousemoveListener = (e: LeafletMouseEvent) => {
            props.circleElement.instance.fireEvent('resize', e);
            circleEditElement.instance.setRadius(circleEditElement.instance.getLatLng().distanceTo(e.latlng));
        };

        const mouseupListener = () => {
            context.map.doubleClickZoom.enable();
            context.map.dragging.enable();
            context.map.removeLayer(pathElement.instance);
            context.map.off('mousemove', mousemoveListener);
            context.map.off('mouseup', mouseupListener);
        };

        circleEditElement.instance.on('mousedown', () => {
            context.map.doubleClickZoom.disable();
            context.map.dragging.disable();
            context.map.addLayer(pathElement.instance);
            context.map.on('mousemove', mousemoveListener);
            context.map.on('mouseup', mouseupListener);
        });

        props.circleElement.instance.on('drag', (e: LeafletMouseEvent) => {
            circleEditElement.instance.setLatLng(e.latlng);
        });

        circleEditElement.instance.on('remove', () => {
            context.map.removeLayer(pathElement.instance);
            context.map.removeLayer(dragElement.instance);
        });
    });

    return circleEditLayerElement;
};
