export default class FreehandDraw {
    id: string;
    positions: L.LatLng[];
    options: L.PolylineOptions;

    constructor(id: string, positions: L.LatLng[], options: L.PolylineOptions) {
        this.id = id;
        this.positions = positions;
        this.options = options;
    }
}

export const defaultFreehandDrawOptions: L.PolylineOptions = {
    stroke: true,
    color: '#3388ff',
    weight: 3,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: undefined,
    fillColor: undefined,
    fillOpacity: 0.2,
    fillRule: 'evenodd',
    interactive: true,
    bubblingMouseEvents: true,
};
