import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import ListingHelper from '../../../../lib/listing-helper';
import Analytics from '../../../../lib/user-analytics';
import { ListingGroup } from '../../../../store/Map/MapSelection/model';
import PreviewTile from '../../../Shared/preview-tile';
import { PulseLoader } from '../../../Shared/pulse-loader';
import SoarModal from '../../../Shared/soar-modal';

interface MultiSelectModalProps {
    tileLayerGroup: ListingGroup;
    toggle: () => void;
    onSelectTileLayer: (tileLayerId: number) => void;
}

export interface ListingGroupTileLayers {
    id: number;
    authorName: string;
    title: string;
}

const LOAD_IN_COUNT = 20; // Items to load in when scroll reached
const LIST_ITEM_HEIGHT = 58; // Height of card loaded in to determine if container needs to be less that the default passed in
const DEFAULT_CONTAINER_HEIGHT = 400; // Default container height if more than the load count

const MultiSelectModal = (props: MultiSelectModalProps) => {
    const [dataList, setDataList] = useState<ListingGroupTileLayers[]>([]);
    const [tileLayers, setTileLayers] = useState<ListingGroupTileLayers[]>([]);
    const [containerHeight, setContainerHeight] = useState<number>(DEFAULT_CONTAINER_HEIGHT);

    const [hasMore, setHasMore] = useState<boolean>(true);

    useEffect(() => {
        if (!tileLayers?.length && props.tileLayerGroup?.tileLayers?.length) {
            const dataList = [...props.tileLayerGroup.tileLayers].sort((a, b) => b.id - a.id);
            setDataList(dataList);
            setTileLayers(dataList.splice(0, LOAD_IN_COUNT));
            setContainerHeight(
                dataList.length <= LOAD_IN_COUNT ? tileLayers.length * LIST_ITEM_HEIGHT : DEFAULT_CONTAINER_HEIGHT
            );
        }
    }, [props.tileLayerGroup?.tileLayers, tileLayers?.length]);

    const onClickTileLayer = (tileLayerId: number) => {
        props.onSelectTileLayer(tileLayerId);
        props.toggle();
        Analytics.Event('Main View', 'Clicked to select from multiselect', tileLayerId);
    };

    const fetchMoreData = () => {
        if (tileLayers.length >= dataList.length) {
            setHasMore(false);
            return;
        }
        Analytics.Event('Main View', 'Scrolled multiselect');
        const moreData = tileLayers.concat(dataList.splice(tileLayers.length, LOAD_IN_COUNT));
        setTileLayers(moreData);
    };

    return (
        <SoarModal
            isOpen={true}
            width={'600px'}
            toggle={props.toggle}
            title={`There are ${props.tileLayerGroup.tileLayers.length} maps to choose from here`}
        >
            {tileLayers ? (
                <MultiSelectInfiniteScroll
                    dataLength={tileLayers.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<PulseLoader iconSize="10px" />}
                    height={containerHeight}
                >
                    {tileLayers.map((tileLayer) => {
                        if (tileLayer) {
                            return (
                                <Container
                                    key={tileLayer.id}
                                    onClick={() => onClickTileLayer(tileLayer.id)}
                                    title={tileLayer.title}
                                >
                                    <PreviewTile
                                        title={tileLayer.title}
                                        previewUrl={ListingHelper.getPreviewUrlForListing(tileLayer.id, 'small')}
                                        subText={`by ${tileLayer.authorName || 'Soar User'}`}
                                    />
                                </Container>
                            );
                        } else {
                            return (
                                <Container>
                                    <PreviewTile previewUrl="/assets/basemap/black-pixel.png" />
                                </Container>
                            );
                        }
                    })}
                </MultiSelectInfiniteScroll>
            ) : null}
        </SoarModal>
    );
};

export default MultiSelectModal;

const MultiSelectInfiniteScroll = styled(InfiniteScroll)`
    margin-top: 5px;

    &::-webkit-scrollbar-track {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        margin: 3px 0;
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin: 3px 0;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #313131;
        border-radius: 4px;
    }
`;

const Container = styled.button`
    background-color: transparent;
    outline: none;
    text-align: left;
    padding: 0px;
    cursor: pointer;
    height: 48px;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin: 0 0 3px 0;
    border: 1px solid rgba(0, 0, 0, 0.3);
    &:hover {
        border: 1px solid #eed309;
    }
`;
