import React, { useState } from 'react';
import SoarModal from '../../../Shared/soar-modal';
import { useMap } from 'react-leaflet';
import { StyledButton, StyledDarkButton } from '../../../Shared/styled-button';
import styled from 'styled-components';
import { ScreenShotFileType, takeLeafletScreenShot } from './screenshot-control-util';
import ScreenShotLoader from './screenshot-loader';

interface ScreenShotModalProps {
    isOpen: boolean;
    toggle: () => void;
}

const ScreenShotModal = (props: ScreenShotModalProps) => {
    const [isCreatingScreenShot, setIsCreatingScreenShot] = useState<boolean>(false);
    const map = useMap();

    const handleScreenShot = async (type: ScreenShotFileType) => {
        setIsCreatingScreenShot(true);
        await takeLeafletScreenShot(map, type);
        setIsCreatingScreenShot(false);
    };

    return (
        <React.Fragment>
            {!isCreatingScreenShot ? (
                <ScreenShotModalContainer isOpen={props.isOpen} toggle={props.toggle} title="Select an export format">
                    <ScreenShotModalText>Select the format you wish to export the image as.</ScreenShotModalText>
                    <ExportButtonContainer>
                        <ExportButton onClick={() => handleScreenShot(ScreenShotFileType.JPEG)}>JPEG</ExportButton>
                        <ExportButton onClick={() => handleScreenShot(ScreenShotFileType.PNG)}>PNG</ExportButton>
                        {/* <ExportButton onClick={() => handleScreenShot(ScreenShotFileType.PDF)}>PDF</ExportButton> */}
                        <ExportCloseButton onClick={props.toggle}>Cancel</ExportCloseButton>
                    </ExportButtonContainer>
                </ScreenShotModalContainer>
            ) : (
                <ScreenShotLoader />
            )}
        </React.Fragment>
    );
};

export default ScreenShotModal;

const ScreenShotModalContainer = styled(SoarModal)``;

const ScreenShotModalText = styled.div`
    color: white;
    font-size: 16px;
    margin: 10px;
    text-align: center;
`;

const ExportButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 10px;
`;

const ExportButton = styled(StyledButton)`
    width: 130px;
`;

const ExportCloseButton = styled(StyledDarkButton)`
    width: 130px;
`;
