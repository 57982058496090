import L from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import { v4 as uuidv4 } from 'uuid';
import Rectangle, { defaultRectangleOptions } from './rectangle';

interface RectangleAnnotationBuilderProps {
    onCreateRectangle: (rectangle: Rectangle) => void;
}

const createRectangleBuilderElement = (props: RectangleAnnotationBuilderProps, context: LeafletContextInterface) => {
    const emptyLatLngBounds = new L.LatLngBounds(new L.LatLng(0, 0), new L.LatLng(0, 0));
    const rectangleElement = createElementObject<L.Rectangle, RectangleAnnotationBuilderProps>(
        new L.Rectangle(emptyLatLngBounds, defaultRectangleOptions),
        context
    );

    rectangleElement.instance.on('add', () => {
        context.map.dragging.disable();
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.on('mousedown', (e: L.LeafletMouseEvent) => {
            const startLatLng = e.latlng;
            rectangleElement.instance.setBounds(new L.LatLngBounds(e.latlng, e.latlng));

            context.map.on('mousemove', (e: L.LeafletMouseEvent) => {
                rectangleElement.instance.setBounds(new L.LatLngBounds(startLatLng, e.latlng));
            });

            context.map.on('mouseup', () => {
                context.map.off('mousemove');
                context.map.off('mousedown');
                context.map.off('mouseup');
                context.map.dragging.enable();
                L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

                const rectangle: Rectangle = {
                    id: uuidv4(),
                    boundingBox: rectangleElement.instance.getBounds(),
                    options: rectangleElement.instance.options,
                };
                props.onCreateRectangle(rectangle);
            });
        });
    });

    return rectangleElement;
};

const useRectangleBuilder = createElementHook<L.Rectangle, RectangleAnnotationBuilderProps, LeafletContextInterface>(
    createRectangleBuilderElement
);

const RectangleAnnotationBuilder = (props: RectangleAnnotationBuilderProps) => {
    const context = useLeafletContext();
    const rectangleBuilder = useRectangleBuilder(props, context);
    useLayerLifecycle(rectangleBuilder.current, context);
    return null;
};

export default RectangleAnnotationBuilder;
