import React from 'react';
import { UserDTO } from '../../../api/model';

import styled from 'styled-components';
import { isMobileVersion } from '../../../lib/soar-helper';
import { MarkdownViewer } from '../../Shared/markdown-viewer';

interface ProfileFullInfoProps {
    userProfile: UserDTO;
}

const ProfileFullInfo = (props: ProfileFullInfoProps) => {
    return (
        <Container>
            {!isMobileVersion ? (
                <ProfileBodyContainer>
                    <ProfileBio>
                        {/* <ProfileDescription> */}
                        <MarkdownViewer text={props.userProfile.longDescription || ''} />
                        {/* </ProfileDescription> */}
                    </ProfileBio>
                </ProfileBodyContainer>
            ) : (
                <React.Fragment>
                    {props.userProfile.longDescription ? (
                        <Award>
                            <AwardImage src="/assets/user-profile-logos/mobile-description-icon.png" />
                            <ProfileBio>
                                <MarkdownViewer text={props.userProfile.longDescription || ''} />
                            </ProfileBio>
                        </Award>
                    ) : (
                        <Divider />
                    )}
                </React.Fragment>
            )}
        </Container>
    );
};

export default ProfileFullInfo;

const Award = styled.div`
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
`;

const AwardImage = styled.img`
    position: relative;
    float: left;
    margin-right: 10px;
    height: 20px;
    margin-top: 4px;
`;

const Container = styled.div`
    text-align: left;
`;

const ProfileBodyContainer = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
`;

const Divider = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

const ProfileBio = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: justify;
    color: #ffffff;
    margin-bottom: 10px;
    opacity: 0.67;
    word-break: break-all;

    @media only screen and (max-width: 600px) {
        opacity: 0.87;
    }
`;
