import L from 'leaflet';

export default class Marker {
    id: string;
    position: L.LatLng;
    options: L.MarkerOptions;

    constructor(id: string, position: L.LatLng, options: L.MarkerOptions) {
        this.id = id;
        this.position = position;
        this.options = options;
    }
}

export const defaultMarkerOptions: L.MarkerOptions = {
    interactive: true,
};

export const defaultIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
});
