import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';

const BoxZoomControl = createControlComponent((props) => {
	return new L.Control.BoxZoomControl({
		position: 'topright',
		onClick: props.onClick,
		sticky: props.sticky,
	});
})

export default BoxZoomControl;

// TODO: Overhaul to make it look and feel like the other annotation tools
L.Control.BoxZoomControl = L.Control.extend({
	_style: null,
	_activeStyle: null,
	_isActive: false,
	_startLatLng: null,
	_sticky: false,
	_drawPolygon: null,
	_boxZoomButton: null,

	initialize: function(element) {
		this.options.position = element.position;
		this._buttonClicked = element.onClick;

		if (element.sticky !== undefined) {
			this._sticky = element.sticky;
		}
	},
	onAdd: function(map) {
		const boxZoomButton = L.DomUtil.create('button');
		boxZoomButton.setAttribute('style',this._style);
		boxZoomButton.setAttribute('id', 'box-zoom-button');
		boxZoomButton.setAttribute('title', 'Box zoom');

		boxZoomButton.onclick = () => {
			if(this._buttonClicked) this._buttonClicked();

			if (this._isActive) {
				this._stop(map, boxZoomButton);
			} else {
				this._start(map, boxZoomButton);
			}
		};
		this._boxZoomButton = boxZoomButton;

		return boxZoomButton;
	},
	onRemove: function(_) {
		// Do nothing
	},
	_stop: function(map, boxZoomButton) {
		map.dragging.enable();
		boxZoomButton.setAttribute('style', this._style);
		L.DomUtil.removeClass(map._container, 'crosshair-cursor-enabled');
		this._isActive = false;
		this._startLatLng = null;

		L.DomEvent.off(map, 'mousemove', this._handleMouseMove, this);
		L.DomEvent.off(map, 'mouseup', this._handleMouseUp, this);
		L.DomEvent.off(map, 'mouseout', this.forceStop, this);
		L.DomEvent.off(map, 'mousemove', this._handleMouseOut, this);
	},
	_handleMouseDown: function(e) {
		this._startLatLng = e.latlng;
		this._mouseMoved = false;
	},
	_handleMouseMove: function(e) {
		if (this._startLatLng === null || this._startLatLng === undefined) { return; }

		this._mouseMoved = true;

		const ne = this._startLatLng;
		const nw = new L.LatLng(this._startLatLng.lat, e.latlng.lng);
		const sw = e.latlng;
		const se = new L.LatLng(e.latlng.lat, this._startLatLng.lng);

		if (this._drawPolygon === null || this._drawPolygon === undefined) {
			this._drawPolygon = new L.Polygon([ne, nw, sw, se]);
			this._map.addLayer(this._drawPolygon);
		} else {
			this._drawPolygon.setLatLngs([ne, nw, sw, se]);
		}
	},
	_handleMouseOut: function(e) {
		const mapSize = this._map.getSize();
		if(
				e.containerPoint.x < 0 ||
				e.containerPoint.y < 0 ||
				e.containerPoint.x > mapSize.x ||
				e.containerPoint.y > mapSize.y) {
			this.forceStop();
		}
		e.originalEvent.preventDefault();
	},
	_handleMouseUp: function(e) {
		const map = this._map;
		if(!this._mouseMoved && this._isActive) {
			this._stop(this._map, this._boxZoomButton);
			return;
		}

		if (
			this._startLatLng === null || 
			this._startLatLng === undefined || 
			this._startLatLng.lat === e.latlng.lat ||
			this._startLatLng.lng === e.latlng.lng ||
			this._isActive === false) { return; }

		const ne = this._startLatLng;
		const sw = e.latlng;

		const bounds = L.latLngBounds([ne, sw])
		map.fitBounds(bounds, { animate: false });

		this._startLatLng = null;
		map.removeLayer(this._drawPolygon);
		this._drawPolygon = null;

		if (this._sticky) {
			this._startLatLng = null;
		} else {
			map.dragging.enable();
			this._boxZoomButton.setAttribute('style', this._style);
			L.DomUtil.removeClass(map._container, 'crosshair-cursor-enabled');
			this._isActive = false;

			L.DomEvent.off(map, 'mousemove', this._handleMouseMove, this);
			L.DomEvent.off(map, 'mouseup', this._handleMouseUp, this);
			L.DomEvent.off(map, 'mouseout', this.forceStop, this);
			L.DomEvent.off(map, 'mousemove', this._handleMouseOut, this);
		}
	},
	_start: function(map, boxZoomButton) {
		boxZoomButton.setAttribute('style', this._style + this._activeStyle);
		map.dragging.disable();
		L.DomUtil.addClass(map._container, 'crosshair-cursor-enabled')
		this._isActive = true;
		this._startLatLng = null;

		L.DomEvent.on(map, 'mousedown', this._handleMouseDown, this);
		L.DomEvent.on(map, 'mousemove', this._handleMouseMove, this);
		L.DomEvent.on(map, 'mouseup', this._handleMouseUp, this);
		L.DomEvent.on(map, 'mouseout', this.forceStop, this);
		L.DomEvent.on(map, 'mousemove', this._handleMouseOut, this);

	},
	forceStop: function() {
		const map = this._map;
		const boxZoomButton = L.DomUtil.get('box-zoom-button');
		if(this._drawPolygon) {
			map.removeLayer(this._drawPolygon);
			this._startLatLng = null;
			this._drawPolygon = null;
		}
		this._stop(map, boxZoomButton);
	},
	forceStart: function() {
		const map = this._map;
		const boxZoomButton = L.DomUtil.get('box-zoom-button');
		this._start(map, boxZoomButton);
	},
});

L.control.boxZoomControl = (opts) => {
	return new L.Control.BoxZoomControl({...opts});
}