import L from 'leaflet';
import {
    LeafletContextInterface,
    createElementHook,
    createElementObject,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import { ImageOverlayProps } from 'react-leaflet';

interface ImageOverlayEventedProps extends ImageOverlayProps {
    onLoad: () => void;
    onError: (error: Error) => void;
    onStart: () => void;
}

const createImageOverlayEvented = (props: ImageOverlayEventedProps, context: LeafletContextInterface) => {
    const imageOverlay = createElementObject<L.ImageOverlay, ImageOverlayEventedProps>(
        new L.ImageOverlay(props.url, props.bounds, {}),
        context
    );

    imageOverlay.instance.addEventListener('error', () => {
        // The image overlay does not give the actual url error so generic response supplied
        // We could add a xhr request but seems it will only compound the issue with more requests made
        props.onError(new Error('High Resolution Image failed'));
    });

    props.onStart();

    imageOverlay.instance.addEventListener('load', () => {
        props.onLoad();
    });

    return imageOverlay;
};

const updateImageOverlayEvented = (
    instance: L.ImageOverlay,
    props: ImageOverlayEventedProps,
    prevProps: ImageOverlayEventedProps
) => {
    if (prevProps.opacity !== props.opacity && props.opacity) {
        instance.setOpacity(props.opacity);
    }
};

const useImageOverlayEvented = createElementHook(createImageOverlayEvented, updateImageOverlayEvented);

const ImageOverlayEvented = (props: ImageOverlayEventedProps) => {
    const context = useLeafletContext();
    const imageOverlayEvented = useImageOverlayEvented(props, context);
    useLayerLifecycle(imageOverlayEvented.current, context);
    return null;
};

export default ImageOverlayEvented;
