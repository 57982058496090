import ColorSwatch from '../Popup/color-swatch';
import AnnotationPopup from '../annotation-popup';
import Highlighter from './highlighter';

interface HighlighterPopupProps {
    highlighter: Highlighter;
    onDelete: () => void;
    onSelectColor: (color: string) => void;
}

const HighlighterPopup = (props: HighlighterPopupProps) => {
    return (
        <AnnotationPopup title="Highlighter" onDelete={props.onDelete}>
            <ColorSwatch
                selectedColor={props.highlighter.options.color || '#3388ff'}
                onSelectColor={(color) => {
                    props.onSelectColor(color);
                }}
            />
        </AnnotationPopup>
    );
};

export default HighlighterPopup;
