import styled from 'styled-components';
import AnnotationPopup from '../annotation-popup';
import Coordinate from './coordinate';
import CoordinateCopyControl from './coordinate-annotation-control-copy';

interface MarkerControlPopupProps {
    coordinate: Coordinate;
    onDelete: () => void;
}

const CoordinateControlPopup = (props: MarkerControlPopupProps) => {
    return (
        <CoordinatePopup title="Coordinate" onDelete={() => props.onDelete()}>
            <CoordinateCopyControl latlng={props.coordinate.position} />
        </CoordinatePopup>
    );
};

export default CoordinateControlPopup;

const CoordinatePopup = styled(AnnotationPopup)`
    width: 240px;
    bottom: 35px;

    &.leaflet-popup {
        bottom: 20px !important;
        left: -260px !important;
    }
`;
