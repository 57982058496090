import L from 'leaflet';
import Rectangle from './rectangle';
import {
    createContainerComponent,
    createElementHook,
    createElementObject,
    createPathHook,
    extendContext,
    LeafletContextInterface,
    PathProps,
} from '@react-leaflet/core';
import { defaultRectangleOptions } from './rectangle';
import { createRectangleGhostElement } from './rectangle-annotation-ghost-element';
import { createRectangleEditElement } from './rectangle-annotation-edit-element';

interface RectangleAnnotationProps extends PathProps {
    rectangle: Rectangle;
    onUpdateRectangleBoundingBox: (boundingBox: L.LatLngBounds) => void;
    children?: React.ReactNode;
}

export const ANNOTATION_TOOL_BOUNDS_CHANGED_EVENT = 'annotationTools:rectangle:updateBounds';

const createRectangleElement = (props: RectangleAnnotationProps, context: LeafletContextInterface) => {
    const rectangle = new L.Rectangle(props.rectangle.boundingBox, {
        ...defaultRectangleOptions,
        ...props.rectangle.options,
    });
    const rectangleElement = createElementObject<L.Rectangle, RectangleAnnotationProps>(
        rectangle,
        extendContext(context, { overlayContainer: rectangle })
    );

    const onClickMap = (e: L.LeafletMouseEvent) => {
        // Only deselect when a DIV (the map) is clicked.
        // This prevents deselection when the click event is triggered by the edit elements.
        // There is probably a better way to do this.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const target = (e.originalEvent.target as any).nodeName;
        if (target === 'DIV') {
            onDeselect();
        }
    };

    const onSelect = () => {
        context.map.dragging.disable();
        context.map.doubleClickZoom.disable();
        rectangleElement.instance.fireEvent(ANNOTATION_TOOL_BOUNDS_CHANGED_EVENT, {
            boundingBox: rectangleElement.instance.getBounds(),
        });
        context.map.on('mousedown', onClickMap);
        rectangleElement.instance.openPopup();
        context.map.removeLayer(rectangleGhostElement.instance);
        context.map.addLayer(editRectangleElement.instance);
    };

    const onDeselect = () => {
        context.map.dragging.enable();
        context.map.doubleClickZoom.enable();
        context.map.addLayer(rectangleGhostElement.instance);
        rectangleGhostElement.instance.fire('mouseout');
        context.map.removeLayer(editRectangleElement.instance);
        context.map.off('click', onDeselect);
        context.map.off('click', onClickMap);
        rectangleElement.instance.closePopup();
    };

    const editRectangleElement = createRectangleEditElement(
        {
            bounds: rectangleElement.instance.getBounds(),
            context: context,
            rectangleElement: rectangleElement,
        },
        context
    );

    const rectangleGhostElement = createRectangleGhostElement(
        {
            bounds: rectangleElement.instance.getBounds(),
            context: context,
            rectangleElement: rectangleElement,
        },
        context
    );

    rectangleElement.instance.on('mouseover', () =>
        rectangleElement.instance.setStyle({ ...rectangleElement.instance.options, weight: 4 })
    );

    rectangleElement.instance.on('mouseout', () => {
        rectangleElement.instance.setStyle({ ...rectangleElement.instance.options, weight: 3 });
    });

    rectangleElement.instance.on('add', () => {
        context.map.addLayer(rectangleGhostElement.instance);
    });

    rectangleElement.instance.on('update', () => {
        props.onUpdateRectangleBoundingBox(rectangleElement.instance.getBounds());
        rectangleElement.instance.fireEvent(ANNOTATION_TOOL_BOUNDS_CHANGED_EVENT, {
            boundingBox: rectangleElement.instance.getBounds(),
        });
    });

    rectangleElement.instance.on('remove', () => {
        context.map.closePopup();
        context.map.off('click', onDeselect);
        context.map.off('click', onClickMap);
        context.map.removeLayer(rectangleGhostElement.instance);
        context.map.removeLayer(editRectangleElement.instance);
        context.map.dragging.enable();
        context.map.doubleClickZoom.enable();
    });

    rectangleElement.instance.on('click', onSelect);

    return rectangleElement;
};

const updateRectangleElement = (
    instance: L.Rectangle,
    props: RectangleAnnotationProps,
    prevProps: RectangleAnnotationProps
) => {
    instance.setStyle({ ...instance.options, ...props.rectangle.options });

    if (!props.rectangle.boundingBox.equals(prevProps.rectangle.boundingBox)) {
        instance.setBounds(props.rectangle.boundingBox);
        props.onUpdateRectangleBoundingBox(props.rectangle.boundingBox);
    }
};

const useRectangleAnnotation = createElementHook<L.Rectangle, RectangleAnnotationProps, LeafletContextInterface>(
    createRectangleElement,
    updateRectangleElement
);
const useRectangle = createPathHook<L.Rectangle, RectangleAnnotationProps>(useRectangleAnnotation);
const RectangleAnnotation = createContainerComponent(useRectangle);

export default RectangleAnnotation;
