import styled from 'styled-components';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import NavigationButton from '../Shared/navigation-button';
import NavigationPanel from '../Shared/navigation-panel';
import { isMobileVersion } from '../../../lib/soar-helper';

interface DataManagementNavigationProps {
    mapsCount: number | undefined;
}

const DataManagementNavigation = (props: DataManagementNavigationProps) => {
    const { mapsCount } = props;
    return (
        <NavigationPanel label="Data Management">
            <MobileMapContainer>
                {(isMobileVersion && mapsCount) ||
                    ((mapsCount ? mapsCount : 0) > 0 && <MobileMapContainer> {`(${mapsCount})`} </MobileMapContainer>)}
            </MobileMapContainer>
            {!isMobileVersion && (
                <>
                    <NavigationButton
                        label="UPLOAD MAPS"
                        onClick={() => {
                            UriHelper.navigateToDrawer(SideDrawerMode.SHARE_MAP);
                            Analytics.Event('Dashboard', 'Clicked upload maps');
                        }}
                        active={true}
                    />
                    <ImageryMapCount>{`MAPS (${mapsCount ? mapsCount : '0'})`}</ImageryMapCount>
                </>
            )}
        </NavigationPanel>
    );
};

export default DataManagementNavigation;

const ImageryMapCount = styled.span`
    color: white;
    margin: auto 10px;

    @media only screen and (max-width: 600px) {
        margin: auto;
    }
`;

const MobileMapContainer = styled.div`
    margin: 0;
    color: white;
    display: none;

    @media only screen and (max-width: 600px) {
        margin: 20px 15px 0px 0px;
        display: inline-block;
    }
`;
