import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import ApiUser from '../../api/api-user';
import Analytics from '../../lib/user-analytics';
import UserHelper from '../../lib/user-helper';
import { actionLoginSuccess } from '../../store/Account/actions';
import { StyledButton } from '../Shared/styled-button';
import { SubmittingSpinner } from '../Shared/submitting-spinner';
import { passwordActiveUrl, passwordInActiveUrl } from './registration-icons';

interface LoginProps {
    email: string;
    onEmailChange: (email: string) => void;
    onClose: () => void;
    handleClickForgotPassword: () => void;
    handleClickRegister: () => void;
}

const Login = (props: LoginProps) => {
    const { email } = props;

    const dispatch = useDispatch();
    const apiLoginSuccess = (idToken: string) => dispatch(actionLoginSuccess(idToken));

    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loginSubmitting, setLoginSubmitting] = useState<boolean>(false);

    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        setError(undefined);
        if (!UserHelper.isEmailValid(email)) {
            setError('Please check your email and try again');
            return;
        }

        if (!password) {
            setError('Please check your password and try again');
            return;
        }

        try {
            setLoginSubmitting(true);
            const idToken = await ApiUser.login(email, password);
            apiLoginSuccess(idToken);
            Analytics.Event('Registration', 'Logged in');
            props.onClose();
        } catch (err) {
            setError(err.message.replace('401:', ''));
        } finally {
            setLoginSubmitting(false);
        }
    };

    return (
        <>
            <LoginModalBody>
                <Form onSubmit={(e) => handleSubmitLogin(e)}>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                            type="email"
                            placeholder="Email Address"
                            suggested="email"
                            value={email}
                            onChange={(e) => {
                                props.onEmailChange(e.target.value);
                                setError(undefined);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Password</Label>
                        <Input
                            data-sentry-block
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            value={password}
                            suggested="current-password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setError(undefined);
                            }}
                        />
                        <LoginFormPasswordIcon
                            showPassword={showPassword}
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </FormGroup>
                    {error && (
                        <LoginModalError>
                            <LoginModalErrorIcon />
                            {error}
                        </LoginModalError>
                    )}
                    {!loginSubmitting ? (
                        <LoginSubmitButton onClick={(e) => handleSubmitLogin(e)} error={error ? true : false}>
                            SIGN IN
                        </LoginSubmitButton>
                    ) : (
                        <SubmittingSpinner />
                    )}
                    <LoginFormForgotPassword onClick={props.handleClickForgotPassword}>
                        Forgot password?
                    </LoginFormForgotPassword>
                </Form>
            </LoginModalBody>
            <LoginModalFooter>
                New to Soar?
                <LoginFooterLink onClick={props.handleClickRegister}>Create your free account.</LoginFooterLink>
            </LoginModalFooter>
        </>
    );
};

export default Login;

const LoginModalError = styled.div`
    color: red !important;
    -webkit-text-fill-color: red;
    padding: 10px;
    padding-top: 0px;
    display: flex;
`;

const LoginModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const LoginModalBody = styled(ModalBody)`
    color: rgba(255, 255, 255, 0.87);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.87);
    padding-bottom: 0px;

    label {
        color: rgba(255, 255, 255, 0.87);
        width: 100%;
    }

    input {
        background-color: rgba(0, 0, 0, 0) !important;
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgb(255 255 255 / 0.3) !important;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6);

        :hover {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
            -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
        }

        :focus {
            border: 1px solid rgba(238 227 8 / 0.6) !important;
            border-color: rgba(238 227 8 / 0.6) !important;
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
            -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
        }
    }
`;

const LoginFormForgotPassword = styled.div`
    text-align: end;
    margin: 5px 0px;
    font-size: 11px;
    color: rgb(255 255 255 / 0.6) !important;
    text-decoration: underline;
    cursor: pointer;
`;

interface PasswordIconProps {
    showPassword: boolean;
}

const LoginFormPasswordIcon = styled.div<PasswordIconProps>`
    background-image: url('${(props) => (props.showPassword ? passwordActiveUrl : passwordInActiveUrl)}');
    background-repeat: no-repeat;
    width: 25px;
    height: 20px;
    float: right;
    margin-top: -26px;
    margin-right: 5px;
`;

interface ErrorProps {
    error: boolean;
}

const LoginSubmitButton = styled(StyledButton)<ErrorProps>`
    margin: 0 auto;
    margin-top: ${(props) => (props.error ? '0px' : '20px')};
    background-color: gray;
    width: 100%;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
        -webkit-text-fill-color: black;
        color: black;
    }
`;

const LoginModalFooter = styled(ModalFooter)`
    color: rgba(255, 255, 255, 0.87);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    display: block;
    text-align: center;
`;

const LoginFooterLink = styled.a`
    color: #eed926 !important;
    margin-left: 3px;
    text-decoration: underline !important;
    cursor: pointer;
`;
