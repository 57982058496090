import { Map } from 'leaflet';
import * as htmlToImage from 'html-to-image';

// eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
const download = require('downloadjs');

export enum ScreenShotFileType {
    PNG = 'image/png',
    JPEG = 'image/jpeg',
    PDF = 'application/pdf',
}

export const takeLeafletScreenShot = async (map: Map, filetype: ScreenShotFileType) => {
    const filteredElements = (node) => {
        const exclusionClasses = ['leaflet-snapshot-ignore', 'leaflet-control-container', 'leaflet-popup'];
        return !exclusionClasses.some((classname) => node.classList?.contains(classname));
    };
    try {
        const settings = {
            width: map.getSize().x,
            height: map.getSize().y,
            cacheBust: false,
            filter: filteredElements,
            //skipAutoScale: true,
        };
        const filename = `${Date.now()}_by_soar`;
        // For now we handle only png and jpeg but can be extended to other formats
        // List available here https://github.com/bubkoo/html-to-image
        let data;
        if (filetype === ScreenShotFileType.JPEG) {
            data = await htmlToImage.toJpeg(map.getContainer(), settings);
        } else if (filetype === ScreenShotFileType.PNG) {
            data = await htmlToImage.toPng(map.getContainer(), settings);
        } else if (filetype === ScreenShotFileType.PDF) {
            // TODO - out of scope for current task
        }
        download(data, filename, filetype);
    } catch (error) {
        console.error(error);
    }
};
