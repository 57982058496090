import { LatLng, LatLngBounds } from 'leaflet';
import React, { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import UriHelper from '../../lib/uri-helper';
import {
    actionFlyToInvalidate,
    actionFlyToZoom,
    actionMapLoaded,
    actionMapUnloaded,
    actionSetMapBounds,
    actionSetMapZoom,
} from '../../store/App/actions';
import {
    selectFlyToMapPosition,
    selectMapZoom,
    selectShouldMapUpdatePositionAtZoomLevel,
    selectShouldMapUpdatePositionImmediately,
} from '../../store/App/selectors';

// Values that give the map an aesthetically pleasing start position
const WORLD_ZOOM = 2.5;

// Level to zoom to when focusing on a point
const FLYTO_ZOOM_LEVEL = 16;

// Force the map back to these bounds while panning
const WORLD_DRAG_REBOUND_BOUNDS: [number, number][] = [
    [-89.98155760646617, -260],
    [89.99346179538875, 240],
];

const MapViewDispatcher = () => {
    const mapPosition = useSelector(selectFlyToMapPosition);
    const mapPositionUpdatesWithZoomLevel = useSelector(selectShouldMapUpdatePositionAtZoomLevel);
    const mapPositionUpdatesImmediately = useSelector(selectShouldMapUpdatePositionImmediately);
    const mapZoom = useSelector(selectMapZoom);

    const dispatch = useDispatch();

    const map = useMap();

    const [mapLoaded, setMapLoaded] = useState(false);

    // We prevent the map from wrapping (below) however in Hawaii it's possible to
    // view the map on both sides of the Anti-meridian eg longitude 200 && -150
    // which causes "No maps" and "No sentinel results" bugs like #3039
    // This wraps the viewport back to world bounds -180 .. 180
    const fixAntiMeridianIfRequired = useCallback(() => {
        if (map) {
            const center = map.getCenter();
            const zoom = map.getZoom();

            if (zoom >= 5) {
                const wrappedCenter = center.wrap();

                if (!wrappedCenter.equals(center)) {
                    flyToPosition(wrappedCenter, zoom, true);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setMapBounds = useCallback(
        (bounds) => {
            if (bounds) {
                dispatch(actionSetMapBounds(bounds));
            }
        },
        [dispatch]
    );

    const setMapZoom = useCallback(
        (zoom) => {
            if (zoom) {
                dispatch(actionSetMapZoom(zoom));
            }
        },
        [dispatch]
    );

    // Prevent the map from wrapping by forcing in to pan inside world bounds
    useEffect(() => {
        if (map) {
            map.on('drag', () => {
                map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
            });

            map.on('zoomend', () => {
                map.panInsideBounds(WORLD_DRAG_REBOUND_BOUNDS, { animate: false });
                fixAntiMeridianIfRequired();
            });

            mapLoaded &&
                map.on('moveend', () => {
                    const bounds = map.getBounds();
                    setMapBounds(bounds);
                    const center = map.getCenter();
                    const zoom = map.getZoom();
                    setMapZoom(zoom);
                    const key = 'pos';
                    const value = center.lat.toString() + ',' + center.lng.toString() + ',' + zoom.toFixed(2);
                    fixAntiMeridianIfRequired();
                    UriHelper.addParameterToUri(key, value);
                    return;
                });

            if (!mapZoom) {
                setMapZoom(map.getZoom());
            }
            setMapLoaded(true);
        }
        // Disables mapZoom as a dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, fixAntiMeridianIfRequired, setMapBounds, setMapZoom, mapLoaded]);

    useEffect(() => {
        return () => {
            dispatch(actionFlyToInvalidate());
        };
    }, [dispatch]);

    useEffect(() => {
        const setHasMapLoaded = () => dispatch(actionMapLoaded());
        if (mapLoaded) {
            setHasMapLoaded();
        }
    }, [mapLoaded, dispatch]);

    useEffect(() => {
        const setHasMapUnloaded = () => dispatch(actionMapUnloaded());
        return () => {
            setHasMapUnloaded();
            setMapZoom(WORLD_ZOOM);
        };
    }, [dispatch, setMapZoom]);

    useEffect(() => {
        if (map) {
            if (mapPosition instanceof LatLng) {
                const position = mapPosition as LatLng;
                const zoomLevel = mapPositionUpdatesWithZoomLevel ? mapPositionUpdatesWithZoomLevel : FLYTO_ZOOM_LEVEL;
                const immediately = mapPositionUpdatesImmediately ? true : false;
                flyToPosition(position, zoomLevel, immediately);
            } else if (mapPosition instanceof LatLngBounds) {
                const bounds = mapPosition as LatLngBounds;
                const immediately = mapPositionUpdatesImmediately ? true : false;
                flyToBounds(bounds, immediately);
            } else if (mapPositionUpdatesWithZoomLevel) {
                flyToZoom(mapPositionUpdatesWithZoomLevel);
                dispatch(actionFlyToZoom(undefined));
            }
        } else {
            console.error('ERROR: Attempted flyTo before map was available');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapPosition, mapPositionUpdatesImmediately, mapPositionUpdatesWithZoomLevel, dispatch]);

    const flyToPosition = (position: LatLng, zoomLevel: number, immediately: boolean) => {
        if (map) {
            if (immediately) {
                map.setView(position, zoomLevel, { animate: false });
            } else {
                map.flyTo(position, zoomLevel);
            }
        }
    };

    const flyToBounds = (bounds: LatLngBounds, immediately: boolean) => {
        if (map) {
            if (immediately) {
                map.fitBounds(bounds, { animate: false });
            } else {
                map.flyToBounds(bounds);
            }
        }
    };

    const flyToZoom = (zoom: number) => {
        if (map) {
            map.setZoom(zoom);
        }
    };

    return <React.Fragment />;
};

export default MapViewDispatcher;
