import React, { useState } from 'react';
import AnnotationControl from '../annotation-control';
import CoordinateBuilder from './coordinate-annotation-builder';
import Coordinate from './coordinate';
import CoordinateAnnotation from './coordinate-annotation';
import CoordinateControlPopup from './coordinate-annotation-control-popup';

interface CoordinateControlProps {
    hideControl: boolean;
}

const CoordinateControl = (props: CoordinateControlProps) => {
    const [isCreateActive, setIsCreateActive] = useState(false);
    const [coordinateAnnotations, setCoordinateAnnotations] = useState<Coordinate[]>([]);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="end"
                hideControl={props.hideControl}
                imageUrl="/assets/annotations/icon-coordinates.svg"
                name="GPS"
                onClick={() => setIsCreateActive(true)}
            />

            {isCreateActive ? (
                <CoordinateBuilder
                    onCreateCoordinate={(coordinate: Coordinate) => {
                        setIsCreateActive(false);
                        setCoordinateAnnotations([...coordinateAnnotations, coordinate]);
                    }}
                />
            ) : null}

            {coordinateAnnotations.map((coordinate) => {
                return (
                    <CoordinateAnnotation
                        key={coordinate.id}
                        editMode={!props.hideControl}
                        coordinate={coordinate}
                        onUpdateCoordinate={(coordinate) => {
                            setCoordinateAnnotations((current) => {
                                const index = current.findIndex((c) => c.id === coordinate.id);
                                const newState = [...current];
                                newState[index] = coordinate;
                                return newState;
                            });
                        }}
                    >
                        <CoordinateControlPopup
                            coordinate={coordinate}
                            onDelete={() => {
                                setCoordinateAnnotations((current) => {
                                    return current.filter((c) => c.id !== coordinate.id);
                                });
                            }}
                        />
                    </CoordinateAnnotation>
                );
            })}
        </React.Fragment>
    );
};

export default CoordinateControl;
