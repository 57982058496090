import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { GeoJsonObject } from 'geojson';
import { useSelector } from 'react-redux';
import { ListingDictionary, ListingGroup } from '../../../../store/Map/MapSelection/model';
import { selectHighlightedContinent } from '../../../../store/Map/MapSelection/selectors';
import { selectActiveAllMapIsFetching, selectActiveAllMapIsLoading } from '../../../../store/Map/SuperMap/selectors';
import ContinentClusterTooltip from './continent-cluster-tooltip';

interface ContinentClusterProps {
    listingDictionary: ListingDictionary | undefined;
    handleSelectContinent: (listingGroup: ListingGroup) => void;
}

/**
 * The continent clustering is much simpler than the polygon clustering
 *  - It doesn't have disparate highlighted polygon
 *  - It uses a GeoJSON react leaflet object instead of a Polygon
 *  - It doesn't need dedicated mouse event system since there are no overlaps
 */
const ContinentCluster = (props: ContinentClusterProps) => {
    const activeMapIsFetching = useSelector(selectActiveAllMapIsFetching);
    const activeMapIsLoading = useSelector(selectActiveAllMapIsLoading);

    const highlightedContinent = useSelector(selectHighlightedContinent);

    if (!props.listingDictionary) return <React.Fragment />;
    if (activeMapIsFetching || activeMapIsLoading) return <React.Fragment />;

    const activeStyle = {
        fillColor: '#eed923',
        color: '#eed923',
        weight: 1,
    };

    const inactiveStyle = {
        fillColor: 'transparent',
        color: 'rgba(255, 255, 255, 0.4)',
        weight: 1,
    };

    const dataFromGeoJSON = (geoJSON: GeoJsonObject | GeoJsonObject[]): GeoJsonObject => {
        if (Array.isArray(geoJSON)) {
            return geoJSON[0];
        } else {
            return geoJSON;
        }
    };

    return (
        <React.Fragment>
            {Array.from(props.listingDictionary.values()).map((t) => {
                if (
                    highlightedContinent &&
                    highlightedContinent.continent &&
                    t.continent &&
                    highlightedContinent.continent.id === t.continent.id
                ) {
                    return (
                        <GeoJSON
                            data={dataFromGeoJSON(highlightedContinent.continent.geoJSON)}
                            key={`${highlightedContinent.continent.name}-highlighted`}
                        />
                    );
                }

                if (t.continent) {
                    return (
                        <GeoJSON
                            data={dataFromGeoJSON(t.continent.geoJSON)}
                            key={t.continent.name}
                            interactive={true}
                            pathOptions={{
                                fillColor: 'transparent',
                                color: 'rgba(255, 255, 255, 0.4)',
                                weight: 1,
                            }}
                            onEachFeature={(_, layer) => {
                                layer.on('click', () => {
                                    props.handleSelectContinent(t);
                                }),
                                    layer.on('mouseover', (e) => {
                                        e.target.setStyle(activeStyle);
                                    });
                                layer.on('mouseout', (e) => {
                                    e.target.setStyle(inactiveStyle);
                                });
                            }}
                        >
                            <ContinentClusterTooltip
                                key={t.tileLayers[0].id}
                                previewUrl={t.continent.iconUrl}
                                title={t.continent.name}
                                subtext={`Click to explore the ${t.tileLayers.length?.toLocaleString()} maps in ${
                                    t.continent.name
                                }`}
                            />
                        </GeoJSON>
                    );
                } else {
                    return <React.Fragment />;
                }
            })}
        </React.Fragment>
    );
};

export default ContinentCluster;
