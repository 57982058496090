import React, { useState } from 'react';
import AnnotationControl from '../annotation-control';
import HighlighterBuilder from './highlighter-builder';
import Highlighter from './highlighter';
import HighlighterAnnotation from './highlighter-annotation';
import HighlighterPopup from './highlighter-popup';

interface HighlighterControlProps {
    hideControl: boolean;
}

const HighlighterControl = (props: HighlighterControlProps) => {
    const [isHighlighterActive, setIsHighlighterActive] = useState(false);
    const [highlighters, setHighlighters] = useState<Highlighter[]>([]);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="end"
                hideControl={props.hideControl}
                imageUrl="/assets/annotations/icon-highlighter.svg"
                name="Highlighter"
                onClick={() => setIsHighlighterActive(true)}
            />

            {isHighlighterActive ? (
                <HighlighterBuilder
                    onCreateHighlighter={(highlighter) => {
                        setHighlighters([...highlighters, highlighter]);
                        setIsHighlighterActive(false);
                    }}
                />
            ) : null}

            {highlighters.map((highlighter) => {
                return (
                    <HighlighterAnnotation lock={props.hideControl} key={highlighter.id} highlighter={highlighter}>
                        <HighlighterPopup
                            highlighter={highlighter}
                            onDelete={() => {
                                setHighlighters(highlighters.filter((h) => h.id !== highlighter.id));
                            }}
                            onSelectColor={(color) => {
                                const index = highlighters.findIndex((t) => t.id === highlighter.id);
                                const newHighlighters = [...highlighters];
                                newHighlighters[index].options = { ...highlighter.options, color: color };
                                setHighlighters(newHighlighters);
                            }}
                        />
                    </HighlighterAnnotation>
                );
            })}
        </React.Fragment>
    );
};
export default HighlighterControl;
