import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { CommentResultsDTO } from '../../../../api/model';
import ActiveCommentActions from './comment-actions';
import CommentVote from './comment-vote';
import UriHelper from '../../../../lib/uri-helper';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetResetCommentSection, actionSetSelectedCommentId } from '../../../../store/SocialMapping/actions';
import { selectMyProfile } from '../../../../store/Account/selectors';
import UserHelper from '../../../../lib/user-helper';
import Analytics from '../../../../lib/user-analytics';

interface CommentDetailsProps {
    comment: CommentResultsDTO;
    isSelected: boolean;
    handleOpenDeleteComment: () => void;
    handleEditComment: () => void;
    handleReportComment: () => void;
    handleModerateComment: () => void;
}

export const CommentDetails = (props: CommentDetailsProps) => {
    const dispatch = useDispatch();
    const myProfile = useSelector(selectMyProfile);

    const handleSelectProfile = (e: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.comment.userId) {
            UriHelper.navigateToPath(`/profile/${props.comment.userId}`);
            Analytics.Event('Comments', 'Clicked comment profile', props.comment.userId);
            dispatch(actionSetResetCommentSection(true));
            dispatch(actionSetSelectedCommentId(''));
        }
    };

    const isCommentOwner = props.comment.userId === myProfile?.userId;

    return (
        <CommentContainer>
            <CommentVote comment={props.comment} />
            <CommentCard>
                <TopRow>
                    <TopRowCommentUser>
                        <Avatar
                            src={
                                props.comment.userAvatar
                                    ? props.comment.userAvatar
                                    : UserHelper.getDefaultAvatar(props.comment.userId as string)
                            }
                        />
                        <TopRowContent>
                            <TopRowUserDetails>
                                <Username isSelectable={isCommentOwner} onClick={(e) => handleSelectProfile(e)}>
                                    {props.comment.userName}
                                </Username>
                                {props.comment.communityModerator && props.comment.userReputable ? (
                                    <>🏆😎</>
                                ) : props.comment.communityModerator ? (
                                    <>😎</>
                                ) : props.comment.userReputable ? (
                                    <>🏆</>
                                ) : null}

                            </TopRowUserDetails>

                            <CommentDate>{moment(new Date(props.comment.createdAt * 1000)).fromNow()}</CommentDate>
                        </TopRowContent>
                    </TopRowCommentUser>
                    {props.isSelected ? (
                        <ActiveCommentActions
                            handleCommentDelete={props.handleOpenDeleteComment}
                            handleCommentEdit={props.handleEditComment}
                            handleReportComment={props.handleReportComment}
                            handleModerateComment={props.handleModerateComment}
                            commentUserId={props.comment.userId}
                        />
                    ) : null}
                </TopRow>

                <SecondRow>
                    <Message>{props.comment.text}</Message>
                </SecondRow>
            </CommentCard>
        </CommentContainer>
    );
};

interface CommentDetailsStyleProps {
    isSelectable: boolean;
}

const CommentContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const CommentCard = styled.div`
    width: 100%;
`;

const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 7px 0px 0px 30px;
`;

const TopRowCommentUser = styled.div`
    display: flex;
    flex-direction: row;
    width: 93%;
`;

const TopRowContent = styled.div`
    width: 88%;
    margin-left: 7px;

    @media only screen and (max-width: 600px) {
        max-width: calc(100vw - 130px);
    }
`;

const TopRowUserDetails = styled.div`
    display: flex;
    flex-direction: row;
    color: white;
`;

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 40px;
`;

const Username = styled.h4<CommentDetailsStyleProps>`
    color: #eed926;
    margin-bottom: 0px;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    cursor: pointer;
    max-width: 370px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: -moz-fit-content;
    white-space: nowrap;
    font-size: 16px;
    text-decoration: ${(props) => props.isSelectable && 'underline'};

    @media only screen and (max-width: 600px) {
        font-size: 1rem;
    }
`;

const CommentDate = styled.span`
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    width: 120px;
    line-height: 22px;

    @media only screen and (max-width: 600px) {
        margin-top: 1px;
        font-size: 9px;
    }
`;

const SecondRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const Message = styled.p`
    color: rgba(255, 255, 255, 0.87);
    font-size: 13px;
    margin: 1px 15px 5px 10px;
    word-break: break-word;
    border-radius: 4px;
    width: 100%;
`;
