import L from 'leaflet';
import AnnotationPopup from '../annotation-popup';
import Ruler from './ruler';
import ColorSwatch from '../Popup/color-swatch';

interface RectangleAnnotationPopupProps {
    ruler: Ruler;
    onDeleteRuler: () => void;
    onUpdateRuler: (options: L.PolylineOptions) => void;
}

const RulerAnnotationPopup = (props: RectangleAnnotationPopupProps) => {
    return (
        <AnnotationPopup
            title="Ruler"
            keepInView={true}
            closeButton={false}
            autoClose={false}
            closeOnClick={false}
            onDelete={props.onDeleteRuler}
            eventHandlers={{
                add: (e: L.LeafletEvent) => {
                    const source = e.target._source as L.Polyline;
                    const sourceTarget = e.sourceTarget;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    source.addEventListener('update', (e) => {
                        const startLatLng = source.getLatLngs()[0] as L.LatLng;
                        const endLatLng = source.getLatLngs()[1] as L.LatLng;
                        const rulerBounds = new L.LatLngBounds(startLatLng, endLatLng);
                        const rulerTopCenter = new L.LatLng(rulerBounds.getNorth(), rulerBounds.getCenter().lng);

                        requestAnimationFrame(() => {
                            sourceTarget.setLatLng(rulerTopCenter);
                        });
                    });

                    const startLatLng = source.getLatLngs()[0] as L.LatLng;
                    const endLatLng = source.getLatLngs()[1] as L.LatLng;
                    const rulerBounds = new L.LatLngBounds(startLatLng, endLatLng);
                    const rulerTopCenter = new L.LatLng(rulerBounds.getNorth(), rulerBounds.getCenter().lng);
                    requestAnimationFrame(() => {
                        sourceTarget.setLatLng(rulerTopCenter);
                    });
                },
            }}
        >
            <ColorSwatch
                selectedColor={props.ruler.options.color || '#3388ff'}
                onSelectColor={(color) => {
                    props.onUpdateRuler({
                        ...props.ruler.options,
                        color: color,
                    });
                }}
            />
        </AnnotationPopup>
    );
};

export default RulerAnnotationPopup;
