import L, { LatLngBounds, LatLngExpression } from 'leaflet';

export const resizeBoundsByCornerWhilePreservingAspectRatio = (
    context: Readonly<{ map: L.Map }>,
    bounds: L.LatLngBounds,
    corner: 'ne' | 'nw' | 'se' | 'sw',
    newLatLng: L.LatLng
): L.LatLngBounds => {
    let cornerBeingDragged: L.LatLng;
    let oppositeCorner: L.LatLng;

    switch (corner) {
        case 'ne':
            cornerBeingDragged = bounds.getNorthEast();
            oppositeCorner = bounds.getSouthWest();
            break;
        case 'nw':
            cornerBeingDragged = bounds.getNorthWest();
            oppositeCorner = bounds.getSouthEast();
            break;
        case 'sw':
            cornerBeingDragged = bounds.getSouthWest();
            oppositeCorner = bounds.getNorthEast();
            break;
        case 'se':
            cornerBeingDragged = bounds.getSouthEast();
            oppositeCorner = bounds.getNorthWest();
            break;
        default:
            throw new Error('Invalid corner');
    }

    const scale = calculateScalingFactor(context, cornerBeingDragged, newLatLng);

    const corner1 = context.map.latLngToLayerPoint(cornerBeingDragged);
    const corner2 = context.map.latLngToLayerPoint(oppositeCorner);
    const w = Math.abs(corner1.x - corner2.x);
    const h = Math.abs(corner1.y - corner2.y);
    const distance = Math.sqrt(w * w + h * h);

    const edgeMinSize = 10; // Stop the image closing to nothing but may have some sideaffects with small images.
    if (distance > edgeMinSize || scale > 1) {
        return scaleBy(context.map, bounds, scale);
    } else {
        return scaleBy(context.map, bounds, 1);
    }
};

/**
 * Calculate the scaling factor between two points.
 * @param element The image overlay.
 * @param latlngA Latlng of the first point.
 * @param latlngB Latlng of the second point.
 * @returns The scaling factor.
 */
const calculateScalingFactor = (
    context: Readonly<{ map: L.Map }>,
    latlngA: L.LatLngExpression,
    latlngB: L.LatLngExpression
): number => {
    const map = context.map;
    const centerPoint = map.latLngToLayerPoint(map.getCenter());
    const formerPoint = map.latLngToLayerPoint(latlngA);
    const newPoint = map.latLngToLayerPoint(latlngB);
    const formerRadiusSquared = distanceBetweenTwoPointsInCartesianSpace(centerPoint, formerPoint);
    const newRadiusSquared = distanceBetweenTwoPointsInCartesianSpace(centerPoint, newPoint);

    return Math.sqrt(newRadiusSquared / formerRadiusSquared);
};

/**
 * Scale the image overlay by a given number.
 * @param element The image overlay.
 * @param scale The scaling factor.
 * @returns The scaled LatLngBounds.
 */
const scaleBy = (context: L.Map, bounds: L.LatLngBounds, scale: number): L.LatLngBounds => {
    const map = context;

    const boundsAsPolygon = polygonForBounds(bounds);
    const center = bounds.getCenter();

    const centerPoint = map.project(center);
    const scaledCorners = {};

    for (let i = 0; i < 4; i++) {
        const p = map.project(boundsAsPolygon[i]).subtract(centerPoint).multiplyBy(scale).add(centerPoint);
        scaledCorners[i] = map.unproject(p);
    }
    const scaledBounds = new L.LatLngBounds(scaledCorners[0], scaledCorners[2]);
    return scaledBounds;
};

/**
 * Distance between two points in cartesian space, squared (distance formula). .
 * @param a  The first point.
 * @param b  The second point.
 * @returns  The distance between the two points.
 */
const distanceBetweenTwoPointsInCartesianSpace = (a: L.Point, b: L.Point): number => {
    const dx = a.x - b.x;
    const dy = a.y - b.y;

    return Math.pow(dx, 2) + Math.pow(dy, 2);
};

/**
 * Imageoverlays do not come with a latlngexpression array so we need to create one.
 * @param bounds The bounds of the image overlay as a LatLngbounds.
 * @returns LatLngExpression[].
 */
export const polygonForBounds = (bounds: LatLngBounds): LatLngExpression[] => {
    const latlngs: LatLngExpression[] = [];
    latlngs.push({ lat: bounds.getNorth(), lng: bounds.getEast() });
    latlngs.push({ lat: bounds.getSouth(), lng: bounds.getEast() });
    latlngs.push({ lat: bounds.getSouth(), lng: bounds.getWest() });
    latlngs.push({ lat: bounds.getNorth(), lng: bounds.getWest() });
    return latlngs;
};
