import L from 'leaflet';

/**
 * Calculate the topmost point of the circle using harvesine formula
 * @param latlng - center of the circle
 * @param radius - radius of the circle
 * @returns latlng - topmost point of the circle in LatLng format
 * */
export const calculateTopmostLatLng = (center: L.LatLng, radius: number): L.LatLng => {
    const earthRadius = 6378.137; // Radius of the Earth in kilometers
    const distance = radius / 1000; // Convert radius from meters to kilometers

    // Convert degrees to radians
    const centerLatRad = center.lat * (Math.PI / 180);
    const centerLngRad = center.lng * (Math.PI / 180);

    // Calculate latitude of the topmost point
    const topmostLatRad = Math.asin(
        Math.sin(centerLatRad) * Math.cos(distance / earthRadius) +
            Math.cos(centerLatRad) * Math.sin(distance / earthRadius) * Math.cos(0)
    );

    // Calculate longitude of the topmost point
    const topmostLngRad =
        centerLngRad +
        Math.atan2(
            Math.sin(0) * Math.sin(distance / earthRadius) * Math.cos(centerLatRad),
            Math.cos(distance / earthRadius) - Math.sin(centerLatRad) * Math.sin(topmostLatRad)
        );

    // Convert radians to degrees
    const topmostLat = topmostLatRad * (180 / Math.PI);
    const topmostLng = topmostLngRad * (180 / Math.PI);

    // Conver to LatLng object
    const newLatLng = L.latLng(topmostLat, topmostLng);
    return newLatLng;
};
