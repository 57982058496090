import { actionTypes as at } from './constants';
import { LatLng, LatLngBounds } from 'leaflet';
import ApiSubdomain from '../../api/api-subdomain';
import { Config } from '../App/model';

export const actionUpdateConfig = () => {
    return async (dispatch, getState) => {
        try {
            const result: Config = await ApiSubdomain.configGet();
            dispatch({
                type: at.UPDATE_CONFIG_SUCCESS,
                payload: result,
            });
        } catch (err) {
            dispatch({
                type: at.UPDATE_CONFIG_FAILURE,
            });
        }
    };
};

export const actionFlyTo = (position: LatLng | LatLngBounds, zoom?: number, immediately?: boolean) => {
    return {
        type: at.FLY_TO,
        payload: {
            position: position,
            zoom: zoom,
            immediately: immediately ? immediately : false,
        },
    };
};

export const actionFlyToZoom = (zoom: number | undefined) => {
    return {
        type: at.FLY_TO_ZOOM,
        payload: zoom,
    };
};

export const actionFlyToOnMap = (location: LatLng | LatLngBounds) => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.FLY_TO_POSITION,
            payload: location,
        });
        if (location) {
            await sleep(1000);
            dispatch(actionFlyToInvalidate());
        }
    };
};

export const actionFlyToInvalidate = () => {
    return {
        type: at.FLY_TO_POSITION_INVALIDATE,
    };
};

export const actionMapLoaded = () => {
    return {
        type: at.APP_MAP_LOADED,
    };
};

export const actionMapUnloaded = () => {
    return {
        type: at.APP_MAP_UNLOADED,
    };
};

export const actionShowTutorial = () => {
    return {
        type: at.SHOW_TUTORIAL,
    };
};

export const actionDismissTutorial = () => {
    return {
        type: at.DISMISS_TUTORIAL,
    };
};

export const actionShowLoginDialog = (show: boolean) => {
    return {
        type: at.SHOW_LOGIN_DIALOG,
        payload: show,
    };
};

function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const actionOpenNavbarDropdownForTutorial = (showDropdown) => {
    return {
        type: at.SHOW_NAVBAR_DROPDOWN,
        payload: showDropdown,
    };
};

export const actionSetMapBounds = (bounds) => {
    return {
        type: at.SET_MAP_BOUNDS,
        payload: bounds,
    };
};

export const actionSetMapZoom = (zoom: number | undefined) => {
    return {
        type: at.SET_MAP_ZOOM,
        payload: zoom,
    };
};

export const actionShowUploadDialog = (show: boolean) => {
    return {
        type: at.SHOW_UPLOAD_DIALOG,
        payload: show,
    };
};
