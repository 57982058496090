import {
    LeafletContextInterface,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createLayerHook,
    extendContext,
} from '@react-leaflet/core';
import L, { DivIcon, LatLng, LeafletMouseEvent } from 'leaflet';
import Coordinate from './coordinate';
import { CoordinateSvg } from './coordinate-annotation-svg';

interface CoordinateAnnotationProps extends L.MarkerOptions {
    coordinate: Coordinate;
    onUpdateCoordinate: (marker: Coordinate) => void;
    editMode: boolean;
    children?: React.ReactNode;
}

const createCoordionateElement = (props: CoordinateAnnotationProps, context: LeafletContextInterface) => {
    const coordinate = new L.Marker(props.coordinate.position, {
        draggable: props.editMode,
        ...props.coordinate.options,
    });

    const coordinateElement = createElementObject<L.Marker, CoordinateAnnotationProps>(
        coordinate,
        extendContext(context, { overlayContainer: coordinate })
    );

    const handleIcon = (latlng: LatLng) => {
        return new DivIcon({
            iconSize: [20, 20],
            iconAnchor: [280, 27],
            html: CoordinateSvg(props.coordinate.id, latlng),
            className: 'coordinate-builder-icon',
        });
    };

    const handleDragStart = (e: LeafletMouseEvent) => {
        coordinateElement.instance.setIcon(handleIcon(e.latlng));
        coordinateElement.instance.setLatLng(e.latlng);
    };

    coordinateElement.instance.on('drag', () => {
        context.map.on('mousemove', handleDragStart);
    });

    context.map.on('mouseup', () => {
        coordinateElement.instance.off('drag', () => handleDragStart);
        context.map.off('mousemove', handleDragStart);
    });

    coordinateElement.instance.on('dragend', () => {
        props.onUpdateCoordinate({ ...props.coordinate, position: coordinateElement.instance.getLatLng() });
    });

    coordinateElement.instance.dragging?.enable();

    return coordinateElement;
};

const useCoordinateElement = createElementHook<L.Marker, CoordinateAnnotationProps, LeafletContextInterface>(
    createCoordionateElement
);

const useCoordinate = createLayerHook(useCoordinateElement);
const MarkerAnnotation = createContainerComponent(useCoordinate);
export default MarkerAnnotation;
