import L from 'leaflet';
import Geometry from './area-measurement-geometry-util';

export function findCorners(ne, nw, sw, se) {
    let tmp
    if (ne.lat < se.lat) {
        tmp = ne;
        ne = se;
        se = tmp;

        tmp = nw;
        nw = sw;
        sw = tmp;
    }
    if (ne.lng > nw.lng) {
        tmp = ne;
        ne = nw;
        nw = tmp;

        tmp = sw;
        sw = se;
        se = tmp;
    }
    return [ne, nw, sw, se];
}

export function _pixelDistance(_map, from, to) {
    if (from && to) {
        return _map.latLngToLayerPoint(from).distanceTo(_map.latLngToLayerPoint(to));
    } else return 0;
}

export function _manageScale(scaleActive, _map, polygon, index, from, to, rotate) {
    if (!scaleActive) return polygon;

    if (!polygon['scale']) polygon['scale'] = {};

    if (polygon['scale'] && polygon['scale'][index]) {
        polygon['scale'][index].forEach((point) => {
            _map.removeLayer(point);
        });
    }
    polygon['scale'][index] = [];

    if (_pixelDistance(_map, from, to) > 100) {
        const totalDistance = _distance(from, to);
        let numberOfPoints;
        let increment;
        if (totalDistance < 1 / 100) {
            numberOfPoints = 5;
            increment = 1 / 1000;
            polygon = _calcScale(_map, polygon, increment, numberOfPoints, from, to, index, rotate);
        } else if (totalDistance < 1) {
            numberOfPoints = 5;
            increment = 1 / 100;
            polygon = _calcScale(_map, polygon, increment, numberOfPoints, from, to, index, rotate);
        } else if (totalDistance < 100) {
            numberOfPoints = 5;
            increment = 1;
            polygon = _calcScale(_map, polygon, increment, numberOfPoints, from, to, index, rotate);
        } else if (totalDistance < 1000) {
            numberOfPoints = 5;
            increment = 10;
            polygon = _calcScale(_map, polygon, increment, numberOfPoints, from, to, index, rotate);
        } else {
            numberOfPoints = 5;
            increment = 100;
            polygon = _calcScale(_map, polygon, increment, numberOfPoints, from, to, index, rotate);
        }
    }

    return polygon;
}

export function _manageDistanceLabels(_map, polygon, from, to, minPixelDist, customisation, options) {
    if (
        options &&
        options.disableCreate &&
        (!polygon ||
            !polygon['_distanceLabels'] ||
            !polygon['_distanceLabels'][from] ||
            !polygon['_distanceLabels'][from][to])
    ) {
        return polygon;
    }

    if (!polygon['_distanceLabels']) polygon['_distanceLabels'] = {};
    if (!polygon['_distanceLabels'][from]) polygon['_distanceLabels'][from] = [];
    if (!polygon['_distanceLabels'][from][to]) polygon['_distanceLabels'][from][to] = {};

    const bounds = options && options.bounds ? options.bounds : polygon['_bounds'];

    const pixelDistance = _pixelDistance(_map, bounds[from], bounds[to]);
    const midPoint = _twoPointsCenter(bounds[from], bounds[to]);
    //Update only on state change
    if (
        polygon['_distanceLabels'][from][to]['midPoint'] !== midPoint &&
        polygon['_distanceLabels'][from][to]['pixelDistance'] !== pixelDistance
    ) {
        polygon['_distanceLabels'][from][to]['pixelDistance'] = pixelDistance;
        polygon['_distanceLabels'][from][to]['midPoint'] = midPoint;
        if (polygon['_distanceLabels'][from][to]['marker']) {
            const layer = polygon['_distanceLabels'][from][to]['marker'];
            _map.removeLayer(layer);
            polygon['_distanceLabels'][from][to]['marker'] = null;
        }
        const distance = _distance(bounds[from], bounds[to]);
        const readableDistanceHtml = readableDistance(distance);
        const icon = L.divIcon({
            className: 'area-measurement-label',
            iconSize: null,
            interactive: false,
            zIndex: 1,
            html: '<span>' + readableDistanceHtml + '</span>',
        });
        if (_pixelDistance(_map, bounds[from], bounds[to]) > minPixelDist) {
            const finalPoint = offsetPixelFromLatLng(
                _map,
                midPoint,
                parseInt(customisation['offsetX']),
                parseInt(customisation['offsetY'])
            );

            const marker = new L.marker(finalPoint, { icon, interactive: false });
            marker.setRotationAngle(_twoPointsAngle(_map, bounds[from], bounds[to]) - 90);
            marker.setRotationOrigin(customisation['transform']);
            marker.addTo(_map);
            polygon['_distanceLabels'][from][to]['marker'] = marker;
        }
    }
    return polygon;
}

export function removePolygon(_map, polygon) {
    if (polygon) {
        if (polygon['drawPolygon']) {
            _map.removeLayer(polygon['drawPolygon']);
            polygon['drawPolygon'] = null;
        }
        if (polygon['drawRuler']) {
            _map.removeLayer(polygon['drawRuler']);
            polygon['drawRuler'] = null;
        }
        if (polygon['_areaLabel']) {
            _map.removeLayer(polygon['_areaLabel']);
            polygon['_areaLabel'] = null;
        }
        if (polygon['_corners']) {
            for (const corner in polygon['_corners']) {
                if (polygon['_corners'][corner]) _map.removeLayer(polygon['_corners'][corner]);
            }
        }
        if (polygon['_midPointCorners']) {
            for (const midpoint in polygon['_midPointCorners']) {
                if (midpoint && polygon['_midPointCorners'][midpoint]) {
                    _map.removeLayer(polygon['_midPointCorners'][midpoint]);
                }
            }
        }
        if (polygon['_distanceLabels']) {
            for (const segment in polygon['_distanceLabels']) {
                if (segment && polygon['_distanceLabels'][segment]) {
                    for (const label in polygon['_distanceLabels'][segment]) {
                        if (
                            label &&
                            polygon['_distanceLabels'][segment][label] &&
                            polygon['_distanceLabels'][segment][label]['marker']
                        ) {
                            _map.removeLayer(polygon['_distanceLabels'][segment][label]['marker']);
                            polygon['_distanceLabels'][segment][label] = null;
                        }
                    }
                }
            }
            polygon['_distanceLabels'] = null;
        }
        if (polygon['scale']) {
            for (const scale in polygon['scale']) {
                polygon['scale'][scale].forEach((point) => {
                    _map.removeLayer(point);
                });
            }
        }
        polygon = _deleteDeleteMarker(undefined, _map, polygon, 'trash');
    }
    return polygon;
}

export function _deleteDeleteMarker(self, _map, polygon, type) {
    if (self && polygon && polygon[type] && polygon[type]['deleteMarker']) {
        if (self && self.deleteTimeout) {
            clearTimeout(self.deleteTimeout);
        }
        _map.removeLayer(polygon[type]['deleteMarker']);
        polygon[type]['deleteMarker'] = null;
        polygon[type]['deleteLife'] = false;
    }
    return polygon;
}

export function _updateBounds(polygon, ne, nw, sw, se) {
    let bounds = polygon['_bounds'];
    if (!bounds) bounds = {};
    if (ne) bounds['ne'] = ne;
    if (nw) bounds['nw'] = nw;
    if (sw) bounds['sw'] = sw;
    if (se) bounds['se'] = se;
    polygon['_bounds'] = bounds;
    return polygon;
}

export function setDeleteMarker(self, polygon, master, type, pos, svg) {
    if (self._parent && self._parent._disableEdit) return;
    deleteMarkerOnLifeEnd(self, polygon, type);
    if (polygon['drawRuler']) {
        polygon['deleteClicked'] = false;
        self._map.on('click', (event) => {
            const clickPixelDistance = _pixelDistance(
                self._map,
                nearestPointOnLine(event.latlng, polygon['_bounds']['ne'], polygon['_bounds']['sw']),
                event.latlng
            );
            if (clickPixelDistance < 10 && polygon['drawRuler'] && polygon['deleteClicked']) {
                addDeleteMarker(self, polygon, event, master, type, pos, svg);
            } else {
                polygon['deleteClicked'] = true;
            }
        });
    } else if (polygon['drawPolygon']) {
        polygon['drawPolygon'].on('click', (event) => {
            addDeleteMarker(self, polygon, event, master, type, pos, svg);
        });
    }
}
export function deleteMarkerOnLifeEnd(self, polygon, type) {
    self._map.on('click', (_) => {
        if (!polygon[type]) polygon[type] = {};
        if (polygon[type]['deleteMarker'] && polygon[type]['deleteLife'] && polygon[type]['deleteLife'] === true) {
            polygon = _deleteDeleteMarker(self, self._map, polygon, type);
        } else {
            polygon[type]['deleteLife'] = true;
        }
    });
}

export function _addCorner(self, masterIndex, index, latLng, colour, size, options) {
    let polygon;

    // TODO seems messy
    if (self.boxSelectCollection) {
        polygon = self.boxSelectCollection[masterIndex];
    } else {
        polygon = self._polygon;
    }

    const bounds = polygon['_bounds'];
    const corners = polygon['_corners'];

    bounds[index] = latLng;
    const _colour = colour ? colour : '#3388ff';
    const _size = size ? size : 5;

    if (polygon['_pointsMap']) {
        if (polygon['_pointsMap'][index]) {
            polygon['_points'][polygon['_pointsMap'][index]].latLng = latLng;
        }
    }
    if (latLng) {
        const editable = self._parent && self._parent._disableEdit;
        const marker = new L.circleMarker(latLng, {
            radius: _size,
            weight: 9,
            zIndex: 10000,
            color: _colour,
            interactive: !editable,
        });

        marker.on('mouseover', () => {
            marker.setStyle({ radius: _size + 0.5 });
        });
        marker.on('mouseout', () => {
            marker.setStyle({ radius: _size });
        });

        marker.on('mousedown', () => {
            self._map.dragging.disable();
            if (!polygon['_currCornerPosition']) polygon['_currCornerPosition'] = {};
            polygon['_currCornerPosition'][index] = latLng;
            polygon['_cornerActive'][index] = true;
            polygon['_cornerListenerActive'] = true;

            const element = self;
            L.DomEvent.on(
                self._map,
                'mousedown',
                L.bind(_enableCornerListener, self, polygon, masterIndex, index),
                self
            );
            L.DomEvent.on(self._map, 'mousemove', L.bind(_moveCorner, self, self, masterIndex, index), self);
            L.DomEvent.on(self._map, 'mouseup', L.bind(_endMoveCorner, self, element, masterIndex, index), self);
            L.DomEvent.on(self._map, 'mouseout', L.bind(_endMoveCorner, self, element, masterIndex, index), self);
            if (options && options.cornerRemain) {
                // FARK....
            } else {
                L.DomEvent.on(
                    corners[index],
                    'mouseup',
                    L.bind(_askDeleteCorner, self, self, masterIndex, index),
                    self
                );
                L.DomEvent.on(
                    corners[index],
                    'mouseout',
                    L.bind(_askDeleteCorner, self, self, masterIndex, index),
                    self
                );
            }
            polygon['_disableDelete'] = false;
        });
        marker.addTo(self._map);
        corners[index] = marker;
    }

    polygon['_bounds'] = bounds;
    polygon['_corners'] = corners;
}

export function _addMidpoint(self, masterIndex, indexFrom, indexTo) {
    let polygon;
    // TODO seems messy
    if (self.boxSelectCollection) {
        polygon = self.boxSelectCollection[masterIndex];
    } else {
        polygon = self._polygon;
    }

    const pointFrom = getPoint(polygon, indexFrom).latLng;
    const pointTo = getPoint(polygon, indexTo).latLng;
    const midpoint = _twoPointsCenter(pointFrom, pointTo);
    const editable = self._parent && self._parent._disableEdit;
    polygon['_midPointCorners'][indexFrom + indexTo] = new L.circleMarker(midpoint, {
        radius: 4,
        weight: 2,
        zIndex: 10000,
        interactive: !editable,
    })
        .addTo(self._map)
        .on('mousedown', () => {
            if (polygon['_midPointCorners'][indexFrom + indexTo]._latlng) {
                const position = polygon['_midPointCorners'][indexFrom + indexTo]._latlng;

                const iTo = polygon['_pointsMap'][indexTo];
                removeDistanceLabel(polygon, self._map, indexFrom, indexTo);

                polygon = insertPointToPolygon(polygon, iTo, {
                    label: indexFrom + indexTo,
                    latLng: position,
                    css: 'area-measurement-label',
                    offsetX: 0,
                    offsetY: 0,
                });

                _addCorner(self, masterIndex, indexFrom + indexTo, position);
                polygon['_corners'][indexFrom + indexTo].fire('mousedown');
                polygon['_disableDelete'] = true;

                self._map.removeLayer(polygon['_midPointCorners'][indexFrom + indexTo]);
                _addMidpoint(self, masterIndex, indexFrom + indexTo, indexTo);
                _addMidpoint(self, masterIndex, indexFrom, indexFrom + indexTo);
            }
        });
}

export function _twoPointsCenter(from, to) {
    return L.latLng(from.lat + (to.lat - from.lat) / 2, from.lng + (to.lng - from.lng) / 2);
}

export function _distance(from, to) {
    const distance = Geometry.distance([from.lng, from.lat], [to.lng, to.lat]);
    return distance;
}

export function _calcScale(_map, polygon, increment, numberOfPoints, from, to, index, rotate) {
    for (let dist = increment; dist < numberOfPoints; dist += increment) {
        const scale = _twoPointsMidTravel(from, to, dist);
        if (scale && _pixelDistance(_map, scale, to) > 10) {
            const icon = L.divIcon({
                iconSize: new L.Point(0, 0),
                html: '<div style="height: 1px;width:5px"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus" class="svg-inline--fa fa-minus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 10"><path fill="blue" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></div>',
            });
            const marker = new L.marker(scale, { icon }).addTo(_map);
            marker.setRotationAngle(_twoPointsAngle(_map, from, to) - rotate);
            marker.setRotationOrigin('0% 0%');
            polygon['scale'][index].push(marker);
        }
    }
    for (let dist = numberOfPoints * increment; dist < _distance(from, to); dist += numberOfPoints * increment) {
        const scale = _twoPointsMidTravel(from, to, dist);
        if (scale && _pixelDistance(_map, scale, to) > 10) {
            const icon = L.divIcon({
                iconSize: new L.Point(0, 0),
                html: '<div style="height: 1px;width:10px"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus" class="svg-inline--fa fa-minus fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 10"><path fill="blue" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></div>',
            });
            const marker = new L.marker(scale, { icon }).addTo(_map);
            marker.setRotationAngle(_twoPointsAngle(_map, from, to) - rotate);
            marker.setRotationOrigin('0% 0%');
            polygon['scale'][index].push(marker);
        }
    }
    return polygon;
}

export function _twoPointsMidTravel(from, to, distanceKM) {
    const relativeDistance = distanceKM / _distance(from, to);
    if (relativeDistance && distanceKM < _distance(from, to)) {
        return L.latLng(
            from.lat + relativeDistance * (to.lat - from.lat),
            from.lng + relativeDistance * (to.lng - from.lng)
        );
    } else {
        return null;
    }
}

export function _twoPointsAngle(_map, from, to) {
    const _from = _map.latLngToLayerPoint(from);
    const _to = _map.latLngToLayerPoint(to);
    const vector = {
        x: _to.x - _from.x ? _to.x - _from.x : 0.01,
        y: _to.y - _from.y ? _to.y - _from.y : 0.01,
    };
    let pixelBearing = (180 * Math.atan(vector.y / vector.x)) / Math.PI + 90;
    if (vector.y < 0 && vector.x < 0) {
        pixelBearing -= 180;
    }
    return pixelBearing;
}

export function offsetPixelFromLatLng(_map, originalLatLng, pixelOffsetX, pixelOffsetY) {
    const origin = _map.containerPointToLatLng({ x: 0, y: 0 });
    const rex = _map.containerPointToLatLng({ x: pixelOffsetX, y: pixelOffsetY });
    const offset = L.latLng(origin.lat - rex.lat, origin.lng - rex.lng);
    return L.latLng(originalLatLng.lat + offset.lat, originalLatLng.lng + offset.lng);
}

export function readableDistance(distance) {
    if (distance >= 1) {
        return distance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'km';
    } else {
        if (1000 * distance < 100) distance = (distance * 1000).toFixed(3);
        else distance = (distance * 1000).toFixed(2);
        return distance.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'm';
    }
}

export function nearestPointOnLine(position, linePoint1, linePoint2) {
    const lines = [
        [linePoint1.lng, linePoint1.lat],
        [linePoint2.lng, linePoint2.lat],
    ];
    const pos = [position.lng, position.lat];
    const point = Geometry.closestPointOnLine(pos, lines);
    return L.latLng(point[1], point[0]);
}

export function getPolygonCenter(latLngs) {
    const points = [];
    latLngs.forEach((point) => {
        points.push([point.lng, point.lat]);
    });
    //Push first point to make a ring
    points.push([latLngs[0].lng, latLngs[0].lat]);
    const center = Geometry.center(points);
    return L.latLng(center[1], center[0]);
}

export function insertPointToPolygon(polygon, index, pointInfo) {
    polygon['_points'].splice(index, 0, pointInfo);
    polygon['_pointsSimple'].splice(index, 0, pointInfo.latLng);
    polygon['_points'].map((point, index) => {
        polygon['_pointsMap'][point.label] = index;
    });
    return polygon;
}

function removeDistanceLabel(polygon, _map, from, to) {
    if (polygon['_distanceLabels']) {
        if (
            polygon['_distanceLabels'][from] &&
            polygon['_distanceLabels'][from][to] &&
            polygon['_distanceLabels'][from][to]['marker']
        ) {
            _map.removeLayer(polygon['_distanceLabels'][from][to]['marker']);
        }
        if (
            polygon['_distanceLabels'][to] &&
            polygon['_distanceLabels'][to][from] &&
            polygon['_distanceLabels'][to][from]['marker']
        ) {
            _map.removeLayer(polygon['_distanceLabels'][to][from]['marker']);
        }
    }
}

function getPoint(polygon, index) {
    return polygon['_points'][polygon['_pointsMap'][index]];
}

function addDeleteMarker(self, polygon, event, master, type, pos, svg, deleteCorner) {
    if (!polygon[type]) polygon[type] = {};
    if (polygon[type]['deleteMarker']) {
        polygon = _deleteDeleteMarker(self, self._map, polygon, type);
    }
    const _svg = svg
        ? svg
        : '<img src="data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAACwAAAAvCAYAAACYECivAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB2UlEQVRogWP8//+/w/WbtxIYBjng5OD4oSAv18HCwMDg8P3b9/iz5y8MahcH+fv+YGBgWAFyMAPIsbPmLhx4V+EBttZWP4SFhRiYBq0LcYBRB9MaDDkHs1BqAC8PD0NRQTaDj6c7XnWz5y2kSsamOIQjw4MJOhYEUpPiGXy8PCi1Dn8Ig0Jv5tR+BlUVZYotAoH66jIwxgW2bN/J0NjSidcMvCEMCj1qOZYYAIopY0MD8h08GAFeBx84dJThy5evdHP27Tt3GW7dvoNXDd40DNIclZDKICUhQW234bTv85cveNUQLNaeP38BxoMFEFUOpyXHg4slWoKMnCIGYlqMo1UzrcHIcjCoGMJW7JEqTgqgyMHp2YUMja2YVSmp4nRzMKjM/PwZs9wkVZwUMJrpaA1GHUxrMOpgWoNRB9MajDqY1mDUwbQGFA1VqamqMKipYY5b4BIHjfzw8nJT5CWKHDxzSj8DDw+mA3CJ4xv1IRYQlSRwdb2xOQqfODUAUQ4+e+4izRwAAqBeCLFzLEQ5GDTAARqooxXonTiVaJOJTsOgUUVQSEtJilPV2SAzSZnBIinTbdm2gxw3URWMVhy0BqMOpjUATY43gCqhIeJeR5CDB4E7iAQMDAwApn2hegugDdsAAAAASUVORK5CYII=" style="padding: 0px; padding-bottom: 0px; background-color: white;width:30px"></img>';
    const icon = L.divIcon({
        iconSize: new L.Point(0, 0),
        //zIndex: 9999,
        className: 'delete-icon',
        html: _svg,
    });
    let trashPosition = pos ? pos : event.latlng;
    if (pos) {
        const center = getPolygonCenter(polygon['_pointsSimple']);
        const offsetCenter = offsetPixelFromLatLng(self._map, center, 0, -45);
        trashPosition = offsetCenter;
    }
    polygon[type]['deleteMarker'] = L.marker(trashPosition, { icon })
        .on('mousedown', () => {
            if (!deleteCorner) {
                self._removeBox(master, removeCommand);
                _deleteDeleteMarker(self, self._map, polygon, type);
            } else {
                const index = deleteCorner.index;
                deleteCorner.remove(self, master, index, polygon);
            }
        })
        .addTo(self._map);
    self.deleteTimeout = setTimeout(() => {
        let polygon;
        if (self.boxSelectCollection) {
            polygon = self.boxSelectCollection[master];
        } else {
            polygon = self._polygon;
        }
        // TODO this may break
        //polygon = _deleteDeleteMarker(self, self._map, polygon, type);
        _deleteDeleteMarker(self, self._map, polygon, type);
    }, 2000);

    polygon[type]['deleteLife'] = false;
}

function _enableCornerListener(polygon, masterIndex, index, event) {
    polygon['_cornerListenerActive'] = true;
    polygon['_currCornerPosition'][index] = event.latlng;
}

function _askDeleteCorner(self, masterIndex, index, event) {
    let polygon;
    if (self.boxSelectCollection) {
        polygon = self.boxSelectCollection[masterIndex];
    } else {
        polygon = self._polygon;
    }

    if (
        (self._map.dragging._enabled || !polygon['_disableDelete']) &&
        polygon['_currCornerPosition'][index].lat === event.latlng.lat &&
        polygon['_currCornerPosition'][index].lng === event.latlng.lng &&
        polygon['_points'] &&
        polygon['_points'].length > 3 &&
        polygon['_cornerListenerActive']
    ) {
        polygon['_disableDelete'] = false;

        const svg =
            '<img src="data:image/png;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAACXBIWXMAAAsSAAALEgHS3X78AAADCUlEQVRYhWP8//8/Q1PPlBXv370z+PfvHwfDIAb8/AIvZOXkPBhrWvs67t29U37zxo3B7F4wYGNnZ/Dx9bvHxMbG5jEUHAwCv37+ZHj8+JE007dvXz8MAvcQDf7/+8/ONETcigJGHY0PRIUHMxzYuQmMjQ31KTKLhdaO9fVyZ0hLjmeQlBCHi4E8cPb8RbLNpJqjeXl4GCLDgxmiwoIZeHi4Gc6dv8ggKSmB4lgYePb8JUV2UZw8QI4FheTmtcsY0pLiwA4GASNDfQwHP3/xkqGxtYuhd+JUDHMaaspRaJo5GpQ20R2LDcAc6xscxbB5204MM8CObelEpfE4niJHpyfH43Xsly9fGWbNW8QQFZ+G4ViYo3ClbZjjsQGy07Saqgo4zeJy7LJVaxlWr9vMIKmgyGBo68jw+9dPhjtXrzC8fvEMnBHxOQoGQLGAzVMkO1pKUgKchn083XA6dvnKtQyfv3xhcPEPZuAXEkbolVNgOL53F8OylWuJsgvkYJB9z56/IM/R+BwLAlu272LonTAV7FiYA5EdDAP65lYMzx49INZacGiT5WhQCbFpzVKcjp01dyGGwQLCmA4GAS4eHqIdDALoeYGBWEdjS7u4HAsDH96+xSr+DRoTlACiHH3r9h2Gg4ePMtjbWoMrjZ6J08Bi+AAoCXx89xYjiVw8eYw+jgaB4oo6kg0/uH0zg7yqOjh9I5celAKSSw9QWwJXUUcI6CpIoqgAJS9yAFmONqKwlUapo0fb0/QCFDu6b9I0cPGHDNJzisCNJBgAlTwgddQCFDv65q07DJu37kARA1W/z5HK75u374LVDRpHDwQYdTS9wKij6QVGHU0vMOpoegGKR5h8vT0wxEB9SVBvHQYc7Kyp6h2KHY2towsavEEGairKYEwtQHLy+EyFPh4DdNSJXECyo2fOXQQe36AUYBvPIxaQnDxAHVrQmJyaKvnRDQplXL14YgBZaRqURCgZX6YUjJbT9AJDztGcXFyfmVhYWE+oa2gMAucQBqAZW1FR0ddDb25cTs4DANe/MKs9C9kOAAAAAElFTkSuQmCC" style="padding: 0px; padding-bottom: 0px; background-color: white;width:30px"></img>';

        addDeleteMarker(self, polygon, event, masterIndex, 'delete_corner', undefined, svg, {
            index,
            polygon,
            remove: _deleteCorner,
        });
    }
    polygon['_currCornerPosition'][index] = event.latlng;
    polygon['_cornerListenerActive'] = false;
    L.DomEvent.off(
        polygon['_corners'][index],
        'mouseup',
        L.bind(_askDeleteCorner, self, self, masterIndex, index),
        self
    );
    L.DomEvent.off(
        polygon['_corners'][index],
        'mouseout',
        L.bind(_askDeleteCorner, self, self, masterIndex, index),
        self
    );
}

function _deleteCorner(self, master, index, polygon) {
    const integerIndex = polygon['_pointsMap'][index];

    const closestPoint1 = polygon['_points'][_mod(integerIndex + 1, polygon['_points'].length)].label;
    const closestPoint2 = polygon['_points'][_mod(integerIndex - 1, polygon['_points'].length)].label;
    removeDistanceLabel(polygon, self._map, polygon['_points'][integerIndex].label, closestPoint1);
    removeDistanceLabel(polygon, self._map, polygon['_points'][integerIndex].label, closestPoint2);
    polygon = deleteNeighboringMidpointCorners(self, polygon, index, closestPoint1, closestPoint2);
    _addMidpoint(self, master, closestPoint2, closestPoint1);

    polygon['_points'].splice(integerIndex, 1);
    polygon['_pointsSimple'].splice(integerIndex, 1);
    polygon['_points'].map((point, index) => {
        polygon['_pointsMap'][point.label] = index;
    });
    if (polygon['_corners'][index]) self._map.removeLayer(polygon['_corners'][index]);
    polygon['drawPolygon'].setLatLngs(polygon['_pointsSimple']);
}

function deleteNeighboringMidpointCorners(self, polygon, index, closestPoint1, closestPoint2) {
    const point1Index = index + closestPoint1;
    const point1 = polygon['_midPointCorners'][point1Index];
    if (point1) {
        self._map.removeLayer(point1);
        polygon['_midPointCorners'][point1Index] = null;
    }
    const point2Index = closestPoint2 + index;
    const point2 = polygon['_midPointCorners'][point2Index];
    if (point2) {
        self._map.removeLayer(point2);
        polygon['_midPointCorners'][point2Index] = null;
    }
    return polygon;
}

function _moveCorner(self, masterIndex, index, event) {
    if (!self._isActive) {
        let polygon;
        // TODO fix this shit
        if (self.boxSelectCollection) {
            polygon = self.boxSelectCollection[masterIndex];
        } else {
            polygon = self._polygon;
        }

        if (!polygon) return;

        if (event && polygon['_corners'][index] && polygon['_cornerActive'][index]) {
            event.originalEvent.preventDefault();
            if (event.originalEvent.buttons === 0) {
                _endMoveCorner(self, masterIndex, index, event);
            }

            polygon['_corners'][index].setLatLng(event.latlng);
            polygon['_corners'][index].bringToFront();
            polygon['_bounds'][index] = event.latlng;
            if (polygon['_pointsMap']) {
                const pointMapIndex = polygon['_pointsMap'][index];
                const point = polygon['_points'][pointMapIndex];
                point.latLng = event.latlng;
                polygon['_pointsSimple'][pointMapIndex] = event.latlng;

                const numberOfPoints = polygon['_points'].length;
                moveMidpoint(
                    self,
                    masterIndex,
                    point.label,
                    polygon['_points'][(pointMapIndex + 1) % numberOfPoints].label
                );

                const mod = _mod(pointMapIndex - 1, numberOfPoints);
                moveMidpoint(self, masterIndex, polygon['_points'][mod].label, point.label);
            }

            polygon['deleteClicked'] = false;
            self._polygon = polygon;
            polygon = self.manageLabelsAndScale(polygon, masterIndex);
        }
    }
}

function _endMoveCorner(self, masterIndex, index, event) {
    let polygon;
    if (self.boxSelectCollection) {
        polygon = self.boxSelectCollection[masterIndex];
    } else {
        polygon = self._polygon;
    }

    if (!polygon || !polygon['_cornerActive']) return;

    _moveCorner(self, index, event);
    if (polygon['_cornerActive'][index]) {
        polygon['_cornerActive'][index] = false;
        L.DomEvent.off(self._map, 'mousemove', _moveCorner, self);
        L.DomEvent.off(self._map, 'mouseup', _endMoveCorner, self);
        L.DomEvent.off(self._map, 'mouseout', _endMoveCorner, self);
        if (self._sendSelectedAOI && self._validAOITrueFalse) {
            const bounds = L.latLngBounds(sw, ne);
            const ne = bounds.getNorthEast();
            const se = bounds.getSouthEast();
            const sw = bounds.getSouthWest();
            const nw = bounds.getNorthWest();
            self._sendSelectedAOI(L.latLngBounds(nw, se));
        }

        if (self._finishEndMoveCorner) self._finishEndMoveCorner(polygon);

        self._map.dragging.enable();
    }
}

function moveMidpoint(self, masterIndex, indexFrom, indexTo) {
    if (!self._isActive) {
        let polygon = self._polygon;
        if (self.boxSelectCollection) {
            polygon = self.boxSelectCollection[masterIndex];
        }
        const pointFrom = getPoint(polygon, indexFrom).latLng;
        const pointTo = getPoint(polygon, indexTo).latLng;
        const midpoint = _twoPointsCenter(pointFrom, pointTo);
        if (polygon['_midPointCorners'][indexFrom + indexTo]) {
            polygon['_midPointCorners'][indexFrom + indexTo].setLatLng(midpoint);
        }
    }
}

export function _mod(a, b) {
    return ((a % b) + b) % b;
}

export function removeCommand(_map, item) {
    _map.removeLayer(item);
    return null;
}

export const svgTextDisplay = (text, lineWidth, lineHeight) => {
    const textNode = L.SVG.create('div');
    const textPath = L.SVG.create('div');
    textPath.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#textPath');
    textPath.classList.add('area-measurement-label');
    textPath.appendChild(document.createTextNode(text));
    textPath.setAttribute('text-anchor', 'middle');
    textPath.setAttribute('transform-origin', 'top center');
    textNode.appendChild(textPath);
    textNode.style.width = `${lineWidth}px`;
    textNode.style.marginLeft = `-${lineWidth / 2}px`;
    textNode.style.height = `${lineHeight}px`;
    textNode.style.marginTop = `-${lineHeight / 2}px`;

    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(textNode);
    return svgString;
};
