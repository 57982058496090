import moment from 'moment';
import { useEffect, useState } from 'react';
import { isMobileVersion } from '../../lib/soar-helper';
import styled from 'styled-components';
import { ListingType, ListingMetadata } from '../../api/model';
import SoarHelper from '../../lib/soar-helper';
import Analytics from '../../lib/user-analytics';
import UriHelper from '../../lib/uri-helper';
import { NormalizeMarkdownViewer } from '../Shared/normalize-markdown-viewer';
import ListingHelper from '../../lib/listing-helper';

interface ExploreCardProps {
    listingType: string;
    userName: string;
    id: number;
    metadata: string;
    dateUploaded: number;
    owner?: string;
}

const ExploreCard = ({ listingType, userName, metadata, id, dateUploaded, owner }: ExploreCardProps) => {
    const [metaData, setMetaData] = useState<ListingMetadata>();
    const isSoar = SoarHelper.isSoar();

    useEffect(() => {
        if (metadata) {
            const meta = JSON.parse(metadata);
            setMetaData(meta);
        }
    }, [metadata]);

    const handleImageSelect = () => {
        Analytics.Event('Explore', 'Clicked to view', id);
        if (listingType === ListingType.IMAGE) {
            Analytics.Event('Clicked to view', 'Drone - explore', id);
        } else {
            Analytics.Event('Clicked to view', 'Map - explore', id);
        }
        UriHelper.navigateToPath(`/maps/${id}`);
    };

    const handleProfileSelect = () => {
        Analytics.Event('Explore', 'Clicked to open users profile', owner);
        UriHelper.navigateToPath(`/profile/${owner}`);
    };

    return (
        <ExploreImageContainer>
            <ImageContainer href={`/maps/${id}`} onClick={(e) => e.preventDefault()}>
                <ImgContainer onClick={handleImageSelect}>
                    {id ? (
                        <ExploreCardImage src={ListingHelper.getPreviewUrlForListing(id)} />
                    ) : (
                        <div style={{ height: '300px' }} />
                    )}
                </ImgContainer>
                {!isMobileVersion && (
                    <OverlaidDiv onClick={handleImageSelect} data-testid="handle-image-select">
                        <DateTaken>{moment.unix(dateUploaded).format('DD.MM.YYYY')}</DateTaken>
                        <ExploreCardOverlayDetails>
                            {metaData && metaData.description && (
                                <Description>
                                    <NormalizeMarkdownViewer text={metaData.description} />
                                </Description>
                            )}
                        </ExploreCardOverlayDetails>
                    </OverlaidDiv>
                )}
                <ExploreCardDetails>
                    {metaData && metaData.title && !isMobileVersion && (
                        <ExploreCardTitle>{metaData.title}</ExploreCardTitle>
                    )}
                    {metaData && metaData.title && isMobileVersion && (
                        <ExploreCardTitle onClick={handleImageSelect}>{metaData.title}</ExploreCardTitle>
                    )}
                    <ExploreCardText>
                        by{' '}
                        <ExploreCardLink
                            href={`/profile/${owner}`}
                            onClick={() => (isSoar ? handleProfileSelect() : '')}
                            data-testid="handle-profile-select"
                        >
                            {userName ? userName : 'Unknown User'}
                        </ExploreCardLink>
                    </ExploreCardText>
                </ExploreCardDetails>
            </ImageContainer>
        </ExploreImageContainer>
    );
};

export default ExploreCard;

const ExploreCardImage = styled.img`
    cursor: pointer;
    border: 1px solid transparent;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 2%);
    border-radius: 6px;
    display: flex;
    transition: height 5s ease-in-out;
`;

const ExploreCardTitle = styled.p`
    color: #fff;
    padding-top: 5px;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    display: flex;
    letter-spacing: 0.03em;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

const ExploreCardText = styled.p`
    color: #fff;
    font-size: 12px;
    line-height: 20%;
    margin-top: -10px;
    margin-bottom: 15px;
`;

const ExploreCardLink = styled.a`
    color: #eed926 !important;
    text-decoration: underline !important;
    cursor: pointer;
    word-break: break-word;
    line-height: 15px;
`;

const ExploreCardDetails = styled.div`
    padding: 0px 0px 5px 5px;
    position: absolute;
    opacity: 1;
`;

const ExploreImageContainer = styled.div`
    padding-bottom: 50px;
`;

const ImageContainer = styled.a`
    display: block;
    position: relative;
    cursor: pointer;
`;

const ImgContainer = styled.div`
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    position: relative;
    vertical-align: middle;
    padding: 0px;
    border-radius: 6px;
`;

const OverlaidDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 6px;
    background: transparent;
    color: #ffffff;

    ${ImageContainer}:hover & {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: rgba(0, 0, 0, 0.4);
        -webkit-transition: all 0.2s 0s ease-in-out;
        -moz-transition: all 0.2s 0s ease-in-out;
        -o-transition: all 0.2s 0s ease-in-out;
        transition: all 0.2s 0s ease-in-out;
    }
`;

const ExploreCardOverlayDetails = styled.div`
    padding-left: 5px;
    padding-bottom: 5px;
    bottom: 0px;
    position: absolute;
`;

const DateTaken = styled.p`
    position: absolute;
    top: 5px;
    left: 5px;
    opacity: 0;
    color: rgba(255, 255, 255, 0.87);
    font-size: 13px;
    z-index: 100;

    ${ImageContainer}:hover & {
        -webkit-transition: all 0.3s 0s ease-in-out;
        -moz-transition: all 0.3s 0s ease-in-out;
        -o-transition: all 0.3s 0s ease-in-out;
        transition: all 0.3s 0s ease-in-out;
        opacity: 1;
    }
`;

const Description = styled.p`
    opacity: 0.8;
    display: flex;
    flex-wrap: wrap;
    color: rgba(255, 255, 255, 0.87);
    padding-bottom: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;

    ${ImageContainer}:hover & {
        -webkit-transition: all 0.3s 0s ease-in-out;
        -moz-transition: all 0.3s 0s ease-in-out;
        -o-transition: all 0.3s 0s ease-in-out;
        transition: all 0.3s 0s ease-in-out;
        opacity: 1;
    }
`;
