import L from 'leaflet';
import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import RulerAnnotationHandle from './ruler-annotation-handle';

interface RulerEditElement {
    rulerElement: Readonly<{ instance: L.Polyline; context: Readonly<{ map: L.Map }> }>;
}

const dragMarkerOptions: L.MarkerOptions = {
    draggable: true,
    bubblingMouseEvents: false,
    icon: new L.DivIcon({
        iconSize: new L.Point(16, 16),
        iconAnchor: new L.Point(0, 0),
    }),
};

const createDragMarker = (position: L.LatLng, instance: L.Polyline, context: LeafletContextInterface) => {
    const markerElement = createElementObject<L.Marker, RulerEditElement>(
        new L.Marker(position, dragMarkerOptions),
        context
    );

    markerElement.instance.on('mouseover', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
    });

    markerElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-interactive');
    });

    markerElement.instance.on('add', () => {
        const icon = new L.DivIcon({
            iconSize: [16, 16],
            iconAnchor: [0, 8],
            html: RulerAnnotationHandle(instance.options.color ? instance.options.color : '#3387FD'),
            className: 'ruler-builder-icon',
        });
        markerElement.instance.setIcon(icon);
    });

    return markerElement;
};

export const createRulerEditElement = (props: RulerEditElement, context: LeafletContextInterface) => {
    const startLatLng = props.rulerElement.instance.getLatLngs()[0] as L.LatLng;
    const endLatLng = props.rulerElement.instance.getLatLngs()[1] as L.LatLng;
    const startLatLngMarker = createDragMarker(startLatLng, props.rulerElement.instance, context);
    const endLatLngMarker = createDragMarker(endLatLng, props.rulerElement.instance, context);

    const editLayer = new L.LayerGroup();
    const editLayerElement = createElementObject<L.LayerGroup>(editLayer, context);

    editLayerElement.instance.on('add', () => {
        editLayerElement.instance.addLayer(startLatLngMarker.instance);
        editLayerElement.instance.addLayer(endLatLngMarker.instance);
    });

    editLayerElement.instance.on('remove', () => {
        editLayerElement.instance.removeLayer(startLatLngMarker.instance);
        editLayerElement.instance.removeLayer(endLatLngMarker.instance);
    });

    props.rulerElement.instance.on('update-ruler-color', () => {
        const icon = L.divIcon({
            iconSize: [16, 16],
            iconAnchor: [0, 8],
            html: RulerAnnotationHandle(
                props.rulerElement.instance.options.color ? props.rulerElement.instance.options.color : '#3387FD'
            ),
            className: 'ruler-builder-icon',
        });
        startLatLngMarker.instance.setIcon(icon);
        endLatLngMarker.instance.setIcon(icon);
    });

    startLatLngMarker.instance.on('drag', (e: L.LeafletMouseEvent) => {
        const endLatLng = props.rulerElement.instance.getLatLngs()[1] as L.LatLng;
        props.rulerElement.instance.setLatLngs([e.latlng, endLatLng]);
        props.rulerElement.instance.fireEvent('update', {
            ...e,
            startLatLng: e.latlng,
            endLatLng: endLatLng,
        });
    });

    endLatLngMarker.instance.on('drag', (e: L.LeafletMouseEvent) => {
        const startLatLng = props.rulerElement.instance.getLatLngs()[0] as L.LatLng;
        props.rulerElement.instance.setLatLngs([startLatLng, e.latlng]);
        props.rulerElement.instance.fireEvent('update', {
            ...e,
            startLatLng: startLatLng,
            endLatLng: e.latlng,
        });
    });

    startLatLngMarker.instance.on('dragend', () => {
        props.rulerElement.instance.fireEvent('dragend');
    });

    endLatLngMarker.instance.on('dragend', () => {
        props.rulerElement.instance.fireEvent('dragend');
    });

    return editLayerElement;
};
