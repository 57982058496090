import { AppState } from '../root-reducer';

export const selectAdminUsersData = (state: AppState) => state.subDomain.adminUserData;

export const selectAdminListingsData = (state: AppState) => state.subDomain.adminListingsData;

export const selectSubdomainInfo = (state: AppState) => state.subDomain.subdomainInfo;

export const selectUploadToSuperMapError = (state: AppState) => state.subDomain.uploadToSuperMapError;

export const selectUploadToSuperMapStatusReport = (state: AppState) => state.subDomain.uploadToSuperMapStatusReport;

export const selectLoadingAdminListingData = (state: AppState) => state.subDomain.adminListingLoading;

export const selectReuploadMapId = (state: AppState) => state.subDomain.reuploadMapId;
