import L from 'leaflet';

import {
    createContainerComponent,
    createElementHook,
    createElementObject,
    createPathHook,
    extendContext,
    LeafletElement,
    LeafletContextInterface,
    PathProps,
} from '@react-leaflet/core';

import { ghostPathOptions, PolygonPath } from './path';

interface PolygonAnnotationProps extends L.PathOptions {
    path: PolygonPath;
    children: React.ReactNode;
}

const createPolygonGhost = (
    pathElement: Readonly<{ instance: L.Polygon; context: Readonly<{ map: L.Map }> }>,
    context: LeafletContextInterface
) => {
    const pathGhost = new L.Polygon(pathElement.instance.getLatLngs() as L.LatLng[], ghostPathOptions);
    const pathGhostElement = createElementObject<L.Polygon, PathProps>(pathGhost, context);
    context.map.addLayer(pathGhostElement.instance);

    pathElement.instance.on('mouseover', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
        const currentWeight = pathElement.instance.options.weight || 1;
        pathElement.instance.setStyle({ weight: currentWeight + 3 });
    });

    pathElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-interactive');

        const originalWeight = pathElement.instance.options.weight || 4;
        pathElement.instance.setStyle({ weight: originalWeight - 3 });
    });

    pathGhostElement.instance.on('remove', () => {
        const originalWeight = pathElement.instance.options.weight || 4;
        pathElement.instance.setStyle({ weight: originalWeight - 3 });
    });

    pathGhostElement.instance.on('mouseover', (e: L.LeafletMouseEvent) => {
        pathElement.instance.fire('mouseover', e);
    });

    pathGhostElement.instance.on('mouseout', (e: L.LeafletMouseEvent) => {
        pathElement.instance.fire('mouseout', e);
    });

    pathElement.instance.on('update', () => {
        pathGhostElement.instance.setLatLngs(pathElement.instance.getLatLngs());
    });

    pathGhostElement.instance.on('click', (e: L.LeafletMouseEvent) => {
        pathElement.instance.fireEvent('click', e);
    });

    return pathGhostElement;
};

const createNodeMarkers = (
    pathElement: Readonly<{ instance: L.Polygon; context: Readonly<{ map: L.Map }> }>,
    context: LeafletContextInterface
): LeafletElement<L.Marker, LeafletContextInterface>[] => {
    const nodeMarkers = (pathElement.instance.getLatLngs()[0] as L.LatLng[]).map((position, index) => {
        const dotMarkerIcon = new L.DivIcon({
            iconSize: [12.0, 12.0],
            iconAnchor: [6.0, 6.0],
            className: 'annotation-node-dot-marker',
        });

        const marker = new L.Marker(position, { interactive: true, draggable: true, icon: dotMarkerIcon });
        const markerElement = createElementObject<L.Marker>(marker, context);

        markerElement.instance.on('drag', (e: L.LeafletMouseEvent) => {
            const latlngs = pathElement.instance.getLatLngs()[0] as L.LatLng[];
            pathElement.instance.setLatLngs(latlngs.map((latLng, i) => (i === index ? e.latlng : latLng)));
        });

        markerElement.instance.on('dragend', () => {
            pathElement.instance.fire('update');
        });

        context.map.addLayer(markerElement.instance);
        return markerElement;
    });

    return nodeMarkers;
};

const createPolygonAnnotation = (props: PolygonAnnotationProps, context: LeafletContextInterface) => {
    const polygon = new L.Polygon(props.path.positions, props.path.options);
    const polygonElement = createElementObject<L.Polygon, PathProps>(
        polygon,
        extendContext(context, { overlayContainer: polygon })
    );

    let nodeMarkers: LeafletElement<L.Marker, LeafletContextInterface>[] = [];

    const polygonGhostElement = createPolygonGhost(polygonElement, context);

    polygonElement.instance.on('remove', () => {
        context.map.removeLayer(polygonGhostElement.instance);
        nodeMarkers.forEach((nodeMarker) => {
            context.map.removeLayer(nodeMarker.instance);
        });
    });

    polygonElement.instance.on('click', () => {
        context.map.removeLayer(polygonGhostElement.instance);
        nodeMarkers = createNodeMarkers(polygonElement, context);
        nodeMarkers.forEach((nodeMarker) => {
            context.map.addLayer(nodeMarker.instance);
        });

        context.map.on('click', () => {
            context.map.addLayer(polygonGhostElement.instance);
            nodeMarkers.forEach((nodeMarker) => {
                context.map.removeLayer(nodeMarker.instance);
            });
        });
    });

    return polygonElement;
};

const updatePolygonAnnotation = (instance: L.Polygon, props: PolygonAnnotationProps, _: PolygonAnnotationProps) => {
    instance.setStyle({ ...instance.options, ...props.path.options });
};

const usePolygonAnnotation = createElementHook<L.Polygon, PolygonAnnotationProps, LeafletContextInterface>(
    createPolygonAnnotation,
    updatePolygonAnnotation
);
const usePolygonPath = createPathHook(usePolygonAnnotation);
const PolygonAnnotation = createContainerComponent(usePolygonPath);

export default PolygonAnnotation;
