import L, { Map } from 'leaflet';

/**
 * Updates the ruler text marker.
 * @param map The map to add the marker to.
 * @param instance The ruler annotation instance.
 * @returns html string containing the text to display.
 * */
export const handleUpdateRulerIconText = (map: Map, instance: L.Polyline) => {
    const startLatLng = instance.getLatLngs()[0] as L.LatLng;
    const endLatLng = instance.getLatLngs()[1] as L.LatLng;
    const rotation = rotateAngle(map, startLatLng, endLatLng);
    const text = metricTextDisplay(startLatLng, endLatLng, Metric.Kilometers);
    const lineLengthAsPx = lineLengthAsPixels(map, startLatLng, endLatLng);
    const textDisplay = svgTextDisplay(rotation, text, lineLengthAsPx);
    return textDisplay;
};

/**
 * Calculates the angle of the line in pixels.
 * @param map The map to calculate the line angle on.
 * @param fromLatLng The starting point of the line.
 * @param toLatLng The ending point of the line.
 * @returns The angle of the line in pixels.
 */
const rotateAngle = (map: L.Map, fromLatLng: L.LatLng, toLatLng: L.LatLng): string => {
    const from = map.latLngToLayerPoint(fromLatLng);
    const to = map.latLngToLayerPoint(toLatLng);
    const vector = {
        x: to.x - from.x ? to.x - from.x : 0.01,
        y: to.y - from.y ? to.y - from.y : 0.01,
    };
    const pixelBearing = (180 * Math.atan(vector.y / vector.x)) / Math.PI;
    return pixelBearing.toFixed(0);
};

/**
 * Calculates the length of the line in pixels.
 * @param map The map to calculate the line length on.
 * @param fromLatLng The starting point of the line.
 * @param toLatLng The ending point of the line.
 * @returns The length of the line in pixels.
 */
const lineLengthAsPixels = (map: L.Map, fromLatLng: L.LatLng, toLatLng: L.LatLng): number => {
    const from = map.latLngToLayerPoint(fromLatLng);
    const to = map.latLngToLayerPoint(toLatLng);
    const lineLengthAsPixels = from.distanceTo(to);
    return lineLengthAsPixels;
};

/**
 * Creates an svg text to display on the ruler.
 * @param rotation The rotation to apply to the text.
 * @param text The text to display.
 * @param lineLength The length of the line.
 * @returns html string containing the text to display.
 */
const svgTextDisplay = (rotation: string, text: string, lineLength: number): string => {
    const textNode = L.SVG.create('div');
    const textPath = L.SVG.create('div');
    textPath.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#textPath');
    textPath.classList.add('ruler-measurement-text');
    textPath.appendChild(document.createTextNode(text));
    textPath.setAttribute('text-anchor', 'middle');
    textPath.setAttribute('transform-origin', 'top center');
    textNode.appendChild(textPath);
    textPath.style.transform = `rotate(${rotation}deg)`;
    textPath.style.padding = `${paddingOnRotation(rotation)}px 0px 0px 0px`;
    textPath.style.width = `${lineLength}px`;
    textPath.style.marginLeft = `-${lineLength / 2}px`;
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(textNode);
    return svgString;
};

enum Metric {
    Kilometers,
    Miles,
}

/**
 * Create a string representation to display.
 * @param startLatLng The starting point of the line.
 * @param endLatLng The ending point of the line.
 * @param metric The metric to display.
 * @returns html string containing the text to display.
 */
const metricTextDisplay = (startLatLng: L.LatLng, endLatLng: L.LatLng, metric: Metric): string => {
    const distance = startLatLng.distanceTo(endLatLng);
    // Simple implementation since we will probably only have the 2 types of measurements
    if (metric === Metric.Miles) {
        const meterAsMiles = 0.00062137; // 1 meter = 0.00062137 miles
        const lineLength = distance * meterAsMiles;
        return `${readableDistance(lineLength, 'mi')}`.replace(/ /g, '\u00A0'); // Keeps to one line without setting width
    } else {
        const lineLength = distance / 1000;
        return `${readableDistance(lineLength, 'km')}`.replace(/ /g, '\u00A0'); // Keeps to one line without setting width
    }
};

/**
 * Interpolates a value between two points.
 * @param x1 The first x value.
 * @param x2 The second x value.
 * @param y1 The first y value.
 * @param y2 The second y value.
 * @param x The x value to interpolate.
 * @returns The interpolated value.
 */
const interpolate = (x1: number, x2: number, y1: number, y2: number, x: number): number => {
    return y1 + ((x - x1) * (y2 - y1)) / (x2 - x1);
};

/**
 * Calculates the padding to apply to the text based on the rotation, so it does not go over the polyline, the number returned is between 1-20 which seems to be an asthetically pleasing range.
 * @param rotation The rotation to apply to the text.
 * @returns The padding to apply to the text.
 */
const paddingOnRotation = (rotation: string): number => {
    const rotationAsNumber = Number(rotation);
    if ((rotationAsNumber >= 45 && rotationAsNumber <= 90) || (rotationAsNumber <= -45 && rotationAsNumber >= -90)) {
        let rotationPixels: number;
        if (rotationAsNumber < 0) {
            rotationPixels = interpolate(-90, -45, 10, 1, -Math.abs(rotationAsNumber));
            return Math.abs(rotationPixels);
        } else {
            rotationPixels = interpolate(45, 90, 1, 20, rotationAsNumber);
            return Math.abs(Math.min(rotationPixels, 20));
        }
    }
    return 0;
};

/**
 * Format the string output when in km and meters.
 * @param distance The distance to display.
 * @param metric The metric to display.
 * @returns html string containing the text to display.
 */
const readableDistance = (distance: number, metric: string) => {
    if (distance >= 1) {
        return `${distance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${metric}`;
    } else {
        if (1000 * distance < 100) {
            return `${(distance * 1000).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}m`;
        } else {
            return `${(distance * 1000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}m`;
        }
    }
};
