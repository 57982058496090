import L, { Map } from 'leaflet';
import { handleUpdateRulerIconText } from './ruler-annotation-util';

const marker = L.marker(L.latLng(0, 0));

/**
 * Creates a temporary marker while the ruler is being built.
 * @param map The map to add the marker to.
 * @param instance The ruler annotation instance.
 * @returns The marker.
 */

export const rulerTempBuilderMarkerCreate = (map: Map, instance: L.Polyline) => {
    const icon = L.divIcon({
        className: 'ruler-text-marker',
        iconSize: [0, 0],
        iconAnchor: [0, 0],
        html: handleUpdateRulerIconText(map, instance),
    });
    marker.setLatLng(instance.getCenter());
    marker.setIcon(icon);
    marker.addTo(map);
};

/**
 * Removes the temporary marker.
 */
export const rulerTempBuilderMarkerRemove = () => {
    marker.remove();
};
