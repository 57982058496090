import L from 'leaflet';
import { LeafletContextInterface, createElementObject, extendContext } from '@react-leaflet/core';

/**
 * The ghost element is an invisible rectangle that floats over the border.
 * It is used to detect when the cursor is close to the image
 */
interface GhostReactangleElementProps {
    bounds: L.LatLngBounds;
    rectangleElement: Readonly<{ instance: L.Rectangle; context: Readonly<{ map: L.Map }> }>;
    context: LeafletContextInterface;
}

const ghostRectangleOptions: L.PolylineOptions = {
    color: 'transparent',
    weight: 30,
    opacity: 1.0,
    fill: false,
    interactive: true,
    bubblingMouseEvents: false,
};

export const createRectangleGhostElement = (props: GhostReactangleElementProps, context: LeafletContextInterface) => {
    const rectangleGhost = new L.Rectangle(props.bounds, ghostRectangleOptions);
    const rectangleGhostElement = createElementObject<L.Rectangle>(
        rectangleGhost,
        extendContext(context, { overlayContainer: rectangleGhost })
    );

    rectangleGhostElement.instance.on('add', () => {
        rectangleGhostElement.instance.on('mouseover', (e: L.LeafletMouseEvent) => {
            // Add the focus element to the map and fire its mouseover event
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
            props.rectangleElement.instance.fireEvent('mouseover', e);
        });

        rectangleGhostElement.instance.on('mouseout', (e: L.LeafletMouseEvent) => {
            // Remove the focus element from the map
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-interactive');
            props.rectangleElement.instance.fireEvent('mouseout', e);
        });

        rectangleGhostElement.instance.on('click', (e: L.LeafletMouseEvent) => {
            // Propagate the on click event to the focus element
            props.rectangleElement.instance.fireEvent('click', e);
        });
    });

    props.rectangleElement.instance.on('update', () => {
        rectangleGhostElement.instance.setBounds(props.rectangleElement.instance.getBounds());
    });

    props.rectangleElement.instance.on('remove', () => {
        rectangleGhostElement.instance.off('mouseover');
        rectangleGhostElement.instance.off('mouseout');
        rectangleGhostElement.instance.off('click');
        rectangleGhostElement.instance.off('add');
        context.map.removeLayer(rectangleGhostElement.instance);
        rectangleGhostElement.instance.remove();
    });

    return rectangleGhostElement;
};
