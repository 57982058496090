import React from 'react';
import DistortedImageOverlay from '../Annotations/DistortedImageOverlay/distorted-image-overlay';
import { useSelector } from 'react-redux';
import {
    selectSatelliteAOI,
    selectSatelliteSelectedFeatures,
    selectSatelliteZIndex,
} from '../../../store/Map/SatelliteArchive/selectors';
import GeoUtil from '../../../lib/geo-util';
import SatelliteQualityWatermark from '../satellite-quality-watermark';

const SatelliteFeatures = () => {
    const aoi = useSelector(selectSatelliteAOI);
    const selectedFeatures = useSelector(selectSatelliteSelectedFeatures);
    const zIndex = useSelector(selectSatelliteZIndex);

    if (!aoi || !selectedFeatures?.length) {
        return <React.Fragment />;
    }

    return (
        <React.Fragment>
            {selectedFeatures.map((feature) => {
                const bounds = GeoUtil.polygonFromPolygonWKT(feature.geometryWKT);
                return (
                    <DistortedImageOverlay
                        key={feature.id}
                        url={feature.previewUrl}
                        anchors={bounds}
                        clip={aoi}
                        zIndex={(zIndex[feature.id] || 0) + 100000}
                    />
                );
            })}
            <SatelliteQualityWatermark bounds={aoi} />;
        </React.Fragment>
    );
};

export default SatelliteFeatures;
