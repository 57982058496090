import React, { useState } from 'react';
import AnnotationControl from '../annotation-control';
import RectangleAnnotation from './rectangle-annotation';
import RectangleAnnotationBuilder from './rectangle-annotation-builder';
import RectangleAnnotationPopup from './rectangle-annotation-popup';
import Rectangle from './rectangle';
import { Rectangle as LeafletRectangle } from 'react-leaflet';

interface RectangleControlProps {
    hideControl: boolean;
}

const RectangleControl = (props: RectangleControlProps) => {
    const [isRectangleControlActive, setIsRectangleControlActive] = useState(false);
    const [rectangleAnnotations, setRectangleAnnotations] = useState<Rectangle[]>([]);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="middle"
                hideControl={props.hideControl}
                imageUrl="/assets/annotations/icon-rectangle.svg"
                name="Rectangle"
                onClick={() => setIsRectangleControlActive((isActive) => !isActive)}
            />
            {isRectangleControlActive ? (
                <RectangleAnnotationBuilder
                    onCreateRectangle={(rectangle) => {
                        setIsRectangleControlActive(false);
                        setRectangleAnnotations((current) => [...current, rectangle]);
                    }}
                />
            ) : null}
            {props.hideControl &&
                rectangleAnnotations.map((rectangle) => {
                    return (
                        <LeafletRectangle
                            key={rectangle.id}
                            bounds={rectangle.boundingBox}
                            color={rectangle.options.color}
                            fillOpacity={rectangle.options.fillOpacity}
                            opacity={rectangle.options.opacity}
                            weight={rectangle.options.weight}
                            interactive={false}
                        />
                    );
                })}

            {!props.hideControl &&
                rectangleAnnotations.map((rectangle) => {
                    return (
                        <RectangleAnnotation
                            key={rectangle.id}
                            rectangle={rectangle}
                            onUpdateRectangleBoundingBox={(boundingBox) => {
                                setRectangleAnnotations((current) => {
                                    const index = current.findIndex((t) => t.id === rectangle.id);
                                    const newState = [...current];
                                    newState[index].boundingBox = boundingBox;
                                    return newState;
                                });
                            }}
                        >
                            <RectangleAnnotationPopup
                                rectangle={rectangle}
                                onDeleteRectangle={() => {
                                    setRectangleAnnotations((current) => {
                                        return current.filter((t) => t.id !== rectangle.id);
                                    });
                                }}
                                onUpdateRectangle={(rectangleOptions) => {
                                    setRectangleAnnotations((current) => {
                                        const index = current.findIndex((t) => t.id === rectangle.id);
                                        const newState = [...current];
                                        newState[index].options = rectangleOptions;
                                        return newState;
                                    });
                                }}
                            />
                        </RectangleAnnotation>
                    );
                })}
        </React.Fragment>
    );
};

export default RectangleControl;
