import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AnnotationControl from '../annotation-control';
import TextBoxAnnotation from './svg-text-box';
import TextBoxBuilder from './text-box-placer';
import TextBoxPopup from './text-box-popup';
import TextAnnotation from './svg-text';
import L from 'leaflet';

interface TextControlProps {
    hideControl: boolean;
}

export interface TextBox {
    id: string;
    text: string;
    topLeftPosition: L.LatLng;
    bounds: L.LatLngBounds;
}

const TextControl = (props: TextControlProps) => {
    const [isCreatingText, setIsCreatingText] = useState(false);
    const [text, setText] = useState<TextBox[]>([]);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="middle"
                hideControl={props.hideControl}
                imageUrl="/assets/annotations/icon-text.svg"
                name="Text"
                onClick={() => setIsCreatingText(true)}
            />

            {isCreatingText ? (
                <TextBoxBuilder
                    onPlaceTextBox={(bounds) => {
                        const newTextBox: TextBox = {
                            id: uuidv4(),
                            text: 'Enter text...',
                            bounds: bounds,
                            topLeftPosition: bounds.getNorthWest(),
                        };
                        setText([...text, newTextBox]);
                        setIsCreatingText(false);
                    }}
                />
            ) : null}

            {props.hideControl &&
                text.map((t) => {
                    return <TextAnnotation key={t.id} text={t} />;
                })}

            {!props.hideControl &&
                text.map((t) => {
                    return (
                        <TextBoxAnnotation
                            text={t}
                            bounds={t.bounds}
                            key={t.id}
                            onUpdateTextBox={(updateTextBox) => {
                                console.log('onUpdateTextBox');
                                setText((current) => {
                                    const index = current.findIndex((c) => c.id === t.id);
                                    const newText = [...current];
                                    newText[index].bounds = updateTextBox.bounds;
                                    return newText;
                                });
                            }}
                        >
                            <TextBoxPopup
                                text={t}
                                onDeleteText={() => {
                                    setText((current) => current.filter((c) => c.id !== t.id));
                                }}
                                onSetText={(updatedText) => {
                                    setText((current) => {
                                        const index = current.findIndex((c) => c.id === t.id);
                                        const newText = [...current];
                                        newText[index].text = updatedText;
                                        return newText;
                                    });
                                }}
                            />
                        </TextBoxAnnotation>
                    );
                })}
        </React.Fragment>
    );
};

export default TextControl;
