import React from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

// Do not remove the className it is used to remove the element from the screen shot
const ScreenShotLoader = () => {
    return (
        <React.Fragment>
            <SnapShotBackgroundOverlay className="leaflet-snapshot-ignore" />
            <SnapShotSubmittingContainer className="leaflet-snapshot-ignore">
                <SnapshotSubmitting />
                <SnapshotSubmittingText>Exporting Image...</SnapshotSubmittingText>
            </SnapShotSubmittingContainer>
        </React.Fragment>
    );
};

export default ScreenShotLoader;

const SnapShotBackgroundOverlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`;

const SnapShotSubmittingContainer = styled.div`
    position: fixed;
    top: 40%;
    text-align: center;
    width: 150px;
`;

const SnapshotSubmitting = styled(Spinner)`
    width: 80px;
    height: 80px;
    color: #eed926;
`;

const SnapshotSubmittingText = styled.div`
    font-size: 14px;
    margin-top: 10px;
    color: white;
`;
