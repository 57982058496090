import React, { useState } from 'react';
import styled from 'styled-components';
import Analytics from '../../../../lib/user-analytics';
import { LatLng } from 'leaflet';

interface CoordinateCopyControlProps {
    latlng: LatLng;
}

const CoordinateCopyControl = (props: CoordinateCopyControlProps) => {
    const [copied, setCopied] = useState<boolean>(false);

    const handleCopyLatLng = () => {
        setCopied(true);
        navigator.clipboard.writeText(`Lat: ${props.latlng.lat.toFixed(5)}, Long: ${props.latlng.lng.toFixed(5)}`);
        Analytics.Event(`Annotations`, 'Copied latlng');
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };
    return (
        <ShareCoordinate onClick={handleCopyLatLng}>
            {copied ? (
                'Copied! '
            ) : (
                <React.Fragment>
                    <Icon className={'fa fa-clipboard'} />
                    Lat: {props.latlng.lat.toFixed(5)}, Long: {props.latlng.lng.toFixed(5)}
                </React.Fragment>
            )}
        </ShareCoordinate>
    );
};

export default CoordinateCopyControl;

const Icon = styled.i`
    color: white;
    font-size: 16px;
    margin-right: 8px;
    pointer-events: all;
`;

const ShareCoordinate = styled.div`
    margin: auto;
    width: max-content;
    display: flex;
    flex-direction: row;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.3);

    &:hover {
        color: #eed926;
        background-color: rgba(255, 255, 255, 0.1);
    }

    &:hover ${Icon} {
        color: #eed926;
    }
`;
