import { LeafletContextInterface, createElementObject } from '@react-leaflet/core';
import L from 'leaflet';
import { translateLatLngBounds } from '../Text/text-annotation-util';
import { MarkerProps } from 'react-leaflet';

interface EditRectangleElementProps {
    bounds: L.LatLngBounds;
    rectangleElement: Readonly<{ instance: L.Rectangle; context: Readonly<{ map: L.Map }> }>;
    context: LeafletContextInterface;
}

const focusOptions: L.PolylineOptions = {
    color: 'transparent',
    weight: 3,
    interactive: false,
    fill: false,
};

const dragOptions: L.PolylineOptions = {
    color: 'transparent',
    interactive: true,
    fill: true,
    fillColor: 'transparent',
    bubblingMouseEvents: false,
};

const dragMarkerOptions: L.MarkerOptions = {
    draggable: true,
    bubblingMouseEvents: false,
    icon: new L.Icon({
        iconSize: new L.Point(16, 16),
        iconUrl: '/assets/aoi-handle.png',
        iconAnchor: new L.Point(8, 8),
    }),
};

interface RectangleResizeElementProps {
    rectangleElement: Readonly<{ instance: L.Rectangle; context: Readonly<{ map: L.Map }> }>;
    context: LeafletContextInterface;
}

const createCornerDragMarker = (position: L.LatLng, cursorClass: string, context: LeafletContextInterface) => {
    const markerElement = createElementObject<L.Marker, MarkerProps>(
        new L.Marker(position, dragMarkerOptions),
        context
    );

    markerElement.instance.on('mouseover', () => {
        L.DomUtil.addClass(context.map.getContainer(), cursorClass);
    });

    markerElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), cursorClass);
    });

    return markerElement;
};

export const svgTextBoxCornerResizeElement = (props: RectangleResizeElementProps, context: LeafletContextInterface) => {
    const rectangleResizeLayer = new L.LayerGroup();
    const rectangleResizeLayerElement = createElementObject<L.LayerGroup>(rectangleResizeLayer, context);

    const northWestCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getNorthWest(),
        'leaflet-nw-resize',
        context
    );

    const northEastCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getNorthEast(),
        'leaflet-ne-resize',
        context
    );

    const southEastCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getSouthEast(),
        'leaflet-se-resize',
        context
    );
    const southWestCornerMarker = createCornerDragMarker(
        props.rectangleElement.instance.getBounds().getSouthWest(),
        'leaflet-sw-resize',
        context
    );

    const updateBounds = (newBounds: L.LatLngBounds, event: L.LeafletMouseEvent) => {
        northEastCornerMarker.instance.setLatLng(newBounds.getNorthEast());
        northWestCornerMarker.instance.setLatLng(newBounds.getNorthWest());
        southWestCornerMarker.instance.setLatLng(newBounds.getSouthWest());
        southEastCornerMarker.instance.setLatLng(newBounds.getSouthEast());
        props.rectangleElement.instance.setBounds(newBounds);
        props.rectangleElement.instance.fire('resize', { bounds: newBounds });
        props.rectangleElement.instance.fireEvent('resize-end', { bounds: newBounds });

    };

    const onDragNorthEastCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new L.LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getSouthWest());
        updateBounds(newBounds, e);
    };

    const onDragNorthWestCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new L.LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getSouthEast());
        updateBounds(newBounds, e);
    };

    const onDragSouthEastCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new L.LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getNorthWest());
        updateBounds(newBounds, e);
    };

    const onDragSouthWestCornerMarker = (e: L.LeafletMouseEvent) => {
        const newBounds = new L.LatLngBounds(e.latlng, props.rectangleElement.instance.getBounds().getNorthEast());
        updateBounds(newBounds, e);
    };

    const onDragStart = () => {
        props.rectangleElement.instance.setStyle({ opacity: 0.66 });
    };

    const onDragEnd = () => {
        const bounds = new L.LatLngBounds(
            southWestCornerMarker.instance.getLatLng(),
            northEastCornerMarker.instance.getLatLng()
        );
        props.rectangleElement.instance.setStyle({ opacity: 1 });
        //props.rectangleElement.instance.fireEvent('resize-end', { bounds: bounds });
    };

    props.rectangleElement.instance.on('update', () => {
        const bounds = props.rectangleElement.instance.getBounds();
        northEastCornerMarker.instance.setLatLng(bounds.getNorthEast());
        northWestCornerMarker.instance.setLatLng(bounds.getNorthWest());
        southWestCornerMarker.instance.setLatLng(bounds.getSouthWest());
        southEastCornerMarker.instance.setLatLng(bounds.getSouthEast());
    });

    rectangleResizeLayerElement.instance.on('add', () => {
        rectangleResizeLayerElement.instance.addLayer(northEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.addLayer(northWestCornerMarker.instance);
        rectangleResizeLayerElement.instance.addLayer(southEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.addLayer(southWestCornerMarker.instance);

        northEastCornerMarker.instance.on('drag', onDragNorthEastCornerMarker);
        northWestCornerMarker.instance.on('drag', onDragNorthWestCornerMarker);
        southEastCornerMarker.instance.on('drag', onDragSouthEastCornerMarker);
        southWestCornerMarker.instance.on('drag', onDragSouthWestCornerMarker);

        northWestCornerMarker.instance.on('dragstart', onDragStart);
        northEastCornerMarker.instance.on('dragstart', onDragStart);
        southEastCornerMarker.instance.on('dragstart', onDragStart);
        southWestCornerMarker.instance.on('dragstart', onDragStart);

        northWestCornerMarker.instance.on('dragend', onDragEnd);
        northEastCornerMarker.instance.on('dragend', onDragEnd);
        southEastCornerMarker.instance.on('dragend', onDragEnd);
        southWestCornerMarker.instance.on('dragend', onDragEnd);
    });

    rectangleResizeLayerElement.instance.on('remove', () => {
        rectangleResizeLayerElement.instance.removeLayer(northEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.removeLayer(northWestCornerMarker.instance);
        rectangleResizeLayerElement.instance.removeLayer(southEastCornerMarker.instance);
        rectangleResizeLayerElement.instance.removeLayer(southWestCornerMarker.instance);

        northEastCornerMarker.instance.off('drag', onDragNorthEastCornerMarker);
        northWestCornerMarker.instance.off('drag', onDragNorthWestCornerMarker);
        southEastCornerMarker.instance.off('drag', onDragSouthEastCornerMarker);
        southWestCornerMarker.instance.off('drag', onDragSouthWestCornerMarker);
    });

    return rectangleResizeLayerElement;
};

export const svgTextBoxResizeElement = (props: EditRectangleElementProps, context: LeafletContextInterface) => {
    const rectangleEditLayer = new L.LayerGroup();
    const rectangleEditLayerElement = createElementObject<L.LayerGroup>(rectangleEditLayer, context);

    const focusOutline = new L.Rectangle(props.rectangleElement.instance.getBounds(), focusOptions);
    const focusOutlineElement = createElementObject<L.Rectangle>(focusOutline, context);

    const dragRectangle = new L.Rectangle(props.rectangleElement.instance.getBounds(), dragOptions);
    const dragRectangleElement = createElementObject<L.Rectangle>(dragRectangle, context);

    const resizeElement = svgTextBoxCornerResizeElement({ rectangleElement: props.rectangleElement, context }, context);

    props.rectangleElement.instance.on('update', () => {
        dragRectangleElement.instance.setBounds(props.rectangleElement.instance.getBounds());
        focusOutlineElement.instance.setBounds(props.rectangleElement.instance.getBounds());
    });

    props.rectangleElement.instance.on('remove', () => {
        rectangleEditLayerElement.instance.fireEvent('remove');
    });

    rectangleEditLayerElement.instance.on('remove', () => {
        dragRectangleElement.instance.clearAllEventListeners();
        rectangleEditLayerElement.instance.clearLayers();
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
    });

    rectangleEditLayerElement.instance.on('add', () => {
        rectangleEditLayerElement.instance.addLayer(focusOutlineElement.instance);
        rectangleEditLayerElement.instance.addLayer(dragRectangleElement.instance);
        rectangleEditLayerElement.instance.addLayer(resizeElement.instance);

        dragRectangleElement.instance.on('mouseover', () => {
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-move');
        });

        dragRectangleElement.instance.on('mouseout', () => {
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
        });

        let lastLatLng: L.LatLng | undefined;
        const onDragStart = (e: L.LeafletMouseEvent) => {
            lastLatLng = e.latlng;
            dragRectangleElement.instance.on('mousemove', onDrag);
            dragRectangleElement.instance.on('mouseup', onDragEnd);
        };

        const onDrag = (e: L.LeafletMouseEvent) => {
            const imageOverlayBounds = props.rectangleElement.instance.getBounds();
            if (lastLatLng) {
                const newBounds = translateLatLngBounds(imageOverlayBounds, lastLatLng, e.latlng);
                lastLatLng = e.latlng;
                props.rectangleElement.instance.setBounds(newBounds);
                dragRectangleElement.instance.setBounds(newBounds);
                focusOutlineElement.instance.setBounds(newBounds);
                props.rectangleElement.instance.fire('update', e);
            }
        };

        const onDragEnd = () => {
            lastLatLng = undefined;
            dragRectangleElement.instance.off('mousemove', onDrag);
            dragRectangleElement.instance.off('mouseup', onDragEnd);
        };

        dragRectangleElement.instance.on('mousedown', onDragStart);
    });

    return rectangleEditLayerElement;
};
