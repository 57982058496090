import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiUser from '../../api/api-user';
import { AutocompleteDTO } from '../../api/model';
import UserHelper from '../../lib/user-helper';

interface SearchResultsAutocompleteChipProps {
    autocomplete: AutocompleteDTO;
    onSelectAutocomplete: (location: AutocompleteDTO) => void;
}

const SearchResultsAutocompleteChip = (props: SearchResultsAutocompleteChipProps) => {
    const onClick = () => {
        props.onSelectAutocomplete(props.autocomplete);
    };

    const [userAvatarUrl, setUserAvatarUrl] = useState('/assets/avatars/Avatar1.png');

    useEffect(() => {
        if (props.autocomplete.type === 'USER' && props.autocomplete.userId) {
            ApiUser.getUserProfile(props.autocomplete.userId).then((res) => {
                if (res.avatarUrl) {
                    setUserAvatarUrl(res.avatarUrl);
                } else {
                    const defaultAvatarIcon = UserHelper.getDefaultAvatar(res.userId);
                    setUserAvatarUrl(defaultAvatarIcon);
                }
            });
        }
    }, [props.autocomplete]);

    if (props.autocomplete.type === 'USER') {
        return (
            <TagItem onClick={onClick} title={`Open ${props.autocomplete.title}'s profile`}>
                <UserIcon src={userAvatarUrl} />
                <TagText>{props.autocomplete.title}</TagText>
            </TagItem>
        );
    }

    if (props.autocomplete.type === 'COORDINATE') {
        return (
            <TagItem onClick={onClick} title={`Open the map at ${props.autocomplete.title}`}>
                <LocationIcon />
                <TagText>Coordinate: {props.autocomplete.title}</TagText>
            </TagItem>
        );
    }

    return (
        <TagItem onClick={onClick} title={`Open the map at ${props.autocomplete.title}`}>
            <LocationIcon />
            <TagText>{props.autocomplete.title}</TagText>
        </TagItem>
    );
};

export default SearchResultsAutocompleteChip;

const UserIcon = styled.img`
    width: 16px;
    height: 16px;
    margin: 3px 4px 0px 2px;
    border-radius: 8px;
`;

const LocationIcon = styled.div`
    cursor: pointer;
`;

const TagText = styled.p`
    display: inline-block;
    cursor: pointer;
`;

const TagItem = styled.span`
    height: max-content;
    border-radius: 6px;
    background: rgb(22, 27, 34);
    border: 1px solid rgb(48, 54, 61);
    margin: 4px 4px 0px 0px;
    text-align: center;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    padding: 2px;
    background: #eed923;
    user-select: none;

    ${LocationIcon} {
        display: inline-block;
        background-image: url('/assets/explore-dropdown/marker-icon-black.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 17px;
        height: 14px;
        padding: 0;
        margin: 3px -3px 4px 4px;
    }

    ${TagText} {
        padding-right: 4px;
        color: black;
        position: relative;
        margin-bottom: 0px;
        overflow: hidden;
    }
    &:hover {
        opacity: 0.8;
        transform: scale(1.05);
    }
`;
