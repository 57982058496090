import React from 'react';
import { LatLng, Icon, LeafletEvent } from 'leaflet';
import { Marker } from 'react-leaflet';
import { selectDroneImageManualPosition } from '../../../store/Map/Uploads/selectors';
import { actionSetDroneImageManualPosition } from '../../../store/Map/Uploads/actions';
import Analytics from '../../../lib/user-analytics';
import { useDispatch, useSelector } from 'react-redux';

const DroneUploadManualMarker = () => {
    const manualPosition = useSelector(selectDroneImageManualPosition);

    const dispatch = useDispatch();
    const setDroneImagePosition = (position: LatLng | undefined) =>
        dispatch(actionSetDroneImageManualPosition(position));

    const onDragMarkerEnd = (e: LeafletEvent) => {
        if (!e?.target?.getLatLng()) return;
        const markerLatLng = e.target.getLatLng();
        if (markerLatLng) {
            setDroneImagePosition(markerLatLng);
            Analytics.Event('Main View', 'Drone marker manually set for upload', markerLatLng);
        }
    };

    if (!manualPosition) return <React.Fragment />;

    const icon = new Icon({
        iconUrl: '/assets/marker-icon.png',
        iconSize: [25, 41],
        className: 'marker-large',
        iconAnchor: [14, 40],
    });

    return (
        <Marker
            eventHandlers={{ dragend: (e) => onDragMarkerEnd(e) }}
            position={manualPosition}
            icon={icon}
            draggable={true}
        />
    );
};

export default DroneUploadManualMarker;
