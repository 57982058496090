import L from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import Arrow from './arrow';
import { v4 as uuidv4 } from 'uuid';
import { createArrowHeadElement } from './arrow-head';

interface ArrowBuilderProps {
    onCreateArrow: (arrow: Arrow) => void;
}

const arrowCreateOptions: L.PolylineOptions = {
    color: '#3388ff',
    interactive: false,
};

const createArrowBuilder = (props: ArrowBuilderProps, context: LeafletContextInterface) => {
    let startLatLng: L.LatLng = new L.LatLng(0, 0);
    let endLatLng: L.LatLng = new L.LatLng(0, 0.0);

    const arrowPath = new L.Polyline([startLatLng, endLatLng], arrowCreateOptions);
    const arrowPathElement = createElementObject<L.Polyline, ArrowBuilderProps>(arrowPath, context);

    const id = uuidv4();

    const arrowHead = createArrowHeadElement(
        {
            id: '0',
            arrowShaftElement: arrowPathElement,
        },
        context
    );

    arrowPathElement.instance.on('add', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.on('mousedown', (e: L.LeafletMouseEvent) => {
            startLatLng = e.latlng;
            context.map.dragging.disable();

            context.map.addLayer(arrowHead.instance);

            arrowHead.instance.setLatLng(startLatLng);
            context.map.addLayer(arrowHead.instance);

            context.map.on('mousemove', (e: L.LeafletMouseEvent) => {
                endLatLng = e.latlng;
                arrowPathElement.instance.setLatLngs([startLatLng, endLatLng]);
                arrowHead.instance.setLatLng(endLatLng);
                arrowHead.instance.fire('update', { ...e, startLatLng: startLatLng, endLatLng: endLatLng });
            });

            context.map.on('mouseup', () => {
                L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');

                context.map.off('mousemove');
                context.map.off('mousedown');
                context.map.off('mouseup');
                context.map.dragging.enable();

                const newArrow: Arrow = {
                    id: id,
                    startLatLng: startLatLng,
                    endLatLng: endLatLng,
                    options: arrowPathElement.instance.options,
                };

                props.onCreateArrow(newArrow);
            });
        });

        arrowPathElement.instance.on('remove', () => {
            context.map.removeLayer(arrowHead.instance);
            context.map.removeLayer(arrowPathElement.instance);
        });
    });

    return arrowPathElement;
};

const useArrowBuilder = createElementHook<L.Polyline, ArrowBuilderProps, LeafletContextInterface>(createArrowBuilder);

const ArrowBuilder = (props: ArrowBuilderProps) => {
    const context = useLeafletContext();
    const arrowBuilder = useArrowBuilder(props, context);
    useLayerLifecycle(arrowBuilder.current, context);
    return null;
};

export default ArrowBuilder;
