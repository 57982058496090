import React from 'react';
import AnnotationPopup from '../annotation-popup';
import Marker from './marker';
import styled from 'styled-components';
import MarkerColorSelect from './marker-color-select';
import MarkerSelectModal from './marker-select-modal';

interface MarkerControlPopupProps {
    marker: Marker;
    icon: L.Icon;
    setIcon: (icon: L.Icon) => void;
    onDelete: () => void;
    onUpdate: (options: L.MarkerOptions) => void;
}

const MarkerControlPopup = (props: MarkerControlPopupProps) => {
    return (
        <AnnotationPopup title="Marker" onDelete={() => props.onDelete()}>
            <MarkerPopupContainer>
                <MarkerColorSelect
                    marker={props.marker}
                    onUpdate={props.onUpdate}
                    icon={props.icon}
                    onIconChange={(icon) => {
                        props.setIcon(icon);
                    }}
                />
                <MarkerSelectModal
                    marker={props.marker}
                    onUpdate={props.onUpdate}
                    icon={props.icon}
                    onIconChange={(icon) => props.setIcon(icon)}
                />
            </MarkerPopupContainer>
        </AnnotationPopup>
    );
};

export default MarkerControlPopup;

const MarkerPopupContainer = styled.div`
    width: 180px;
`;
