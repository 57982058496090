import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledButton } from '../../../Shared/styled-button';
import ScreenShotModal from './screenshot-modal';
import AnnotationControl from '../annotation-control';

const ScreenShotControl = () => {
    const [screenShotModalOpen, setScreenShotModalOpen] = useState<boolean>(false);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="isolated"
                hideControl={false}
                imageUrl="/assets/annotations/share-icon-white.svg"
                name="Screenshot"
                onClick={() => setScreenShotModalOpen(true)}
            />

            <ScreenShotModal isOpen={screenShotModalOpen} toggle={() => setScreenShotModalOpen(false)} />
        </React.Fragment>
    );
};

export default ScreenShotControl;
