import { RgbColor, RgbColorPicker } from 'react-colorful';
import styled from 'styled-components';

interface CustomColorPopupProps {
    color: string;
    setColor: (color: string) => void;
    onClose: () => void;
}

const CustomColorPopup = (props: CustomColorPopupProps) => {
    const colorToRGB = (color: string): RgbColor => {
        const rgba: RgbColor = {
            r: parseInt(color.slice(1, 3), 16),
            g: parseInt(color.slice(3, 5), 16),
            b: parseInt(color.slice(5, 7), 16),
        };
        return rgba;
    };

    const rgbToColor = (rgb: RgbColor): string => {
        return `#${rgb.r.toString(16)}${rgb.g.toString(16)}${rgb.b.toString(16)}`;
    };

    return (
        <Container>
            <TitleContainer>
                <Title>Color picker</Title>
                <CloseIcon className="fa fa-close" onClick={() => props.onClose()} />
            </TitleContainer>
            <ColorPickerContainer>
                <RgbColorPicker
                    color={colorToRGB(props.color)}
                    onChange={(newColor) => {
                        props.setColor(rgbToColor(newColor));
                    }}
                />
            </ColorPickerContainer>
        </Container>
    );
};

export default CustomColorPopup;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 330px;
    width: auto;
    display: flex;
    flex-direction: column;

    background-color: rgba(20, 20, 19, 1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    margin: 0;
    padding: 0;
`;

const ColorPickerContainer = styled.div`
    margin: 8px;
`;

const TitleContainer = styled.div`
    padding: 7px 0 7px 0;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: row;
`;

const Title = styled.h4`
    color: white;
    display: block;
    text-align: left;
    font-size: 1.1rem;
    padding: 0 0 0 5px;
    margin: 0;
`;

const CloseIcon = styled.i`
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.1rem;
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 6px;
`;
