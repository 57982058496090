import L from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import Highlighter from './highlighter';
import { v4 as uuidv4 } from 'uuid';

interface HighlighterBuilderProps {
    onCreateHighlighter: (highlighter: Highlighter) => void;
}

const highlighterPathOptions: L.PolylineOptions = {
    color: '#3388ff',
    opacity: 0.3,
    weight: 30,
    fill: false,
    lineCap: 'square',
    lineJoin: 'miter',
};

const createHighlighterBuilder = (props: HighlighterBuilderProps, context: LeafletContextInterface) => {
    const highlighterPath = new L.Polyline([], highlighterPathOptions);
    const highlighterPathElement = createElementObject<L.Polyline, HighlighterBuilderProps>(highlighterPath, context);

    highlighterPathElement.instance.on('add', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
        context.map.on('mousedown', onMouseDown);
    });

    const onMouseDown = (e: L.LeafletMouseEvent) => {
        context.map.dragging.disable();
        highlighterPathElement.instance.addLatLng(e.latlng);
        context.map.on('mousemove', onMouseMove);
        context.map.on('mouseup', onMouseUp);
    };

    const onMouseMove = (e: L.LeafletMouseEvent) => {
        highlighterPathElement.instance.addLatLng(e.latlng);
    };

    const onMouseUp = () => {
        const highlighter: Highlighter = {
            id: uuidv4(),
            positions: highlighterPathElement.instance.getLatLngs() as L.LatLng[],
            options: highlighterPathOptions,
        };

        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
        context.map.dragging.enable();
        props.onCreateHighlighter(highlighter);
        context.map.off('mousemove', onMouseMove);
        context.map.off('mouseup', onMouseUp);
        context.map.off('mousedown', onMouseDown);
    };

    return highlighterPathElement;
};

const useHighlighterBuilderElement = createElementHook(createHighlighterBuilder);

const HighlighterBuilder = (props: HighlighterBuilderProps) => {
    const context = useLeafletContext();
    const highlighterBuilderElement = useHighlighterBuilderElement(props, context);
    useLayerLifecycle(highlighterBuilderElement.current, context);

    return null;
};

export default HighlighterBuilder;
