import L from 'leaflet';
import { LeafletContextInterface, createElementObject, extendContext } from '@react-leaflet/core';

/**
 * The focus element is only visible when the cursor is close to the image.
 * It is used to highlight the image overlay that is about to be selected.
 */
interface FocusElementProps {
    bounds: L.LatLngBounds;
    imageOverlayElement: Readonly<{ instance: L.ImageOverlay; context: Readonly<{ map: L.Map }> }>;
    context: LeafletContextInterface;
}

const focusOptions: L.PolylineOptions = {
    color: '#3388ff',
    weight: 3,
    interactive: false,
    fill: false,
};

export const createFocusElement = (props: FocusElementProps, context: LeafletContextInterface) => {
    const focusOutline = new L.Rectangle(props.bounds, focusOptions);
    const focusOutlineElement = createElementObject<L.Rectangle>(
        focusOutline,
        extendContext(context, { overlayContainer: focusOutline })
    );

    // We don't want the focus element to be interactive, since it may interfere with other event handlers
    // Instead, the event is fired by the ghost element and we just change the cursor
    focusOutlineElement.instance.on('mouseover', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-interactive');
    });

    focusOutlineElement.instance.on('mouseout', () => {
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-interactive');
    });

    focusOutlineElement.instance.on('click', (e: L.LeafletMouseEvent) => {
        props.imageOverlayElement.instance.fire('click', e);
    });

    return focusOutlineElement;
};
