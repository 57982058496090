import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, FormGroup, Input, Label, ModalFooter, Spinner } from 'reactstrap';
import styled from 'styled-components';
import ApiUser from '../../../api/api-user';
import Analytics from '../../../lib/user-analytics';
import UserHelper from '../../../lib/user-helper';
import { selectMyProfile } from '../../../store/Account/selectors';
import ProfileDeleteModal from '../Profile/profile-delete-modal';
import NavigationPanel from '../Shared/navigation-panel';
import { StyledButton } from '../../Shared/styled-button';
import SuccessResetPasswordModal from '../Profile/success-reset-password-modal';
import DashboardContainer from '../Shared/dashboard-container';

const AccountSettings = () => {
    const [oldPassword, setOldPassword] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

    const handleSubmit = () => {
        setIsSubmitting(true);

        if (!UserHelper.isPasswordValid(password)) {
            setIsSubmitting(false);
            setError('Password must be at least 6 characters');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        if (oldPassword === confirmPassword) {
            setIsSubmitting(false);
            setError('Your new password is not valid');
            setPassword('');
            setOldPassword('');
            setConfirmPassword('');
            return;
        }

        if (password !== confirmPassword) {
            setIsSubmitting(false);
            setError('Confirm password does not match');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        if (oldPassword !== oldPassword) {
            setIsSubmitting(false);
            setError('Incorrect old password');
            setPassword('');
            setConfirmPassword('');
            return;
        }

        ApiUser.updatePassword(oldPassword, confirmPassword)
            .then(() => {
                setIsSubmitting(false);
                setHasSubmitted(true);
                setPassword('');
                setOldPassword('');
                setConfirmPassword('');
                Analytics.Event('My Dashboard', 'Clicked to reset password');
            })
            .catch((err) => {
                setError(err.toString().replace('Error: 400: ', ''));
                setOldPassword('');
                setPassword('');
                setConfirmPassword('');
                setIsSubmitting(false);
            });
    };

    const [error, setError] = useState<string | undefined>(undefined);
    const [openDeleteAccount, setOpenDeleteAccount] = useState<boolean>(false);
    const toggleDeleteAccount = () => setOpenDeleteAccount(!openDeleteAccount);
    const myProfile = useSelector(selectMyProfile);

    return (
        <React.Fragment>
            <NavigationPanel label="Account Settings"></NavigationPanel>
            <DashboardContainer>
                <ResetPasswordContainer>
                    <h3>Reset Password</h3>
                    <p>Password must be at least 6 characters</p>
                    <ResetPasswordBody>
                        <Form>
                            <FormGroup>
                                <PasswordInputLabel for="oldPassword">Original Password</PasswordInputLabel>
                                <PasswordInput
                                    data-sentry-block
                                    autoComplete="current-password"
                                    type="password"
                                    name={oldPassword}
                                    placeholder="Enter your original password..."
                                    value={oldPassword}
                                    data-testid="original-password"
                                    onChange={(e) => {
                                        setOldPassword(e.target.value);
                                        setError(undefined);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <PasswordInputLabel for="password">New Password</PasswordInputLabel>
                                <PasswordInput
                                    data-sentry-block
                                    autoComplete="new-password"
                                    type="password"
                                    name={password}
                                    placeholder="Enter your new password..."
                                    data-testid="new-password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setError(undefined);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <PasswordInputLabel for="confirmPassword">Confirm Password</PasswordInputLabel>
                                <PasswordInput
                                    data-sentry-block
                                    autoComplete="new-password"
                                    type="password"
                                    name={confirmPassword}
                                    placeholder="Confirm your new password"
                                    data-testid="confirm-password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value);
                                        setError(undefined);
                                    }}
                                />
                            </FormGroup>
                        </Form>
                        <ResetModalFooter>
                            {isSubmitting && !hasSubmitted && (
                                <ResetPasswordSubmittingContainer>
                                    <Spinner style={{ color: '#EED926', height: '40px', width: '40px' }} />
                                </ResetPasswordSubmittingContainer>
                            )}
                            {hasSubmitted && !isSubmitting && (
                                <SuccessResetPasswordModal
                                    openSuccessModal={hasSubmitted}
                                    toggle={() => setHasSubmitted(false)}
                                    email={myProfile?.email}
                                    onClose={() => {
                                        setHasSubmitted(false);
                                    }}
                                />
                            )}
                            {error && (
                                <PasswordModalError>
                                    <PasswordModalErrorIcon />
                                    {error}
                                </PasswordModalError>
                            )}
                            {!isSubmitting && !hasSubmitted && (
                                <ResetButtonsContainer>
                                    <ResetSubmitButton onClick={() => handleSubmit()}>
                                        CHANGE PASSWORD
                                    </ResetSubmitButton>
                                </ResetButtonsContainer>
                            )}
                        </ResetModalFooter>
                    </ResetPasswordBody>
                </ResetPasswordContainer>

                <DeleteAccountContainer>
                    <h3>Delete Account</h3>
                    <DeleteNotification>
                        <p>Once you delete your account, there is no going back. Please be certain.</p>
                        <DeleteAccountButton onClick={toggleDeleteAccount}>DELETE</DeleteAccountButton>
                        {openDeleteAccount && (
                            <ProfileDeleteModal userId={myProfile?.userId} toggle={toggleDeleteAccount} />
                        )}
                    </DeleteNotification>
                </DeleteAccountContainer>
            </DashboardContainer>
        </React.Fragment>
    );
};

export default AccountSettings;

const ResetPasswordContainer = styled.div`
    overflow-x: hidden;
    h3 {
        color: white;
        -webkit-text-fill-color: white;
        text-align: left;
        padding: 24px 0px 0px 18px;
    }
    p {
        color: rgba(255, 255, 255, 0.3);
        text-decoration: italic;
        padding: 5px 0px 0px 22px;
    }
`;

const ResetPasswordBody = styled.div`
    display: block;
    align-self: center;
    text-align: left;
    justify-content: center;
    overflow: hidden;
    margin: 0px 150px 0px 150px;

    @media only screen and (max-width: 600px) {
        text-align: center;
        margin: 0px;
    }
`;

const ResetButtonsContainer = styled.div`
    display: inline-block;
    align-content: center;
    width: 100%;
    margin-top: 10px;
`;

const PasswordInputLabel = styled(Label)`
    float: left;
    color: rgba(255, 255, 255, 0.87);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.87);
    margin: 0px 15px 0px 24px;
    width: 120px;

    @media only screen and (max-width: 600px) {
        float: center;
        margin: 0px 20px 0px 20px;
        width: 350px;
    }
`;

const PasswordInput = styled(Input)`
    background-color: transparent;
    max-width: 50%;
    margin: 0px 15px 0px 24px;
    padding-left: 24px;
    display: inline-block;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.6) !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;

    @media only screen and (max-width: 600px) {
        max-width: 300px;
        margin: 0px 50px 0px 40px;
        display: block;
    }

    :hover,
    :focus {
        background-color: transparent;
        border: 1px solid rgba(238, 217, 38, 0.6);
        box-shadow: 0 0 0 0.1rem rgba(238, 217, 38, 0.3);
        -webkit-text-fill-color: rgba(255, 255, 255, 0.6) !important;
    }

    @media only screen and (max-width: 600px) {
        max-width: 300px;
        margin: 0px 50px 0px 40px;
        display: block;
    }
`;

const ResetModalFooter = styled(ModalFooter)`
    border-top: none;
    background-color: transparent;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100vh;

    @media screen and (max-width: 600px) {
        padding: 0px;
        width: 100%;
    }
`;

const ResetSubmitButton = styled(StyledButton)`
    margin: 0px 0px 0px 18px;
    display: inline-block;
    padding: 10px;

    &:hover {
        opacity: 0.6;
    }

    @media only screen and (min-width: 601px) and (max-width: 1024px) {
        margin: 0 auto;
    }
`;

const ResetPasswordSubmittingContainer = styled.div`
    width: 100%;
    text-align: center;
`;

const PasswordModalError = styled.div`
    color: red !important;
    -webkit-text-fill-color: red;
    padding: 0px;
    margin: 0px 0px 0px 18px;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media only screen and (min-width: 601px) and (max-width: 1024px) {
        margin: 0px;
    }
`;

const PasswordModalErrorIcon = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 17V29' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20 11V13' stroke='%23FF0000' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='20' cy='20' r='19.5' stroke='%23FF0000'/%3E%3C/svg%3E ");
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin: 0px 10px 0px 160px;
    float: left;

    @media only screen and (max-width: 600px) {
        margin: 0px -20px 0px 30px;
    }
`;

const DeleteAccountContainer = styled.div`
    margin-top: 50px;
    align-items: flex-end;

    h3 {
        color: white;
        -webkit-text-fill-color: red;
        text-align: left;
        padding: 24px 0px 0px 18px;
        overflow: hidden;
        display: block;
    }

    @media screen and (max-width: 600px) {
        align-items: center;
    }
`;

const DeleteNotification = styled.div`
    padding-bottom: 20px;
    height: 10vh;

    p {
        color: #fff;
        padding: 15px 0px 0px 22px;
        margin: 0px;
        float: left;

        @media only screen and (max-width: 600px) {
            margin: 6px;
        }
    }

    @media screen and (max-width: 600px) {
        margin: 6px;

        p {
            padding-bottom: 20px;
        }
    }
`;

const DeleteAccountButton = styled.button`
    background-color: #272b33;
    border: 1px solid red;
    border-radius: 4px;
    padding: 8px 25px;
    margin: 12px 50px 12px 10px;
    float: right;
    color: red;
    font-weight: 500;
    display: flex;

    &:hover {
        opacity: 0.6;
        background-color: red;
        color: #fff;
    }

    @media only screen and (max-width: 600px) {
        overflow-x: none;
        float: none;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
`;
