import L from 'leaflet';
import { LeafletContextInterface } from '@react-leaflet/core';

export const calculateTextSize = (
    text: string,
    fontFamily: string,
    fontSize: string,
    padding: number
): [number, number] => {
    const body = document.getElementsByTagName('body')[0];
    const dummyDiv = document.createElement('div');

    dummyDiv.style.fontFamily = fontFamily;
    dummyDiv.style.fontSize = fontSize;
    dummyDiv.style.fontWeight = 'normal';
    dummyDiv.style.lineHeight = 'normal';
    dummyDiv.style.position = 'absolute';
    dummyDiv.style.visibility = 'hidden';
    dummyDiv.style.left = '-999px';
    dummyDiv.style.top = '-999px';
    dummyDiv.style.width = 'auto';
    dummyDiv.style.height = 'auto';
    dummyDiv.style.wordBreak = 'normal';

    const splitText = text.split('\n');

    const dummyText = document.createTextNode(text);

    dummyDiv.appendChild(dummyText);
    body.appendChild(dummyDiv);
    const width = dummyDiv.clientWidth + 1 + padding * 2;
    const height = (dummyDiv.clientHeight + 1) * splitText.length + padding * 2;
    body.removeChild(dummyDiv);

    return [width, height];
};

export const createSVG = (width: number, height: number): SVGSVGElement => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
    return svg;
};

export const createSVGText = (text: string, textColor: string, fontSize: string): SVGTextElement => {
    const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    textElement.setAttribute('x', '0');
    textElement.setAttribute('y', '0');
    textElement.setAttribute('font-size', fontSize);
    textElement.setAttribute('fill', textColor);

    const splitText = text.split('\n');
    splitText.forEach((line) => {
        const tSpan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        tSpan.setAttribute('x', `0`);
        tSpan.setAttribute('dy', `${fontSize}`);
        tSpan.textContent = line;
        textElement.appendChild(tSpan);
    });

    return textElement;
};

export const svgSize = (text, fontSize): DOMRect => {
    const temporarySVG = createSVG(10, 10);
    //temporarySVG.setAttribute('visibility', 'hidden');
    const temporaryText = createSVGText(text, 'white', fontSize);
    temporarySVG.appendChild(temporaryText);
    document.body.appendChild(temporarySVG);
    const bbox = temporarySVG.getBBox();
    document.body.removeChild(temporarySVG);
    return bbox;
};

export const svgForText = (
    text: string,
    fontSize: string,
    textColor: string,
    backgroundColor: string,
    width: number,
    height: number,
    padding: number
): SVGElement => {
    const splitText = text.split('\n');

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('version', '1.1');
    svg.setAttribute('viewBox', `0 0 ${width} ${height}`);
    svg.setAttribute('overflow', 'visible');

    const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    textElement.setAttribute('x', '0');
    textElement.setAttribute('y', '0');

    splitText.forEach((line) => {
        const tSpan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        tSpan.setAttribute('x', `0`);
        tSpan.setAttribute('dy', '1em');
        tSpan.textContent = line;
        textElement.appendChild(tSpan);
    });

    svg.appendChild(textElement);

    return svg;
};

export const boundsForText = (
    context: LeafletContextInterface,
    position: L.LatLng,
    textSize: [number, number]
): L.LatLngBounds => {
    const topLeft = context.map.latLngToContainerPoint(position);
    const bottomRight = new L.Point(topLeft.x + textSize[0], topLeft.y + textSize[1]);

    const southEast = context.map.containerPointToLatLng(bottomRight);
    const southWest = new L.LatLng(southEast.lat, position.lng);
    const northEast = new L.LatLng(position.lat, southEast.lng);

    const bounds = new L.LatLngBounds(southWest, northEast);
    return bounds;
};

export const translateLatLngBounds = (
    bounds: L.LatLngBounds,
    startPoint: L.LatLng,
    endPoint: L.LatLng
): L.LatLngBounds => {
    const latDiff = startPoint.lat - endPoint.lat;
    const lngDiff = startPoint.lng - endPoint.lng;

    const north = bounds.getNorth() - latDiff;
    const south = bounds.getSouth() - latDiff;
    const west = bounds.getWest() - lngDiff;
    const east = bounds.getEast() - lngDiff;

    return new L.LatLngBounds(new L.LatLng(south, west), new L.LatLng(north, east));
};
