import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useMap, ImageOverlay as LeafletImageOverlay } from 'react-leaflet';
import styled from 'styled-components';
import SoarModal from '../../../Shared/soar-modal';
import { TransparentButton } from '../../../Shared/styled-button';
import AnnotationControl from '../annotation-control';
import ImageAnnotation from './image-annotation';
import ImageAnnotationPopup from './image-annotation-popup';
import { calculateInitialBounds } from './image-control-util';
import { default as ImageObject } from './image';
import { v4 as uuidv4 } from 'uuid';

interface ImageControlProps {
    hideControl: boolean;
}

const ImageControl = (props: ImageControlProps) => {
    const map = useMap();
    const [showOpenImageDialog, setShowOpenImageDialog] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const [imageAnnotations, setImageAnnotations] = useState<ImageObject[]>([]);

    useEffect(() => {
        if (file) {
            const img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.addEventListener('load', () => {
                const initialBounds = calculateInitialBounds(map, img);
                const annotation: ImageObject = {
                    id: uuidv4(),
                    image: img,
                    bounds: initialBounds,
                    opacity: 1,
                };
                setImageAnnotations((current) => [...current, annotation]);
            });
        }
    }, [file, map]);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="end"
                hideControl={props.hideControl}
                imageUrl="/assets/annotations/icon-image.svg"
                name="Image"
                onClick={() => {
                    setShowOpenImageDialog(true);
                }}
            />

            <SoarModal isOpen={showOpenImageDialog} title="Select image" toggle={() => setShowOpenImageDialog(false)}>
                <ModalContent>
                    <Dropzone
                        accept="image/jpeg, image/png, image/gif, image/bmp, image/tiff, image/webp"
                        style={{
                            margin: '8px',
                            border: '1px dashed rgba(255, 255, 255, 0.6)',
                            borderRadius: '6px',
                            padding: '12px',
                        }}
                        activeStyle={{
                            border: '1px dashed #EED926',
                        }}
                        onDrop={(acceptedFiles: File[]) => {
                            setShowOpenImageDialog(false);
                            setFile(acceptedFiles[0]);
                        }}
                    >
                        <UploadIcon />
                        <UploadText>Drag and drop your image here</UploadText>
                        <UploadText className="italic">or</UploadText>
                        <UploadButton>
                            <TransparentButton>CHOOSE FILE</TransparentButton>
                        </UploadButton>
                    </Dropzone>
                    <DropzoneInstructionText>
                        Formats we currently support: JPG / PNG / GIF / BMP / TIFF / WEBP
                    </DropzoneInstructionText>
                </ModalContent>
            </SoarModal>

            {props.hideControl &&
                imageAnnotations.map((imageAnnotation) => {
                    return (
                        <LeafletImageOverlay
                            key={imageAnnotation.id}
                            url={imageAnnotation.image.src}
                            bounds={imageAnnotation.bounds}
                            opacity={imageAnnotation.opacity}
                        />
                    );
                })}

            {!props.hideControl &&
                imageAnnotations.map((imageAnnotation) => {
                    return (
                        <ImageAnnotation
                            key={imageAnnotation.id}
                            imageAnnotation={imageAnnotation}
                            onUpdateImageBoundingBox={(bounds) => {
                                setImageAnnotations((current) => {
                                    const index = current.findIndex((x) => x.id === imageAnnotation.id);
                                    const newState = [...current];
                                    newState[index].bounds = bounds;
                                    return newState;
                                });
                            }}
                            url={imageAnnotation.image.src}
                            bounds={imageAnnotation.bounds}
                            opacity={imageAnnotation.opacity}
                            interactive={props.hideControl}
                        >
                            <ImageAnnotationPopup
                                image={imageAnnotation}
                                onDeleteImage={() => {
                                    // remove from state
                                    setImageAnnotations((current) => {
                                        return current.filter((x) => x.id !== imageAnnotation.id);
                                    });
                                }}
                                opacity={imageAnnotation.opacity}
                                handleOpacity={(opacity) => {
                                    setImageAnnotations((current) => {
                                        const index = current.findIndex((x) => x.id === imageAnnotation.id);
                                        const newState = [...current];
                                        newState[index].opacity = opacity;
                                        return newState;
                                    });
                                }}
                            />
                        </ImageAnnotation>
                    );
                })}
        </React.Fragment>
    );
};

export default ImageControl;

const ModalContent = styled.div`
    flex: 1 1 auto;
    margin: 0px 2px 0px 2px;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
`;

const UploadIcon = styled.div`
    margin: 15px auto;
    background-position: center;
    background-repeat: no-repeat;
    width: 150px;
    height: 100px;
    cursor: pointer;
    background-image: url('/assets/upload-icon.svg');
`;

const UploadText = styled.div`
    color: white;
    text-align: center;
    font-size: 16px;
    margin: 0;

    &.italic {
        font-style: italic;
    }
`;

const UploadButton = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
`;

const DropzoneInstructionText = styled.p`
    color: rgba(255, 255, 255, 0.5);
    margin: 12px 8px 8px 8px;
    text-align: left;
`;
