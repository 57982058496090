import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ListingDTO } from '../../../api/model';
import UriHelper from '../../../lib/uri-helper';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { actionSetResetCommentSection } from '../../../store/SocialMapping/actions';
import ActiveListingCommentInput from '../../Drawer/Maps/Comments/comment-input';
import { CommentList } from '../../Drawer/Maps/Comments/comment-list';
import ShareComponent from '../../Drawer/Maps/Sharing/map-options';
import { MarkdownViewer } from '../../Shared/markdown-viewer';
import ActiveMapOpacitySlider from './active-map-opacity-slider';
import { BottomSheetState } from './bottom-sheet';
import Analytics from '../../../lib/user-analytics';
import ActiveMapLegend from '../../Drawer/Maps/active-map-legend';
import { actionUpdateActiveSearchPosition } from '../../../store/Search/actions';
import MapViewHistoryHelper from '../../../lib/map-view-history-helper';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
interface ActiveMapBottomSheetProps {
    listing: ListingDTO;
    bottomSheetState: BottomSheetState;
    onCloseMap: () => void;
    isClosing: boolean;
}

const ActiveMapBottomSheet = (props: ActiveMapBottomSheetProps) => {
    const { isClosing, onCloseMap } = props;
    const [commentUpdate, setCommentUpdate] = useState<boolean>(false);
    const [commentCount, setCommentCount] = useState<number | undefined>(undefined);

    const dispatch = useDispatch();

    const handleViewProfile = () => {
        UriHelper.navigateToDrawer(SideDrawerMode.PROFILE);
        dispatch(actionSetResetCommentSection(true));
        dispatch(actionActiveMapCleared());
    };

    useEffect(() => {
        if (props.listing) {
            MapViewHistoryHelper.addListingToViewedMapsHistory(props.listing);
        }
    }, [props.listing]);

    useEffect(() => {
        const closeActiveMap = () => {
            UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
            dispatch(actionSetResetCommentSection(true));
            onCloseMap();
            Analytics.Event('Mobile - Active Map Bottom Sheet', 'Clicked Close Active Map Bottom Sheet');
        };

        if (isClosing) {
            closeActiveMap();
        }
    }, [dispatch, isClosing, onCloseMap]);

    // Clears the marker if the user selects a map or has added a marker then leaves the map
    // Note: This is an opinionated choice to have the marker removed when a map is selected
    // and could remain on the map until the search input has actually been cleared.
    useEffect(() => {
        const handleClearMarker = () => {
            dispatch(actionUpdateActiveSearchPosition(undefined));
        };

        handleClearMarker();
        return () => {
            handleClearMarker();
        };
    }, [dispatch]);

    return (
        <React.Fragment>
            <TopContainer bottomSheetClosed={props.bottomSheetState === BottomSheetState.close}>
                <MapDetails>
                    <Title singleLine={props.bottomSheetState === BottomSheetState.close}>{props.listing.title}</Title>
                    <Author singleLine={props.bottomSheetState === BottomSheetState.close}>
                        by{' '}
                        <Link onClick={handleViewProfile} to={`/profile/${props.listing.userId}`}>
                            {props.listing.userName}
                        </Link>
                        {props.listing.communityModerator && props.listing.reputable ? (
                            <>🏆😎</>
                        ) : props.listing.communityModerator ? (
                            <>😎</>
                        ) : props.listing.reputable ? (
                            <>🏆</>
                        ) : null}

                    </Author>
                </MapDetails>
            </TopContainer>
            <Divider />
            <ActiveMapOpacitySlider tileLayer={props.listing} />
            <Divider />
            <ShareComponent />
            <Divider />
            <DescriptionContainer>
                <MarkdownViewer text={props.listing.description} />
            </DescriptionContainer>
            {props.listing.tags?.length ? (
                <TagContainer>
                    {props.listing.tags.map((tag, index) => {
                        return <Tag key={`${tag}-${index}`}>{tag}</Tag>;
                    })}
                </TagContainer>
            ) : null}
            <ActiveMapLegend listing={props.listing} />
            <Divider />
            <CommentList listingId={props.listing.id} commentUpdate={commentUpdate} setCommentCount={setCommentCount} />
            <CommentInputContainer>
                <ActiveListingCommentInput
                    commentCount={commentCount}
                    listingId={props.listing.id}
                    handleCommentUpdate={setCommentUpdate}
                />
            </CommentInputContainer>
        </React.Fragment>
    );
};

export default ActiveMapBottomSheet;

const TopContainer = styled.div<{ bottomSheetClosed: boolean }>`
    height: ${(props) => (props.bottomSheetClosed ? '100px' : 'auto')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const MapDetails = styled.div`
    width: calc(100% - 60px);
`;

const Title = styled.h1<{ singleLine: boolean }>`
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    width: calc(100%); /* The trick is here! */
    color: white;
    text-align: left;
    font-size: 22px;
    line-height: 26.4px;
    margin: 12px 0 0 12px;

    text-overflow: ellipsis;
    overflow: auto;
    white-space: ${(props) => (props.singleLine ? 'nowrap' : 'unset')};
    overflow-wrap: ${(props) => (props.singleLine ? 'unset' : 'break-word')};
`;

const Author = styled.p<{ singleLine: boolean }>`
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    text-overflow: ellipsis;
    width: calc(100%); /* The trick is here! */
    text-align: left;
    font-size: 16px;
    color: white;
    margin: 0 0 16px 12px;
    line-height: 21px;

    text-overflow: ${(props) => (props.singleLine ? 'ellipsis' : 'unset')};
    white-space: ${(props) => (props.singleLine ? 'nowrap' : 'unset')};
    overflow: ${(props) => (props.singleLine ? 'hidden' : 'unset')};

    a {
        color: #eed926 !important;
        text-decoration: underline !important;
        pointer-events: all;
    }
`;

const Divider = styled.div`
    height: 1px;
    width: 100vw;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const DescriptionContainer = styled.div`
    margin: 12px 12px 0 12px;
`;

const TagContainer = styled.div`
    flex: 0 1 auto;
    text-align: left;
    display: flex;
    margin: 0 12px 8px 12px;
    flex-wrap: wrap;
    max-height: auto;
    overflow: visible;
`;

const Tag = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 1px 3px 3px 3px;
    line-height: 14px;
    opacity: 0.5;
    margin: 0 2px 2px 0;
    color: white;
    width: auto;
    word-break: break-all;
    font-size: 12px;
`;

const CommentInputContainer = styled.div`
    padding-top: 2px;
    box-shadow: inset 0 2px 1px rgb(255 255 255 / 10%), inset 0 1px 0px rgb(255 255 255 / 10%);
`;
