import { LeafletContextInterface, createElementObject, extendContext } from '@react-leaflet/core';
import L, { LeafletMouseEvent } from 'leaflet';
import { circleMarkerOptions } from './circle';

interface CircleDragElementProps {
    circleElement: Readonly<{ instance: L.Circle; context: Readonly<{ map: L.Map }> }>;
    color?: string;
}

const CIRCLE_MARKER_RADIUS = 5;

export const createCircleDragElement = (props: CircleDragElementProps, context: LeafletContextInterface) => {
    const circleDragLayer = new L.LayerGroup();
    const circleDragLayerElement = createElementObject<L.LayerGroup>(circleDragLayer, context);

    const circleMarker = new L.CircleMarker(props.circleElement.instance.getLatLng(), {
        ...circleMarkerOptions,
        radius: CIRCLE_MARKER_RADIUS,
    });

    const circleMarkerElement = createElementObject<L.CircleMarker, CircleDragElementProps>(
        circleMarker,
        extendContext(context, { overlayContainer: circleMarker })
    );

    circleDragLayerElement.instance.on('add', () => {
        circleDragLayerElement.instance.addLayer(circleMarkerElement.instance);
        circleMarkerElement.instance.setStyle({ color: props.color, fillColor: props.color });

        circleMarkerElement.instance.on('mouseover', () => {
            L.DomUtil.addClass(context.map.getContainer(), 'leaflet-move');
        });

        circleMarkerElement.instance.on('mouseout', () => {
            L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-move');
        });

        const mousemoveListener = (e: LeafletMouseEvent) => {
            context.map.doubleClickZoom.disable();
            context.map.dragging.disable();
            props.circleElement.instance.fireEvent('drag', e);
            circleMarkerElement.instance.setLatLng(e.latlng);
        };

        const mouseupListener = () => {
            context.map.doubleClickZoom.enable();
            context.map.dragging.enable();
            context.map.off('mousemove', mousemoveListener);
            context.map.off('mouseup', mouseupListener);
        };

        circleMarkerElement.instance.on('mousedown', () => {
            context.map.doubleClickZoom.disable();
            context.map.dragging.disable();
            context.map.on('mousemove', mousemoveListener);
            context.map.on('mouseup', mouseupListener);
        });
    });

    circleDragLayerElement.instance.on('remove', () => {
        circleDragLayerElement.instance.removeLayer(circleMarkerElement.instance);
    });

    return circleDragLayerElement;
};
