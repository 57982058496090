import React, { useRef } from 'react';
import { Icon, LatLng } from 'leaflet';
import { Marker } from 'react-leaflet';

export enum SearchResultMarkerType {
    normal,
    large,
    small,
}

interface SearchResultMarkerIconProps {
    position: LatLng;
    type?: SearchResultMarkerType;
    color?: string;
}

const SearchResultMarkerIcon = (props: SearchResultMarkerIconProps) => {
    const markerRef = useRef<any>(null); // eslint-disable-line @typescript-eslint/no-explicit-any

    let markerImg;
    let markerSize;
    let iconAnchor;
    const color = props.color ? props.color : 'FF5A5A';
    const iconSVG = `data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 10 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.84995 0C2.28625 0 0.199951 2.033 0.199951 4.5325C0.199951 8.084 4.41285 11.751 4.59213 11.905C4.66602 11.9685 4.75798 12 4.84995 12C4.94192 12 5.03388 11.9685 5.10777 11.9055C5.28705 11.751 9.49995 8.084 9.49995 4.5325C9.49995 2.033 7.41365 0 4.84995 0ZM4.84995 7C3.4255 7 2.26662 5.8785 2.26662 4.5C2.26662 3.1215 3.4255 2 4.84995 2C6.2744 2 7.43328 3.1215 7.43328 4.5C7.43328 5.8785 6.2744 7 4.84995 7Z' fill='%23${color}'/%3E%3C/svg%3E%0A`;

    switch (props.type) {
        case SearchResultMarkerType.large:
            markerImg = iconSVG;
            markerSize = [15, 20];
            iconAnchor = [7, 20];
            break;

        case SearchResultMarkerType.small:
            markerImg = iconSVG;
            markerSize = [8, 10];
            iconAnchor = [3, 10];
            break;

        case SearchResultMarkerType.normal:
        default:
            markerImg = iconSVG;
            markerSize = [15, 20];
            iconAnchor = [7, 20];
            break;
    }

    const icon = new Icon({
        iconUrl: markerImg,
        iconSize: markerSize,
        iconAnchor: iconAnchor,
    });

    return <Marker ref={markerRef} icon={icon} position={props.position} />;
};

export default SearchResultMarkerIcon;
