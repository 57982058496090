export enum SideDrawerMode {
    NONE = 'NONE',

    HIDDEN = 'HIDDEN',

    MAPS = 'MAPS',
    SUBDOMAIN_MAPS = 'SUBDOMAIN_MAPS',
    YOUR_MAPS = 'YOUR_MAPS',
    MY_BOOKMARKS = 'MY_BOOKMARKS',
    SATELLITE = 'SATELLITE',
    SHARE_MAP = 'SHARE_MAP',

    PROFILE = 'PROFILE',
    PROFILE_ALL_MAPS = 'PROFILE_ALL_MAPS',

    // Satellites
    SATELLITE_SKYMAP50 = 'SATELLITE_SKYMAP50',
    SATELLITE_SKYMAP50_IMAGE_THE_FUTURE = 'SATELLITE_SKYMAP50_IMAGE_THE_FUTURE',
    SATELLITE_SKYMAP50_SEARCH_ARCHIVE = 'SATELLITE_SKYMAP50_SEARCH_ARCHIVE',
    SATELLITE_SKYMAP50_CUSTOM_JOB = 'SATELLITE_SKYMAP50_CUSTOM_JOB',
    SATELLITE_SENTINEL = 'SATELLITE_SENTINEL',
    SATELLITE_LANDSAT = 'SATELLITE_LANDSAT',

    SATELLITE_CGSTL = 'SATELLITE_CGSTL',
    SATELLITE_CGSTL_ARCHIVE_50 = 'SATELLITE_CGSTL_ARCHIVE_50',
    SATELLITE_CGSTL_ARCHIVE_75 = 'SATELLITE_CGSTL_ARCHIVE_75',
    SATELLITE_CGSTL_ARCHIVE_100 = 'SATELLITE_CGSTL_ARCHIVE_100',
    SATELLITE_CGSTL_ARCHIVE_NIGHT = 'SATELLITE_CGSTL_ARCHIVE_NIGHT',
    SATELLITE_CGSTL_NEW_COLLECT = 'SATELLITE_CGSTL_NEW_COLLECT',
}

export const SIDE_DRAWER_PROFILE_MODES = [SideDrawerMode.PROFILE, SideDrawerMode.PROFILE_ALL_MAPS];

export const SIDE_DRAWER_MOBILE_SATELLITE_MODES = [
    SideDrawerMode.SATELLITE,
    SideDrawerMode.SATELLITE_LANDSAT,
    SideDrawerMode.SATELLITE_SENTINEL,
];
