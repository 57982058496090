import L from 'leaflet';

import {
    createContainerComponent,
    createElementHook,
    createElementObject,
    createPathHook,
    extendContext,
    LeafletContextInterface,
    PathProps,
} from '@react-leaflet/core';

import { defaultFreehandDrawOptions } from './freehand-draw';

interface FreehandDrawProps extends PathProps {
    positions: L.LatLng[];
    options: L.PolylineOptions;
    children?: React.ReactNode;
}

const HOVER_WEIGHT_INCREASE = 3;

const createFreehandDraw = (props: FreehandDrawProps, context: LeafletContextInterface) => {
    const freehandDrawOptions: L.PolylineOptions = {
        ...defaultFreehandDrawOptions,
        interactive: false,
    };

    const freehandDrawGhostOptions: L.PolylineOptions = {
        ...defaultFreehandDrawOptions,
        weight: 30,
        color: 'transparent',
        interactive: true,
    };

    const freehandDraw = new L.Polyline(props.positions, freehandDrawOptions);
    const freehandDrawElement = createElementObject<L.Polyline, PathProps>(
        freehandDraw,
        extendContext(context, { overlayContainer: freehandDraw })
    );

    const freehandDrawGhost = new L.Polyline(props.positions, freehandDrawGhostOptions);
    const freehandDrawGhostElement = createElementObject<L.Polyline, PathProps>(freehandDrawGhost, context);

    freehandDrawGhostElement.instance.on('mouseover', () => {
        const options = freehandDrawElement.instance.options;
        const hoverWeight = options.weight ? options.weight + HOVER_WEIGHT_INCREASE : 6;
        freehandDrawElement.instance.setStyle({ ...options, weight: hoverWeight });
    });

    freehandDrawGhostElement.instance.on('mouseout', () => {
        const options = freehandDrawElement.instance.options;
        const originalWeight = options.weight ? options.weight - HOVER_WEIGHT_INCREASE : 3;
        freehandDrawElement.instance.setStyle({ ...options, weight: originalWeight });
    });

    freehandDrawGhostElement.instance.on('click', () => {
        freehandDrawElement.instance.fireEvent('click');
    });

    freehandDrawElement.instance.on('remove', () => {
        context.map.removeLayer(freehandDrawGhostElement.instance);
    });

    context.map.addLayer(freehandDrawGhostElement.instance);

    return freehandDrawElement;
};

const updateFreehandDraw = (instance: L.Polyline, props: FreehandDrawProps, _: FreehandDrawProps) => {
    instance.setStyle(props.options);
};

const useFreehandDraw = createElementHook(createFreehandDraw, updateFreehandDraw);
const useFreehandDrawPath = createPathHook(useFreehandDraw);
const FreehandDrawAnnotation = createContainerComponent(useFreehandDrawPath);

export default FreehandDrawAnnotation;
