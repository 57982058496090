const RADIUS = 6378137;

export default class Geometry {
    static _rad(_) {
        return (_ * Math.PI) / 180;
    }

    /**
     * @public  returns area from polygon object or array
     * @param {points} array of positions in lat, lng order eg.  [[number, number], [number, number], [number, number]]
     * @param {points} array of positions in lat, lng from polygon object
     */
    static area(points) {
        let p1;
        let p2;
        let p3;
        let lowerIndex;
        let middleIndex;
        let upperIndex;
        let rArea = 0;
        let coords;
        // check if we are dealing with an array or polygon object
        if (Array.isArray(points)) {
            coords = points;
        } else {
            coords = points.getLatLngs()['0'];
        }

        const coordsLength = coords.length;
        if (coordsLength > 2) {
            for (let i = 0; i < coordsLength; i++) {
                if (i === coordsLength - 2) {
                    // i = N-2
                    lowerIndex = coordsLength - 2;
                    middleIndex = coordsLength - 1;
                    upperIndex = 0;
                } else if (i === coordsLength - 1) {
                    // i = N-1
                    lowerIndex = coordsLength - 1;
                    middleIndex = 0;
                    upperIndex = 1;
                } else {
                    // i = 0 to N-3
                    lowerIndex = i;
                    middleIndex = i + 1;
                    upperIndex = i + 2;
                }

                p1 = coords[lowerIndex];
                p2 = coords[middleIndex];
                p3 = coords[upperIndex];

                // throw a check at the end so it can calculate based on array or polygon object
                if (Array.isArray(points)) {
                    rArea += (this._rad(p3[1]) - this._rad(p1[1])) * Math.sin(this._rad(p2[0]));
                } else {
                    rArea += (this._rad(p3.lng) - this._rad(p1.lng)) * Math.sin(this._rad(p2.lat));
                }
            }
        }
        return Math.abs((rArea = (rArea * RADIUS * RADIUS) / 2));
    }

    /**
     * @public  returns center location of polygon object or array
     * @param {points} array of positions in lat, lng order eg.  [[number, number], [number, number], [number, number]]
     * @param {points} array of positions in lat, lng from polygon object
     */
    static center(points) {
        if (Array.isArray(points)) {
            const x = points.map((xy) => xy[0]);
            const y = points.map((xy) => xy[1]);
            const cx = (Math.min(...x) + Math.max(...x)) / 2.0;
            const cy = (Math.min(...y) + Math.max(...y)) / 2.0;
            return [cx, cy];
        } else {
            const coords = points.getLatLngs()['0'];
            const x = coords.map((xy) => xy.lat);
            const y = coords.map((xy) => xy.lng);
            const cx = (Math.min(...x) + Math.max(...x)) / 2.0;
            const cy = (Math.min(...y) + Math.max(...y)) / 2.0;
            return [cx, cy];
        }
    }

    /**
     * @name distance returned as km
     * @description Result returned as km
     * @param { Coord } from origin point
     * @param { Coord } to destination point
     * @returns { res } distance between the two points
     */
    static distance(from, to) {
        // http://www.movable-type.co.uk/scripts/latlong.html
        const degreesToRadians = Math.PI / 180;
        let dLat;
        let dLng;
        let lat1;
        let lat2;

        if (Array.isArray(from) && Array.isArray(to)) {
            dLat = degreesToRadians * (to[1] - from[1]);
            dLng = degreesToRadians * (to[0] - from[0]);
            lat1 = degreesToRadians * from[1];
            lat2 = degreesToRadians * to[1];
        } else {
            dLat = degreesToRadians * (to.lng - from.lng);
            dLng = degreesToRadians * (to.lat - from.lat);
            lat1 = degreesToRadians * from.lng;
            lat2 = degreesToRadians * to.lng;
        }

        const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLng / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
        const res = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return res * RADIUS;
    }

    /**
     * @name closestPointOnLine Returns coordinates of closest point on line
     * @description Can not mismatch array types, choose either leaflet polyline + latlng or array + point
     * @param { point } point location for checking takes in latlng or point [number, number]
     * @param { line } start and finish point of line taking in a polyline or array [[number, number], [number,number]]
     * @returns { return } [number, number]
     */
    static closestPointOnLine(point, line) {
        if (Array.isArray(line) && Array.isArray(point)) {
            // logic has been kept seperate due to complexity
            let point1 = line[0];
            let point2 = line[1];
            let atob = { x: point2[0] - point1[0], y: point2[1] - point1[1] };
            let atop = { x: point[0] - point1[0], y: point[1] - point1[1] };
            let len = atob.x * atob.x + atob.y * atob.y;
            let dot = atop.x * atob.x + atop.y * atob.y;
            let t = Math.min(1, Math.max(0, dot / len));
            dot = (point2[0] - point1[0]) * (point[1] - point1[1]) - (point2[1] - point1[1]) * (point[1] - point1[1]);
            let lat = point1[0] + atob.x * t;
            let lng = point1[1] + atob.y * t;
            return [lat, lng];
        } else {
            let point1 = line.getLatLngs()['0'];
            let point2 = line.getLatLngs()['1'];
            let atob = { x: point2.lat - point1.lat, y: point2.lng - point1.lng };
            let atop = { x: point.lat - point1.lat, y: point.lng - point1.lng };
            let len = atob.x * atob.x + atob.y * atob.y;
            let dot = atop.x * atob.x + atop.y * atob.y;
            let t = Math.min(1, Math.max(0, dot / len));
            dot =
                (point2.lat - point1.lat) * (point.lng - point1.lng) -
                (point2.lng - point1.lng) * (point.lng - point1.lng);
            let lat = point1.lat + atob.x * t;
            let lng = point1.lng + atob.y * t;
            return [lat, lng];
        }
    }

    /**
     * @name contains boolean is point inside polygon
     * @param { coords } coords type of leaflet polygon or array eg. [[number, number], [number, number], [number, number],[number, number]]
     * @param { point } point location to be checked [number, number]
     * @returns { return } boolean
     */
    static contains(coords, point) {
        // ray-casting algorithm based on
        // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
        const x = point[0];
        const y = point[1];
        if (Array.isArray(coords)) {
            for (let i = 0, j = coords.length - 1; i < coords.length; j = i++) {
                const xi = coords[i][0];
                const yi = coords[i][1];
                const xj = coords[j][0];
                const yj = coords[j][1];
                const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
                if (intersect) {
                    return true;
                }
            }
        } else {
            const polygonArray = coords.getLatLngs();
            for (let i = 0, j = polygonArray[0].length - 1; i < polygonArray[0].length; j = i++) {
                const xi = polygonArray[0][i].lat;
                const yi = polygonArray[0][i].lng;
                const xj = polygonArray[0][j].lat;
                const yj = polygonArray[0][j].lng;
                const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
                if (intersect) {
                    return true;
                }
            }
        }
        return false;
    }
}
