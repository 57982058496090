export default class Ruler {
    id: string;
    startLatLng: L.LatLng;
    endLatLng: L.LatLng;
    options: L.PolylineOptions;
    constructor(id: string, startLatLng: L.LatLng, endLatLng: L.LatLng, options: L.PolylineOptions) {
        this.startLatLng = startLatLng;
        this.endLatLng = endLatLng;
        this.options = options;
    }
}

export const defaultRulerOptions: L.PolylineOptions = {
    color: '#3388ff',
    interactive: false,
};
export interface LeafletRulerMouseEvent extends L.LeafletMouseEvent {
    startLatLng: L.LatLng;
    endLatLng: L.LatLng;
}
