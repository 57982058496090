import L, { LeafletMouseEvent } from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import FreehandDraw, { defaultFreehandDrawOptions } from './freehand-draw';
import { v4 as uuidv4 } from 'uuid';

interface FreehandDrawBuilderProps {
    onCreateFreehand: (freehandDraw: FreehandDraw) => void;
}

const createFreehandDrawBuilder = (props: FreehandDrawBuilderProps, context: LeafletContextInterface) => {
    const freehandDrawPath = new L.Polyline([], { ...defaultFreehandDrawOptions, interactive: false });
    const freehandDrawPathElement = createElementObject<L.Polyline, FreehandDrawBuilderProps>(
        freehandDrawPath,
        context
    );

    const onMouseDown = (e: LeafletMouseEvent) => {
        context.map.on('mouseup', onMouseUp);
        context.map.on('mousemove', onMouseMove);
        freehandDrawPath.setLatLngs([e.latlng]);
    };

    const onMouseUp = () => {
        context.map.off('mousemove', onMouseMove);
        context.map.off('mouseup', onMouseUp);

        const freehandDraw: FreehandDraw = {
            id: uuidv4(),
            positions: freehandDrawPathElement.instance.getLatLngs() as L.LatLng[],
            options: freehandDrawPathElement.instance.options as L.PolylineOptions,
        };

        props.onCreateFreehand(freehandDraw);
    };

    const onMouseMove = (e: LeafletMouseEvent) => {
        freehandDrawPath.addLatLng(e.latlng);
    };

    freehandDrawPathElement.instance.on('add', () => {
        context.map.dragging.disable();
        context.map.on('mousedown', onMouseDown);
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');
    });

    freehandDrawPathElement.instance.on('remove', () => {
        context.map.dragging.enable();
        context.map.off('mousedown', onMouseDown);
        context.map.off('mouseup', onMouseUp);
        context.map.off('mousemove', onMouseMove);
        L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
    });

    return freehandDrawPathElement;
};

const useFreehandDrawBuilder = createElementHook<L.Polyline, FreehandDrawBuilderProps, LeafletContextInterface>(
    createFreehandDrawBuilder
);

const FreehandDrawBuilder = (props: FreehandDrawBuilderProps) => {
    const context = useLeafletContext();
    const freehandDrawBuilder = useFreehandDrawBuilder(props, context);
    useLayerLifecycle(freehandDrawBuilder.current, context);
    return null;
};

export default FreehandDrawBuilder;
