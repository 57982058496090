import L from 'leaflet';

import {
    LeafletContextInterface,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createPathHook,
    extendContext,
} from '@react-leaflet/core';

import MathUtil from '../../../../lib/math-util';
import Arrow from './arrow';
import { createArrowEditElement } from './arrow-edit-element';
import { createArrowGhostElement } from './arrow-ghost-element';
import { createArrowHeadElement } from './arrow-head';

interface ArrowAnnotationProps extends L.PolylineOptions {
    locked: boolean;
    arrow: Arrow;
    onUpdateArrow: (arrow: Arrow) => void;
    children: React.ReactNode;
}

const createArrowAnnotation = (props: ArrowAnnotationProps, context: LeafletContextInterface) => {
    // The arrow itself is an invisible polyline that represents the start and end points of the arrow
    const arrow = new L.Polyline([props.arrow.startLatLng, props.arrow.endLatLng], { color: 'transparent' });
    const arrowElement = createElementObject<L.Polyline, ArrowAnnotationProps>(
        arrow,
        extendContext(context, { overlayContainer: arrow })
    );

    // The arrow shaft is the visible polyline. We need to remove the burr from the end of this polyline
    const arrowShaft = new L.Polyline([props.arrow.startLatLng, props.arrow.endLatLng], props.arrow.options);
    const arrowShaftElement = createElementObject<L.Polyline, ArrowAnnotationProps>(arrowShaft, context);

    // The arrow head is a marker with a DivIcon and a rotation transform
    const arrowHeadElement = createArrowHeadElement({ id: props.arrow.id, arrowShaftElement: arrowElement }, context);

    // The arrow ghost is an invisible polygon that increases the selection area of the arrow
    const arrowGhostElement = createArrowGhostElement({ arrowShaftElement: arrowElement }, context);

    // The arrow edit is placed once the arrow is selected. It allows moving the arrow start and end points
    const arrowEditElement = createArrowEditElement(
        { arrowShaftElement: arrowElement, arrowHeadElement: arrowHeadElement },
        context
    );

    // The burr is the extra bit of the arrow that extends past the arrow head icon
    const removeBurr = () => {
        const startLatLng = arrowElement.instance.getLatLngs()[0] as L.LatLng;
        const endLatLng = arrowElement.instance.getLatLngs()[1] as L.LatLng;

        const startPoint = context.map.latLngToLayerPoint(startLatLng);
        const endPoint = context.map.latLngToLayerPoint(endLatLng);
        const pixelLengthOfArrow = startPoint.distanceTo(endPoint);
        const fractionToRemove = 15;
        const lerpAmount = 1 - fractionToRemove / pixelLengthOfArrow;
        const newEndLat = MathUtil.lerp(startLatLng.lat, endLatLng.lat, lerpAmount);
        const newEndLng = MathUtil.lerp(startLatLng.lng, endLatLng.lng, lerpAmount);
        const newEndLatLng = new L.LatLng(newEndLat, newEndLng);
        arrowShaftElement.instance.setLatLngs([startLatLng, newEndLatLng]);
    };

    const onMapZoom = () => {
        removeBurr();
    };

    arrowElement.instance.on('add', () => {
        context.map.addLayer(arrowElement.instance);
        context.map.addLayer(arrowShaftElement.instance);
        context.map.addLayer(arrowHeadElement.instance);
        context.map.addLayer(arrowGhostElement.instance);
        context.map.on('zoomend', onMapZoom);
        removeBurr();
    });

    arrowElement.instance.on('remove', () => {
        context.map.removeLayer(arrowElement.instance);
        context.map.removeLayer(arrowShaftElement.instance);
        context.map.removeLayer(arrowHeadElement.instance);
        context.map.removeLayer(arrowGhostElement.instance);
        context.map.removeLayer(arrowEditElement.instance);
        context.map.off('zoomend', onMapZoom);
    });

    const onMouseOver = () => {
        arrowShaftElement.instance.setStyle({ ...arrowShaftElement.instance.options, weight: 6 });
    };

    const onMouseOut = () => {
        arrowShaftElement.instance.setStyle({ ...arrowShaftElement.instance.options, weight: 3 });
    };

    arrowElement.instance.on('mouseover', onMouseOver);
    arrowElement.instance.on('mouseout', onMouseOut);

    arrowElement.instance.on('update', () => {
        arrowShaftElement.instance.setLatLngs(arrowElement.instance.getLatLngs());
        removeBurr();
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    arrowElement.instance.on('update-color', (e: any) => {
        arrowShaftElement.instance.setStyle({ ...arrowShaftElement.instance.options, color: e.arrow.options.color });
        arrowHeadElement.instance.fireEvent('update-color', { ...e });
    });

    arrowElement.instance.on('lock', () => {
        context.map.removeLayer(arrowGhostElement.instance);
        arrowElement.instance.off('mouseover', onMouseOver);
        arrowElement.instance.off('mouseout', onMouseOut);
        arrowElement.instance.off('click', onSelect);
        arrowElement.instance.setStyle({
            ...arrowElement.instance.options,
            interactive: false,
            bubblingMouseEvents: false,
        });
        arrowShaftElement.instance.setStyle({ ...arrowShaftElement.instance.options, interactive: false });
        const element = arrowElement.instance.getElement() as HTMLElement;
        L.DomUtil.removeClass(element, 'leaflet-interactive');
    });

    arrowElement.instance.on('unlock', () => {
        context.map.addLayer(arrowGhostElement.instance);
        arrowElement.instance.on('mouseover', onMouseOver);
        arrowElement.instance.on('mouseout', onMouseOut);
        arrowElement.instance.on('click', onSelect);
        arrowElement.instance.setStyle({ ...arrowElement.instance.options, interactive: true });
        arrowShaftElement.instance.setStyle({ ...arrowShaftElement.instance.options, interactive: true });
        const element = arrowElement.instance.getElement() as HTMLElement;
        L.DomUtil.addClass(element, 'leaflet-interactive');
    });

    const onSelect = () => {
        arrowElement.instance.openPopup();
        context.map.addLayer(arrowEditElement.instance);
        context.map.on('click', onDeselect);
    };

    const onDeselect = () => {
        arrowElement.instance.closePopup();

        context.map.removeLayer(arrowEditElement.instance);
        context.map.off('click', onDeselect);
        const startLatLng = arrowElement.instance.getLatLngs()[0] as L.LatLng;
        const endLatLng = arrowElement.instance.getLatLngs()[1] as L.LatLng;

        props.onUpdateArrow({
            ...props.arrow,
            startLatLng: startLatLng,
            endLatLng: endLatLng,
        });
        arrowGhostElement.instance.fireEvent('mouseout'); // Otherwise the ghost remains highlighted
    };

    arrowElement.instance.on('click', onSelect);

    return arrowElement;
};

const updateArrowElement = (instance: L.Polyline, props: ArrowAnnotationProps, _: ArrowAnnotationProps) => {
    instance.fireEvent('update-color', { ...props });
    console.log('Updated');
    console.log(props.locked);
    if (props.locked) {
        instance.fireEvent('lock');
    } else {
        instance.fireEvent('unlock');
    }
};

const useArrowAnnotation = createElementHook<L.Polyline, ArrowAnnotationProps, LeafletContextInterface>(
    createArrowAnnotation,
    updateArrowElement
);

const useArrowAnnotationArrow = createPathHook<L.Polyline, ArrowAnnotationProps>(useArrowAnnotation);
const ArrowAnnotation = createContainerComponent(useArrowAnnotationArrow);

export default ArrowAnnotation;
