import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SoarHelper, { isMobileVersion } from '../../../../lib/soar-helper';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../../store/App/actions';
import { selectMapZoom } from '../../../../store/App/selectors';
import {
    actionFetchListingDictionary,
    actionFetchListingDictionarySuccess,
    actionSetSelectedContinent,
} from '../../../../store/Map/MapSelection/actions';
import { ListingGroup } from '../../../../store/Map/MapSelection/model';
import { selectContinentListings, selectListingDictionary } from '../../../../store/Map/MapSelection/selectors';
import ContinentCluster from '../ContinentCluster/continent-cluster';
import PolygonCluster from '../PolygonCluster/polygon-cluster';

const AllMapsProvider = () => {
    const dispatch = useDispatch();
    const mapZoom = useSelector(selectMapZoom);
    const continentsDictionary = useSelector(selectContinentListings);
    const listingDictionary = useSelector(selectListingDictionary);

    useEffect(() => {
        dispatch(actionFetchListingDictionary());
    }, [dispatch]);

    const onSelectListing = (id: number, listingGroup: ListingGroup) => {
        // Invalidate the listing dictionary so they don't show while the ListingDTO is being fetched
        dispatch(actionFetchListingDictionarySuccess(undefined));

        // FlyTo occurs because otherwise we need to wait for the ListingDTO before the map moves
        if (listingGroup) {
            dispatch(actionFlyToOnMap(listingGroup.latlngBounds));
        }
        UriHelper.navigateToMap(id);
    };

    const onSelectContinent = (listingGroup: ListingGroup) => {
        if (listingGroup.continent) {
            Analytics.Event('Continents clustering', 'Selected continent', listingGroup.continent.name);
            dispatch(actionSetSelectedContinent(listingGroup));
        }
    };

    if (!mapZoom && !isMobileVersion && SoarHelper.isSoar()) return <React.Fragment />;

    if (mapZoom && mapZoom < 4.0 && SoarHelper.isSoar()) {
        return <ContinentCluster listingDictionary={continentsDictionary} handleSelectContinent={onSelectContinent} />;
    } else {
        return <PolygonCluster listingDictionary={listingDictionary} handleSelectListing={onSelectListing} />;
    }
};

export default AllMapsProvider;
