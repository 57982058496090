import L from 'leaflet';

export default class Coordinate {
    id: string;
    position: L.LatLng;
    options: L.MarkerOptions;

    constructor(position: L.LatLng, options: L.MarkerOptions) {
        this.position = position;
        this.options = options;
    }
}

export const defaultCoordinateOptions: L.MarkerOptions = {
    interactive: true,
};
