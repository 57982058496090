import { useCallback, useEffect, useState } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { CountryInfo, CSWCollectionRequestCost } from '../../../../../api/model';

import Constants from '../../../../../constants';
import GeoUtil from '../../../../../lib/geo-util';
import Analytics, { ConversionEvent } from '../../../../../lib/user-analytics';
import { selectLoggedIn } from '../../../../../store/Account/selectors';
import LoginRegisterDialog from '../../../../Registration/login-register-dialog';
import { LoginModalMode } from '../../../../Registration/login-enum';
import SatelliteOrderConditions, {
    SKYMAP50_NEW_COLLECTION_TERMS,
} from '../../OrderWorkflow/satellite-order-conditions';
import SatelliteOrderCreditCardDetails from '../../OrderWorkflow/satellite-order-credit-card-details';
import SatelliteOrderPurchaseComplete from '../../OrderWorkflow/satellite-order-purchase-complete';
import SatelliteOrderUserDetails, { Industry } from '../../OrderWorkflow/satellite-order-user-details';
import Skymap50NewCollectPurchaseDetails from './skymap50-new-collect-purchase-details';
import { useDispatch, useSelector } from 'react-redux';
import { LatLngBounds } from 'leaflet';
import {
    Container,
    Button,
    Content,
    TeaserTitle,
    TeaserIcon,
    TeaserText,
    Logo,
    LogoContainer,
} from '../../satellite-drawer-styles';
import UriHelper from '../../../../../lib/uri-helper';
import { SideDrawerMode } from '../../../../../store/SideDrawer/model';
import { SatelliteDrawerAOIError } from '../../satellite-drawer-aoi-error';
import { useHistoryNavigation } from '../../../../Shared/use-history-navigation';
import {
    actionSatelliteBeginBoxSelect,
    actionSatelliteResetAOI,
} from '../../../../../store/Map/SatelliteArchive/actions';
import {
    selectSatelliteAOI,
    selectIsSatelliteBoxSelectActive,
} from '../../../../../store/Map/SatelliteArchive/selectors';
import DrawerContainerHeader from '../../../drawer-container-header';
import ApiSupplier from '../../../../../api/api-supplier';

enum NewCollectWorkflow {
    SelectAOI,
    AOITooSmall,
    AOITooLarge,
    AOITooThick,
    AOITooNarrow,
    AOITooHigh,
    AOITooShort,
    AOIRestricted,
    PurchaseDetails,
    UserDetails,
    Conditions,
    Payment,
    PurchaseComplete,
}

const Skymap50NewCollect = () => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const skymap50AOI = useSelector(selectSatelliteAOI);
    const isSkymap50NewCollectBoxSelectActive = useSelector(selectIsSatelliteBoxSelectActive);

    const dispatch = useDispatch();
    const beginNewCollectBoxSelect = () => dispatch(actionSatelliteBeginBoxSelect());
    const clearBoxSelect = () => dispatch(actionSatelliteResetAOI());

    const { setNavigateToPath } = useHistoryNavigation();

    const [workflow, setWorkflow] = useState<NewCollectWorkflow>(NewCollectWorkflow.SelectAOI);

    const [cswCost, setCswCost] = useState<CSWCollectionRequestCost | undefined>(undefined);
    const [cswCostError, setCswCostError] = useState<Error | undefined>(undefined);
    const [validVoucher, setValidVoucher] = useState<string | undefined>(undefined);
    const [cswError, setCswError] = useState<Error | undefined>(undefined);
    const [userDetails, setUserDetails] = useState<
        { companyName: string; industry: Industry; country: CountryInfo } | undefined
    >(undefined);
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [validAOI, setValidAOI] = useState<LatLngBounds | undefined>(undefined);

    const params = Constants.SKYMAP50_PARAMETERS.NEW_COLLECT;

    useEffect(() => {
        setShowLogin(!isLoggedIn ? true : false);
    }, [isLoggedIn]);

    useEffect(() => {
        if (skymap50AOI) {
            const selectedArea = GeoUtil.area(skymap50AOI) / 1000000;
            const height = GeoUtil.heightKilometers(skymap50AOI);
            const width = GeoUtil.widthKilometers(skymap50AOI);

            if (selectedArea < params.minArea) {
                setWorkflow(NewCollectWorkflow.AOITooSmall);
            } else if (selectedArea > params.maxArea) {
                setWorkflow(NewCollectWorkflow.AOITooLarge);
            } else if (width > params.maxWidth) {
                setWorkflow(NewCollectWorkflow.AOITooThick);
            } else if (width < params.minWidth) {
                setWorkflow(NewCollectWorkflow.AOITooNarrow);
            } else if (height > params.maxHeight) {
                setWorkflow(NewCollectWorkflow.AOITooHigh);
            } else if (height < params.minHeight) {
                setWorkflow(NewCollectWorkflow.AOITooShort);
            } else {
                setWorkflow(NewCollectWorkflow.PurchaseDetails);
                setValidAOI(skymap50AOI);
            }
        }
    }, [skymap50AOI, params]);

    const getCollectionRequestCost = useCallback(
        (validAOI: LatLngBounds, voucher = validVoucher) => {
            setIsLoading(true);
            setCswCostError(undefined);
            setCswError(undefined);

            ApiSupplier.calculateTaskCost(validAOI, 'SKYM50', 'NC50', voucher, userDetails?.country.countryCode)
                .then((res) => {
                    setCswCost(res);
                    setValidVoucher(voucher);
                })
                .catch((err) => {
                    if (err.toString().includes('restricted_area')) {
                        setWorkflow(NewCollectWorkflow.AOIRestricted);
                    } else {
                        setCswCostError(err);
                    }
                })
                .finally(() => setIsLoading(false));
        },
        [userDetails?.country.countryCode, validVoucher]
    );

    useEffect(() => {
        if (validAOI) {
            getCollectionRequestCost(validAOI);
        }
    }, [validAOI, getCollectionRequestCost]);

    const onEnterVoucher = (voucher: string) => {
        if (validAOI) {
            getCollectionRequestCost(validAOI, voucher);
        }
    };

    const createOrder = (companyName: string, industry: Industry, country: CountryInfo, stripeToken?: string) => {
        if (companyName && industry && country && skymap50AOI) {
            Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked to create order', skymap50AOI);
            setIsLoading(true);
            ApiSupplier.createTaskOrder(
                skymap50AOI,
                'SKYM50',
                'NC50',
                companyName,
                industry,
                country,
                stripeToken,
                validVoucher
            )
                .then(() => {
                    setWorkflow(NewCollectWorkflow.PurchaseComplete);
                })
                .catch((err) => {
                    setCswError(err);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const renderWorkflow = (): JSX.Element => {
        switch (workflow) {
            case NewCollectWorkflow.SelectAOI:
                return (
                    <Content>
                        <TeaserTitle>NEW COLLECT</TeaserTitle>
                        <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                        <TeaserText>Click the button and drag an Area of Interest.</TeaserText>
                        <TeaserText>Let's see what we can find!</TeaserText>
                        <Button
                            disabled={isSkymap50NewCollectBoxSelectActive}
                            onClick={() => {
                                beginNewCollectBoxSelect();
                                setValidVoucher(undefined);
                                Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked to create aoi');
                                Analytics.Conversion(ConversionEvent.DRAW_AOI);
                            }}
                        >
                            DRAW AOI
                        </Button>
                    </Content>
                );

            case NewCollectWorkflow.AOITooLarge:
                return (
                    <SatelliteDrawerAOIError
                        title="NEW COLLECT"
                        icon="/assets/floating-drawer-icons/map-teaser-icon.svg"
                        onClick={() => {
                            clearBoxSelect();
                            setWorkflow(NewCollectWorkflow.SelectAOI);
                            Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked reset aoi too large');
                        }}
                        error={`This AOI is too large. It must be less than ${params.maxArea}km²`}
                    />
                );

            case NewCollectWorkflow.AOITooSmall:
                return (
                    <SatelliteDrawerAOIError
                        title="NEW COLLECT"
                        icon="/assets/floating-drawer-icons/map-teaser-icon.svg"
                        onClick={() => {
                            clearBoxSelect();
                            setWorkflow(NewCollectWorkflow.SelectAOI);
                            Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked reset aoi too small');
                        }}
                        error={`This AOI is too small. It must be greater than ${params.minArea}km²`}
                    />
                );

            case NewCollectWorkflow.AOITooNarrow:
                return (
                    <SatelliteDrawerAOIError
                        title="NEW COLLECT"
                        icon="/assets/floating-drawer-icons/map-teaser-icon.svg"
                        onClick={() => {
                            clearBoxSelect();
                            setWorkflow(NewCollectWorkflow.SelectAOI);
                            Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked reset aoi too narrow');
                        }}
                        error={`This AOI is too thin. Its width must be greater than ${params.minWidth}km`}
                    />
                );

            case NewCollectWorkflow.AOITooThick:
                return (
                    <SatelliteDrawerAOIError
                        title="NEW COLLECT"
                        icon="/assets/floating-drawer-icons/map-teaser-icon.svg"
                        onClick={() => {
                            clearBoxSelect();
                            setWorkflow(NewCollectWorkflow.SelectAOI);
                            Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked reset aoi too thick');
                        }}
                        error={`This AOI is too wide. Its width must be less than ${params.maxWidth}km`}
                    />
                );

            case NewCollectWorkflow.AOITooHigh:
                return (
                    <SatelliteDrawerAOIError
                        title="NEW COLLECT"
                        icon="/assets/floating-drawer-icons/map-teaser-icon.svg"
                        onClick={() => {
                            clearBoxSelect();
                            setWorkflow(NewCollectWorkflow.SelectAOI);
                            Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked reset aoi too high');
                        }}
                        error={`This AOI is too high. Its height must be less than ${params.maxHeight}km`}
                    />
                );

            case NewCollectWorkflow.AOITooShort:
                return (
                    <SatelliteDrawerAOIError
                        title="NEW COLLECT"
                        icon="/assets/floating-drawer-icons/map-teaser-icon.svg"
                        onClick={() => {
                            clearBoxSelect();
                            setWorkflow(NewCollectWorkflow.SelectAOI);
                            Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked reset aoi too short');
                        }}
                        error={`This AOI is too short. Its height must be greater than ${params.minHeight}km`}
                    />
                );

            case NewCollectWorkflow.AOIRestricted:
                return (
                    <SatelliteDrawerAOIError
                        title="NEW COLLECT"
                        icon="/assets/floating-drawer-icons/map-teaser-icon.svg"
                        onClick={() => {
                            clearBoxSelect();
                            setWorkflow(NewCollectWorkflow.SelectAOI);
                            Analytics.Event('Satellite - SkyMap50 - New Collect', 'Clicked reset aoi restricted area');
                        }}
                        error="Sorry due to data access restrictions SkyMap50 imagery is not available in this area. Please check back soon."
                    />
                );

            case NewCollectWorkflow.PurchaseDetails:
                return (
                    <Skymap50NewCollectPurchaseDetails
                        isLoading={isLoading}
                        cswCost={cswCost}
                        cswCostError={cswCostError}
                        onEnterVoucher={(voucher) => onEnterVoucher(voucher)}
                        onConfirm={() => {
                            setWorkflow(NewCollectWorkflow.UserDetails);
                        }}
                    />
                );

            case NewCollectWorkflow.UserDetails:
                if (showLogin) {
                    return (
                        <LoginRegisterDialog
                            isOpen={showLogin}
                            initialMode={LoginModalMode.LOGIN}
                            onClose={() => {
                                setWorkflow(NewCollectWorkflow.PurchaseDetails);
                            }}
                        />
                    );
                }

                if (!cswCost) {
                    throw new Error('Invalid workflow state. Cost does not exist in NewCollectWorkflow.Payment');
                }

                return (
                    <SatelliteOrderUserDetails
                        handleSubmitUserDetails={(companyName, industry, country) => {
                            setUserDetails({
                                companyName: companyName,
                                industry: industry,
                                country: country,
                            });
                            setWorkflow(NewCollectWorkflow.Conditions);
                        }}
                    />
                );

            case NewCollectWorkflow.Conditions:
                if (!cswCost) {
                    throw new Error('Invalid workflow state. Cost does not exist in NewCollectWorkflow.Conditions');
                }
                return (
                    <SatelliteOrderConditions
                        termsAndConditions={SKYMAP50_NEW_COLLECTION_TERMS}
                        termsAndConditionsHref="/skymap50-terms"
                        handleSubmitConditions={() => {
                            if (cswCost.totalPrice === 0 && userDetails) {
                                createOrder(userDetails.companyName, userDetails.industry, userDetails.country);
                            } else {
                                setWorkflow(NewCollectWorkflow.Payment);
                            }
                        }}
                        isLoading={isLoading}
                    />
                );

            case NewCollectWorkflow.Payment:
                if (!cswCost) {
                    throw new Error('Invalid workflow state. Cost does not exist in NewCollectWorkflow.Payment');
                }
                return (
                    <StripeProvider apiKey={Constants.STRIPE_API_KEY}>
                        <Elements>
                            <SatelliteOrderCreditCardDetails
                                data-sentry-block
                                termsAndConditionsHref="/skymap50-terms"
                                totalPrice={cswCost.totalPrice}
                                totalTax={cswCost.totalTax}
                                currency={cswCost.currency}
                                handleStripeTokenSubmitted={(stripeToken: string) => {
                                    if (stripeToken && userDetails) {
                                        createOrder(
                                            userDetails.companyName,
                                            userDetails.industry,
                                            userDetails.country,
                                            stripeToken
                                        );
                                    }
                                }}
                                error={cswError}
                            />
                        </Elements>
                    </StripeProvider>
                );

            case NewCollectWorkflow.PurchaseComplete:
                return (
                    <SatelliteOrderPurchaseComplete
                        handleClickFinished={() => {
                            clearBoxSelect();
                            setValidVoucher(undefined);
                            setWorkflow(NewCollectWorkflow.SelectAOI);
                            UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_SKYMAP50);
                        }}
                    />
                );

            default:
                throw new Error('Invalid workflow');
        }
    };

    const handleClickBack = () => {
        switch (workflow) {
            case NewCollectWorkflow.SelectAOI:
                setNavigateToPath(SideDrawerMode.SATELLITE_SKYMAP50);
                break;

            case NewCollectWorkflow.AOITooThick:
            case NewCollectWorkflow.AOITooNarrow:
            case NewCollectWorkflow.AOITooHigh:
            case NewCollectWorkflow.AOITooShort:
            case NewCollectWorkflow.AOITooLarge:
            case NewCollectWorkflow.AOITooSmall:
                clearBoxSelect();
                setWorkflow(NewCollectWorkflow.SelectAOI);
                break;

            case NewCollectWorkflow.AOIRestricted:
                clearBoxSelect();
                setWorkflow(NewCollectWorkflow.SelectAOI);
                break;

            case NewCollectWorkflow.PurchaseDetails:
                clearBoxSelect();
                setWorkflow(NewCollectWorkflow.SelectAOI);
                break;

            case NewCollectWorkflow.UserDetails:
                setWorkflow(NewCollectWorkflow.PurchaseDetails);
                break;

            case NewCollectWorkflow.Conditions:
                setWorkflow(NewCollectWorkflow.UserDetails);
                break;

            case NewCollectWorkflow.Payment:
                setWorkflow(NewCollectWorkflow.Conditions);
                break;

            case NewCollectWorkflow.PurchaseComplete:
                clearBoxSelect();
                setWorkflow(NewCollectWorkflow.SelectAOI);
                UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE_SKYMAP50);
                break;

            default:
                throw new Error('Unhandled back state for New collection workflow');
        }
    };

    return (
        <Container>
            <DrawerContainerHeader handleBack={() => handleClickBack()} data-testid="sentinel-back-button">
                <LogoContainer>
                    <Logo src="/assets/floating-drawer-satellite-icons/satellite-skymap50-logo.png" />
                </LogoContainer>
            </DrawerContainerHeader>
            {renderWorkflow()}
        </Container>
    );
};

export default Skymap50NewCollect;
