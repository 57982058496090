import React, { useState } from 'react';
import AnnotationControl from '../annotation-control';
import Arrow from './arrow';
import ArrowAnnotation from './arrow-annotation';
import ArrowBuilder from './arrow-builder';
import ArrowAnnotationPopup from './arrow-popup';

interface ArrowControlProps {
    hideControl: boolean;
}

const ArrowControl = (props: ArrowControlProps) => {
    const [isCreatingArrow, setIsCreatingArrow] = useState(false);
    const [arrows, setArrows] = useState<Arrow[]>([]);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="middle"
                hideControl={props.hideControl}
                imageUrl="/assets/annotations/icon-arrow.svg"
                name="Arrow"
                onClick={() => setIsCreatingArrow(true)}
            />
            {isCreatingArrow && (
                <ArrowBuilder
                    onCreateArrow={(arrow) => {
                        setIsCreatingArrow(false);
                        setArrows([...arrows, arrow]);
                    }}
                />
            )}

            {arrows.map((arrow) => {
                return (
                    <ArrowAnnotation
                        locked={props.hideControl}
                        key={arrow.id}
                        arrow={arrow}
                        onUpdateArrow={(arrow: Arrow) => {
                            setArrows((current) => {
                                const index = current.findIndex((c) => c.id === arrow.id);
                                current[index] = arrow;
                                return current;
                            });
                        }}
                    >
                        <ArrowAnnotationPopup
                            arrow={arrow}
                            onDelete={() => {
                                setArrows((current) => {
                                    return current.filter((c) => c.id !== arrow.id);
                                });
                            }}
                            onUpdate={(options) => {
                                setArrows((current) => {
                                    const index = current.findIndex((c) => c.id === arrow.id);
                                    const newArrows = [...current];
                                    newArrows[index].options = options;
                                    return newArrows;
                                });
                            }}
                        />
                    </ArrowAnnotation>
                );
            })}
        </React.Fragment>
    );
};

export default ArrowControl;
