import React from 'react';
import { isMobileVersion } from '../../../../lib/soar-helper';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListingType } from '../../../../api/model';
import SoarHelper from '../../../../lib/soar-helper';
import { selectActiveAllMap } from '../../../../store/Map/SuperMap/selectors';
import MobileReportMap from '../../../Mobile/Reporting/mobile-report-map';
import ActiveMapBookmark from '../active-map-bookmark';
import EmbeddedMapDialog from '../EmbedMap/embedded-map-dialog';
import PinMap from './pin-map';
import ShareCopy from './share-copy-link';
import ShareLike from './share-like';
import ShareLinks from './social-links';

interface ShareComponentProps {
    listingPage?: boolean;
}

const MapOptions = (props: ShareComponentProps) => {
    const isSoar = SoarHelper.isSoar();
    const selectedMap = useSelector(selectActiveAllMap);

    // If the listing is a satellite order do not bother
    if (!selectedMap || selectedMap.listingType === ListingType.ORDER) {
        return <React.Fragment />;
    }

    return (
        <ShareContainer>
            <ShareIcons>
                {isSoar && <ShareLike />}
                {isSoar && <ShareLinks />}
                <ShareCopy />
                {!props.listingPage && !isMobileVersion && <PinMap />}
                {!isMobileVersion && isSoar && !props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
                {isMobileVersion && <ShareIcons>{isSoar && <ActiveMapBookmark mapId={selectedMap.id} />}</ShareIcons>}
            </ShareIcons>
            {!isMobileVersion && <ShareIcons>{isSoar && <ActiveMapBookmark mapId={selectedMap.id} />}</ShareIcons>}
            {isMobileVersion ? <MobileReportMap /> : null}
        </ShareContainer>
    );
};

export default MapOptions;

const ShareContainer = styled.div`
    display: flex;
    margin: 13px 16px 3px 10px;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
        margin: 15px 15px 15px 10px;
    }
`;

const ShareIcons = styled.div`
    display: flex;
`;
