import React, { useState } from 'react';
import AnnotationControl from '../annotation-control';
import FreehandDrawAnnotation from './freehand-draw-annotation';
import FreehandDrawBuilder from './freehand-draw-builder';
import FreehandDrawPopup from './freehand-draw-popup';
import FreehandDraw from './freehand-draw';
import { Polyline as LeafletPolyline } from 'react-leaflet';

interface FreehandDrawControlProps {
    hideControl: boolean;
}

const FreehandDrawControl = (props: FreehandDrawControlProps) => {
    const [isActive, setIsActive] = useState(false);
    const [freehandDrawAnnotations, setFreehandDrawAnnotations] = useState<FreehandDraw[]>([]);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="start"
                hideControl={props.hideControl}
                onClick={() => {
                    setIsActive((current) => !current);
                }}
                imageUrl="/assets/annotations/icon-draw.svg"
                name="Sketch"
            />

            {isActive ? (
                <FreehandDrawBuilder
                    onCreateFreehand={(freehand) => {
                        setIsActive(false);
                        setFreehandDrawAnnotations((current) => [...current, freehand]);
                    }}
                />
            ) : null}

            {props.hideControl &&
                freehandDrawAnnotations.map((annotation) => (
                    <LeafletPolyline
                        key={annotation.id}
                        positions={annotation.positions}
                        color={annotation.options.color}
                        weight={annotation.options.weight}
                        opacity={annotation.options.opacity}
                        interactive={false}
                    />
                ))}

            {!props.hideControl &&
                freehandDrawAnnotations.map((annotation) => (
                    <FreehandDrawAnnotation
                        key={annotation.id}
                        positions={annotation.positions}
                        options={annotation.options}
                    >
                        <FreehandDrawPopup
                            onDelete={() => {
                                setFreehandDrawAnnotations((current) => {
                                    return current.filter((freehand) => freehand.id !== annotation.id);
                                });
                            }}
                            onUpdate={(options) => {
                                const index = freehandDrawAnnotations.findIndex(
                                    (freehand) => freehand.id === annotation.id
                                );
                                const newAnnotations = [...freehandDrawAnnotations];
                                newAnnotations[index].options = options;
                                setFreehandDrawAnnotations(newAnnotations);
                            }}
                            freehandDraw={annotation}
                        />
                    </FreehandDrawAnnotation>
                ))}
        </React.Fragment>
    );
};

export default FreehandDrawControl;
