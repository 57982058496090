import L from 'leaflet';

import {
    createElementHook,
    createElementObject,
    LeafletContextInterface,
    useLayerLifecycle,
    useLeafletContext,
} from '@react-leaflet/core';
import { v4 as uuidv4 } from 'uuid';
import Ruler, { defaultRulerOptions } from './ruler';
import { rulerTempBuilderMarkerCreate, rulerTempBuilderMarkerRemove } from './ruler-builder-text-control';
interface RulerBuilderProps {
    onCreateRuler: (ruler: Ruler) => void;
}

const createRulerBuilder = (props: RulerBuilderProps, context: LeafletContextInterface) => {
    const rulerPath = new L.Polyline([], defaultRulerOptions);
    const rulerPathElement = createElementObject<L.Polyline, RulerBuilderProps>(rulerPath, context);

    let startLatLng: L.LatLng = new L.LatLng(0, 0);
    let endLatLng: L.LatLng = new L.LatLng(0, 0);
    const id = uuidv4();

    rulerPathElement.instance.on('add', () => {
        L.DomUtil.addClass(context.map.getContainer(), 'leaflet-crosshair');

        context.map.on('mousedown', (e: L.LeafletMouseEvent) => {
            startLatLng = e.latlng;
            context.map.dragging.disable();
            context.map.on('mousemove', (e: L.LeafletMouseEvent) => {
                endLatLng = e.latlng;
                rulerPathElement.instance.setLatLngs([startLatLng, endLatLng]);
                rulerTempBuilderMarkerCreate(context.map, rulerPathElement.instance);
            });

            context.map.on('mouseup', () => {
                L.DomUtil.removeClass(context.map.getContainer(), 'leaflet-crosshair');
                context.map.off('mousemove');
                context.map.off('mousedown');
                context.map.off('mouseup');
                context.map.off('update');
                context.map.dragging.enable();

                const newRuler: Ruler = {
                    id: id,
                    startLatLng: startLatLng,
                    endLatLng: endLatLng,
                    options: rulerPathElement.instance.options,
                };
                rulerTempBuilderMarkerRemove();
                props.onCreateRuler(newRuler);
            });
        });

        rulerPathElement.instance.on('remove', () => {
            context.map.removeLayer(rulerPathElement.instance);
        });
    });

    return rulerPathElement;
};

const useRulerBuilder = createElementHook<L.Polyline, RulerBuilderProps, LeafletContextInterface>(createRulerBuilder);

const RulerBuilder = (props: RulerBuilderProps) => {
    const context = useLeafletContext();
    const rulerBuilder = useRulerBuilder(props, context);
    useLayerLifecycle(rulerBuilder.current, context);
    return null;
};

export default RulerBuilder;
