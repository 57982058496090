import { LayersControl, TileLayer as LeafletTileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import Constants from '../../../constants';
import { BaseMap } from '../../../store/Map/Mobile/model';
import { selectBaseMap } from '../../../store/Map/Mobile/selectors';

const MobileBasemap = () => {
    const selectedBaseMap = useSelector(selectBaseMap);

    return (
        <LayersControl position="bottomright">
            <LayersControl.BaseLayer name="Open Elevation" checked={selectedBaseMap === BaseMap.OSM_Elevation}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={17} url={Constants.MAPS.OSM_TOPO} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="OpenStreetMaps" checked={selectedBaseMap === BaseMap.OSM_Street}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={19} url={Constants.MAPS.OSM_STREET} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Satellite" checked={selectedBaseMap === BaseMap.GSM_Satellite}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.GOOGLE_SATELLITE} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Hybrid" checked={selectedBaseMap === BaseMap.GSM_Hybrid}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.GOOGLE_HYBRID} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Street" checked={selectedBaseMap === BaseMap.GSM_Street}>
                <LeafletTileLayer maxZoom={28} maxNativeZoom={20} url={Constants.MAPS.GOOGLE_STREET} />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Dark Blank" checked={selectedBaseMap === BaseMap.Empty_Black}>
                <LeafletTileLayer url="/assets/basemap/black-pixel.png" />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="White Blank" checked={selectedBaseMap === BaseMap.Empty_White}>
                <LeafletTileLayer url="/assets/basemap/white-pixel.png" />
            </LayersControl.BaseLayer>
        </LayersControl>
    );
};

export default MobileBasemap;
