import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import { Col, Row } from 'reactstrap';
import ApiAnalytics from '../../../../api/api-analytics';
import { AnalyticsAction, SatelliteProvider } from '../../../../api/model';
import GeoUtil from '../../../../lib/geo-util';
import SatelliteUtil from '../../../../lib/satellite-util';
import { actionSentinelCloseDownload } from '../../../../store/Map/Sentinel/actions';
import {
    selectIsSentinelDownloadOpen,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
} from '../../../../store/Map/Sentinel/selectors';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import DotsText from '../../../Shared/dots-text';
import Analytics from '../../../../lib/user-analytics';
import LoginRegisterDialog from '../../../Registration/login-register-dialog';
import styled from 'styled-components';
import { LoginModalMode } from '../../../Registration/login-enum';
import DrawerHint from '../../drawer-hint';
import { SentinelImageDimension } from '../../../../api/model';
import { useDispatch, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import { DownloadDetails } from './download-details';
import { LatLngBounds } from 'leaflet';
import SoarModal, { StyledModalBody } from '../../../Shared/soar-modal';

const Download = () => {
    const [sentinelRequestError, setSentinelRequestError] = useState<boolean>(false);
    const [previewImageFailed, setPreviewImageFailed] = useState<boolean>(false);

    const bbox = useSelector(selectSentinelSelectedAOI);
    const sentinelFeature = useSelector(selectSentinelSelectedFeature);
    const isSentinelDownloadOpen = useSelector(selectIsSentinelDownloadOpen);
    const loggedIn = useSelector(selectLoggedIn);

    const dispatch = useDispatch();
    const closeDownload = () => dispatch(actionSentinelCloseDownload());

    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [areaKmSqr, setAreaKmSqr] = useState<number>(0);
    const [availableImageDimensions, setAvailableImageDimensions] = useState<SentinelImageDimension[]>();
    const [selectedImageDimension, setSelectedImageDimension] = useState<SentinelImageDimension>();
    const [showLoginModal, setShowLoginModal] = useState(false);

    const handleAvailableImageDimensions = useCallback(
        (bounds: LatLngBounds) => {
            if (sentinelFeature?.layerKey === 'LANDSAT_8') {
                const landsatDimension = SatelliteUtil.landsatQualityEvaluation(bounds);
                if (landsatDimension) {
                    setSelectedImageDimension(landsatDimension[0]);
                }
            } else {
                const sentinelDimensions = SatelliteUtil.sentinelQualityEvaluation(bounds);
                const availableDimensionsList = sentinelDimensions.filter((dimension) => dimension.isAvailable);
                setAvailableImageDimensions(availableDimensionsList);
                setSelectedImageDimension(availableDimensionsList[0]);
            }
        },
        [sentinelFeature?.layerKey]
    );
    useEffect(() => {
        if (!sentinelFeature) {
            return;
        }
        const bounds = bbox ? bbox : sentinelFeature.bbox;
        const area = GeoUtil.area(bounds);
        const areaKmSqr = area / 1000 / 1000;
        handleAvailableImageDimensions(bounds);

        setAreaKmSqr(areaKmSqr);
        setIsDownloading(false);
    }, [bbox, handleAvailableImageDimensions, sentinelFeature]);

    useEffect(() => {
        if (loggedIn) {
            setShowLoginModal(false);
        }
    }, [loggedIn]);

    const handleClose = () => {
        setIsDownloading(false);
        setSelectedImageDimension(undefined); // Reset selectedImageDimension
        Analytics.Event('Satellite - Sentinel', 'Closed Download');
        closeDownload();
    };

    const handleSelectQuality = (quality: string) => {
        Analytics.Event('Satellite - Sentinel', 'Change Quality', quality);
        if (!availableImageDimensions) {
            return;
        }
        const selectedQualityIndex = availableImageDimensions.findIndex((dimensions) => dimensions.quality === quality);
        setSelectedImageDimension(availableImageDimensions[selectedQualityIndex]);
    };

    const handleClickDownloadFile = (url: string) => {
        setSentinelRequestError(false);
        if (sentinelFeature) {
            Analytics.Event('Satellite - Sentinel', 'Download File', sentinelFeature.bbox.toBBoxString());

            ApiAnalytics.postAnalyticsSatellite(
                url,
                SatelliteProvider.SINERGISE,
                sentinelFeature.satelliteAnalytics,
                AnalyticsAction.DOWNLOAD
            );
            const sensor = sentinelFeature.satellite.replace(' ', '');
            const layer = sentinelFeature.layer;
            const date = sentinelFeature.date;
            const fileName =
                sensor !== 'Landsat8' ? `Soar-${sensor}_${layer}_${date}.tif` : `Soar-${layer}_${date}.tif`;

            setIsDownloading(true);

            let downloadUrl = url;

            if (selectedImageDimension && sentinelFeature) {
                const newUrl = new URL(url);
                newUrl.searchParams.set('resX', `${selectedImageDimension.resolution}m`);
                newUrl.searchParams.set('resY', `${selectedImageDimension.resolution}m`);
                downloadUrl = newUrl.toString();
            }

            Axios({
                url: downloadUrl,
                method: 'GET',
                responseType: 'blob', // important
            })
                .then((response) => {
                    FileSaver.saveAs(new Blob([response.data]), fileName);
                    setIsDownloading(false);
                    closeDownload();
                })
                .catch((err) => {
                    setSentinelRequestError(true);
                    setIsDownloading(false);
                    SatelliteUtil.sendSentinelSlackMessage(
                        `ERROR: ${sentinelFeature.satellite.toUpperCase()} ${sentinelFeature.layer} - FAILED DOWNLOAD`,
                        err.toString(),
                        url
                    );
                });
        }
    };

    if (!isSentinelDownloadOpen) {
        return <React.Fragment />;
    }

    if (!sentinelFeature) {
        return <React.Fragment />;
    }

    const downloadUrl = sentinelFeature.downloadUrl;

    return (
        <React.Fragment>
            <LoginRegisterDialog
                initialMode={LoginModalMode.LOGIN}
                isOpen={showLoginModal}
                onClose={() => setShowLoginModal(false)}
            />
            <SoarModal
                title={
                    <Logo
                        src={
                            sentinelFeature.layer.includes('LANDSAT')
                                ? '/assets/floating-drawer-satellite-icons/satellite-nasa-logo.png'
                                : '/assets/floating-drawer-satellite-icons/satellite-sentinel-logo.png'
                        }
                    />
                }
                isOpen={isSentinelDownloadOpen}
                toggle={handleClose}
                width="600px"
            >
                <StyledModalBody>
                    <Row>
                        <Col xs={5}>
                            <Title>Preview</Title>
                            <PreviewImage
                                alt="Sentinel Preview"
                                src={
                                    previewImageFailed
                                        ? '/assets/image-unavailable/preview_unavailable.png'
                                        : sentinelFeature.previewUrl
                                }
                                onError={() => setPreviewImageFailed(true)}
                            />
                        </Col>

                        {selectedImageDimension && sentinelFeature && (
                            <Col xs={7}>
                                {loggedIn ? (
                                    <DownloadDetails
                                        sentinelFeature={sentinelFeature}
                                        area={areaKmSqr}
                                        handleSelectQuality={handleSelectQuality}
                                        sentinelImageDimensions={selectedImageDimension}
                                        availableImageDimensions={availableImageDimensions}
                                    />
                                ) : (
                                    <React.Fragment>
                                        <LoginMessage>
                                            Please sign in or create your free account to continue
                                        </LoginMessage>
                                        <LoginButton onClick={() => setShowLoginModal(true)}>
                                            JOIN / SIGN IN
                                        </LoginButton>
                                    </React.Fragment>
                                )}
                            </Col>
                        )}
                    </Row>

                    {selectedImageDimension && selectedImageDimension.isAvailable === false && loggedIn && (
                        <DownloadErrorContainer>
                            <DrawerHint error>
                                The selected image quality is too large for this area. Please choose a smaller Area of
                                Interest or reduce the quality
                            </DrawerHint>
                        </DownloadErrorContainer>
                    )}

                    {sentinelRequestError && (
                        <DownloadErrorContainer>
                            <DrawerHint error>
                                We are having trouble downloading this image, please try again in a moment.
                            </DrawerHint>
                        </DownloadErrorContainer>
                    )}

                    {loggedIn && selectedImageDimension && (
                        <DownloadButtonContainer>
                            {isDownloading ? (
                                <DownloadButton disabled>
                                    <DotsText text="WORKING ON IT" />
                                </DownloadButton>
                            ) : (
                                <DownloadButton
                                    disabled={!selectedImageDimension.isAvailable}
                                    onClick={() => handleClickDownloadFile(downloadUrl)}
                                >
                                    DOWNLOAD
                                </DownloadButton>
                            )}
                        </DownloadButtonContainer>
                    )}
                </StyledModalBody>
            </SoarModal>
        </React.Fragment>
    );
};

export default Download;

const Logo = styled.img`
    max-width: 148px;
    max-height: 34px;
    margin-top: -3px;
`;

const Title = styled.div`
    color: rgb(176, 176, 176);
    font-size: 2rem;
    width: 113px;
    margin: 0 auto;
    width: 100%;
`;

const PreviewImage = styled.img`
    height: auto;
    width: 100%;
    border-radius: 8px;
`;

const DownloadButtonContainer = styled.div`
    display: block;
    width: 100%;
    margin: 0 auto;
`;

const DownloadButton = styled.button`
    margin: 0 auto;
    display: block;
    margin-top: 25px;
    margin-bottom: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    -webkit-text-fill-color: black;
    border-radius: 4px;
    background-color: gray;
    width: -webkit-fill-available;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
    }
`;

const LoginButton = styled.button`
    margin: 0 auto;
    display: block;
    margin-top: 25px;
    margin-bottom: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    -webkit-text-fill-color: black;
    border-radius: 4px;
    background-color: gray;

    :not(:disabled) {
        cursor: pointer;
        background-color: #eed926 !important;
    }
`;

const LoginMessage = styled.p`
    color: white;
    margin: 60px 20px 20px 20px;
    text-align: center;
`;

const DownloadErrorContainer = styled.div`
    padding-top: 20px;
`;
