import {
    LeafletContextInterface,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createLayerHook,
    extendContext,
} from '@react-leaflet/core';
import L from 'leaflet';
import Marker from './marker';

interface MarkerAnnotationProps extends L.MarkerOptions {
    marker: Marker;
    onUpdateMarker: (marker: Marker) => void;
    editMode: boolean;
    children?: React.ReactNode;
    icon: L.Icon;
}

const createMarkerElement = (props: MarkerAnnotationProps, context: LeafletContextInterface) => {
    const marker = new L.Marker(props.marker.position, { draggable: props.editMode, ...props.marker.options });
    const markerElement = createElementObject<L.Marker, MarkerAnnotationProps>(
        marker,
        extendContext(context, { overlayContainer: marker })
    );

    markerElement.instance.on('dragend', () => {
        props.onUpdateMarker({ ...props.marker, position: markerElement.instance.getLatLng() });
    });

    markerElement.instance.dragging?.enable();

    return markerElement;
};

const updateMarkerElement = (instance: L.Marker, props: MarkerAnnotationProps, _: MarkerAnnotationProps) => {
    if (props.icon) {
        instance.setIcon(props.icon);
    }
};

const useMarkerElement = createElementHook<L.Marker, MarkerAnnotationProps, LeafletContextInterface>(
    createMarkerElement,
    updateMarkerElement
);

const useMarker = createLayerHook(useMarkerElement);
const MarkerAnnotation = createContainerComponent(useMarker);
export default MarkerAnnotation;
