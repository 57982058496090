import { useState } from 'react';
import Ruler from './ruler';
import React from 'react';
import AnnotationControl from '../annotation-control';
import RulerBuilder from './ruler-builder';
import RulerAnnotation from './ruler-annotation';
import RulerAnnotationPopup from './ruler-annotation-popup';

interface RulerControlProps {
    hideControl: boolean;
}

const RulerControl = (props: RulerControlProps) => {
    const [isCreatingActive, setIsCreatingActive] = useState(false);
    const [rulerAnnotations, setRulerAnnotations] = useState<Ruler[]>([]);

    return (
        <React.Fragment>
            <AnnotationControl
                layout="middle"
                hideControl={props.hideControl}
                imageUrl="/assets/annotations/icon-ruler.svg"
                name="Ruler"
                onClick={() => setIsCreatingActive(true)}
            />
            {isCreatingActive && (
                <RulerBuilder
                    onCreateRuler={(ruler) => {
                        setIsCreatingActive(false);
                        setRulerAnnotations((current) => [...current, ruler]);
                    }}
                />
            )}

            {rulerAnnotations.map((ruler) => {
                return (
                    <RulerAnnotation key={ruler.id} ruler={ruler}>
                        <RulerAnnotationPopup
                            ruler={ruler}
                            onDeleteRuler={() => {
                                setRulerAnnotations((current) => {
                                    return current.filter((r) => r.id !== ruler.id);
                                });
                            }}
                            onUpdateRuler={(rulerOptions) => {
                                setRulerAnnotations((current) => {
                                    const index = current.findIndex((r) => r.id === ruler.id);
                                    const newState = [...current];
                                    newState[index].options = rulerOptions;
                                    return newState;
                                });
                            }}
                        />
                    </RulerAnnotation>
                );
            })}
        </React.Fragment>
    );
};

export default RulerControl;
