import {
    LeafletContextInterface,
    createContainerComponent,
    createElementHook,
    createElementObject,
    createLayerHook,
    extendContext,
} from '@react-leaflet/core';
import L, { LeafletMouseEvent } from 'leaflet';
import Circle, { HOVER_WEIGHT } from './circle';
import { calculateTopmostLatLng } from './circle-annotation-util';
import { createCircleEditElement } from './circle-edit-element';
import { createGhostCircleElement } from './circle-ghost-element';

interface CircleAnnotationProps extends L.CircleOptions {
    circle: Circle;
    onUpdateCircle: (circle: Circle) => void;
    editMode: boolean;
    children?: React.ReactNode;
}

export const ANNOTATION_TOOL_UPDATE_CHANGED_EVENT = 'annotationTools:circle:update';

const createCircleElement = (props: CircleAnnotationProps, context: LeafletContextInterface) => {
    const circle = new L.Circle(props.circle.center, props.circle.radius, props.circle.options);
    const circleElement = createElementObject<L.Circle, CircleAnnotationProps>(
        circle,
        extendContext(context, { overlayContainer: circle })
    );

    const handleUpdateCircle = () => {
        props.onUpdateCircle({
            ...props.circle,
            center: circleElement.instance.getLatLng(),
            radius: circleElement.instance.getRadius(),
            options: {
                ...props.circle.options,
                radius: circleElement.instance.getRadius(),
                color: circleElement.instance.options.color,
            },
        });
        const topmostLatLng = calculateTopmostLatLng(
            circleElement.instance.getLatLng(),
            circleElement.instance.getRadius()
        );
        circleElement.instance.fireEvent(ANNOTATION_TOOL_UPDATE_CHANGED_EVENT, {
            latlng: topmostLatLng,
        });
    };

    circleElement.instance.on('drag', (e: LeafletMouseEvent) => {
        circleElement.instance.setLatLng(e.latlng);
        handleUpdateCircle();
    });

    circleElement.instance.on('resize', (e: LeafletMouseEvent) => {
        circleElement.instance.setRadius(circleElement.instance.getLatLng().distanceTo(e.latlng));
        handleUpdateCircle();
    });

    const onClickMap = (e: L.LeafletMouseEvent) => {
        // Only deselect when a DIV (the map) is clicked.
        // This prevents deselection when the click event is triggered by the edit elements.
        // There is probably a better way to do this.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const target = (e.originalEvent.target as any).nodeName;
        if (target === 'DIV') {
            onDeselect();
        }
    };

    const onDeselect = () => {
        context.map.addLayer(ghostElement.instance);
        context.map.removeLayer(editElement.instance);
        context.map.off('click', onDeselect);
        context.map.off('click', onClickMap);
        circleElement.instance.setStyle({ ...circleElement.instance.options, weight: HOVER_WEIGHT });
        circleElement.instance.closePopup();
    };

    const onSelect = () => {
        context.map.on('mousedown', onClickMap);
        circleElement.instance.openPopup();
        context.map.removeLayer(ghostElement.instance);
        context.map.addLayer(editElement.instance);
    };

    const ghostElement = createGhostCircleElement({ focusElement: circleElement }, context);

    const editElement = createCircleEditElement(
        { circleElement: ghostElement, color: circleElement.instance.options.color },
        context
    );

    circleElement.instance.on('click', onSelect);

    circleElement.instance.on('add', () => {
        context.map.addLayer(ghostElement.instance);
    });

    circleElement.instance.on('remove', () => {
        context.map.removeLayer(ghostElement.instance);
        context.map.removeLayer(editElement.instance);
    });

    return circleElement;
};

const updateCircleElement = (instance: L.Circle, props: CircleAnnotationProps, _: CircleAnnotationProps) => {
    instance.setStyle({ ...instance.options, ...props.circle.options });

    if (!props.circle.center.equals(instance.getLatLng())) {
        props.onUpdateCircle({
            ...props.circle,
            center: instance.getLatLng(),
            radius: instance.getRadius(),
            options: {
                ...props.circle.options,
                radius: instance.getRadius(),
                color: instance.options.color,
            },
        });
    }
};

const useCircleElement = createElementHook<L.Circle, CircleAnnotationProps, LeafletContextInterface>(
    createCircleElement,
    updateCircleElement
);

const useCircle = createLayerHook(useCircleElement);
const CircleAnnotation = createContainerComponent(useCircle);
export default CircleAnnotation;
