interface LineStylePreviewProps {
    width: number;
    height: number;
    lineStyle: L.PolylineOptions;
}

const LineStylePreview = (props: LineStylePreviewProps) => {
    const yAxis = () => {
        if (!props.lineStyle.weight) {
            return 0;
        } else {
            return Math.ceil(props.height / 2);
        }
    };

    return (
        <svg
            viewBox={`0 0 ${props.width}px ${props.height}px`}
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            stroke="white"
        >
            <line
                x1="0"
                y1={yAxis()}
                x2={props.width}
                y2={yAxis()}
                stroke="white"
                stroke-dasharray={props.lineStyle.dashArray ? props.lineStyle.dashArray.toString() : ''}
                stroke-width={props.lineStyle.weight}
            />
        </svg>
    );
};

export default LineStylePreview;
