import ColorSwatch from '../Popup/color-swatch';
import OpacitySlider from '../Popup/opacity-slider';
import AnnotationPopup from '../annotation-popup';
import { PolygonPath } from './path';

interface PolygonPopupProps {
    polygon: PolygonPath;
    onDelete: () => void;
    onUpdate: (options: L.PolylineOptions) => void;
}

const PolygonPopup = (props: PolygonPopupProps) => {
    return (
        <AnnotationPopup
            title="Path"
            onDelete={props.onDelete}
            eventHandlers={{
                add: (e: L.LeafletEvent) => {
                    const latlngs = props.polygon.positions;

                    // Place at the top of the annotation
                    const sourceTarget = e.sourceTarget;
                    const top = latlngs.reduce((prev, current) => {
                        return prev.lat > current.lat ? prev : current;
                    });
                    sourceTarget._latlng = top;
                },
            }}
        >
            <ColorSwatch
                selectedColor={props.polygon.options.color || '#3388ff'}
                onSelectColor={(color) => {
                    props.onUpdate({
                        ...props.polygon.options,
                        color: color,
                        fillColor: color,
                    });
                }}
            />

            <OpacitySlider
                color={props.polygon.options.color || '#3388ff'}
                opacity={props.polygon.options.fillOpacity || 0.2}
                onSelectOpacity={(opacity) => {
                    props.onUpdate({
                        ...props.polygon.options,
                        fill: opacity === 0 ? false : true,
                        fillOpacity: opacity,
                    });
                }}
            />
        </AnnotationPopup>
    );
};

export default PolygonPopup;
