import { LatLng } from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';

export const CoordinateSvg = (id: string, latlng: LatLng) => {
    // The styles are what we currently have implemented and have only been adjusted slightly but we can change it however easily enough
    const svgHeight = 19;
    return ReactDOMServer.renderToString(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`-82 0 190 ${svgHeight}`}
            id={`latlng-tool-${id}`}
            width="280px"
            height="28px"
        >
            <SvgContainer x="-82" width="190" height={svgHeight} id={`latlng-tool-background-${id}`} />
            <SvgText text-anchor="middle" id={`latlng-tool-element-${id}`} x="-65" y={`${(svgHeight * 7) / 10}`}>
                {`Lat: ${latlng.lat.toFixed(5)} Long: ${latlng.lng.toFixed(5)}`}
            </SvgText>
            <SvgCornerTriangle x1={100 - 5} y1={svgHeight} x2={100 + svgHeight / 2} y2={svgHeight / 2 - 5} />
            <defs>
                <SvgPolygon
                    id="corner-triangle"
                    points={`${svgHeight / 2} 0, ${svgHeight / 2} ${svgHeight / 2}, 0 ${svgHeight / 2}`}
                />
            </defs>
            <use x="100" y={`${svgHeight / 2}`} xlinkHref="#corner-triangle" />
        </svg>
    );
};

const SvgContainer = styled.rect`
    fill: #212529;
    stroke: #eed926;
    stroke-width: 1;
`;

const SvgText = styled.text`
    font-size: 0.8rem;
    font-family: 'Exo', sans-serif;
    font-weight: 500;
    fill: #eed926;
`;

const SvgCornerTriangle = styled.line`
    stroke-width: 2;
    stroke: #eed926;
`;

const SvgPolygon = styled.polygon`
    fill: #eed926;
`;
