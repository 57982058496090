import L from 'leaflet';

import { createElementObject, LeafletContextInterface } from '@react-leaflet/core';

interface RulerFocusElementProps extends L.PolylineOptions {
    rulerElement: Readonly<{ instance: L.Polyline; context: Readonly<{ map: L.Map }> }>;
}

export const createRulerFocusElement = (props: RulerFocusElementProps, context: LeafletContextInterface) => {
    const originalWeight = props.rulerElement.instance.options.weight || 3;
    const rulerFocusOptions: L.PolylineOptions = {
        ...props.rulerElement.instance.options,
        weight: originalWeight + 3,
    };

    const startLatLng = props.rulerElement.instance.getLatLngs()[0] as L.LatLng;
    const endLatLng = props.rulerElement.instance.getLatLngs()[1] as L.LatLng;

    const rulerFocus = new L.Polyline([startLatLng, endLatLng], rulerFocusOptions);
    const rulerFocusElement = createElementObject<L.Polyline, RulerFocusElementProps>(rulerFocus, context);

    rulerFocusElement.instance.on('click', (e: L.LeafletMouseEvent) =>
        props.rulerElement.instance.fireEvent('click', e)
    );

    rulerFocusElement.instance.on('mouseover', () => {
        rulerFocusElement.instance.setStyle({ color: props.rulerElement.instance.options.color });
    });

    props.rulerElement.instance.on('update', () => {
        const startLatLng = props.rulerElement.instance.getLatLngs()[0] as L.LatLng;
        const endLatLng = props.rulerElement.instance.getLatLngs()[1] as L.LatLng;
        rulerFocusElement.instance.setLatLngs([startLatLng, endLatLng]);
    });

    return rulerFocusElement;
};
